import ActionsApi from '@/services/api/Actions'
import { transformCountValues } from '@/utils/helpers'
import { getDateRangeOpt } from '@/constants'

const _cloneDeep = require('lodash/cloneDeep')

// initial state
const state = () => ({
  status: '',
  inProgressManualActions: [],
  inProgressAutomatedActions: [],
  selectedDueDateRange: getDateRangeOpt('ever'),
})

// getters
const getters = {
  inProgressManualActions: (state) => state.inProgressManualActions,
  inProgressAutomatedActions: (state) => state.inProgressAutomatedActions,
  actionsLoadingStatus: (state) => state.status === 'loading',
  selectedDueDateRange: (state) => state.selectedDueDateRange,
}

// actions
const actions = {
  async fetchInProgressActions({ commit }, options = {}) {
    return new Promise((resolve, reject) => {
      commit('request')
      ActionsApi.getActions(options)
        .then((resp) => {
          const actionsWithProspects = resp.data?.filter(
            (action) => action.prospect
          )

          if (
            options.showAutomated ||
            options.showOutbox ||
            options.showScheduled ||
            options.showMergeTagIssues ||
            options.showThrottled ||
            options.showFailed
          ) {
            commit('setInProgressAutomatedActions', actionsWithProspects)
          } else if (options.showManual) {
            commit('setInProgressManualActions', actionsWithProspects)
          }

          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          // ignore error if the req. was aborted
          if (!err.aborted) {
            commit('error')
            reject(err)
          }
        })
    })
  },
}

// mutations
const mutations = {
  setMailingSummaryOnActions(state, payload) {
    const { activityList, actionsArrKey } = payload
    state[actionsArrKey] = state[actionsArrKey].map((action) => {
      let propsectActivity = activityList.find(
        (entry) => entry.x === action.prospect.id
      )
      propsectActivity = transformCountValues(propsectActivity)
      if (propsectActivity) {
        return {
          ...action,
          activity: {
            openCount: propsectActivity['Mailings.openCountDistinct'],
            clickCount: propsectActivity['Mailings.clickCount'],
            replyCount: propsectActivity['Mailings.replyCount'],
          },
        }
      } else return { ...action, activity: null }
    })
  },
  setInProgressManualActions(state, actions) {
    state.inProgressManualActions = actions
  },
  setInProgressAutomatedActions(state, actions) {
    state.inProgressAutomatedActions = actions
  },
  request(state) {
    state.status = 'loading'
  },
  success(state) {
    state.status = 'success'
  },
  error(state) {
    state.status = 'error'
  },
  setSelectedDueDateRange(state, range) {
    state.selectedDueDateRange = _cloneDeep(range)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
