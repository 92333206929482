/**
 * Helper to abstract away try/catch logic from consuming component
 * will return { response, error } object.
 *
 * @param {function} fn
 * @param {*} args
 * @return {object}
 */
export const withAsync = async (fn, ...args) => {
  try {
    const response = await fn(...args)
    return {
      response,
      error: null,
    }
  } catch (error) {
    return {
      response: null,
      error,
    }
  }
}
