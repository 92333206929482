<template>
  <div :class="oneLine ? 'd-flex align-center' : ''">
    <!-- Icon + Label -->
    <div class="d-flex align-center">
      <v-icon small class="mr-2">
        {{ icon }}
      </v-icon>
      <div class="text-caption grey--text font-weight-bold text--darken-1">
        <slot name="label" v-if="$slots.label" />
        <div v-else>{{ label }}:</div>
      </div>
      <v-spacer />
      <v-btn
        icon
        x-small
        v-visible="allowEdit && hover && hasValue"
        @click="editValue"
      >
        <v-icon size="15">mdi-pencil</v-icon>
      </v-btn>
    </div>

    <!-- Editing state -->
    <div v-if="isEditing" class="px-4">
      <slot name="editState" v-if="$slots.editState" />
      <edit-with-sugggestions
        v-else-if="!!showSuggestions"
        v-model="tempValue"
        class="my-2"
        :account="showSuggestions === 'account'"
        :placeholder="showSuggestions === 'account' ? 'Account' : ''"
        @suggestion-selected="$emit('suggestion-selected', $event)"
      />
      <div
        v-else
        class="grey--text text--darken-4 my-2"
        :class="{ 'extra-dense-fields': dataType !== 'text' }"
      >
        <component
          v-model="tempValue"
          dense
          outlined
          autofocus
          hide-details
          rows="3"
          item-text="text"
          item-value="value"
          :type="dataType === 'integer' ? 'number' : 'text'"
          :hideTime="dataType === 'date'"
          :menu-props="{ offsetY: true }"
          :placeholder="label"
          :is="editComponent"
          :items="editOptions"
          :multiple="dataType === 'set'"
          :noValidate="!isRequired"
          @keydown.enter="dataType !== 'text' ? saveNewValue() : () => {}"
        >
          <template v-if="id === 'prospect-status'" v-slot:item="{ item }">
            <div class="d-flex align-center w-100">
              <div>{{ item.text || item.name }}</div>
              <v-spacer />
              <!-- Triggers -->
              <v-tooltip v-if="!!item.triggers?.length" bottom open-delay="50">
                <template v-slot:activator="{ on }">
                  <div v-on="on" class="d-flex align-center ml-2">
                    <span class="grey--text text-caption">{{
                      item.triggers.length
                    }}</span>
                    <v-icon size="20" color="grey"> mdi-lightning-bolt </v-icon>
                  </div>
                </template>
                This prospect status has following triggers:
                <ul>
                  <li v-for="trigger in item.triggers" :key="trigger.id">
                    {{ getDynamicTriggerLabel(trigger) }}
                  </li>
                </ul>
              </v-tooltip>
            </div>
          </template>
        </component>
      </div>
      <div class="d-flex align-center justify-end w-100">
        <v-btn
          x-small
          color="grey lighten-2"
          class="px-2 mr-2"
          @click="clearEdit"
        >
          Cancel
        </v-btn>
        <v-btn
          x-small
          color="primary"
          class="white--text px-2"
          @click="saveNewValue"
        >
          <v-progress-circular
            v-if="saveLoader"
            indeterminate
            color="white"
            width="2"
            size="15"
          />
          <div v-else>Save</div>
        </v-btn>
      </div>
    </div>

    <!-- Value -->
    <div v-else-if="showValue" class="ml-2" :class="{ 'ml-6': !oneLine }">
      <slot name="value" v-if="$slots.value" />
      <v-switch
        v-else-if="dataType === 'boolean'"
        v-model="tempValue"
        dense
        hide-details
        class="mt-1"
        :loading="saveLoader"
        @change="saveNewValue"
      />
      <div v-else-if="dataType === 'date'" class="grey--text text--darken-4">
        {{ formatToLocalDate(value) }}
      </div>
      <div
        v-else-if="dataType === 'datetime'"
        class="grey--text text--darken-4"
      >
        {{ convertToLocalTimeShort(value) }}
      </div>
      <a v-else-if="dataType === 'link'" target="_blank" :href="value">{{
        value
      }}</a>
      <div
        v-else
        v-text="valueToDisplay"
        class="grey--text text--darken-4"
        style="white-space: pre-wrap"
      ></div>
    </div>

    <div
      v-else
      class="ml-2"
      :class="{ 'cursor-pointer': hover, 'ml-5 mt-1': !oneLine }"
    >
      <div
        v-if="hover"
        class="px-1 d-flex align-center blue--text text-lighten-3"
      >
        <v-icon size="14" color="blue lighten-1">mdi-plus</v-icon>
        <span
          class="font-weight-medium text-caption"
          @click="allowEdit ? editValue() : $emit('add-value')"
        >
          Add value</span
        >
      </div>
      <div
        v-else
        class="font-italic font-weight-light ml-2 text-caption d-flex align-center"
      >
        {{ valuePlaceholder }}
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import { EventBus } from '@/services/event-bus'

import datesFormatter from '@/utils/formatters/dates'
import { getProspectFieldComponent, getDynamicTriggerLabel } from '@/constants'

const _isEmpty = require('lodash/isEmpty')
const _cloneDeep = require('lodash/cloneDeep')

export default {
  mixins: [datesFormatter],
  components: {
    VTextField: () => import('vuetify/lib/components/VTextField'),
    VTextArea: () => import('vuetify/lib/components/VTextarea'),
    VSelect: () => import('vuetify/lib/components/VSelect'),
    VSwitch: () => import('vuetify/lib/components/VSwitch'),
    DateTimePicker: () => import('@/components/utilities/DateTimePicker'),
    ManageDrivesStepper: () =>
      import('@/components/steppers/drives/ManageDrivesStepper'),
    EditWithSugggestions: () =>
      import('@/components/universal-search/EditWithSugggestions.vue'),
    URLInputField: () => import('@/components/utilities/URLInputField'),
  },
  props: {
    id: {
      default: null,
    },
    hover: {
      type: Boolean,
    },
    icon: {
      type: String,
    },
    label: {
      type: String,
    },
    showValue: {
      type: Boolean,
    },
    value: {
      default: null,
    },
    displayWithCommas: {
      type: Boolean,
    },
    valuePlaceholder: {
      type: String,
    },
    oneLine: {
      type: Boolean,
    },
    allowEdit: {
      type: Boolean,
    },
    disableInlineEdit: {
      type: Boolean,
    },
    editComponent: {
      type: String,
    },
    editOptions: {
      type: Array,
      default: () => [],
    },
    showSuggestions: {
      type: String,
      default: '',
    },
    isRequired: {
      type: Boolean,
    },
    dataType: {
      type: String,
    },
    fieldType: {
      type: String,
      // Example: "enum" or "boolean", etc.
    },
    saveCb: {
      type: Function, // A function that returns a promise (would probably be an api call)
      // Args: (key, newValue, type)
      // key: field key = Example: first_name
      // newValue: value to set = Example: "John"
      // type: field type for custom fields = Example: enum/set/string/text/date/datetime/boolean, etc.
      // Must return a promise
    },
  },

  data: () => ({
    isEditing: false,
    saveLoader: false,
    tempValue: null,
  }),

  watch: {
    value(val) {
      if (this.dataType === 'boolean') this.tempValue = val
    },
  },

  methods: {
    getDynamicTriggerLabel,
    getProspectFieldComponent,
    onValueSaved() {
      this.saveLoader = false
      this.isEditing = false
    },
    saveNewValue() {
      this.saveLoader = true
      if (this.saveCb)
        this.saveCb(this.id, this.tempValue, this.fieldType).finally(() => {
          this.onValueSaved()
          this.$emit('value-saved')
        })
      else this.$emit('save-clicked', this.tempValue)
    },
    editValue() {
      this.$emit('edit-clicked')
      if (this.disableInlineEdit) return
      this.isEditing = true
      this.tempValue =
        typeof this.value === 'object' ? _cloneDeep(this.value) : this.value
    },
    clearEdit() {
      this.saveLoader = false
      this.isEditing = false
      if (this.dataType !== 'boolean') this.tempValue = null
    },
  },

  computed: {
    hasValue() {
      return !_isEmpty(this.value)
    },
    valueToDisplay() {
      if (this.displayWithCommas)
        return (parseInt(this.value) || 0).toLocaleString()
      return this.value
    },
  },

  created() {
    EventBus.$on('clear-icon-label-edit', (id) => {
      if (this.id === id) this.clearEdit()
    })

    if (this.dataType === 'boolean') this.tempValue = this.value
  },
}
</script>
