import { ApiStatus } from '@/constants'
import { capitalizeFirstLetter } from '@/utils/helpers'

/**
 * Will generate computed properties for each api status
 * For example, if the reactivePropertyKey = 'fetchDataStatus'
 * then the returned object will be as follows
 *
 * {
 *   fetchDataStatusIdle: function() {
 *     return this['fetchDataStatus'] === 'IDLE'
 *   }
 *
 *   fetchDataStatusLoading: function() {
 *     return this['fetchDataStatus'] === 'LOADING'
 *   }
 *
 *   fetchDataStatusSuccess: function() {
 *     return this['fetchDataStatus'] === 'SUCCESS'
 *   }
 *
 *   fetchDataStatusError: function() {
 *     return this['fetchDataStatus'] === 'ERROR'
 *   }
 * }
 *
 * @important - reactivePropertyKey must be a reactive value
 *
 * @param {string[] | string} reactivePropertyKeys
 * @return {object}
 */
export const apiStatusComputedFactory = (reactivePropertyKeys = '') => {
  let computed = {}

  // If the argument passed is an array then assign it,
  // otherwise, wrap it in an array
  const properties = Array.isArray(reactivePropertyKeys)
    ? reactivePropertyKeys
    : [reactivePropertyKeys]

  for (const reactivePropertyKey of properties) {
    // Loop through API statuses
    // IDLE, LOADING, SUCCESS, ERROR
    for (const [key, value] of Object.entries(ApiStatus)) {
      // Normalise status key
      // IDLE -> Idle
      const normalisedStatus = capitalizeFirstLetter(key)

      // Add computed property
      computed[`${reactivePropertyKey}${normalisedStatus}`] = function() {
        return this[reactivePropertyKey] === value
      }
    }
  }

  return computed
}
