import Vue from 'vue'

const _isEmpty = require('lodash/isEmpty')

const initialState = {
  query: '',
  filters: [],
}

const state = Vue.observable(initialState)

export const getFiltersQuery = {
  get: function () {
    return state.query
  },
  set: function (newVal) {
    state.query = newVal
  },
}

export const getActiveFilters = {
  get: function () {
    return state.filters
  },
  set: function (newVal) {
    state.filters = newVal
  },
}

const mergeQueries = (queries, newQuery) => {
  const hasTwoParams = newQuery?.split('&').length === 2
  if (hasTwoParams) return [...queries, newQuery]

  const [newQueryParamName, newQueryParamValue] = newQuery?.split('=')
  let shouldPushNewQuery = true
  const newQueries = queries.map((query) => {
    const [queryParamName, queryParamValue] = query?.split('=')
    // Merge Case
    if (queryParamName === newQueryParamName) {
      shouldPushNewQuery = false
      return `${queryParamName}=${queryParamValue + ',' + newQueryParamValue}`
    }
    return query
  })
  // Add Case
  if (shouldPushNewQuery) newQueries.push(newQuery)

  return newQueries
}

export const generateFilterQuery = (filterList, filterByItems) => {
  let subQueries = []
  let customFieldFilters = {}

  filterList.forEach(({ filterBy, filterOperation, filterValue }) => {
    const filterItem = filterByItems[filterBy]
    if (filterItem) {
      if (filterItem.customField) {
        let customFieldQueryKeyValuePair =
          filterItem.allowedOpsQuery[filterOperation](filterValue)

        if (
          customFieldQueryKeyValuePair[
            Object.keys(customFieldQueryKeyValuePair)[0]
          ] ||
          filterItem.type === 'boolean'
        )
          customFieldFilters = {
            ...customFieldFilters,
            ...customFieldQueryKeyValuePair,
          }
      } else {
        const newSubquery =
          filterItem.allowedOpsQuery[filterOperation](filterValue)
        if (newSubquery) subQueries = mergeQueries(subQueries, newSubquery)
      }
    }
  })

  if (!_isEmpty(customFieldFilters))
    subQueries.push(`customFields=${JSON.stringify(customFieldFilters)}`)

  return subQueries.join('&')
}
