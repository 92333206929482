import { DateTime } from 'luxon'
import store from '../store/index'

export const DATE_TIME_FORMAT = 'yyyy-LL-dd HH:mm'
export const DATE_FORMAT = 'yyyy-LL-dd'
export const INBOX_FORMAT = 'LLL dd'
export const LONG_DATE_TIME_FORMAT = 'yyyy-LL-dd HH:mm:ss'
export const FRIENDLY_DATE_FORMAT = 'ccc, LLL dd, yyyy' // Tue, Sep 20, 2020

export const SHORT_DATE_TIME_FORMAT = 'L/dd/y h:mm a' // 9/18/20 11:00 AM
export const TIME_FORMAT = 'h:mm a' // 11:00 AM
const getUserSettingByKey = () => store?.getters['user/getSettingByKey']

export function getUserTimeZone() {
  return getUserSettingByKey?.()?.('timezone')
}

function isIsoDate(str) {
  if (!str || typeof str !== 'string') return false
  return str.includes('Z') || str.includes('T')
}

// Accepts: Luxon's DateTime object || String date time in format yyyy-LL-dd HH:mm
// Returns: Boolean true or false
export function isPast(date) {
  const luxonDateTimeObj = getLuxonObj(date)
  const now = DateTime.now()
  return luxonDateTimeObj.diff(now).toObject().milliseconds < 0
}

// Accepts: Luxon's DateTime object || String date time in format [yyyy-LL-dd HH:mm or ISO]
// Returns: Luxon's DateTime object
/* Provide a timezone to construct the date in provided timezone
(Note that this is not a conversion i.e. Date and time won't change
only zoneName will be set to the provided timezone) */
export function getLuxonObj(date, isTimeStamp = false, timezone = null) {
  if (!date) return DateTime.now()
  let luxonDateTimeObj, format

  // If date is already a Luxon DateTime object, return it
  if (date instanceof DateTime) return date

  if (isTimeStamp) luxonDateTimeObj = DateTime.fromMillis(date)
  else if (typeof date === 'string') {
    if (date.length === 19) format = LONG_DATE_TIME_FORMAT
    else if (date.length === 16) format = DATE_TIME_FORMAT
    else if (date.length === 10) format = DATE_FORMAT
    else format = DATE_TIME_FORMAT

    luxonDateTimeObj = isIsoDate(date)
      ? DateTime.fromISO(date)
      : DateTime.fromFormat(date, format)
  } else if (typeof date === 'object') luxonDateTimeObj = date

  if (timezone) {
    luxonDateTimeObj = luxonDateTimeObj.setZone(timezone, {
      keepLocalTime: true,
    })
  }
  return luxonDateTimeObj
}

// Accepts: Luxon's DateTime object || String date time in format [yyyy-LL-dd HH:mm or ISO]
// Returns: Luxon's DateTime object || String date time in format yyyy-LL-dd HH:mm
export function convertToUserTimeZone(
  date,
  returnString = null,
  returnObj = null,
  format = DATE_TIME_FORMAT
) {
  if (!date) return ''
  let luxonDateTimeObj = getLuxonObj(date)
  if (luxonDateTimeObj.zoneName !== getUserTimeZone())
    luxonDateTimeObj = luxonDateTimeObj.setZone(getUserTimeZone())
  if (
    (typeof date === 'string' && returnString == null && !returnObj) ||
    returnString
  )
    return luxonDateTimeObj.toFormat(format)
  else if (typeof date === 'object' || returnString === false || returnObj)
    return luxonDateTimeObj
}

export function convertToTimeZone(dateObj, timezone) {
  return dateObj.setZone(timezone)
}

export function convertUnixSecondsTimestampToUserTimeZone(timestamp) {
  return convertToUserTimeZone(DateTime.fromSeconds(timestamp))
}

// Accepts: Luxon's DateTime object || String date time in format 2023-04-21 23:45 || null
// Returns:
//  - Luxon's DateTime object if DateTime object provided or null is provided
//  - String date time in format 2023-04-21 23:45 if string date time is provided
export function getUserTimeZoneDate(date = null) {
  if (date) return convertToUserTimeZone(date)
  return convertToUserTimeZone(DateTime.now())
}

// Accepts: Luxon's DateTime object || String date time in format 2023-04-21 23:45
// Returns: Luxon's DateTime object || String date time in format 2023-04-21 23:45
export function convertToUTC(date, isTimeStamp = false) {
  if (!date) return ''

  let luxonDateTimeObj = getLuxonObj(date, isTimeStamp)

  if (luxonDateTimeObj.zoneName === 'UTC') return luxonDateTimeObj
  else luxonDateTimeObj = luxonDateTimeObj.setZone('utc')

  if (typeof date === 'string')
    return luxonDateTimeObj.toFormat(DATE_TIME_FORMAT)
  else return luxonDateTimeObj
}

export function dateTimeRangeInUTC(
  date1, // Luxon's DateTime object || null
  date2, // Luxon's DateTime object || null
  returnArray = false,
  useCurrentTime = false,
  timeformat = DATE_TIME_FORMAT
) {
  const start = date1
    ? convertToUTC(useCurrentTime ? date1 : date1.startOf('day')).toFormat(
        timeformat
      )
    : 'neginf'
  const end = date2
    ? convertToUTC(useCurrentTime ? date2 : date2.endOf('day')).toFormat(
        timeformat
      )
    : 'inf'
  if (returnArray) return [start, end]
  return `${start}..${end}`
}
export function dateTimeRange(
  date1, // Luxon's DateTime object || null
  date2, // Luxon's DateTime object || null
  returnArray = false,
  useCurrentTime = false,
  timeformat = DATE_TIME_FORMAT
) {
  const start = date1
    ? useCurrentTime
      ? date1
      : date1.startOf('day').toFormat(timeformat)
    : 'neginf'
  const end = date2
    ? useCurrentTime
      ? date2
      : date2.endOf('day').toFormat(timeformat)
    : 'inf'
  if (returnArray) return [start, end]
  return `${start}..${end}`
}

// Accepts: Timezone name e.g: "America/Denver"
// Returns: Time right now formatted in 11:00 AM
export function getCurrentTimeFromTimeZone(timeZone) {
  return DateTime.now()
    .setZone(timeZone || getUserTimeZone())
    .toFormat(TIME_FORMAT)
}

// Accepts: Luxon's DateTime object || String date time in format yyyy-LL-dd HH:mm
// Returns: a function which takes a date range/granularity e.g 'day'/'week'/'month'/'year' which further
// takes in 2 dates and return true or false based on if those dates are in same range/granularity
// Note both date1 and date2 input type should be same i.e. either luxon obj or string
export const isSame =
  (duration = 'day') =>
  (date1, date2) => {
    const lxDate1 = getLuxonObj(date1)
    const lxDate2 = getLuxonObj(date2)

    return (
      lxDate1.startOf(duration).toFormat(DATE_TIME_FORMAT) ===
      lxDate2.startOf(duration).toFormat(DATE_TIME_FORMAT)
    )
  }

export function isBefore(date1, date2) {
  const lxDate1 = getLuxonObj(date1)
  const lxDate2 = getLuxonObj(date2)
  return lxDate1 <= lxDate2
}

export function isAfter(date1, date2) {
  const lxDate1 = getLuxonObj(date1)
  const lxDate2 = getLuxonObj(date2)
  return lxDate1 > lxDate2
}

export function isToday(date) {
  const lxDate = getLuxonObj(date)
  const today = DateTime.now()
  return lxDate.diff(today, 'days').toObject().days === 0
}

export function isHoliday(date) {
  return date.weekday === 6 || date.weekday === 7
}

export function friendlyTimeDiff(date1, date2) {
  const diff = date1.diff(date2)
  const [mins, hours] = [
    Math.abs(diff.as('minutes')),
    Math.abs(diff.as('hours')),
  ]
  if (hours < 1) {
    return `${mins} mins`
  }
  return `${hours} hrs`
}
