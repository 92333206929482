import DispositionsApi from '@/services/api/Dispositions'

// initial state
const state = () => ({
  status: '',
  enabledDispositions: [],
  allDispositions: [],
  showArchived: false,
  showDeleted: false,
})

// getters
const getters = {
  allEnabledDispositions: (state) => state.enabledDispositions,
  allDispositions: (state) => state.allDispositions,
  loadingStatus: (state) => state.status === 'loading',
  showArchived: (state) => state.showArchived,
  showDeleted: (state) => state.showDeleted,
}

// actions
const actions = {
  async fetchEnabledDispositions({ commit, state }, options = {}) {
    return new Promise((resolve, reject) => {
      commit('request')

      // clear out existing records if beginning an append paginate
      if (options.append && options.page === 1) {
        commit('setEnabledDispositions', [])
      }

      DispositionsApi.getDispositions({
        ...options,
        showEnabled: true,
      })
        .then((resp) => {
          // append new results instead of replace for growing select lists
          if (options.append) {
            const appendedDispositions = [
              ...state.enabledDispositions,
              ...resp.data,
            ]
            commit('setEnabledDispositions', appendedDispositions)
          } else {
            commit('setEnabledDispositions', resp.data)
          }

          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          // ignore error if the req. was aborted
          if (!err.aborted) {
            commit('error')
            reject(err)
          }
        })
    })
  },

  async fetchAllDispositions({ commit, state }, options = {}) {
    return new Promise((resolve, reject) => {
      commit('request')

      // clear out existing records if beginning an append paginate
      if (options.append && options.page === 1) {
        commit('setEnabledDispositions', [])
      }

      DispositionsApi.getDispositions({
        ...options,
        showArchived: state.showArchived,
        showDeleted: state.showDeleted,
      })
        .then((resp) => {
          // append new results instead of replace for growing select lists
          if (options.append) {
            const appendedDispositions = [
              ...state.allDispositions,
              ...resp.data,
            ]
            commit('setAllDispositions', appendedDispositions)
          } else {
            commit('setAllDispositions', resp.data)
          }

          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          // ignore error if the req. was aborted
          if (!err.aborted) {
            commit('error')
            reject(err)
          }
        })
    })
  },
}

// mutations
const mutations = {
  setEnabledDispositions(state, enabledDispositions) {
    state.enabledDispositions = enabledDispositions
  },
  setAllDispositions(state, allDispositions) {
    state.allDispositions = allDispositions
  },
  setShowArchived(state, showArchived) {
    if (showArchived) state.showDeleted = false
    state.showArchived = showArchived
  },
  setShowDeleted(state, showDeleted) {
    if (showDeleted) state.showArchived = false
    state.showDeleted = showDeleted
  },
  request(state) {
    state.status = 'loading'
  },
  success(state) {
    state.status = 'success'
  },
  error(state) {
    state.status = 'error'
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
