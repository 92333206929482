import api from './Api'

const ENDPOINTS = {
  calendarEvents: (id = '') =>
    id ? `/calendarEvents/${id}` : '/calendarEvents',
  opts: {
    start: (date) => `start=${date}`,
    end: (date) => `end=${date}`,
    userIds: (ids) => `user_ids=${ids.join(',')}`,
    mailboxIds: (ids) => `mailbox_ids=${ids.joind(',')}`,
  },
}

export default {
  getCalendarEvents(options = {}) {
    let path = ENDPOINTS.calendarEvents()
    let pathParams = []

    const { start, end, userIds, mailboxIds } = options

    if (start) pathParams.push(ENDPOINTS.opts.start(start))
    if (end) pathParams.push(ENDPOINTS.opts.end(end))
    if (userIds?.length) pathParams.push(ENDPOINTS.opts.userIds(userIds))
    if (mailboxIds?.length)
      pathParams.push(ENDPOINTS.opts.mailboxIds(mailboxIds))

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.get(path, { abortKey: 'GET_CALENDAR_EVENTS' })
  },

  createCalendarEvent(payload) {
    return api.post(ENDPOINTS.calendarEvents(), payload)
  },

  editCalendarEvent(payload) {
    return api.put(ENDPOINTS.calendarEvents(payload.id), payload)
  },

  deleteCalendarEvent(id) {
    return api.delete(ENDPOINTS.calendarEvents(id))
  },
}
