import Vue from 'vue'

const initialState = {
  quickActionServiceActive: false,
}

const state = Vue.observable(initialState)

export const quickMenuServiceComputed = {
  quickActionServiceActive: {
    get: function () {
      return state.quickActionServiceActive
    },
    set: function (newVal) {
      state.quickActionServiceActive = newVal
    },
  },
}
