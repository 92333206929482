const DriveContainer = () =>
  import(
    /* webpackChunkName: 'DriveContainer' */ '@/views/drives/DriveContainer.vue'
  )
const DriveStepsTab = () =>
  import(
    /* webpackChunkName: 'DriveStepsTab' */ '@/views/drives/DriveStepsTab.vue'
  )
const DriveDispositionsTab = () =>
  import(
    /* webpackChunkName: 'DriveDispositionsTab' */ '@/views/drives/DriveDispositionsTab.vue'
  )
const DrivePeopleTab = () =>
  import(
    /* webpackChunkName: 'DrivePeopleTab' */ '@/views/drives/DrivePeopleTab.vue'
  )
const DriveAnalyticsTab = () =>
  import(
    /* webpackChunkName: 'DriveAnalyticsTab' */ '@/views/drives/DriveAnalyticsTab.vue'
  )
const DriveSettingsTab = () =>
  import(
    /* webpackChunkName: 'DriveSettingsTab' */ '@/views/drives/DriveAdvancedSettingsTab.vue'
  )

const createDriveRoutes = {
  component: DriveContainer,
  props: true,
  meta: {
    requiresAuth: true,
    showNav: true,
    navTitle: 'Sequence',
  },
  children: [
    {
      path: '',
      redirect: 'steps',
    },
    {
      path: 'steps',
      component: DriveStepsTab,
      meta: {
        requiresAuth: true,
        showNav: true,
        navTitle: 'Sequence',
      },
    },
    {
      path: 'dispositions',
      component: DriveDispositionsTab,
      meta: {
        requiresAuth: true,
        showNav: true,
        navTitle: 'Sequence Outcomes',
      },
    },
    {
      path: 'people',
      component: DrivePeopleTab,
      meta: {
        requiresAuth: true,
        showNav: true,
        navTitle: 'Create Sequence',
      },
    },
    {
      path: 'analytics',
      component: DriveAnalyticsTab,
      meta: {
        requiresAuth: true,
        showNav: true,
        navTitle: 'Sequence Analytics',
      },
    },
    {
      path: 'settings',
      component: DriveSettingsTab,
      meta: {
        requiresAuth: true,
        showNav: true,
        navTitle: 'Sequence Settings',
      },
    },
  ],
}

export default [
  {
    path: '/drive/edit/:id?',
    ...createDriveRoutes,
  },
  {
    path: '/drive/clone/:id?',
    ...createDriveRoutes,
  },
  {
    path: '/drive/new',
    ...createDriveRoutes,
  },
]
