import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
const Error = () => import(/* webpackChunkName: 'Error' */ '../views/Error.vue')
const Dashboard = () =>
  import(/* webpackChunkName: 'Dashboard' */ '../views/Dashboard.vue')
const Dial = () => import(/* webpackChunkName: 'Dial' */ '../views/Dial.vue')
const Login = () => import(/* webpackChunkName: 'Login' */ '../views/Login.vue')
const ConnectPipedrive = () =>
  import(
    /* webpackChunkName: 'ConnectPipedrive' */ '../views/ConnectPipedrive.vue'
  )
const Actions = () =>
  import(/* webpackChunkName: 'Actions' */ '../views/actions/Actions.vue')
const ManualActions = () =>
  import(
    /* webpackChunkName: 'ManualActions' */ '../views/actions/ManualActions.vue'
  )
const Outbox = () =>
  import(/* webpackChunkName: 'Outbox' */ '../views/actions/Outbox.vue')
const Contacts = () =>
  import(/* webpackChunkName: 'Contacts' */ '../views/Contacts.vue')
const Accounts = () =>
  import(/* webpackChunkName: 'Accounts' */ '../views/Accounts.vue')
const AccountView = () =>
  import(/* webpackChunkName: 'AccountView' */ '../views/AccountView.vue')
const Analytics = () =>
  import(/* webpackChunkName: 'Analytics' */ '../views/Analytics.vue')
const ContactView = () =>
  import(/* webpackChunkName: 'ContactView' */ '../views/ContactView.vue')
const OrgSettings = () =>
  import(/* webpackChunkName: 'OrgSettings' */ '../views/OrgSettings.vue')
const Settings = () =>
  import(/* webpackChunkName: 'Settings' */ '../views/Settings.vue')
const Drives = () =>
  import(/* webpackChunkName: 'Drives' */ '../views/drives/Drives.vue')
const People = () =>
  import(/* webpackChunkName: 'People' */ '../views/People.vue')
const UpdatePassword = () =>
  import(/* webpackChunkName: 'Login' */ '../views/UpdatePassword')
const ForgotPassword = () =>
  import(/* webpackChunkName: 'Login' */ '../views/ForgotPassword')
const Register = () =>
  import(/* webpackChunkName: 'Register' */ '../views/Register')
const ConnectedAccount = () =>
  import(/* webpackChunkName: 'Login' */ '../views/ConnectedAccount')
const UnsubscribeEmail = () =>
  import(/* webpackChunkName: 'UnsubscribeEmail' */ '../views/UnsubscribeEmail')
const CrmReportImport = () =>
  import(/* webpackChunkName: 'CrmReportImport' */ '../views/CrmReportImport')
const CrmContacts = () =>
  import(/* webpackChunkName: 'CrmContacts' */ '../views/CrmContacts')
const CrmAccounts = () =>
  import(/* webpackChunkName: 'CrmAccounts' */ '../views/CrmAccounts')
const CrmContactReports = () =>
  import(
    /* webpackChunkName: 'CrmContactReports' */ '../views/CrmContactReports'
  )
const CrmAccountReports = () =>
  import(
    /* webpackChunkName: 'CrmAccountReports' */ '../views/CrmAccountReports'
  )
const DuplicateContacts = () =>
  import(
    /* webpackChunkName: 'DuplicateContacts' */ '../views/DuplicateContacts'
  )
const TeamPerformance = () =>
  import(
    /* webpackChunkName: 'TeamPerformance' */ '../views/analytics/TeamPerformance'
  )

const EmailSummary = () =>
  import(
    /* webpackChunkName: 'EmailSummary' */ '../views/analytics/EmailSummary'
  )

const UserEmailSummary = () =>
  import(
    /* webpackChunkName: 'EmailSummary' */ '../views/analytics/UserEmailSummary'
  )

const EmailDetails = () =>
  import(
    /* webpackChunkName: 'EmailDetails' */ '../views/analytics/EmailDetails'
  )

import driveRoutes from '@/router/drive'
import callRoutes from '@/router/calls'

import cubejsApi from '../services/cubejs/index'

// fix vue-router NavigationDuplicated
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return VueRouterPush.call(this, location).catch((error) => {
    if (error.name !== 'NavigationDuplicated') {
      throw error
    }
  })
}
const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return VueRouterReplace.call(this, location).catch((error) => {
    if (error.name !== 'NavigationDuplicated') {
      throw error
    }
  })
}

Vue.use(VueRouter)

let routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    props: true,
    meta: {
      requiresAuth: true,
      showNav: true,
      navTitle: 'Home',
      scrollView: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    props: true,
    meta: {
      showNav: false,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    props: true,
    meta: {
      requiresAuth: false,
      showNav: false,
    },
  },
  {
    path: '/connect-pipedrive',
    name: 'ConnectPipedrive',
    component: ConnectPipedrive,
    props: true,
    meta: {
      showNav: false,
    },
  },

  {
    path: '/unsubscribe/:token',
    name: 'Unsubscribe',
    component: UnsubscribeEmail,
    props: true,
    meta: {
      showNav: false,
    },
  },
  {
    path: '/update-password/:token',
    name: 'UpdatePassword',
    component: UpdatePassword,
    props: true,
    meta: {
      requiresAuth: true,
      showNav: false,
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    props: true,
    meta: {
      showNav: false,
    },
  },
  {
    path: '/connected-account/:status',
    name: 'ConnectedAccount',
    component: ConnectedAccount,
    meta: {
      showNav: false,
    },
  },
  {
    path: '/actions',
    name: 'Actions',
    props: true,
    component: Actions,
    meta: {
      requiresAuth: true,
      showNav: true,
      navTitle: 'Actions',
      hasSubMenu: true,
    },
    children: [
      {
        path: '',
        redirect: 'manual',
      },
      {
        path: 'manual',
        name: 'ManualActions',
        component: ManualActions,
        meta: {
          requiresAuth: true,
          showNav: true,
          navTitle: 'Manual Actions',
          hasSubMenu: true,
        },
      },
      {
        path: 'outbox',
        name: 'Outbox',
        component: Outbox,
        meta: {
          requiresAuth: true,
          showNav: true,
          navTitle: 'Outbox',
          hasSubMenu: true,
        },
      },
    ],
  },
  {
    path: '/dial',
    name: 'Dial',
    component: Dial,
    meta: {
      requiresAuth: true,
      showNav: false,
    },
  },
  {
    path: '/people',
    name: 'Contacts',
    component: Contacts,
    meta: {
      requiresAuth: true,
      showNav: true,
      navTitle: 'People',
    },
    children: [
      {
        path: '',
        name: 'People',
        component: People,
        meta: {
          requiresAuth: true,
          showNav: true,
          navTitle: 'People',
          hasSubMenu: true,
        },
      },
      {
        path: 'accounts',
        name: 'Accounts',
        component: Accounts,
        meta: {
          requiresAuth: true,
          showNav: true,
          navTitle: 'Accounts',
          hasSubMenu: true,
        },
      },
      {
        path: 'crm/accounts',
        name: 'CrmAccounts',
        component: CrmAccounts,
        meta: {
          requiresAuth: true,
          showNav: true,
          navTitle: 'CRM Accounts',
          hasSubMenu: true,
        },
      },
      {
        path: 'crm/account-reports',
        name: 'CrmAccountReports',
        component: CrmAccountReports,
        meta: {
          requiresAuth: true,
          showNav: true,
          navTitle: 'CRM Reports',
          hasSubMenu: true,
        },
      },
      {
        path: 'crm/contacts',
        name: 'CrmContacts',
        component: CrmContacts,
        meta: {
          requiresAuth: true,
          showNav: true,
          navTitle: 'CRM Contacts',
          hasSubMenu: true,
        },
      },
      {
        path: 'crm/contact-reports',
        name: 'CrmContactReports',
        component: CrmContactReports,
        meta: {
          requiresAuth: true,
          showNav: true,
          getter: 'user/crmName',
          navTitleFn: (val) =>
            `CRM ${val === 'Salesforce' ? 'Reports' : 'Lists'}`,
          navTitle: 'CRM List',
          hasSubMenu: true,
        },
      },
      {
        path: '/people/manage/duplicates',
        name: 'DuplicateContacts',
        component: DuplicateContacts,
        meta: {
          requiresAuth: true,
          showNav: true,
          navTitle: 'Duplicate People',
        },
      },
      {
        path: 'report-import',
        name: 'CrmReportImport',
        component: CrmReportImport,
        meta: {
          requiresAuth: true,
          showNav: true,
          navTitle: 'CRM Report Import',
          hasSubMenu: true,
        },
      },
      {
        path: '/people/:id',
        name: 'ContactView',
        component: ContactView,
        props: true,
        meta: {
          requiresAuth: true,
          showNav: true,
          navTitle: 'Person',
          hasSubMenu: true,
        },
      },
      {
        path: '/people/accounts/:id',
        name: 'AccountView',
        component: AccountView,
        meta: {
          requiresAuth: true,
          showNav: true,
          navTitle: 'Account',
          hasSubMenu: true,
        },
      },
    ],
  },
  {
    path: '/analytics',
    name: 'Analytics',
    component: Analytics,
    meta: {
      adminOnly: true,
      requiresAuth: true,
      showNav: true,
      navTitle: 'Analytics',
      hasSubMenu: true,
      scrollView: true,
    },
    children: [
      {
        path: 'performance',
        name: 'Performance',
        component: TeamPerformance,
        meta: {
          adminOnly: true,
          requiresAuth: true,
          showNav: true,
          navTitle: 'Team Performance',
          hasSubMenu: true,
          scrollView: true,
        },
      },
      {
        path: 'email-summary',
        name: 'EmailSummary',
        component: EmailSummary,
        meta: {
          requiresAuth: true,
          adminOnly: true,
          showNav: true,
          navTitle: 'Email Summary',
          hasSubMenu: true,
          scrollView: true,
        },
      },
      {
        path: 'email-summary/:id',
        name: 'UserEmailSummary',
        component: UserEmailSummary,
        meta: {
          requiresAuth: true,
          showNav: true,
          navTitle: 'Email Summary',
          hasSubMenu: true,
          scrollView: true,
        },
      },
      {
        path: 'email-details',
        name: 'EmailDetails',
        component: EmailDetails,
        meta: {
          requiresAuth: true,
          showNav: true,
          navTitle: 'Email details',
          hasSubMenu: true,
          scrollView: true,
        },
      },
      {
        path: 'email-details/:id',
        name: 'EmailDetails',
        component: EmailDetails,
        meta: {
          requiresAuth: true,
          showNav: true,
          navTitle: 'Email details',
          hasSubMenu: true,
          scrollView: true,
        },
      },
    ],
  },
  {
    path: '/org-settings',
    name: 'OrgSettings',
    component: OrgSettings,
    props: true,
    meta: {
      requiresAuth: true,
      showNav: true,
      navTitle: 'Org Settings',
      scrollView: true,
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    props: true,
    meta: {
      requiresAuth: true,
      showNav: true,
      navTitle: 'Settings',
      scrollView: true,
    },
  },
  {
    path: '/drives',
    name: 'Drives',
    component: Drives,
    props: true,
    meta: {
      requiresAuth: true,
      showNav: true,
      navTitle: 'Sequence Management',
    },
  },
  {
    path: '*',
    name: 'Error',
    component: Error,
    meta: {
      requiresAuth: true,
      showNav: true,
    },
  },
]

routes = [...routes, ...driveRoutes, ...callRoutes]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// Before each route evaluates...
router.beforeEach(async (to, from, next) => {
  Vue.prototype.$Progress.start()

  // If auth isn't required for the route, just continue
  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    return next()
  }

  // If destination is update password page...
  if (to.name === 'UpdatePassword') {
    // store auth token before proceeding to protected route
    store.commit('user/store_login_token', to.params.token)
  }

  // If path is dial and there is a token in the url, then we need to log the user in via the token
  if (to.path === '/dial' && !!to.query?.token) {
    await store.dispatch('user/loginViaCrmToken', {
      token: to.query.token,
      externalUserId: to.query.userId,
      sdkId: to.query.id,
    })
  }

  // If auth is required and the user is logged in...
  if (store.getters['user/isLoggedIn']) {
    // Check for new version
    store.dispatch('version/fetchLatestVersion')
    // If cubeToken is not found...
    if (!store.getters['user/user']?.id) {
      // fetch user profile and determine if token is valid
      return store.dispatch('user/validate').then((validUser) => {
        // Continue if the token still represents a valid user,
        // otherwise redirect to login.
        if (validUser) next()
        else if (to.path === '/dial') redirectToLoginExtension()
        else redirectToLogin()

        // if it's valid user..
        if (validUser) {
          // Setup pusher
          setupUser()
        }
      })
    } else {
      return next()
    }
  }

  // If auth is required and the user is NOT currently logged in for dialer EXTENSION
  if (to.path === '/dial') {
    redirectToLoginExtension()
    return
  }

  // If auth is required and the user is NOT currently logged in,
  // redirect to login.
  redirectToLogin()

  function setupUser() {
    cubejsApi()
    store.dispatch('pusher/pusherSubscribe')
  }

  function redirectToLogin() {
    next('/login')
  }

  function redirectToLoginExtension() {
    next('/login?extension=true')
  }
})

// When each route is finished evaluating...
router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  Vue.prototype.$Progress.finish()

  // If a page is admin only, go back
  if (to.meta && to.meta.adminOnly && !store.getters['user/isOrgAdmin']) {
    if (to.meta.redirectFunc) to.meta.redirectFunc(store.getters['user/user'])
    else window.location.replace('/')
  }

  if (to.meta && to.meta.navTitleFn && to.meta.getter)
    to.meta.navTitle = to.meta.navTitleFn(store.getters[to.meta.getter])

  // clear out drive/disposition/email stores after page leave to be ready for next visit
  // TODO REDESIGN: Revisit this later
  if (
    from.fullPath.startsWith('/drive/') &&
    !to.fullPath.startsWith('/drive/')
  ) {
    console.log('🚀 ~ drive/resetState')
    store.commit('drive/resetState')
  } else if (from.name === 'Email' && to.name !== 'Email') {
    store.commit('email/resetState')
  } else if (from.name === 'Disposition' && to.name !== 'Disposition') {
    store.commit('disposition/resetState')
  }
})

export default router
