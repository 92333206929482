import api from './Api'

export default {
  /**
   * Get version data
   * @export
   * @return {object}
   */
  getVersionData() {
    return api.get(`/version`)
  },
}
