import api from './Api'
import store from '@/store'
import { getTablePaginationParams, withAsync } from './helpers'

const mailbox = () => store.getters['user/mailbox']

const ENDPOINTS = {
  mailboxes: (id = '') => (id ? `/mailboxes/${id}` : `/mailboxes`),
  threads: (id = '') => (id ? `/threads/${id}` : `/threads`),
  getSchedulerPages: (id) => `${ENDPOINTS.mailboxes(id)}/schedulerPages`,
  getSchedulerPageAvailability: (id, slug) =>
    `${ENDPOINTS.getSchedulerPages(id)}/${slug}/availability`,
}

export default {
  getMailboxes() {
    return api.get('/mailboxes', { abortKey: 'GET_MAILBOXES' })
  },

  getInboxEmails(options = {}) {
    const { mailboxId, filtersQuery } = options
    let pathParams = []
    let path = `${ENDPOINTS.mailboxes(mailboxId)}/inbox`
    if (filtersQuery) pathParams.push(filtersQuery)

    // Pagination
    const pagParams = getTablePaginationParams(options)
    if (pagParams) pathParams.push(pagParams)

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.get(path)
  },

  async getSchedulerPagesComputedSlugs() {
    const response = await this.getSchedulerPages()
    // transform response into array of links for personalizing
    // get scheduler pages, prepend `scheduler-link` to slug,
    // and add default scheduler link
    let pageSlugs = []
    if (!response.length) {
      pageSlugs = response.map((e) => `scheduler-link-${e.slug}`)
      pageSlugs.unshift('scheduler-link-default')
    }
    return pageSlugs
  },

  async getSchedulerPages({ forceRefresh = false } = {}) {
    let path = ENDPOINTS.getSchedulerPages(mailbox().id)
    if (forceRefresh) path += '?forceRefresh=true'
    const { response, error } = await withAsync(api.get, path, {
      abortKey: 'GET_SCHEDULER_PAGES',
    })
    if (!response) throw error
    return response
  },

  async getSchedulerPageAvailability(slug) {
    let path = ENDPOINTS.getSchedulerPageAvailability(mailbox().id, slug)
    const { response, error } = await withAsync(api.get, path, {
      abortKey: 'GET_SCHEDULER_PAGE_AVAILABILITY',
    })
    if (!response) throw error
    return response
  },

  getOauthUrl() {
    return api.get('/authNylas/create')
  },

  openOauthInNewWindow() {
    this.getOauthUrl()
      .then((resp) => {
        window.open(resp.oauthUrl, 'Email Authorization')
      })
      .catch((err) => {
        console.log(err)
      })
  },

  async updateMailbox({ mailboxId, mailbox }) {
    return api.put(ENDPOINTS.mailboxes(mailboxId), mailbox)
  },

  updateThread({ mailboxId, thread }) {
    return api.put(
      `${ENDPOINTS.mailboxes(mailboxId)}${ENDPOINTS.threads(thread.id)}`,
      thread
    )
  },

  delete(id) {
    return api.delete(ENDPOINTS.mailboxes(id))
  },
}
