import api from './Api'
import { getTablePaginationParams } from './helpers'

const ENDPOINTS = {
  userNotifications: (id = '') =>
    id ? `/userNotifications/${id}` : `/userNotifications`,
  opts: {
    isRead: (val) => `isRead=${val}`,
  },
}

export default {
  getNotifications(options = {}) {
    let path = ENDPOINTS.userNotifications()
    let pathParams = []

    const { isRead } = options

    if (isRead !== undefined) pathParams.push(ENDPOINTS.opts.isRead(isRead))

    // Pagination
    const pagParams = getTablePaginationParams(options)
    if (pagParams) pathParams.push(pagParams)

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.get(path)
  },

  getNotificationById(id) {
    return api.get(ENDPOINTS.userNotifications(id))
  },

  updateRead(id, value) {
    return value
      ? api.post(`${ENDPOINTS.userNotifications(id)}/markRead`)
      : api.post(`${ENDPOINTS.userNotifications(id)}/markUnread`)
  },
}
