// -----------------------------------------------------------------------------
// This is a simple interface for when the dialer is loaded in the Pipedrive CRM
// it allows us to native dial from Pipedrive
// -----------------------------------------------------------------------------
// https://pipedrive.readme.io/docs/custom-ui-extensions-floating-window

import AppExtensionsSDK from '@pipedrive/app-extensions-sdk'
import { Event, Command } from '@pipedrive/app-extensions-sdk'
import { EventBus } from '@/services/event-bus'

const initSdkEvents = (state, sdk) => {
  sdk.listen(Event.VISIBILITY, ({ data }) => {
    const { callToNumber, relatedIds } = data.context
    if (!callToNumber) return
    console.log('📞 Pipedrive dialer plugin dialing number:', callToNumber)
    state.currentPhoneRecord = {
      number: callToNumber,
      externalId: relatedIds?.personId || relatedIds?.orgId || null,
      externalObjectType: relatedIds?.personId
        ? 'prospect'
        : relatedIds?.orgId
        ? 'account'
        : null,
    }
  })
}

const initWatchers = (sdk) => {
  EventBus.$off('call-incoming')
  EventBus.$on('call-incoming', () => {
    console.log('📞 Pipedrive dialer plugin call incoming...')
    sdk.execute(Command.SHOW_FLOATING_WINDOW)
  })
}

export default {
  init: async (state, user) => {
    // SDK detects identifier from URL and uses default custom UI size
    console.log('📞 Pipedrive dialer plugin initializing...')
    const sdk = await new AppExtensionsSDK({
      identifier: user?.crmIframeRequestData?.sdkId,
    }).initialize({ size: { width: 400, height: 485 } })
    initSdkEvents(state, sdk)
    initWatchers(sdk)
  },
}
