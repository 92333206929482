const HISTORY_FILTERS = Object.freeze([
  {
    id: 1,
    name: 'Auto Emails',
    filters: [{ value: 'drive_auto_email,auto_email', name: 'actionType' }],
    isFixed: true,
  },
  {
    id: 2,
    name: 'Calls',
    filters: [{ value: 'call,drive_call', name: 'actionType' }],
    isFixed: true,
  },
  {
    id: 3,
    name: 'Manual Emails',
    filters: [
      {
        value: 'manual_email,drive_manual_email,manual_email_outside_symbo',
        name: 'actionType',
      },
    ],
    isFixed: true,
  },
  {
    id: 4,
    name: 'Inbound Emails',
    filters: [{ value: 'inbound_email', name: 'actionType' }],
    isFixed: true,
  },
  {
    id: 5,
    name: 'Task',
    filters: [{ value: 'drive_task,task', name: 'actionType' }],
    isFixed: true,
  },
  {
    id: 6,
    name: 'LinkedIn Connect',
    filters: [
      { value: 'drive_linkedin_connect,linkedin_connect', name: 'actionType' },
    ],
    isFixed: true,
  },
  {
    id: 7,
    name: 'LinkedIn Engage',
    filters: [
      { value: 'drive_linkedin_engage,linkedin_engage', name: 'actionType' },
    ],
    isFixed: true,
  },
  {
    id: 8,
    name: 'LinkedIn Follow',
    filters: [
      { value: 'drive_linkedin_follow,linkedin_follow', name: 'actionType' },
    ],
    isFixed: true,
  },
  {
    id: 9,
    name: 'LinkedIn Message',
    filters: [
      { value: 'drive_linkedin_message,linkedin_message', name: 'actionType' },
    ],
    isFixed: true,
  },
  {
    id: 10,
    name: 'LinkedIn Inmail',
    filters: [
      { value: 'drive_linkedin_inmail,linkedin_inmail', name: 'actionType' },
    ],
    isFixed: true,
  },
  {
    id: 11,
    name: 'Note',
    filters: [{ value: 'drive_note,note', name: 'actionType' }],
    isFixed: true,
  },
  {
    id: 12,
    name: 'Form submissions',
    filters: [{ value: 'external_form', name: 'actionType' }],
    isFixed: true,
  },
])

export default HISTORY_FILTERS
