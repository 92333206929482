import api from './Api'

const ENDPOINTS = {
  users: (id = '') => (id ? `/admin/users/${id}` : `/admin/users`),
  hijackUser: (id) => `/admin/users/${id}/actions/hijack`,
  resendAccountConfirmEmail: (id = '') =>
    id ? `/users/${id}/actions/resendAccountConfirmationEmail` : '',
}

export default {
  hijackUser(userId) {
    return api.post(ENDPOINTS.hijackUser(userId))
  },

  getOrgUsers() {
    return api.get(ENDPOINTS.users())
  },

  getOrgUserById(id) {
    return api.get(ENDPOINTS.users(id), { abortKey: 'GET_ORG_USER_BY_ID' })
  },

  createOrgUser(data) {
    return api.post(ENDPOINTS.users(), data)
  },

  updateOrgUserById(id, data) {
    return api.put(ENDPOINTS.users(id), data)
  },

  updateUserPass(id, data) {
    return api.post(`${ENDPOINTS.users(id)}/actions/updatePassword`, data)
  },

  deleteOrgUserById(id) {
    return api.delete(ENDPOINTS.users(id))
  },

  resendUserAccountConfirmEmail(id) {
    return api.post(ENDPOINTS.resendAccountConfirmEmail(id))
  },

  getBillingPortalUrl(data) {
    return api.get(`admin/organization/billingPortal`, {
      abortKey: 'GET_BILLING_PORTAL_URL',
      params: data,
    })
  },

  getSubscriptionPaymentIntent() {
    return api.get(`organization/actions/subscriptionPaymentIntent`, {
      abortKey: 'GET_SUBSCRIPTION_PAYMENT_INTENT',
    })
  },

  previewUpdateSubscriptionSeats(seatCount) {
    return api.post(`organization/actions/previewUpdateSubscriptionSeats`, {
      subscription_seats: seatCount,
    })
  },

  updateSubscriptionSeats(seatCount) {
    return api.post(`organization/actions/updateSubscriptionSeats`, {
      subscription_seats: seatCount,
    })
  },

  updateSubscription(data) {
    return api.post(`organization/actions/updateSubscription`, data)
  },
}
