// Outcome trigger Types

const ASSIGN_DRIVE = Object.freeze({
  value: 'assign_drive',
  label: 'Engage sequence',
  icon: '$vuetify.icons.symboDrive',
  color: '#324499',
  enabled: true,
  data: null,
  allowMultiple: false,
})

const END_DRIVE = Object.freeze({
  value: 'end_drive',
  label: 'End sequence',
  icon: '$vuetify.icons.symboDrive',
  color: '#E64A19',
  enabled: true,
  data: null,
  showOnCallOnly: true,
  allowMultiple: false,
})

const ASSIGN_USER = Object.freeze({
  value: 'assign_user',
  label: 'Assign user',
  icon: 'mdi-account',
  color: '#46bcff',
  enabled: true,
  data: null,
  allowMultiple: false,
})

const UPDATE_PROSPECT_STATUS = Object.freeze({
  value: 'update_prospect_status',
  label: 'Update prospect status',
  icon: 'mdi-folder',
  color: '#ea446b',
  enabled: true,
  data: null,
  allowMultiple: false,
})

const REMOVE_FROM_DIAL_SESSION = Object.freeze({
  value: 'remove_from_dial_session',
  label: 'Remove from active power dial session',
  icon: 'mdi-cancel',
  color: '#D32F2F',
  enabled: true,
  data: null,
  noInput: true,
  showOnCallOnly: true,
  allowMultiple: false,
})

const RETRY_POWER_DIAL_CALL = Object.freeze({
  value: 'retry_power_dial_call',
  label: 'Retry power dial call',
  icon: 'mdi-phone-refresh',
  color: '#00897B',
  enabled: true,
  data: null,
  showOnCallOnly: true,
  allowMultiple: false,
})

const BLACKLIST_NUMBER = Object.freeze({
  value: 'blacklist_number',
  label: 'Blacklist number',
  icon: 'mdi-phone-off',
  color: 'black',
  enabled: true,
  data: null,
  noInput: true,
  showOnCallOnly: true,
  allowMultiple: false,
})

const MARK_AS_WRONG_NUMBER = Object.freeze({
  value: 'mark_number_as_wrong',
  label: 'Mark as wrong number',
  icon: 'mdi-phone-alert',
  color: '#C62828',
  enabled: true,
  data: null,
  noInput: true,
  showOnCallOnly: true,
  allowMultiple: false,
})

const UPDATE_PROSPECT_STATUS_X_CALL = Object.freeze({
  value: 'update_prospect_status_on_x_call',
  label: 'Update status after',
  dynamicLabel: (data) =>
    `Update status to "${data.name || data.status_value}" after ${
      data.call_count
    } call${data.call_count > 1 ? 's' : ''}`,
  tooltip: 'Update status after certain number of calls',
  icon: 'mdi-folder',
  color: '#ea446b',
  enabled: true,
  data: null,
  allowMultiple: false,
})

const UPDRATE_PROSPECT_STATUS_SEND_WEBHOOK = Object.freeze({
  value: 'update_prospect_status:send_webhook',
  label: 'Webhook To URL',
  tooltip: 'Use webhook to send events to any URL you want',
  icon: 'mdi-webhook',
  color: '#6b349f',
  enabled: true,
  data: null,
  allowMultiple: false,
})

const UPDATE_PROSPECT_STATUS_FIRST_CALL = Object.freeze({
  value: 'update_prospect_status_on_first_call',
  label: 'Update status on 1st call',
  dynamicLabel: (data) => `Update status to "${data.name}" after 1st call`,
  tooltip: 'Update status on 1st calls',
  icon: 'mdi-folder',
  color: '#ea446b',
  enabled: true,
  data: null,
  allowMultiple: false,
  obsolete: true,
})

export const BASIC_TRIGGERS = Object.freeze([
  ASSIGN_DRIVE,
  END_DRIVE,
  ASSIGN_USER,
  UPDATE_PROSPECT_STATUS,
  REMOVE_FROM_DIAL_SESSION,
  RETRY_POWER_DIAL_CALL,
  BLACKLIST_NUMBER,
  MARK_AS_WRONG_NUMBER,
])

export const PROSPECT_STATUS_TRIGGERS = Object.freeze([
  UPDATE_PROSPECT_STATUS_X_CALL,
  UPDRATE_PROSPECT_STATUS_SEND_WEBHOOK,
])

/**
 * Returns Trigger type object based on the `action_type` field
 *
 * @export
 * @param {string} value - The `value` of triggerType to match
 * @return {object}
 */
export function getTriggerType(value) {
  if (!value) return
  const match = Object.values([
    ...BASIC_TRIGGERS,
    ...PROSPECT_STATUS_TRIGGERS,
    UPDATE_PROSPECT_STATUS_FIRST_CALL,
  ]).find((i) => i.value === value)
  return match || ''
}

export function getDynamicTriggerLabel(triggerObj) {
  const triggerType = getTriggerType(triggerObj.action_type)
  if (!triggerType) return

  if (triggerType?.dynamicLabel)
    return triggerType.dynamicLabel(triggerObj.action_data)
  return triggerType.label
}

export function getSequenceTriggerTypes() {
  return Object.values(BASIC_TRIGGERS).filter((item) => !item.showOnCallOnly)
}

export function getCallTriggerTypes() {
  return Object.values(BASIC_TRIGGERS)
}

export function getProspectStatusTriggerTypes() {
  return Object.values(PROSPECT_STATUS_TRIGGERS)
}
