const driveStepTimeIntervals = Object.freeze([
  { label: '12:00 AM', value: 0, formattedTime: '00:00' },
  { label: '12:05 AM', value: 5, formattedTime: '00:05' },
  { label: '12:10 AM', value: 10, formattedTime: '00:10' },
  { label: '12:15 AM', value: 15, formattedTime: '00:15' },
  { label: '12:20 AM', value: 20, formattedTime: '00:20' },
  { label: '12:25 AM', value: 25, formattedTime: '00:25' },
  { label: '12:30 AM', value: 30, formattedTime: '00:30' },
  { label: '12:35 AM', value: 35, formattedTime: '00:35' },
  { label: '12:40 AM', value: 40, formattedTime: '00:40' },
  { label: '12:45 AM', value: 45, formattedTime: '00:45' },
  { label: '12:50 AM', value: 50, formattedTime: '00:50' },
  { label: '12:55 AM', value: 55, formattedTime: '00:55' },
  { label: '1:00 AM', value: 60, formattedTime: '01:00' },
  { label: '1:05 AM', value: 65, formattedTime: '01:05' },
  { label: '1:10 AM', value: 70, formattedTime: '01:10' },
  { label: '1:15 AM', value: 75, formattedTime: '01:15' },
  { label: '1:20 AM', value: 80, formattedTime: '01:20' },
  { label: '1:25 AM', value: 85, formattedTime: '01:25' },
  { label: '1:30 AM', value: 90, formattedTime: '01:30' },
  { label: '1:35 AM', value: 95, formattedTime: '01:35' },
  { label: '1:40 AM', value: 100, formattedTime: '01:40' },
  { label: '1:45 AM', value: 105, formattedTime: '01:45' },
  { label: '1:50 AM', value: 110, formattedTime: '01:50' },
  { label: '1:55 AM', value: 115, formattedTime: '01:55' },
  { label: '2:00 AM', value: 120, formattedTime: '02:00' },
  { label: '2:05 AM', value: 125, formattedTime: '02:05' },
  { label: '2:10 AM', value: 130, formattedTime: '02:10' },
  { label: '2:15 AM', value: 135, formattedTime: '02:15' },
  { label: '2:20 AM', value: 140, formattedTime: '02:20' },
  { label: '2:25 AM', value: 145, formattedTime: '02:25' },
  { label: '2:30 AM', value: 150, formattedTime: '02:30' },
  { label: '2:35 AM', value: 155, formattedTime: '02:35' },
  { label: '2:40 AM', value: 160, formattedTime: '02:40' },
  { label: '2:45 AM', value: 165, formattedTime: '02:45' },
  { label: '2:50 AM', value: 170, formattedTime: '02:50' },
  { label: '2:55 AM', value: 175, formattedTime: '02:55' },
  { label: '3:00 AM', value: 180, formattedTime: '03:00' },
  { label: '3:05 AM', value: 185, formattedTime: '03:05' },
  { label: '3:10 AM', value: 190, formattedTime: '03:10' },
  { label: '3:15 AM', value: 195, formattedTime: '03:15' },
  { label: '3:20 AM', value: 200, formattedTime: '03:20' },
  { label: '3:25 AM', value: 205, formattedTime: '03:25' },
  { label: '3:30 AM', value: 210, formattedTime: '03:30' },
  { label: '3:35 AM', value: 215, formattedTime: '03:35' },
  { label: '3:40 AM', value: 220, formattedTime: '03:40' },
  { label: '3:45 AM', value: 225, formattedTime: '03:45' },
  { label: '3:50 AM', value: 230, formattedTime: '03:50' },
  { label: '3:55 AM', value: 235, formattedTime: '03:55' },
  { label: '4:00 AM', value: 240, formattedTime: '04:00' },
  { label: '4:05 AM', value: 245, formattedTime: '04:05' },
  { label: '4:10 AM', value: 250, formattedTime: '04:10' },
  { label: '4:15 AM', value: 255, formattedTime: '04:15' },
  { label: '4:20 AM', value: 260, formattedTime: '04:20' },
  { label: '4:25 AM', value: 265, formattedTime: '04:25' },
  { label: '4:30 AM', value: 270, formattedTime: '04:30' },
  { label: '4:35 AM', value: 275, formattedTime: '04:35' },
  { label: '4:40 AM', value: 280, formattedTime: '04:40' },
  { label: '4:45 AM', value: 285, formattedTime: '04:45' },
  { label: '4:50 AM', value: 290, formattedTime: '04:50' },
  { label: '4:55 AM', value: 295, formattedTime: '04:55' },
  { label: '5:00 AM', value: 300, formattedTime: '05:00' },
  { label: '5:05 AM', value: 305, formattedTime: '05:05' },
  { label: '5:10 AM', value: 310, formattedTime: '05:10' },
  { label: '5:15 AM', value: 315, formattedTime: '05:15' },
  { label: '5:20 AM', value: 320, formattedTime: '05:20' },
  { label: '5:25 AM', value: 325, formattedTime: '05:25' },
  { label: '5:30 AM', value: 330, formattedTime: '05:30' },
  { label: '5:35 AM', value: 335, formattedTime: '05:35' },
  { label: '5:40 AM', value: 340, formattedTime: '05:40' },
  { label: '5:45 AM', value: 345, formattedTime: '05:45' },
  { label: '5:50 AM', value: 350, formattedTime: '05:50' },
  { label: '5:55 AM', value: 355, formattedTime: '05:55' },
  { label: '6:00 AM', value: 360, formattedTime: '06:00' },
  { label: '6:05 AM', value: 365, formattedTime: '06:05' },
  { label: '6:10 AM', value: 370, formattedTime: '06:10' },
  { label: '6:15 AM', value: 375, formattedTime: '06:15' },
  { label: '6:20 AM', value: 380, formattedTime: '06:20' },
  { label: '6:25 AM', value: 385, formattedTime: '06:25' },
  { label: '6:30 AM', value: 390, formattedTime: '06:30' },
  { label: '6:35 AM', value: 395, formattedTime: '06:35' },
  { label: '6:40 AM', value: 400, formattedTime: '06:40' },
  { label: '6:45 AM', value: 405, formattedTime: '06:45' },
  { label: '6:50 AM', value: 410, formattedTime: '06:50' },
  { label: '6:55 AM', value: 415, formattedTime: '06:55' },
  { label: '7:00 AM', value: 420, formattedTime: '07:00' },
  { label: '7:05 AM', value: 425, formattedTime: '07:05' },
  { label: '7:10 AM', value: 430, formattedTime: '07:10' },
  { label: '7:15 AM', value: 435, formattedTime: '07:15' },
  { label: '7:20 AM', value: 440, formattedTime: '07:20' },
  { label: '7:25 AM', value: 445, formattedTime: '07:25' },
  { label: '7:30 AM', value: 450, formattedTime: '07:30' },
  { label: '7:35 AM', value: 455, formattedTime: '07:35' },
  { label: '7:40 AM', value: 460, formattedTime: '07:40' },
  { label: '7:45 AM', value: 465, formattedTime: '07:45' },
  { label: '7:50 AM', value: 470, formattedTime: '07:50' },
  { label: '7:55 AM', value: 475, formattedTime: '07:55' },
  { label: '8:00 AM', value: 480, formattedTime: '08:00' },
  { label: '8:05 AM', value: 485, formattedTime: '08:05' },
  { label: '8:10 AM', value: 490, formattedTime: '08:10' },
  { label: '8:15 AM', value: 495, formattedTime: '08:15' },
  { label: '8:20 AM', value: 500, formattedTime: '08:20' },
  { label: '8:25 AM', value: 505, formattedTime: '08:25' },
  { label: '8:30 AM', value: 510, formattedTime: '08:30' },
  { label: '8:35 AM', value: 515, formattedTime: '08:35' },
  { label: '8:40 AM', value: 520, formattedTime: '08:40' },
  { label: '8:45 AM', value: 525, formattedTime: '08:45' },
  { label: '8:50 AM', value: 530, formattedTime: '08:50' },
  { label: '8:55 AM', value: 535, formattedTime: '08:55' },
  { label: '9:00 AM', value: 540, formattedTime: '09:00' },
  { label: '9:05 AM', value: 545, formattedTime: '09:05' },
  { label: '9:10 AM', value: 550, formattedTime: '09:10' },
  { label: '9:15 AM', value: 555, formattedTime: '09:15' },
  { label: '9:20 AM', value: 560, formattedTime: '09:20' },
  { label: '9:25 AM', value: 565, formattedTime: '09:25' },
  { label: '9:30 AM', value: 570, formattedTime: '09:30' },
  { label: '9:35 AM', value: 575, formattedTime: '09:35' },
  { label: '9:40 AM', value: 580, formattedTime: '09:40' },
  { label: '9:45 AM', value: 585, formattedTime: '09:45' },
  { label: '9:50 AM', value: 590, formattedTime: '09:50' },
  { label: '9:55 AM', value: 595, formattedTime: '09:55' },
  { label: '10:00 AM', value: 600, formattedTime: '10:00' },
  { label: '10:05 AM', value: 605, formattedTime: '10:05' },
  { label: '10:10 AM', value: 610, formattedTime: '10:10' },
  { label: '10:15 AM', value: 615, formattedTime: '10:15' },
  { label: '10:20 AM', value: 620, formattedTime: '10:20' },
  { label: '10:25 AM', value: 625, formattedTime: '10:25' },
  { label: '10:30 AM', value: 630, formattedTime: '10:30' },
  { label: '10:35 AM', value: 635, formattedTime: '10:35' },
  { label: '10:40 AM', value: 640, formattedTime: '10:40' },
  { label: '10:45 AM', value: 645, formattedTime: '10:45' },
  { label: '10:50 AM', value: 650, formattedTime: '10:50' },
  { label: '10:55 AM', value: 655, formattedTime: '10:55' },
  { label: '11:00 AM', value: 660, formattedTime: '11:00' },
  { label: '11:05 AM', value: 665, formattedTime: '11:05' },
  { label: '11:10 AM', value: 670, formattedTime: '11:10' },
  { label: '11:15 AM', value: 675, formattedTime: '11:15' },
  { label: '11:20 AM', value: 680, formattedTime: '11:20' },
  { label: '11:25 AM', value: 685, formattedTime: '11:25' },
  { label: '11:30 AM', value: 690, formattedTime: '11:30' },
  { label: '11:35 AM', value: 695, formattedTime: '11:35' },
  { label: '11:40 AM', value: 700, formattedTime: '11:40' },
  { label: '11:45 AM', value: 705, formattedTime: '11:45' },
  { label: '11:50 AM', value: 710, formattedTime: '11:50' },
  { label: '11:55 AM', value: 715, formattedTime: '11:55' },
  { label: '12:00 PM', value: 720, formattedTime: '12:00' },
  { label: '12:05 PM', value: 725, formattedTime: '12:05' },
  { label: '12:10 PM', value: 730, formattedTime: '12:10' },
  { label: '12:15 PM', value: 735, formattedTime: '12:15' },
  { label: '12:20 PM', value: 740, formattedTime: '12:20' },
  { label: '12:25 PM', value: 745, formattedTime: '12:25' },
  { label: '12:30 PM', value: 750, formattedTime: '12:30' },
  { label: '12:35 PM', value: 755, formattedTime: '12:35' },
  { label: '12:40 PM', value: 760, formattedTime: '12:40' },
  { label: '12:45 PM', value: 765, formattedTime: '12:45' },
  { label: '12:50 PM', value: 770, formattedTime: '12:50' },
  { label: '12:55 PM', value: 775, formattedTime: '12:55' },
  { label: '1:00 PM', value: 780, formattedTime: '13:00' },
  { label: '1:05 PM', value: 785, formattedTime: '13:05' },
  { label: '1:10 PM', value: 790, formattedTime: '13:10' },
  { label: '1:15 PM', value: 795, formattedTime: '13:15' },
  { label: '1:20 PM', value: 800, formattedTime: '13:20' },
  { label: '1:25 PM', value: 805, formattedTime: '13:25' },
  { label: '1:30 PM', value: 810, formattedTime: '13:30' },
  { label: '1:35 PM', value: 815, formattedTime: '13:35' },
  { label: '1:40 PM', value: 820, formattedTime: '13:40' },
  { label: '1:45 PM', value: 825, formattedTime: '13:45' },
  { label: '1:50 PM', value: 830, formattedTime: '13:50' },
  { label: '1:55 PM', value: 835, formattedTime: '13:55' },
  { label: '2:00 PM', value: 840, formattedTime: '14:00' },
  { label: '2:05 PM', value: 845, formattedTime: '14:05' },
  { label: '2:10 PM', value: 850, formattedTime: '14:10' },
  { label: '2:15 PM', value: 855, formattedTime: '14:15' },
  { label: '2:20 PM', value: 860, formattedTime: '14:20' },
  { label: '2:25 PM', value: 865, formattedTime: '14:25' },
  { label: '2:30 PM', value: 870, formattedTime: '14:30' },
  { label: '2:35 PM', value: 875, formattedTime: '14:35' },
  { label: '2:40 PM', value: 880, formattedTime: '14:40' },
  { label: '2:45 PM', value: 885, formattedTime: '14:45' },
  { label: '2:50 PM', value: 890, formattedTime: '14:50' },
  { label: '2:55 PM', value: 895, formattedTime: '14:55' },
  { label: '3:00 PM', value: 900, formattedTime: '15:00' },
  { label: '3:05 PM', value: 905, formattedTime: '15:05' },
  { label: '3:10 PM', value: 910, formattedTime: '15:10' },
  { label: '3:15 PM', value: 915, formattedTime: '15:15' },
  { label: '3:20 PM', value: 920, formattedTime: '15:20' },
  { label: '3:25 PM', value: 925, formattedTime: '15:25' },
  { label: '3:30 PM', value: 930, formattedTime: '15:30' },
  { label: '3:35 PM', value: 935, formattedTime: '15:35' },
  { label: '3:40 PM', value: 940, formattedTime: '15:40' },
  { label: '3:45 PM', value: 945, formattedTime: '15:45' },
  { label: '3:50 PM', value: 950, formattedTime: '15:50' },
  { label: '3:55 PM', value: 955, formattedTime: '15:55' },
  { label: '4:00 PM', value: 960, formattedTime: '16:00' },
  { label: '4:05 PM', value: 965, formattedTime: '16:05' },
  { label: '4:10 PM', value: 970, formattedTime: '16:10' },
  { label: '4:15 PM', value: 975, formattedTime: '16:15' },
  { label: '4:20 PM', value: 980, formattedTime: '16:20' },
  { label: '4:25 PM', value: 985, formattedTime: '16:25' },
  { label: '4:30 PM', value: 990, formattedTime: '16:30' },
  { label: '4:35 PM', value: 995, formattedTime: '16:35' },
  { label: '4:40 PM', value: 1000, formattedTime: '16:40' },
  { label: '4:45 PM', value: 1005, formattedTime: '16:45' },
  { label: '4:50 PM', value: 1010, formattedTime: '16:50' },
  { label: '4:55 PM', value: 1015, formattedTime: '16:55' },
  { label: '5:00 PM', value: 1020, formattedTime: '17:00' },
  { label: '5:05 PM', value: 1025, formattedTime: '17:05' },
  { label: '5:10 PM', value: 1030, formattedTime: '17:10' },
  { label: '5:15 PM', value: 1035, formattedTime: '17:15' },
  { label: '5:20 PM', value: 1040, formattedTime: '17:20' },
  { label: '5:25 PM', value: 1045, formattedTime: '17:25' },
  { label: '5:30 PM', value: 1050, formattedTime: '17:30' },
  { label: '5:35 PM', value: 1055, formattedTime: '17:35' },
  { label: '5:40 PM', value: 1060, formattedTime: '17:40' },
  { label: '5:45 PM', value: 1065, formattedTime: '17:45' },
  { label: '5:50 PM', value: 1070, formattedTime: '17:50' },
  { label: '5:55 PM', value: 1075, formattedTime: '17:55' },
  { label: '6:00 PM', value: 1080, formattedTime: '18:00' },
  { label: '6:05 PM', value: 1085, formattedTime: '18:05' },
  { label: '6:10 PM', value: 1090, formattedTime: '18:10' },
  { label: '6:15 PM', value: 1095, formattedTime: '18:15' },
  { label: '6:20 PM', value: 1100, formattedTime: '18:20' },
  { label: '6:25 PM', value: 1105, formattedTime: '18:25' },
  { label: '6:30 PM', value: 1110, formattedTime: '18:30' },
  { label: '6:35 PM', value: 1115, formattedTime: '18:35' },
  { label: '6:40 PM', value: 1120, formattedTime: '18:40' },
  { label: '6:45 PM', value: 1125, formattedTime: '18:45' },
  { label: '6:50 PM', value: 1130, formattedTime: '18:50' },
  { label: '6:55 PM', value: 1135, formattedTime: '18:55' },
  { label: '7:00 PM', value: 1140, formattedTime: '19:00' },
  { label: '7:05 PM', value: 1145, formattedTime: '19:05' },
  { label: '7:10 PM', value: 1150, formattedTime: '19:10' },
  { label: '7:15 PM', value: 1155, formattedTime: '19:15' },
  { label: '7:20 PM', value: 1160, formattedTime: '19:20' },
  { label: '7:25 PM', value: 1165, formattedTime: '19:25' },
  { label: '7:30 PM', value: 1170, formattedTime: '19:30' },
  { label: '7:35 PM', value: 1175, formattedTime: '19:35' },
  { label: '7:40 PM', value: 1180, formattedTime: '19:40' },
  { label: '7:45 PM', value: 1185, formattedTime: '19:45' },
  { label: '7:50 PM', value: 1190, formattedTime: '19:50' },
  { label: '7:55 PM', value: 1195, formattedTime: '19:55' },
  { label: '8:00 PM', value: 1200, formattedTime: '20:00' },
  { label: '8:05 PM', value: 1205, formattedTime: '20:05' },
  { label: '8:10 PM', value: 1210, formattedTime: '20:10' },
  { label: '8:15 PM', value: 1215, formattedTime: '20:15' },
  { label: '8:20 PM', value: 1220, formattedTime: '20:20' },
  { label: '8:25 PM', value: 1225, formattedTime: '20:25' },
  { label: '8:30 PM', value: 1230, formattedTime: '20:30' },
  { label: '8:35 PM', value: 1235, formattedTime: '20:35' },
  { label: '8:40 PM', value: 1240, formattedTime: '20:40' },
  { label: '8:45 PM', value: 1245, formattedTime: '20:45' },
  { label: '8:50 PM', value: 1250, formattedTime: '20:50' },
  { label: '8:55 PM', value: 1255, formattedTime: '20:55' },
  { label: '9:00 PM', value: 1260, formattedTime: '21:00' },
  { label: '9:05 PM', value: 1265, formattedTime: '21:05' },
  { label: '9:10 PM', value: 1270, formattedTime: '21:10' },
  { label: '9:15 PM', value: 1275, formattedTime: '21:15' },
  { label: '9:20 PM', value: 1280, formattedTime: '21:20' },
  { label: '9:25 PM', value: 1285, formattedTime: '21:25' },
  { label: '9:30 PM', value: 1290, formattedTime: '21:30' },
  { label: '9:35 PM', value: 1295, formattedTime: '21:35' },
  { label: '9:40 PM', value: 1300, formattedTime: '21:40' },
  { label: '9:45 PM', value: 1305, formattedTime: '21:45' },
  { label: '9:50 PM', value: 1310, formattedTime: '21:50' },
  { label: '9:55 PM', value: 1315, formattedTime: '21:55' },
  { label: '10:00 PM', value: 1320, formattedTime: '22:00' },
  { label: '10:05 PM', value: 1325, formattedTime: '22:05' },
  { label: '10:10 PM', value: 1330, formattedTime: '22:10' },
  { label: '10:15 PM', value: 1335, formattedTime: '22:15' },
  { label: '10:20 PM', value: 1340, formattedTime: '22:20' },
  { label: '10:25 PM', value: 1345, formattedTime: '22:25' },
  { label: '10:30 PM', value: 1350, formattedTime: '22:30' },
  { label: '10:35 PM', value: 1355, formattedTime: '22:35' },
  { label: '10:40 PM', value: 1360, formattedTime: '22:40' },
  { label: '10:45 PM', value: 1365, formattedTime: '22:45' },
  { label: '10:50 PM', value: 1370, formattedTime: '22:50' },
  { label: '10:55 PM', value: 1375, formattedTime: '22:55' },
  { label: '11:00 PM', value: 1380, formattedTime: '23:00' },
  { label: '11:05 PM', value: 1385, formattedTime: '23:05' },
  { label: '11:10 PM', value: 1390, formattedTime: '23:10' },
  { label: '11:15 PM', value: 1395, formattedTime: '23:15' },
  { label: '11:20 PM', value: 1400, formattedTime: '23:20' },
  { label: '11:25 PM', value: 1405, formattedTime: '23:25' },
  { label: '11:30 PM', value: 1410, formattedTime: '23:30' },
  { label: '11:35 PM', value: 1415, formattedTime: '23:35' },
  { label: '11:40 PM', value: 1420, formattedTime: '23:40' },
  { label: '11:45 PM', value: 1425, formattedTime: '23:45' },
  { label: '11:50 PM', value: 1430, formattedTime: '23:50' },
  { label: '11:55 PM', value: 1435, formattedTime: '23:55' },
])

export default driveStepTimeIntervals

export function getTimeIntervalLabel(mins) {
  const intervalMatch = driveStepTimeIntervals.find((i) => i.value === mins)
  return intervalMatch ? intervalMatch.label : ''
}
