import api from './Api'
import { getTablePaginationParams } from './helpers'

const ENDPOINTS = {
  emailAddresses: () => '/emailAddresses',
  opts: {
    tableSearch: (text) => `tableSearch=${text}`,
    emailSearch: (text) => `email=${text}`,
    emailId: (id) => `id=${id}`,
  },
}

export default {
  getEmailAddresses(options = {}) {
    let path = ENDPOINTS.emailAddresses()
    let pathParams = []

    const { tableSearch, emailSearch, emailId } = options

    // Email address OR Name search
    if (tableSearch) pathParams.push(ENDPOINTS.opts.tableSearch(tableSearch))

    // Email address OR Name search
    if (emailSearch) pathParams.push(ENDPOINTS.opts.emailSearch(emailSearch))

    // Id of email address record
    if (emailId) pathParams.push(ENDPOINTS.opts.emailId(emailId))

    // Pagination
    const pagParams = getTablePaginationParams(options)
    if (pagParams) pathParams.push(pagParams)

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.get(path, { abortKey: 'GET_EMAIL_ADDRESSES' })
  },
}
