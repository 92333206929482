import AccountsApi from '@/services/api/Accounts'
import { EventBus } from '@/services/event-bus'
import BulkAccountExclusion from '../../services/api/BulkAccountExclusion'

const _unionBy = require('lodash/unionBy')

// initial state
const state = () => ({
  status: '',
  accounts: [],
  selectedAccounts: [],
  excludedAccountIds: [],
  accountSearch: '',
  showDeleted: false,
})

// getters
const getters = {
  allAccounts: (state) => state.accounts,
  accountSearch: (state) => state.accountSearch,
  accountsLoading: (state) => state.status === 'loading',
  selectedAccounts: (state) => state.selectedAccounts,
  excludedAccountIds: (state) => state.excludedAccountIds,
  showDeleted: (state) => state.showDeleted,
}

// actions
const actions = {
  async fetchAccounts({ commit, state }, options = {}) {
    return new Promise((resolve, reject) => {
      commit('request')

      // clear out existing records if beginning an append paginate
      if (options.append && options.page === 1) {
        commit('setAccounts', [])
      }

      AccountsApi.getAccounts({
        ...options,
        showDeleted: state.showDeleted,
      })
        .then((resp) => {
          // append new results instead of replace for select lists
          if (options.append) {
            const appendedAccounts = _unionBy(state.accounts, resp.data, 'id')
            commit('setAccounts', appendedAccounts)
          } else {
            commit('setAccounts', resp.data)
          }

          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          // ignore error if the req. was aborted
          if (!err.aborted) {
            commit('error')
            reject(err)
          }
        })
    })
  },

  async createAccount(NULL, account) {
    return new Promise((resolve, reject) => {
      AccountsApi.createAccount(account)
        .then((resp) => resolve(resp))
        .catch((err) => reject(err))
    })
  },

  async editAccount({ commit }, account) {
    return new Promise((resolve, reject) => {
      AccountsApi.editAccount(account)
        .then((resp) => {
          commit('setAccount', resp)
          commit(
            'snackbar/setSnack',
            {
              snack: 'Account updated successfully',
              snackType: 'success',
            },
            { root: true }
          )
          resolve(resp)
        })
        .catch((err) => reject(err))
    })
  },

  async delete(NULL, { accountId, force_delete_prospects = false }) {
    return new Promise((resolve, reject) => {
      AccountsApi.deleteAccount(accountId, force_delete_prospects)
        .then((resp) => resolve(resp))
        .catch((err) => reject(err))
    })
  },

  async bulkDelete({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('request')
      AccountsApi.bulkDeleteAccounts(state.selectedAccounts)
        .then((resp) => {
          const errorCount = resp.errorCount
          const successCount = resp.successCount

          if (successCount != 0 && errorCount == 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: 'Successfully deleted',
                snackType: 'success',
              },
              {
                root: true,
              }
            )
          } else if (successCount != 0 && errorCount != 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: `${successCount} deleted. Unable to delete ${errorCount}.`,
                snackType: 'warning',
              },
              {
                root: true,
              }
            )
          } else if (successCount == 0 && errorCount != 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: `There was an issue deleting, try again.`,
                snackType: 'error',
              },
              {
                root: true,
              }
            )
          }

          EventBus.$emit('people-fetch-accounts', true)

          resolve(resp)
        })
        .catch((err) => {
          commit('contactError')
          commit(
            'snackbar/setSnack',
            {
              snack: `There was an issue deleting, try again.`,
              snackType: 'error',
            },
            {
              root: true,
            }
          )

          reject(err)
        })
    })
  },

  async bulkDeleteExclusion({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('request')
      BulkAccountExclusion.deleteAccounts(state.excludedAccountIds)
        .then((resp) => {
          const errorCount = resp.errorCount
          const successCount = resp.successCount

          if (successCount != 0 && errorCount == 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: 'Successfully deleted',
                snackType: 'success',
              },
              {
                root: true,
              }
            )
          } else if (successCount != 0 && errorCount != 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: `${successCount} deleted. Unable to delete ${errorCount}.`,
                snackType: 'warning',
              },
              {
                root: true,
              }
            )
          } else if (successCount == 0 && errorCount != 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: `There was an issue deleting, try again.`,
                snackType: 'error',
              },
              {
                root: true,
              }
            )
          }

          EventBus.$emit('people-fetch-accounts', true)

          resolve(resp)
        })
        .catch((err) => {
          commit('contactError')
          commit(
            'snackbar/setSnack',
            {
              snack: `There was an issue deleting, try again.`,
              snackType: 'error',
            },
            {
              root: true,
            }
          )

          reject(err)
        })
    })
  },

  async deleteMessage(
    { commit },
    { doIfConfirmed, doIfConfirmedParam, isBulkDelete }
  ) {
    commit(
      'appModal/showModal',
      {
        title: isBulkDelete ? 'Delete Accounts?' : 'Delete the Account?',
        text:
          (isBulkDelete ? '' : 'It ') +
          'Will be moved to the trash for 30 days and then deleted.',
        dangerModal: true,
        doIfConfirmed: doIfConfirmed,
        doIfConfirmedParam: doIfConfirmedParam,
      },
      { root: true }
    )
  },

  importMessage({ commit }, { doIfConfirmed, doIfConfirmedParam }) {
    commit(
      'appModal/showModal',
      {
        title: 'Import account?',
        text: 'Will be added to Symbo accounts.',
        doIfConfirmed: doIfConfirmed,
        doIfConfirmedParam: doIfConfirmedParam,
      },
      {
        root: true,
      }
    )
  },

  async import({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AccountsApi.addAccountByExternalId(payload)
        .then((resp) => {
          commit(
            'snackbar/setSnack',
            {
              snack: 'Successfully imported',
              snackType: 'success',
            },
            {
              root: true,
            }
          )
          EventBus.$emit('people-fetch-accounts')
          resolve(resp)
        })
        .catch((err) => {
          commit(
            'snackbar/setSnack',
            {
              snack: `There was an issue importing, try again.`,
              snackType: 'error',
            },
            {
              root: true,
            }
          )
          reject(err)
        })
    })
  },
}

// mutations
const mutations = {
  setAccounts(state, accounts) {
    state.accounts = accounts
  },
  setShowDeleted(state, showDeleted) {
    state.showDeleted = showDeleted
  },
  setSelectedAccounts(state, selectedAccounts) {
    state.selectedAccounts = selectedAccounts
  },
  setExcludedAccountIds(state, excludedAccountIds) {
    state.excludedAccountIds = excludedAccountIds
  },
  resetAccountSelections(state) {
    state.selectedAccounts = []
    state.excludedAccountIds = []
  },
  request(state) {
    state.status = 'loading'
  },
  success(state) {
    state.status = 'success'
  },
  error(state) {
    state.status = 'error'
  },
  setAccount(state, updatedAccount) {
    const index = state.accounts.findIndex(
      (account) => account.id === updatedAccount.id
    )
    if (index !== -1)
      state.accounts = [
        ...state.accounts.slice(0, index),
        updatedAccount,
        ...state.accounts.slice(index + 1),
      ]
  },
  setAccountSearch(state, search) {
    state.accountSearch = search
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
