import api from './Api'
import { getTablePaginationParams } from './helpers'

const ENDPOINTS = {
  emailTemplates: (id = '') =>
    id ? `/emailTemplates/${id}` : `/emailTemplates`,
  undeleteEmailTemplate: (id) =>
    `${ENDPOINTS.emailTemplates(id)}/actions/undelete`,
  opts: {
    showDeleted: 'onlyTrashed=true',
    owner: (ids) => `owner=${ids.join(',')}`,
  },
}

export default {
  getEmailTemplates(options = {}) {
    let path = ENDPOINTS.emailTemplates()
    let pathParams = []

    const { showDeleted, searchByName, shareType, userIds } = options

    if (shareType) pathParams.push(`shareType=${shareType}`)
    if (userIds?.length) pathParams.push(ENDPOINTS.opts.owner(userIds))

    // Determine template states to show
    if (showDeleted) pathParams.push(ENDPOINTS.opts.showDeleted)

    // Table search input
    if (searchByName) pathParams.push(`name=${searchByName}`)

    // Pagination
    const pagParams = getTablePaginationParams(options)
    if (pagParams) pathParams.push(pagParams)

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.get(path, { abortKey: 'GET_EMAIL_TEMPLATES' })
  },

  getEmailTemplateById(id) {
    return api.get(ENDPOINTS.emailTemplates(id), {
      abortKey: 'GET_EMAIL_TEMPLATE_BY_ID',
    })
  },

  postEmailTemplate(payload) {
    return api.post(ENDPOINTS.emailTemplates(), payload)
  },

  updateEmailTemplate(emailId, payload) {
    return api.patch(ENDPOINTS.emailTemplates(emailId), payload)
  },

  deleteEmailTemplateById(id) {
    return api.delete(ENDPOINTS.emailTemplates(id))
  },

  undeleteEmailTemplateById(id) {
    return api.post(ENDPOINTS.undeleteEmailTemplate(id))
  },
}
