const PROSPECT_FIELD_TYPES = Object.freeze([
  {
    key: 'enum',
    name: 'Single option',
    description: 'Has multiple options out of which one is selectable',
    icon: 'mdi-checkbox-outline',
    component: 'VSelect',
    csvBoxType: 'list',
    useCSVType: 'select',
  },
  {
    key: 'set',
    name: 'Multiple options',
    description: 'Has multiple options out of which multiple are selectable',
    icon: 'mdi-checkbox-multiple-outline',
    component: 'VSelect',
    csvBoxType: 'multiselect_list',
    useCSVType: 'select',
  },
  {
    key: 'string',
    name: 'Text',
    description: 'Any text value under 255 characters',
    icon: 'mdi-text',
    component: 'VTextField',
    csvBoxType: 'text',
    useCSVType: 'string',
  },
  {
    key: 'text',
    name: 'Long text',
    description: 'Longer & detailed texts',
    icon: 'mdi-text-long',
    component: 'VTextArea',
    csvBoxType: 'text',
    useCSVType: 'string',
  },
  {
    key: 'date',
    name: 'Date',
    description: 'Any text value under 255 characters',
    icon: 'mdi-calendar-today-outline',
    component: 'DateTimePicker',
    csvBoxType: 'date',
    useCSVType: 'date',
  },
  {
    key: 'datetime',
    name: 'Date & time',
    description: 'Any text value under 255 characters',
    icon: 'mdi-calendar-clock-outline',
    component: 'DateTimePicker',
    csvBoxType: 'date',
    useCSVType: 'date',
  },
  {
    key: 'boolean',
    name: 'Boolean',
    description: 'Has on & off state',
    icon: 'mdi-toggle-switch-outline',
    component: 'VSwitch',
    csvBoxType: 'boolean',
    useCSVType: 'boolean',
  },
  {
    key: 'double',
    name: 'Decimal',
    description: 'Decimal values like 1.0',
    icon: 'mdi-decimal',
    component: 'VTextField',
    csvBoxType: 'number',
    useCSVType: 'number',
  },
  {
    key: 'integer',
    name: 'Numeric',
    description: 'Any number like 100',
    icon: 'mdi-numeric',
    component: 'VTextField',
    csvBoxType: 'number',
    useCSVType: 'number',
  },
  {
    key: 'link',
    name: 'Link',
    description:
      'Any link you want to pin on the contact. These appear on contact page header.',
    icon: 'mdi-link-variant',
    component: 'URLInputField',
    csvBoxType: 'url',
    useCSVType: 'string',
  },
])

export default PROSPECT_FIELD_TYPES

export function getProspectFieldType(key) {
  if (!key) return
  const type = Object.values(PROSPECT_FIELD_TYPES).find(
    (item) => key === item.key
  )
  return type
}

export function getProspectFieldName(key) {
  if (!key) return
  return getProspectFieldType(key)?.name
}

export function getProspectFieldOptions(key) {
  if (!key) return
  return getProspectFieldType(key)?.options
}

export function getProspectFieldIcon(key) {
  if (!key) return
  return getProspectFieldType(key)?.icon
}

export function getProspectFieldComponent(key) {
  if (!key) return
  return getProspectFieldType(key)?.component
}

export function getProspectFieldCSVBoxType(key) {
  if (!key) return
  return getProspectFieldType(key)?.csvBoxType
}

export function getProspectFieldUseCSVType(key) {
  if (!key) return
  return getProspectFieldType(key)?.useCSVType
}
