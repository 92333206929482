import store from '@/store/index'

const isFeatureEnabled =
  store?.getters?.['user/isFeatureFlagEnabled'] || (() => false)

export default {
  isNextBestActionsEnabled: () =>
    isFeatureEnabled('next-best-actions') || false,
  isPlivoDriverEnabled: () => isFeatureEnabled('plivo-driver') || false,
  isDialerHistorySearchEnabled: () =>
    isFeatureEnabled('dialer-history-search') || false,
  isHubspotDialerPluginEnabled: () =>
    isFeatureEnabled('hubspot-dialer-plugin') || false,
  isSalesforceDialerPluginEnabled: () =>
    isFeatureEnabled('salesforce-dialer-plugin') || false,
  isCallMonitoringEnabled: () => isFeatureEnabled('call-monitoring') || false,
  isAppNotificationsEnabled: () =>
    isFeatureEnabled('app-notifications') || false,
  isPowerDialingEnabled: () => isFeatureEnabled('power-dialing'),
  showPDCredits: () => isFeatureEnabled('show-pd-credits'),
  avoidDNCNumbersInImport: () =>
    isFeatureEnabled('avoid-dnc-numbers-in-contact-import') || false,
  isPinnedActionsEnabled: () => isFeatureEnabled('pinned-actions') || false,
  isZillowBtnEnabled: () => isFeatureEnabled('zillow') || false,
  isGoogleMapsBtnEnabled: () => isFeatureEnabled('gmaps-btn') || false,
  outboundTextMessagingEnabled: () =>
    isFeatureEnabled('outbound-text-messaging') || false,
  hidePdTableOnConnect: () =>
    isFeatureEnabled('hide-pd-table-on-connect') || false,
  plivoStunServersEnabled: () =>
    isFeatureEnabled('plivo-stun-servers-enabled') || false,
  csvUploadUnderMaintenance: () =>
    isFeatureEnabled('csv-upload-under-maintenance') || false,
  machineDetectionV2Enabled: () =>
    isFeatureEnabled('machine-detection-v2-enabled') || false,
  pdPrefetchContactEnabled: () =>
    isFeatureEnabled('pd-prefetch-contact-enabled') || false,
  isTelnyxVerifyEnabled: () =>
    isFeatureEnabled('telnyx-verify-enabled') || false,
  useOldNotesInPDCall: () =>
    isFeatureEnabled('use-old-notes-in-pd-call') || false,
  disableUpdateBilling: () =>
    isFeatureEnabled('disable-update-billing') || false,
}
