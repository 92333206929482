const VARIABLES = Object.freeze({
  ACCOUNT_NAME: {
    label: 'Account Name',
    body: 'account name',
    subject: 'account_name',
  },
  FIRST_NAME: {
    label: 'First Name',
    body: 'first name',
    subject: 'first_name',
  },
  LAST_NAME: {
    label: 'Last Name',
    body: 'last name',
    subject: 'last_name',
  },
  TITLE: {
    label: 'Title',
    body: 'title',
    subject: '',
  },
  USER_EMAIL: {
    label: 'User Email',
    body: 'user email',
    subject: '',
  },
  USER_FIRST_NAME: {
    label: 'User First Name',
    body: 'user first name',
    subject: '',
  },
  USER_LAST_NAME: {
    label: 'User Last Name',
    body: 'user last name',
    subject: '',
  },
  USER_PHONE_NUMBER: {
    label: 'User Phone Number',
    body: 'user phone number',
    subject: '',
  },
  USER_SIGNATURE: {
    label: 'User Signature',
    body: 'user signature',
    subject: '',
  },
})

export default VARIABLES

export function getPersonalizeOptions(value, includeLabel = false) {
  if (value !== 'subject' && value !== 'body') {
    return undefined
  }

  const options = Object.values(VARIABLES)
    .filter(i => i[value] !== '')
    .map(i => {
      if (i[value] !== '') {
        if (includeLabel) {
          return { label: i.label, value: i[value] }
        } else {
          return i[value]
        }
      }
    })

  return options
}
