import { getTags } from '@/services/api/ProspectTags'

// initial state
const state = () => ({
  status: '',
  allProspectTags: [],
})

// getters
const getters = {
  allProspectTags: (state) => state.allProspectTags,
  loadingStatus: (state) => state.status === 'loading',
}

// actions
const actions = {
  async fetchAllProspectTags({ commit }) {
    return new Promise((resolve, reject) => {
      commit('request')
      getTags()
        .then((resp) => {
          commit('success')
          commit('setAllProspectTags', resp)
          resolve(resp)
        })
        .catch((err) => {
          commit('error')
          reject(err)
        })
    })
  },
}

// mutations
const mutations = {
  setAllProspectTags(state, allProspectTags) {
    state.allProspectTags = allProspectTags
  },
  request(state) {
    state.status = 'loading'
  },
  success(state) {
    state.status = 'success'
  },
  error(state) {
    state.status = 'error'
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
