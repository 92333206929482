import api from './Api'
import { getTablePaginationParams } from './helpers'

const ENDPOINTS = {
  prospectTags: (id = '') => (id ? `/prospectTags/${id}` : `/prospectTags`),
}

export function getTags(options = {}) {
  let path = ENDPOINTS.prospectTags()
  let pathParams = []
  // Name search
  const { searchByName } = options
  if (searchByName) pathParams.push(`name=${searchByName}`)

  // Pagination
  const pagParams = getTablePaginationParams(options)
  if (pagParams) pathParams.push(pagParams)

  if (pathParams.length) path += '?' + pathParams.join('&')
  return api.get(path, { abortKey: 'GET_TAGS' })
}

export function createTag(tagName) {
  return api.post(ENDPOINTS.prospectTags(), { name: tagName })
}

export function updateTag(payload) {
  return api.patch(ENDPOINTS.prospectTags(payload.id), payload.tag)
}

export function deleteTag(id) {
  return api.delete(ENDPOINTS.prospectTags(id))
}
