import api from './Api'

const ENDPOINTS = {
  prospectFields: (id = '') =>
    id ? `/prospectFields/${id}` : `/prospectFields`,
}

export default {
  getProspectFields() {
    return api.get(ENDPOINTS.prospectFields())
  },

  createProspectField(payload) {
    return api.post(ENDPOINTS.prospectFields(), payload)
  },

  updateProspectField(payload) {
    const { id } = payload
    return api.put(ENDPOINTS.prospectFields(id), payload)
  },

  deleteProspectField(id) {
    return api.delete(ENDPOINTS.prospectFields(id))
  },
}
