import TransferGroupsApi from '@/services/api/TransferGroups'

const _cloneDeep = require('lodash/cloneDeep')
const _unionBy = require('lodash/unionBy')

// initial state
const state = () => ({
  transferGroups: [],
  groupActionLoading: null,
})

// getters
const getters = {
  transferGroups: (state) => state.transferGroups,
  isItemActionLoading: (state) => (id) => state.groupActionLoading === id,
}

// actions
const actions = {
  fetchTransferGroups({ commit, state }, options = {}) {
    commit('request')
    return new Promise((resolve, reject) => {
      TransferGroupsApi.fetchTransferGroups()
        .then((resp) => {
          // append new results instead of replace
          if (options.append) {
            const appendedData = _unionBy(state.transferGroups, resp, 'id')
            commit('setGroups', appendedData)
          } else {
            commit('setGroups', resp)
          }
          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          commit('error')
          reject(err)
        })
    })
  },
  createTransferGroup({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      TransferGroupsApi.postTransferGroup(payload)
        .then((resp) => {
          commit('setGroups', [resp, ...state.transferGroups])
          resolve(resp)
        })
        .catch((err) => reject(err))
    })
  },
  updateTransferGroup({ commit }, payload) {
    return new Promise((resolve, reject) => {
      TransferGroupsApi.editTransferGroup(payload)
        .then((resp) => {
          commit('setGroupById', resp)
          resolve(resp)
        })
        .catch((err) => reject(err))
    })
  },
  deleteTransferGroup({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit('setActionLoading', id)
      TransferGroupsApi.deleteTransferGroup(id)
        .then((resp) => {
          commit('removeGroup', id)
          resolve(resp)
        })
        .catch((err) => reject(err))
        .finally(() => {
          commit('setActionLoading', null)
        })
    })
  },
}

// mutations
const mutations = {
  setGroups(state, groups) {
    state.transferGroups = _cloneDeep(groups)
  },

  setGroupById(state, newGroupObj) {
    const index = state.transferGroups.findIndex(
      (group) => group.id === newGroupObj.id
    )
    if (index !== -1) {
      state.transferGroups = [
        ...state.transferGroups.slice(0, index),
        newGroupObj,
        ...state.transferGroups.slice(index + 1),
      ]
    }
  },

  removeGroup(state, id) {
    state.transferGroups = state.transferGroups.filter(
      (group) => group.id !== id
    )
  },

  request(state) {
    state.status = 'loading'
  },

  success(state) {
    state.status = 'success'
  },

  error(state) {
    state.status = 'error'
  },

  setActionLoading(state, id) {
    state.groupActionLoading = id
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
