import api from './Api'
import { CrmOptions } from '@/constants'
import {
  getTablePaginationParams,
  getInfScrollPaginationParams,
} from './helpers'

const ENDPOINTS = {
  prospects: `/crm/prospects`,
  accounts: `/crm/accounts`,
  mappingObjects: `/crm/mappingObjects`,
  resetOrgCRM: `/crm/resetOrganizationDefault`,
  syncAvailableCallDispositions: `/crm/syncAvailableCallDispositions`,
  syncAvailableProspectStatuses: `crm/syncAvailableProspectStatuses`,
  searchList: (id = '') => (id ? `/crm/reports/${id}` : `/crm/reports`),
  opts: {
    wildcardTableSearch: (text) => `tableSearch=${text}`,
  },
}

export default {
  getCrm() {
    return api.get('/crm')
  },
  getSalesforceOauthUrl() {
    return api.get('/authSalesForce/create')
  },
  getHubspotOauthUrl() {
    return api.get('/authHubspot/create')
  },
  getPipedriveOauthUrl() {
    return api.get('/authPipedrive/create')
  },
  authenticatePipedrive(code) {
    return api.post('/authPipedrive', { code })
  },
  delete(id) {
    return api.delete(`/crm/${id}`)
  },
  openOauthInNewWindow(crmProvider) {
    if (crmProvider === CrmOptions.SALESFORCE.value) {
      this.getSalesforceOauthUrl()
        .then((resp) => {
          window.open(resp.oauthUrl, 'Salesforce Authorization')
        })
        .catch((err) => {
          console.log(err)
        })
    } else if (crmProvider === CrmOptions.HUBSPOT.value) {
      this.getHubspotOauthUrl()
        .then((resp) => {
          window.open(resp.oauthUrl, 'HubSpot Authorization')
        })
        .catch((err) => {
          console.log(err)
        })
    } else if (crmProvider === CrmOptions.PIPEDRIVE.value) {
      this.getPipedriveOauthUrl()
        .then((resp) => {
          window.open(resp.oauthUrl, 'Pipedrive Authorization')
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  getCrmProspects(options = {}) {
    const { searchTable, filtersQuery, useInfScroll, listId } = options

    let path = listId ? ENDPOINTS.searchList(listId) : ENDPOINTS.prospects
    let pathParams = []

    // Only for crm contacts and not for crm lists
    if (filtersQuery) pathParams.push(filtersQuery)

    // Only for crm contacts and not for crm lists
    if (searchTable) {
      pathParams.push(ENDPOINTS.opts.wildcardTableSearch(searchTable))
    }

    // Pagination
    const pagParams = useInfScroll
      ? getInfScrollPaginationParams(options.infScrollOps)
      : getTablePaginationParams(options)

    if (pagParams) pathParams.push(pagParams)

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.get(path, { abortKey: 'GET_CRM_RECORDS' })
  },
  updateCrmContact(contact) {
    return api.post(ENDPOINTS.prospects, contact)
  },
  getCrmReports(options = {}) {
    let { type } = options
    let path = 'crm/reports'
    let pathParams = []

    // Pagination
    const pagParams = getTablePaginationParams({
      ...options,
      itemsPerPage: 250,
    })
    if (pagParams) pathParams.push(pagParams)

    type = `type=${type || 'prospect'}`

    pathParams.push(type)
    path += '?' + pathParams.join('&')

    return api.get(path)
  },
  runCrmReportById(id, options = {}) {
    let path = `crm/reports/${id}`
    let pathParams = []

    // Pagination
    const pagParams = getTablePaginationParams(options)
    if (pagParams) pathParams.push(pagParams)

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.get(path, { abortKey: 'GET_CRM_REPORT' })
  },
  getCrmAccounts(options = {}) {
    const { searchTable, filtersQuery, useInfScroll, listId } = options

    let path = listId ? ENDPOINTS.searchList(listId) : ENDPOINTS.accounts
    let pathParams = []

    // Only for crm contacts and not for crm lists
    if (filtersQuery) pathParams.push(filtersQuery)

    // Only for crm contacts and not for crm lists
    if (searchTable) {
      pathParams.push(ENDPOINTS.opts.wildcardTableSearch(searchTable))
    }

    // Pagination
    const pagParams = useInfScroll
      ? getInfScrollPaginationParams(options.infScrollOps)
      : getTablePaginationParams(options)
    if (pagParams) pathParams.push(pagParams)

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.get(path, { abortKey: 'GET_CRM_RECORDS' })
  },
  getCrmMappingObjects() {
    return api.get(ENDPOINTS.mappingObjects)
  },
  updateCrmAccount(account) {
    return api.post(ENDPOINTS.accounts, account)
  },
  syncAvailableCallOutcomes() {
    return api.post(ENDPOINTS.syncAvailableCallDispositions)
  },
  syncAvailableProspectStatuses() {
    return api.post(ENDPOINTS.syncAvailableProspectStatuses)
  },
  resetDefaultOrgCrm() {
    return api.post(ENDPOINTS.resetOrgCRM, {
      confirm: true,
    })
  },
  findCrmProspectByPhoneNumber({ phone }) {
    return api.post(`crm/prospects/byPhone`, { phone })
  },
  findCrmProspectByExternalId({ externalId }) {
    return api.post(`crm/prospects/byExternalId`, { externalId })
  },
}
