const SENTIMENTS = Object.freeze([
  {
    color: 'green',
    icon: 'mdi-emoticon-excited-outline',
    sentiment: 'Great',
    minValue: 2.5,
  },
  {
    color: 'blue',
    icon: 'mdi-emoticon-happy-outline',
    sentiment: 'Good',
    minValue: 1,
  },
  {
    color: 'gray darken-2',
    icon: 'mdi-emoticon-happy-outline',
    sentiment: 'Average',
    minValue: 0,
  },
  {
    color: 'orange',
    icon: 'mdi-emoticon-neutral-outline',
    sentiment: 'Poor',
    minValue: -2.5,
  },
  {
    color: 'red',
    icon: 'mdi-emoticon-sad-outline',
    sentiment: 'Bad',
  },
])

export default SENTIMENTS

/**
 * Returns sentiment type object with color, icon and sentiment wording
 *
 * @export
 * @param {string} value - The numeric/float value of sentiment
 * @return {object}
 */
export function getSentiment(value) {
  if (!value) return
  const sentiment =
    Object.values(SENTIMENTS).find(
      (sentiment) => value >= sentiment.minValue
    ) || SENTIMENTS[SENTIMENTS.length - 1]
  return sentiment
}
