const DRIVE_STATES = Object.freeze({
  IN_PROGRESS: {
    label: 'Pending',
    value: 'in_progress',
    color: '#ffcc65',
    bgColor: '#ffcc65',
  },
  COMPLETED: {
    label: 'Completed',
    value: 'completed',
    color: 'green accent-3',
    bgColor: '#c8f7d7',
  },
  CANCELED: {
    label: 'Canceled',
    value: 'incomplete',
    color: 'red',
    bgColor: 'red lighten-3',
  },
})

export default DRIVE_STATES

export function getDriveState(value) {
  if (!value) return
  const match = Object.values(DRIVE_STATES).find((i) => i.value === value)
  return match || ''
}
