import api from './Api'

export default {
  fetchPhones() {
    return api.get('/phones')
  },
  getcallerIdAuthCode(phoneNumber, useTelnyx = false) {
    return api.get(
      `/authCallerIdTwilio/create?phoneNumber=${encodeURIComponent(
        phoneNumber
      )}` + (useTelnyx ? '&useTelnyx=true' : '')
    )
  },
  update({ phoneId, forward_inbound_calls, deactivated }) {
    const payload = {}
    if (forward_inbound_calls != null)
      payload.forward_inbound_calls = forward_inbound_calls
    if (deactivated != null) payload.deactivated = deactivated
    return api.put(`/phones/${phoneId}`, payload)
  },
  delete(id) {
    return api.delete(`/phones/${id}`)
  },
  verifyAuthCode(payload) {
    return api.post(`/verifyCallerIdTwilio`, payload)
  },
  availablePhones(options = {}) {
    let path = '/buyPhones'
    let pathParams = []

    if (options.search) pathParams.push(`search=${options.search}`)
    if (options.country) pathParams.push(`country=${options.country}`)

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.get(path)
  },
  buyPhone(number, swapNumberId) {
    if (swapNumberId)
      return api.post(`/buyPhones/${number}`, { swap_phone_id: swapNumberId })
    return api.post(`/buyPhones/${number}`)
  },
}
