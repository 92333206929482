import AccountsApi from '@/services/api/Accounts'
import ProspectsApi from '@/services/api/Prospects'
import { getContactsWithActivity, transformCountValues } from '@/utils/helpers'

// initial state
const state = () => ({
  status: '',
  account: {},
  accountContacts: [],
  accountContactsStatus: '',
})

// getters
const getters = {
  account: (state) => state.account,
  accountLoading: (state) => state.status === 'loading',
  accountContacts: (state) =>
    state.accountContacts && state.accountContacts.length
      ? state.accountContacts.map((contact) => ({
          ...contact,
          full_name: contact.full_name
            ? contact.full_name
            : contact.first_name || contact.last_name
            ? `${contact.first_name} ${contact.last_name}`.trim()
            : '(unknown)',
        }))
      : [],
  accountContactsLoading: (state) => state.accountContactsStatus === 'loading',
}

// actions
const actions = {
  async fetchAccount({ state, commit }, id) {
    return new Promise((resolve, reject) => {
      commit('request')
      AccountsApi.getAccountById(id)
        .then((resp) => {
          commit('setAccount', {
            ...resp,
            user: resp.user || state.account.user,
          })
          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          commit('error')
          reject(err)
        })
    })
  },

  async fetchAccountContacts({ commit }, { accountId, options = {} }) {
    return new Promise((resolve, reject) => {
      commit('accountContactRequest')
      ProspectsApi.getProspectsByAccountId(accountId, {
        ...options,
        allUsers: true,
      })
        .then((resp) => {
          commit('setAccountContacts', resp.data)
          commit('accountContactSuccess')
          resolve(resp)
        })
        .catch((err) => {
          if (!err.aborted) {
            commit('accountContactError')
            reject(err)
          }
        })
    })
  },
}

// mutations
const mutations = {
  setAccount(state, account) {
    state.account = account
  },
  request(state) {
    state.status = 'loading'
  },
  success(state) {
    state.status = 'success'
  },
  error(state) {
    state.status = 'error'
  },
  setAccountContacts(state, contacts) {
    state.accountContacts = contacts
  },
  setAccountContactsActivity(state, activityList) {
    state.accountContacts = getContactsWithActivity(
      state.accountContacts,
      transformCountValues(activityList)
    )
  },
  accountContactRequest(state) {
    state.accountContactsStatus = 'loading'
  },
  accountContactSuccess(state) {
    state.accountContactsStatus = 'success'
  },
  accountContactError(state) {
    state.accountContactsStatus = 'error'
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
