import ProspectsApi from '@/services/api/Prospects'
import router from './../../../router'
import store from './../../../store'

export default {
  handle(type, data = null) {
    const doFunction = type.split('-')[1]
    this[doFunction](data)
  },
  all(data) {
    new Promise(() => {
      // TODO: This is broken with the pagination updates..
      ProspectsApi.getProspectsByExternalIds(data, {
        itemsPerPage: 1000,
      })
        .then((resp) => {
          parent.postMessage(
            {
              type: 'prospect-all-response',
              data: resp.data,
            },
            '*'
          )
        })
        .catch((err) => {
          //error to chrome
          console.log(err)
        })
    })
    return
  },
  leads(data) {
    new Promise(() => {
      // TODO: This is broken with the pagination updates..
      ProspectsApi.getProspectsByExternalIds(data, {
        itemsPerPage: 1000,
      })
        .then((resp) => {
          parent.postMessage(
            {
              type: 'prospect-leads-response',
              data: resp.data,
            },
            '*'
          )
        })
        .catch((err) => {
          //error to chrome
          console.log(err)
        })
    })
    return
  },
  open(data) {
    parent.postMessage(
      {
        type: 'sidebar-sidecart',
      },
      '*'
    )
    const prospectId = data.id
    router.push({
      path: `/people/${prospectId}`,
    })
  },
  add(data) {
    parent.postMessage(
      {
        type: 'sidebar-sidecart',
      },
      '*'
    )
    const doIfConfirmed = function () {
      new Promise(() => {
        ProspectsApi.addProspectByExternalId({
          externalId: data.externalId,
          isLead: data.isLead,
        })
          .then((resp) => {
            const prospectId = resp.id
            parent.postMessage(
              {
                type: 'prospect-added-response',
                data: {
                  externalId: data.externalId,
                  id: prospectId,
                },
              },
              '*'
            )
            router.push({
              path: `/people/${prospectId}`,
            })
          })
          .catch((err) => {
            //error to chrome
            console.log(err)
          })
      })
    }
    store.commit('appModal/showModal', {
      title: 'Confirm add',
      text: 'Are you sure you want to add the prospect?',
      doIfConfirmed: doIfConfirmed,
    })
  },
  bulk(data) {
    parent.postMessage(
      {
        type: 'sidebar-sidecart',
      },
      '*'
    )
    const doIfConfirmed = function () {
      new Promise(() => {
        ProspectsApi.addBulkProspectsByExternalId({
          externalIds: data.externalIds,
          isLead: data.isLead,
        })
          .then((resp) => {
            const successCount = resp.successCount
            const errorCount = resp.errorCount
            parent.postMessage(
              {
                type: 'prospect-bulk-added-response',
                isLead: data.isLead,
              },
              '*'
            )
            if (successCount != 0 && errorCount == 0) {
              this.$store.commit('snackbar/setSnack', {
                snack: 'Prospect(s) succesfully added',
                snackType: 'success',
              })
            } else if (successCount != 0 && errorCount != 0) {
              this.$store.commit('snackbar/setSnack', {
                snack: `${successCount} prospect(s) added. ${errorCount} could not be added, they may already be in Symbo.`,
                snackType: 'warning',
                multiLine: true,
              })
            } else if (successCount == 0 && errorCount != 0) {
              this.$store.commit('snackbar/setSnack', {
                snack: `Prospect(s) could not be added, they may already be in Symbo.`,
                snackType: 'error',
                multiLine: true,
              })
            }
          })
          .catch((err) => {
            //error to chrome
            console.log(err)
          })
      })
    }
    store.commit('appModal/showModal', {
      title: 'Confirm Bulk Import',
      text: 'Are you sure you want to add these prospects?',
      doIfConfirmed: doIfConfirmed,
    })
  },
  get(data) {
    new Promise(() => {
      ProspectsApi.getProspectByExternalId(data.externalId)
        .then((resp) => {
          console.log(resp)
          parent.postMessage(
            {
              type: 'prospect-get-response',
              data: resp.data[0],
            },
            '*'
          )
        })
        .catch((err) => {
          //error to chrome
          console.log(err)
        })
    })
    return
  },
  converted(data) {
    new Promise(() => {
      ProspectsApi.getProspectByExternalId(data.externalId)
        .then((resp) => {
          ProspectsApi.getProspectById(resp.id)
        })
        .catch((err) => {
          console.log(err)
        })
    })
    return
  },
}
