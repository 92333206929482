<template>
  <overlay-scrollbars :options="options" class="symboScrollbar">
    <slot />
  </overlay-scrollbars>
</template>

<script>
import { OverlayScrollbarsComponent } from '../../../node_modules/overlayscrollbars-vue'
import '../../../node_modules/overlayscrollbars/css/OverlayScrollbars.css'

export default {
  props: {
    options: {
      type: Object,
      default: function () {
        return {
          className: 'os-theme-dark',
          paddingAbsolute: true,
          scrollbars: {
            visibility: 'auto',
            autoHide: 'leave',
          },
        }
      },
    },
    'max-height': {
      type: String,
      default: '',
    },
  },
  components: {
    'overlay-scrollbars': OverlayScrollbarsComponent,
  },
}
</script>
