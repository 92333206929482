// Parent Step Types
const AUTO_EMAIL = Object.freeze({
  label: 'Automated Email',
  value: 'auto_email',
  icon: 'mdi-send-clock',
  color: '#FF5F85',
  tooltip: 'Auto Email',
  labelWidth: '73px',
})

const EMAIL = Object.freeze({
  label: 'Email',
  value: 'manual_email',
  shortValue: 'email',
  icon: 'mdi-email',
  color: '#FF9054',
  tooltip: 'Email',
})

const CALL = Object.freeze({
  label: 'Call',
  value: 'call',
  icon: 'mdi-phone',
  color: '#46BCFF',
  tooltip: 'Call',
})

const LINKEDIN = Object.freeze({
  label: 'LinkedIn',
  value: 'linkedin',
  icon: 'mdi-linkedin',
  color: '#0a66c2',
  tooltip: 'LinkedIn',
  subtypes: [
    { label: 'Connect', value: 'linkedin_connect' },
    { label: 'Engage', value: 'linkedin_engage' },
    { label: 'Follow', value: 'linkedin_follow' },
    { label: 'InMail', value: 'linkedin_inmail' },
    { label: 'Message', value: 'linkedin_message' },
  ],
})

const TASK = Object.freeze({
  label: 'Task',
  value: 'task',
  icon: 'mdi-check-bold',
  color: '#9E9E9E',
  tooltip: 'Task',
})

// Sub Step Types
const LINKEDIN_CONNECT = Object.freeze(
  Object.freeze({
    label: 'Connect',
    labelWidth: '128px',
    value: 'linkedin_connect',
    tooltip: 'LinkedIn - Connect',
    color: '#0a66c2',
    icon: 'mdi-linkedin',
  })
)

const LINKEDIN_ENGAGE = Object.freeze(
  Object.freeze({
    label: 'Engage',
    labelWidth: '122px',
    value: 'linkedin_engage',
    tooltip: 'LinkedIn - Engage',
    color: '#0a66c2',
    icon: 'mdi-linkedin',
  })
)

const LINKEDIN_FOLLOW = Object.freeze(
  Object.freeze({
    label: 'Follow',
    labelWidth: '115px',
    value: 'linkedin_follow',
    tooltip: 'LinkedIn - Follow',
    color: '#0a66c2',
    icon: 'mdi-linkedin',
  })
)

const LINKEDIN_INMAIL = Object.freeze(
  Object.freeze({
    label: 'InMail',
    labelWidth: '113px',
    value: 'linkedin_inmail',
    tooltip: 'LinkedIn - InMail',
    color: '#0a66c2',
    icon: 'mdi-linkedin',
  })
)

const LINKEDIN_MESSAGE = Object.freeze(
  Object.freeze({
    label: 'Message',
    labelWidth: '133px',
    value: 'linkedin_message',
    tooltip: 'LinkedIn - Message',
    color: '#0a66c2',
    icon: 'mdi-linkedin',
  })
)

const NOTE = Object.freeze(
  Object.freeze({
    label: 'Note',
    labelWidth: '100px',
    value: 'note',
    tooltip: 'Note',
    color: '#9E9E9E',
    icon: 'mdi-note',
  })
)

const FORM_SUBMISSION = Object.freeze(
  Object.freeze({
    label: 'Form Submission',
    labelWidth: '100px',
    value: 'external_form',
    tooltip: 'Form Submission',
    color: '#FF8A65',
    icon: 'mdi-hubspot',
  })
)

export const BASE_STEP_TYPES = Object.freeze([
  AUTO_EMAIL,
  EMAIL,
  CALL,
  LINKEDIN,
  TASK,
])

const USABLE_STEP_TYPES = Object.freeze({
  AUTO_EMAIL,
  EMAIL,
  CALL,
  LINKEDIN_CONNECT,
  LINKEDIN_ENGAGE,
  LINKEDIN_FOLLOW,
  LINKEDIN_INMAIL,
  LINKEDIN_MESSAGE,
  TASK,
  NOTE,
  FORM_SUBMISSION,
})

export default USABLE_STEP_TYPES

/**
 * Returns Drive Step type object based on an actions `action_type`
 * or drive steps `step_type` fields
 *
 * @export
 * @param {string} value - The `value` of StepType to match
 * @return {object}
 */
export function getStepType(value) {
  if (!value) return
  // normalize stepType for actions in drives (start with 'drive_')
  const valNormalized = value.replace('drive_', '').replace('inbound_', '')
  const match = Object.values(USABLE_STEP_TYPES).find(
    (i) => i.value === valNormalized || i.shortValue === valNormalized
  )
  return match || ''
}

export function getBaseStepTypeArray() {
  return Object.values(BASE_STEP_TYPES)
}
