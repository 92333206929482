import {
  convertToUserTimeZone,
  FRIENDLY_DATE_FORMAT,
} from '@/utils/dateHelpers'
import { openInTab } from '@/utils/helpers'

import router from '@/router'
import store from '@/store'
const user = () => store.getters['user/user']

const APP_WARNING_TYPES = Object.freeze([
  {
    type: 'missing_payment_method',
    severity: 'critical',
    color: 'red lighten-4',
    textColor: 'red--text text--darken-2',
    actionColor: 'red darken-2',
    getMessage: (isAdmin) =>
      isAdmin
        ? `Your company has no payment method added.`
        : 'Your company has no payment method added. Contact your admin.',
    onAction: () => router.push('/org-settings?type=payment-methods'),
    actionName: 'Add Payment Method',
  },
  {
    type: 'invoice_overdue',
    severity: 'critical',
    color: 'red lighten-4',
    textColor: 'red--text text--darken-2',
    actionColor: 'red darken-2',
    getMessage: (isAdmin, data = null) =>
      isAdmin
        ? `Your company invoice is overdue. Your account will blocked on ${convertToUserTimeZone(
            data?.will_block_at,
            true,
            false,
            FRIENDLY_DATE_FORMAT
          )}.`
        : `Your company invoice is overdue. Your account will blocked on ${convertToUserTimeZone(
            data?.will_block_at,
            true,
            false,
            FRIENDLY_DATE_FORMAT
          )}. Contact your admin.`,
    onAction: () =>
      router.push('/org-settings?type=subscription,payment-methods,wallet'),
    actionName: 'Pay now',
  },
  {
    type: 'low_wallet_balance',
    severity: 'important',
    color: 'orange lighten-4',
    textColor: 'orange--text text--darken-4',
    actionColor: 'orange darken-4',
    getMessage: (isAdmin) =>
      isAdmin
        ? `Your company's wallet balance is low.`
        : `Your company's wallet balance is low. Contact your admin.`,
    onAction: () => router.push('/org-settings?type=wallet'),
    actionName: 'Add balance',
  },
  {
    type: 'invoice_overdue_blocked',
    severity: 'blocker',
    getMessage: (isAdmin) =>
      isAdmin
        ? `Your company invoice is overdue and your account is blocked.`
        : 'Your company invoice is overdue and your account is blocked. Contact your admin.',
    onAction: () =>
      router.push('/org-settings?type=subscription,payment-methods,wallet'),
  },
  {
    type: 'trial_ended',
    severity: 'critical',
    color: 'red lighten-4',
    textColor: 'red--text text--darken-2',
    actionColor: 'red darken-2',
    getMessage: (isAdmin, data) =>
      isAdmin
        ? `Your company trial has ended.  Your account will blocked on ${convertToUserTimeZone(
            data?.will_block_at,
            true,
            false,
            FRIENDLY_DATE_FORMAT
          )}.`
        : `Your company trial has ended.  Your account will blocked on ${convertToUserTimeZone(
            data?.will_block_at,
            true,
            false,
            FRIENDLY_DATE_FORMAT
          )}. Contact your admin.`,
    onAction: () => router.push('/org-settings?type=subscription'),
  },
  {
    type: 'trial_ended_blocked',
    severity: 'blocker',
    getMessage: (isAdmin) =>
      isAdmin
        ? `Your company trial has ended and your account is blocked.`
        : 'Your company trial has ended and your account is blocked. Contact your admin.',
    onAction: () => router.push('/org-settings?type=subscription'),
  },
  {
    type: 'payment_failed',
    severity: 'critical',
    color: 'red lighten-4',
    textColor: 'red--text text--darken-2',
    actionColor: 'red darken-2',
    actionName: 'Make payment',
    getMessage: (isAdmin, data) =>
      isAdmin
        ? `Your payment failed. Your account will blocked on ${convertToUserTimeZone(
            data?.will_block_at,
            true,
            false,
            FRIENDLY_DATE_FORMAT
          )}.`
        : `Your company's recent payment failed. Your account will be blocked on ${convertToUserTimeZone(
            data?.will_block_at,
            true,
            false,
            FRIENDLY_DATE_FORMAT
          )}. Contact your admin.`,
    onAction: (data) => {
      return data?.url
        ? openInTab(data.url)
        : router.push('/org-settings?type=subscription,payment-methods,wallet')
    },
  },
  {
    type: 'hijack-mode',
    severity: 'critical',
    color: 'amber lighten-3',
    textColor: 'primary--text',
    actionColor: 'amber darken-2',
    actionName: 'Exit hijack',
    getMessage: () => (user() ? `Logged in as ${user().full_name}` : ''),
  },
])

export default APP_WARNING_TYPES

export function getWarningColor(type) {
  if (!type) return ''
  const warningType = Object.values(APP_WARNING_TYPES).find(
    (item) => type === item.type
  )
  return warningType?.color || ''
}

export function getWarningMessage(type, isAdmin, data) {
  if (!type) return ''
  const warningType = Object.values(APP_WARNING_TYPES).find(
    (item) => type === item.type
  )
  return warningType?.getMessage(isAdmin, data) || ''
}

export function getWarningLink(type) {
  if (!type) return ''
  const warningType = Object.values(APP_WARNING_TYPES).find(
    (item) => type === item.type
  )
  return warningType?.link || ''
}

export function getWarningSeverity(type) {
  if (!type) return ''
  const warningType = Object.values(APP_WARNING_TYPES).find(
    (item) => type === item.type
  )
  return warningType?.severity || ''
}

export function getWarningActionName(type) {
  if (!type) return 'Fix'
  const warningType = Object.values(APP_WARNING_TYPES).find(
    (item) => type === item.type
  )
  return warningType?.actionName || 'Fix'
}

export function getWarningAction(type) {
  if (!type) return null
  const warningType = Object.values(APP_WARNING_TYPES).find(
    (item) => type === item.type
  )
  return warningType?.onAction || null
}

export function getWarningTextColor(type) {
  if (!type) return ''
  const warningType = Object.values(APP_WARNING_TYPES).find(
    (item) => type === item.type
  )
  return warningType?.textColor || ''
}

export function getWarningActionColor(type) {
  if (!type) return ''
  const warningType = Object.values(APP_WARNING_TYPES).find(
    (item) => type === item.type
  )
  return warningType?.actionColor || ''
}
