const defaultFilterTemplates = Object.freeze({
  contacts: [
    {
      id: '2',
      name: 'Contacts',
      type: 'contacts',
      filter: [
        {
          id: 1,
          filterBy: 'lead',
          filterValue: {
            val1: [
              {
                text: 'False',
                value: 0,
              },
            ],
          },
          filterOperation: 'leadIs',
        },
      ],
      isDefault: true,
    },
    {
      id: '3',
      name: 'Leads',
      type: 'contacts',
      filter: [
        {
          id: 2,
          filterBy: 'lead',
          filterValue: {
            val1: [
              {
                text: 'True',
                value: 1,
              },
            ],
          },
          filterOperation: 'leadIs',
        },
      ],
      isDefault: true,
    },
    {
      id: '4',
      name: 'Opted out',
      type: 'contacts',
      filter: [
        {
          id: 3,
          filterBy: 'optedOut',
          filterOperation: 'optOutIs',
          filterValue: {
            val1: [
              {
                text: 'True',
                value: 1,
              },
            ],
          },
        },
      ],
      isDefault: true,
    },
    {
      id: '5',
      name: 'Unsafe emails',
      type: 'contacts',
      filter: [
        {
          id: 4,
          filterBy: 'emailStatus',
          filterOperation: 'notIncludesSelect',
          filterValue: {
            val1: [
              {
                text: 'Safe',
                value: 'safe',
              },
            ],
          },
        },
      ],
      isDefault: true,
    },
  ],
  'manual-actions': [
    {
      id: '2',
      name: 'Today',
      type: 'manual-actions',
      filter: [
        {
          id: 1,
          filterBy: 'dueDate',
          filterOperation: 'isDueDate',
          filterValue: {
            dateOption: 'today',
            dateValue: null,
          },
        },
      ],
      isFixed: true,
      isDefault: true,
    },
    {
      id: '3',
      name: 'Overdue',
      type: 'manual-actions',
      filter: [
        {
          id: 2,
          filterBy: 'dueDate',
          filterOperation: 'isDueDate',
          filterValue: {
            dateOption: 'overdue',
            dateValue: null,
          },
        },
      ],
      isFixed: true,
      isDefault: true,
    },
    {
      id: '4',
      name: 'Future',
      type: 'manual-actions',
      filter: [
        {
          id: 4,
          filterBy: 'dueDate',
          filterOperation: 'isDueDate',
          filterValue: {
            dateOption: 'future',
            dateValue: null,
          },
        },
      ],
      isFixed: true,
      isDefault: true,
    },
    {
      id: '5',
      name: 'Calls',
      type: 'manual-actions',
      filter: [
        {
          id: 3,
          filterBy: 'actionType',
          filterOperation: 'actionTypeIncludes',
          filterValue: {
            val2: [
              {
                text: 'Call',
                value: 'drive_call,call',
              },
            ],
          },
        },
      ],
      isDefault: true,
    },
    {
      id: '6',
      name: 'Emails',
      type: 'manual-actions',
      filter: [
        {
          id: 4,
          filterBy: 'actionType',
          filterOperation: 'actionTypeIncludes',
          filterValue: {
            val2: [
              {
                text: 'Manual Email',
                value: 'drive_manual_email',
              },
            ],
          },
        },
      ],
      isDefault: true,
    },
    {
      id: '7',
      name: 'Tasks',
      type: 'manual-actions',
      filter: [
        {
          id: 5,
          filterBy: 'actionType',
          filterOperation: 'actionTypeIncludes',
          filterValue: {
            val2: [
              {
                text: 'Task',
                value: 'drive_task,task',
              },
            ],
          },
        },
      ],
      isDefault: true,
    },
  ],
  'duplicate-contacts': [
    {
      id: '2',
      name: 'Email Address',
      type: 'duplicate-contacts',
      filter: [
        {
          id: 1,
          filterBy: 'duplicateBy',
          filterOperation: 'duplicateBy',
          filterValue: {
            val1: [
              {
                text: 'Email',
                value: 'email',
              },
            ],
          },
        },
      ],
      isFixed: true,
      isDefault: true,
    },
    {
      id: '3',
      name: 'Phone Number',
      type: 'duplicate-contacts',
      filter: [
        {
          id: 2,
          filterBy: 'duplicateBy',
          filterOperation: 'duplicateBy',
          filterValue: {
            val1: [
              {
                text: 'Number',
                value: 'number',
              },
            ],
          },
        },
      ],
      isFixed: true,
      isDefault: true,
    },
  ],
  'call-details': [
    {
      id: '1',
      name: 'Outbound calls',
      type: 'call-details',
      filter: [
        {
          id: 1,
          filterBy: 'callDirection',
          filterOperation: 'includesSelect',
          filterValue: {
            val1: [
              {
                text: 'Outbound',
                value: 'outbound',
              },
            ],
          },
        },
      ],
      isDefault: true,
    },
    {
      id: '2',
      name: 'Answered outbound calls',
      type: 'call-details',
      filter: [
        {
          id: 2,
          filterBy: 'callAnswered',
          filterOperation: 'is',
          filterValue: {
            val1: [
              {
                text: 'True',
                value: 1,
              },
            ],
          },
        },
        {
          id: 3,
          filterBy: 'callDirection',
          filterOperation: 'includesSelect',
          filterValue: {
            val1: [
              {
                text: 'Outbound',
                value: 'outbound',
              },
            ],
          },
        },
        {
          id: 4,
          filterBy: 'machineDetected',
          filterOperation: 'is',
          filterValue: {
            val1: [
              {
                text: 'Not Detected',
                value: 0,
              },
            ],
          },
        },
      ],
      isDefault: true,
    },
    {
      id: '3',
      name: 'Inbound calls',
      type: 'call-details',
      filter: [
        {
          id: 3,
          filterBy: 'callDirection',
          filterOperation: 'includesSelect',
          filterValue: {
            val1: [
              {
                text: 'Inbound',
                value: 'inbound',
              },
            ],
          },
        },
      ],
      isDefault: true,
    },
    {
      id: '4',
      name: 'Missed inbound calls',
      type: 'call-details',
      filter: [
        {
          id: 1,
          filterBy: 'callDirection',
          filterOperation: 'includesSelect',
          filterValue: {
            val1: [
              {
                text: 'Inbound',
                value: 'inbound',
              },
            ],
          },
        },
        {
          id: 2,
          filterBy: 'callAnswered',
          filterOperation: 'is',
          filterValue: {
            val1: [
              {
                text: 'False',
                value: 0,
              },
            ],
          },
        },
      ],
      isDefault: true,
    },
    {
      id: '5',
      name: 'Inbound voicemails',
      type: 'call-details',
      filter: [
        {
          id: 1,
          filterBy: 'inboundVoicemail',
          filterOperation: 'exists',
          filterValue: {},
        },
      ],
      isDefault: true,
    },
    {
      id: '6',
      name: 'Voicemail drop attempted',
      type: 'call-details',
      filter: [
        {
          id: 1,
          filterBy: 'voicemailDrop',
          filterOperation: 'is',
          filterValue: {
            val1: [
              {
                text: 'Attempted',
                value: 1,
              },
            ],
          },
        },
      ],
      isDefault: true,
    },
    {
      id: '7',
      name: 'Call with notes',
      type: 'call-details',
      filter: [
        {
          id: 1,
          filterBy: 'hasCallNote',
          filterOperation: 'exists',
          filterValue: {},
        },
      ],
      isDefault: true,
    },
    {
      id: '8',
      name: 'Recorded calls',
      type: 'call-details',
      filter: [
        {
          id: 1,
          filterBy: 'hasCallRecording',
          filterOperation: 'exists',
          filterValue: {},
        },
      ],
      isDefault: true,
    },
    {
      id: '9',
      name: 'Sequence calls',
      type: 'call-details',
      filter: [
        {
          id: 1,
          filterBy: 'driveForCalls',
          filterOperation: 'exists',
          filterValue: {},
        },
      ],
      isDefault: true,
    },
    {
      id: '10',
      name: 'Has coach note',
      type: 'call-details',
      filter: [
        {
          id: 1,
          filterBy: 'hasCoachingNote',
          filterOperation: 'exists',
          filterValue: {},
        },
      ],
      isDefault: true,
    },
  ],
  'email-details': [
    {
      id: '1',
      name: 'Manual emails',
      type: 'email-details',
      filter: [
        {
          id: 1,
          filterBy: 'emailType',
          filterOperation: 'is',
          filterValue: {
            val1: [
              {
                text: 'Manual',
                value: 0,
              },
            ],
          },
        },
      ],
      isDefault: true,
    },
    {
      id: '2',
      name: 'Automated emails',
      type: 'email-details',
      filter: [
        {
          id: 1,
          filterBy: 'emailType',
          filterOperation: 'is',
          filterValue: {
            val1: [
              {
                text: 'Automated',
                value: 1,
              },
            ],
          },
        },
      ],
      isDefault: true,
    },
    {
      id: '3',
      name: 'Opened',
      type: 'email-details',
      filter: [
        {
          id: 1,
          filterBy: 'emailOpened',
          filterOperation: 'is',
          filterValue: {
            val1: [
              {
                text: 'True',
                value: 1,
              },
            ],
          },
        },
      ],
      isDefault: true,
    },
    {
      id: '4',
      name: 'Replied',
      type: 'email-details',
      filter: [
        {
          id: '1de7c7e2-043d-467f-8794-16dda70d59bd',
          filterBy: 'emailReplied',
          filterOperation: 'is',
          filterValue: {
            val1: [
              {
                text: 'True',
                value: 1,
              },
            ],
          },
        },
      ],
      isDefault: true,
    },
    {
      id: '5',
      name: 'Clicked',
      type: 'email-details',
      filter: [
        {
          id: 1,
          filterBy: 'emailClicked',
          filterOperation: 'is',
          filterValue: {
            val1: [
              {
                text: 'True',
                value: 1,
              },
            ],
          },
        },
      ],
      isDefault: true,
    },
    {
      id: '6',
      name: 'Sequence emails',
      type: 'email-details',

      filter: [
        {
          id: 1,
          filterBy: 'driveForCalls',
          filterOperation: 'exists',
          filterValue: {},
        },
      ],
      isDefault: true,
    },
    {
      id: '7',
      name: 'Untracked emails',
      type: 'email-details',
      filter: [
        {
          id: 1,
          filterBy: 'emailTracking',
          filterOperation: 'off',
          filterValue: {},
        },
      ],
      isDefault: true,
    },
  ],
})
const getDefaultFilters = (tableName) =>
  defaultFilterTemplates[tableName]?.filter((item) => !item.isFixed) || []

const getPinnedDefaultTabs = (tableName, pinnedTabs) =>
  defaultFilterTemplates[tableName]?.filter(
    (item) =>
      item.isFixed || (pinnedTabs && pinnedTabs.some((id) => item.id === id))
  )

const getUnpinnedDefaultTabs = (tableName, pinnedTabs) =>
  pinnedTabs
    ? defaultFilterTemplates[tableName]?.filter(
        (item) => !item.isFixed && !pinnedTabs.some((id) => item.id === id)
      ) || []
    : defaultFilterTemplates[tableName]?.filter((item) => !item.isFixed) || []

export {
  defaultFilterTemplates,
  getPinnedDefaultTabs,
  getUnpinnedDefaultTabs,
  getDefaultFilters,
}
