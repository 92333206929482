import CrmApi from '@/services/api/Crm'

// initial state
const state = () => ({
  crmReports: [],
})

// getters
const getters = {
  crmReports: (state) => state.crmReports,
}

// actions
const actions = {
  async fetchCrmLists({ commit }) {
    return new Promise((resolve, reject) => {
      commit('request')
      CrmApi.getCrmReports()
        .then((resp) => {
          commit('setCrmReports', resp.data)
          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          // ignore error if the req. was aborted
          if (!err.aborted) {
            commit('error')
            reject(err)
          }
        })
    })
  },
}

// mutations
const mutations = {
  setCrmReports(state, data) {
    state.crmReports = data.length > 0 ? data : []
  },
  setSelectedReport(state, list) {
    state.selectedReport = list
  },
  request(state) {
    state.status = 'loading'
  },
  success(state) {
    state.status = 'success'
  },
  error(state) {
    state.status = 'error'
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
