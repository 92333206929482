import Vue from 'vue'
import store from '../../store'
import cubejs from '@cubejs-client/core'
import { transformCountValues } from '../../utils/helpers'

export default function () {
  const cubeToken = store.getters['user/cubeToken']
  const cubeInstance = cubejs(cubeToken, {
    apiUrl: process.env.VUE_APP_SYMBO_ANALYTICS_URL + '/cubejs-api/v1',
  })
  const cubejsApi = {
    ...cubeInstance,
    load: (q) => {
      return new Promise((resolve, reject) => {
        cubeInstance
          .load(q)
          .then((res) => {
            const normalizedResponse = transformCountValues(res)
            resolve(normalizedResponse)
          })
          .catch((err) => reject(err))
      })
    },
  }
  Vue.prototype.$cubejsApi = cubejsApi
  return cubejsApi
}
