<template>
  <div>
    <div class="text-caption grey--text text--darken-1">
      <slot name="label" v-if="$slots.label" />
      <template v-else>{{ label }}</template>
    </div>
    <div class="text-body-2 grey--text text--darken-4">
      <slot name="value" v-if="$slots.value" />
      <template v-else>{{ value }}</template>
    </div>
  </div>
</template>

<style></style>

<script>
export default {
  props: ['label', 'value'],
}
</script>
