import DrivesApi from '@/services/api/Drives'
import { getDateRangeOpt } from '@/constants/DateRangeOps'

import {
  getDrivesWithEmailAnalytics,
  getDrivesWithCallAnalytics,
  getDrivesWithActionAnalytics,
  getDrivesWithOutcomeAnalytics,
  getDrivesWithEngagedCounts,
} from '@/utils/helpers'

const _sortBy = require('lodash/sortBy')

// initial state
const state = () => ({
  status: '',
  enabledDrives: [],
  allDrives: [],
  showArchived: false,
  showDeleted: false,
  selectedDateRange: getDateRangeOpt('ever'),
})

// getters
const getters = {
  allEnabledDrives: (state) => state.enabledDrives,
  allDrives: (state) => state.allDrives,
  loadingStatus: (state) => state.status === 'loading',
  showArchived: (state) => state.showArchived,
  showDeleted: (state) => state.showDeleted,
  selectedDateRange: (state) => state.selectedDateRange,
}

// actions
const actions = {
  async fetchEnabledDrives({ commit, state }, options = {}) {
    return new Promise((resolve, reject) => {
      commit('request')

      // clear out existing records if beginning an append paginate
      if (options.append && options.page === 1) {
        commit('setEnabledDrives', [])
      }

      DrivesApi.getDrives({
        ...options,
        showEnabled: true,
      })
        .then((resp) => {
          // append new results instead of replace for growing select lists
          if (options.append) {
            const appendedDrives = [...state.enabledDrives, ...resp.data]
            commit('setEnabledDrives', appendedDrives)
          } else {
            commit('setEnabledDrives', resp.data)
          }
          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          // ignore error if the req. was aborted
          if (!err.aborted) {
            commit('error')
            reject(err)
          }
        })
    })
  },

  async fetchAllDrives({ commit, state }, options = {}) {
    return new Promise((resolve, reject) => {
      commit('request')

      // clear out existing records if beginning an append paginate
      if (options.append && options.page === 1) {
        commit('setAllDrives', [])
      }

      DrivesApi.getDrives({
        ...options,
        showArchived: state.showArchived,
        showDeleted: state.showDeleted,
        resourceRequests: ['userSummary'],
      })
        .then((resp) => {
          // append new results instead of replace for growing select lists
          if (options.append) {
            const sortedDriveSteps = resp.data.map((drive) => ({
              ...drive,
              driveSteps: _sortBy(drive.driveSteps, ['step_order']),
            }))
            const appendedDrives = [...state.allDrives, ...sortedDriveSteps]
            commit('setAllDrives', appendedDrives)
          } else {
            commit('setAllDrives', resp.data)
          }

          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          // ignore error if the req. was aborted
          if (!err.aborted) {
            commit('error')
            reject(err)
          }
        })
    })
  },
}

// mutations
const mutations = {
  setEnabledDrives(state, enabledDrives) {
    state.enabledDrives = enabledDrives
  },
  setAllDrives(state, allDrives) {
    state.allDrives = allDrives
  },
  setDrivesEmailAnalytics(state, activityList) {
    state.allDrives = getDrivesWithEmailAnalytics(state.allDrives, activityList)
  },
  setDrivesCallAnalytics(state, payload = {}) {
    state.allDrives = getDrivesWithCallAnalytics(
      state.allDrives,
      payload.activityList,
      payload.useV2
    )
  },
  setDrivesActionAnalytics(state, activityList) {
    state.allDrives = getDrivesWithActionAnalytics(
      state.allDrives,
      activityList
    )
  },
  setDriveOutcomeAnalytics(state, activityList) {
    state.allDrives = getDrivesWithOutcomeAnalytics(
      state.allDrives,
      activityList
    )
  },
  setDriveEngagedCounts(state, activityList) {
    state.allDrives = getDrivesWithEngagedCounts(state.allDrives, activityList)
  },
  setShowArchived(state, showArchived) {
    if (showArchived) state.showDeleted = false
    state.showArchived = showArchived
  },
  setShowDeleted(state, showDeleted) {
    if (showDeleted) state.showArchived = false
    state.showDeleted = showDeleted
  },
  setSelectedDateRange(state, newVal) {
    state.selectedDateRange = newVal
  },
  request(state) {
    state.status = 'loading'
  },
  success(state) {
    state.status = 'success'
  },
  error(state) {
    state.status = 'error'
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
