import api from './Api'
import { getTablePaginationParams } from './helpers'

const ENDPOINTS = {
  dispositions: (id = '') => (id ? `/dispositions/${id}` : `/dispositions`),
  batchDispositions: (id = '') => {
    if (id) return `/batch/dispositionWithDispositionSteps/${id}`
    else return `/batch/dispositionWithDispositionSteps`
  },
  archiveDisposition: (id) => `${ENDPOINTS.dispositions(id)}/actions/archive`,
  unarchiveDisposition: (id) =>
    `${ENDPOINTS.dispositions(id)}/actions/unarchive`,
  undeleteDisposition: (id) => `${ENDPOINTS.dispositions(id)}/actions/undelete`,
  setDraftDisposition: (id) => `${ENDPOINTS.dispositions(id)}/actions/setDraft`,
  editDriveDispositions: (id) => `${ENDPOINTS.dispositions(id)}/dispositions`,
  bulkDispositions: `/bulk/dispositions`,
  opts: {
    showArchived: 'state=archived',
    showDeleted: 'deleted_at=true',
    showDraftEngaged: 'state=draft,engaged',
    showEnabled: 'enabled=1',
  },
}

export default {
  getDispositions(options = {}) {
    let path = ENDPOINTS.dispositions()
    let pathParams = []

    const { showArchived, showDeleted, showEnabled, searchByName } = options

    // Include all or only enabled templates
    if (showEnabled) pathParams.push(ENDPOINTS.opts.showEnabled)

    // Determine template states to show
    if (showArchived) pathParams.push(ENDPOINTS.opts.showArchived)
    else if (showDeleted) pathParams.push(ENDPOINTS.opts.showDeleted)
    else pathParams.push(ENDPOINTS.opts.showDraftEngaged)

    // Table search input
    if (searchByName) pathParams.push(`name=${searchByName}`)

    // Pagination
    const pagParams = getTablePaginationParams(options)
    if (pagParams) pathParams.push(pagParams)

    // Filter by Drive to which the disposition belongs
    const { driveId } = options
    if (driveId) pathParams.push(`driveId=${driveId}`)

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.get(path, { abortKey: 'GET_DISPOSITIONS' })
  },

  getDispositionById(id) {
    return api.get(ENDPOINTS.dispositions(id), {
      abortKey: 'GET_DISPOSITION_BY_ID',
    })
  },

  createDisposition(payload) {
    return api.post(ENDPOINTS.dispositions(), payload)
  },

  bulkCreateDispositions(payload) {
    return api.post(ENDPOINTS.bulkDispositions, payload)
  },

  postBatchDispositionWithDispositionSteps(payload) {
    return api.post(ENDPOINTS.batchDispositions(), payload)
  },

  updateBatchDispositionWithDispositionSteps(dispositionId, disposition) {
    return api.patch(ENDPOINTS.batchDispositions(dispositionId), disposition)
  },

  deleteDispositionById(id) {
    return api.delete(ENDPOINTS.dispositions(id))
  },

  undeleteDispositionById(id) {
    return api.post(ENDPOINTS.undeleteDisposition(id))
  },

  archiveDisposition(dispositionId) {
    return api.post(ENDPOINTS.archiveDisposition(dispositionId))
  },

  unarchiveDisposition(dispositionId) {
    return api.post(ENDPOINTS.unarchiveDisposition(dispositionId))
  },

  setDraftDisposition(dispositionId) {
    return api.post(ENDPOINTS.setDraftDisposition(dispositionId))
  },

  setDefaultDisposition(dispositionId, defaultDisposition) {
    return api.patch(ENDPOINTS.dispositions(dispositionId), defaultDisposition)
  },
}
