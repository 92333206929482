const weekdayItems = Object.freeze([
  { text: 'Sunday', textShort: 'Sun', value: 'sunday' },
  { text: 'Monday', textShort: 'Mon', value: 'monday' },
  { text: 'Tuesday', textShort: 'Tue', value: 'tuesday' },
  { text: 'Wednesday', textShort: 'Wed', value: 'wednesday' },
  { text: 'Thursday', textShort: 'Thu', value: 'thursday' },
  { text: 'Friday', textShort: 'Fri', value: 'friday' },
  { text: 'Saturday', textShort: 'Sat', value: 'saturday' },
])

export default weekdayItems
