import ProspectFieldsApi from '@/services/api/ProspectFields'

// initial state
const state = () => ({
  prospectFields: [],
  prospectFieldsLoading: false,
})

// getters
const getters = {
  prospectFields: (state) => state.prospectFields || [],
  prospectFieldByKey: (state) => (key) =>
    state.prospectFields.find((field) => field.key === key),
  customProspectFields: (state) =>
    state.prospectFields.filter((field) => !field.default_field) || [],
  customProspectCols: (state, getters) =>
    getters.customProspectFields.map((field) => ({
      text: field.name,
      value: field.key,
      type: field.type,
      sortable: false,
      show: true,
    })) || [],
  defaultProspectFields: (state) =>
    state.prospectFields.filter((field) => !!field.default_field),
  prospectFieldsLoading: (state) => state.prospectFieldsLoading,
}

// actions
const actions = {
  createProspectField({ state, commit }, fieldToCreate) {
    return new Promise((resolve, reject) => {
      ProspectFieldsApi.createProspectField(fieldToCreate)
        .then((res) => {
          commit('setProspectFields', [...state.prospectFields, res])
          resolve(res)
        })
        .catch((err) => reject(err))
    })
  },

  fetchProspectFields({ commit }) {
    commit('request')
    return new Promise((resolve, reject) => {
      ProspectFieldsApi.getProspectFields()
        .then((res) => {
          commit('setProspectFields', res)
          commit('success')
          resolve(res)
        })
        .catch((err) => {
          commit('error')
          commit(
            'snackbar/setSnack',
            {
              snack: 'Error fetching prospect fields. Try again.',
              snackType: 'error',
            },
            {
              root: true,
            }
          )
          reject(err)
        })
    })
  },

  updateProspectField({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      ProspectFieldsApi.updateProspectField(payload)
        .then((res) => {
          const index = state.prospectFields.findIndex(
            (field) => field.id === payload.id
          )
          if (index !== -1)
            commit('setProspectFields', [
              ...state.prospectFields.slice(0, index),
              res,
              ...state.prospectFields.slice(index + 1),
            ])
          resolve(res)
        })
        .catch((err) => reject(err))
    })
  },

  deleteProspectField({ state, commit }, id) {
    return new Promise((resolve, reject) =>
      ProspectFieldsApi.deleteProspectField(id)
        .then(() => {
          const index = state.prospectFields.findIndex(
            (field) => field.id === id
          )
          if (index !== -1)
            commit('setProspectFields', [
              ...state.prospectFields.slice(0, index),
              ...state.prospectFields.slice(index + 1),
            ])
          resolve()
        })
        .catch((err) => reject(err))
    )
  },
}

// mutations
const mutations = {
  setProspectFields(state, fields) {
    state.prospectFields = fields
  },
  request(state) {
    state.prospectFieldsLoading = true
  },
  success(state) {
    state.prospectFieldsLoading = false
  },
  error(state) {
    state.prospectFieldsLoading = false
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
