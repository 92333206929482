import api from './Api'

export default {
  getDriveStateById(id) {
    return api.get(`/driveStates/${id}`, { abortKey: 'GET_DRIVE_STATE_BY_ID' })
  },

  getDriveStatesByProspectId(id) {
    return api.get(
      `/driveStates?state=in_progress,paused,finished&prospectId=${id}`,
      {
        abortKey: 'GET_DRIVE_STATES_BY_PROSPECT_ID',
      }
    )
  },

  postBulkDriveStates(payload) {
    return api.post('/bulk/driveStates', payload)
  },

  postDriveState(payload) {
    return api.post('/driveStates', payload)
  },

  postBulkDriveDispositions(payload) {
    return api.post(`bulk/driveStates/actions/disposition`, payload)
  },

  postDriveDisposition(driveStateId, payload) {
    return api.post(`/driveStates/${driveStateId}/actions/disposition`, payload)
  },

  pauseBulkDriveStates(payload) {
    return api.post(`bulk/driveStates/actions/pause`, {
      driveStates: payload,
    })
  },

  pauseDriveState(driveStateId, payload) {
    return api.post(`driveStates/${driveStateId}/actions/pause`, payload)
  },

  resumeBulkDriveStates(payload) {
    return api.post(`bulk/driveStates/actions/resume`, {
      driveStates: payload,
    })
  },

  resumeDriveState(driveStateId) {
    return api.post(`driveStates/${driveStateId}/actions/resume`)
  },
}
