export default Object.freeze([
  'fuse.net',
  'bellsouth.net',
  'aim.com',
  'mac.com',
  'excite.com',
  'verizon.net',
  'att.net',
  'live.com',
  'outlook.com',
  'aolmail.com',
  'blogger.com',
  'ymail.com',
  'rogers.com',
  'comcast.net',
  '37.com',
  'aol.cn',
  'aol.co.il',
  'aol.com',
  'aol.com.ar',
  'aol.com.mx',
  'aol.kr',
  'bigstring.com',
  'fastmail.com',
  'fastmail.fm',
  'gawab.com',
  'gmail.cn',
  'gmail.co.il',
  'gmail.com',
  'gmail.com.ar',
  'gmail.com.mx',
  'gmail.kr',
  'gmx.com',
  'hushmail.com',
  'inbox.com',
  'juno.com',
  'lycos.com',
  'mail.cn',
  'mail.co.il',
  'mail.com',
  'mail.com.ar',
  'mail.com.mx',
  'mail.kr',
  'mail.ru',
  'msn.cn',
  'msn.co.il',
  'msn.com',
  'msn.com.ar',
  'msn.com.mx',
  'msn.kr',
  'muchomail.com',
  'runbox.com',
  'yahoo.cn',
  'yahoo.co.il',
  'yahoo.com',
  'yahoo.com.ar',
  'yahoo.com.mx',
  'yahoo.kr',
  'hotmail.com',
  'hotmail.com.ar',
  'hotmail.com.mx',
  'hotmail.kr',
  'hotmail.ru',
  'hotmail.cn',
  'hotmail.co.il',
  'cox.net',
  'sbcglobal.net',
  'adelphia.net',
  'rr.com',
  'wp.pl',
  'gmail.ac',
  'gmail.ad',
  'gmail.ae',
  'gmail.com.af',
  'gmail.ag',
  'gmail.ai',
  'gmail.al',
  'gmail.am',
  'gmail.an',
  'gmail.ao',
  'gmail.ca',
  'gmail.as',
  'gmail.at',
  'gmail.com.au',
  'gmail.aw',
  'gmail.ax',
  'gmail.az',
  'gmail.ba',
  'gmail.bb',
  'gmail.com.bd',
  'gmail.be',
  'gmail.bf',
  'gmail.bg',
  'gmail.com.bh',
  'gmail.bi',
  'gmail.bj',
  'gmail.bm',
  'gmail.com.bn',
  'gmail.bo',
  'gmail.com.br',
  'gmail.bs',
  'gmail.bt',
  'gmail.bv',
  'gmail.co.bw',
  'gmail.by',
  'gmail.bz',
  'gmail.cc',
  'gmail.cd',
  'gmail.cf',
  'gmail.cg',
  'gmail.ch',
  'gmail.ci',
  'gmail.ck',
  'gmail.cl',
  'gmail.cm',
  'gmail.co',
  'gmail.cr',
  'gmail.cs',
  'gmail.com.cu',
  'gmail.cv',
  'gmail.cx',
  'gmail.cy',
  'gmail.cz',
  'gmail.de',
  'gmail.dj',
  'gmail.dk',
  'gmail.dm',
  'gmail.do',
  'gmail.dz',
  'gmail.ec',
  'gmail.ee',
  'gmail.com.eg',
  'gmail.eh',
  'gmail.er',
  'gmail.es',
  'gmail.com.et',
  'gmail.eu',
  'gmail.fi',
  'gmail.com.fj',
  'gmail.fk',
  'gmail.fm',
  'gmail.fo',
  'gmail.fr',
  'gmail.ga',
  'gmail.co.uk',
  'gmail.gd',
  'gmail.ge',
  'gmail.gg',
  'gmail.com.gh',
  'gmail.com.gi',
  'gmail.gl',
  'gmail.gm',
  'gmail.gn',
  'gmail.gp',
  'gmail.gq',
  'gmail.gr',
  'gmail.gs',
  'gmail.hn',
  'gmail.gu',
  'gmail.gw',
  'gmail.gy',
  'gmail.com.hk',
  'gmail.hr',
  'gmail.ht',
  'gmail.hu',
  'gmail.co.id',
  'gmail.ie',
  'gmail.com.ni',
  'gmail.im',
  'gmail.in',
  'gmail.io',
  'gmail.iq',
  'gmail.ir',
  'gmail.is',
  'gmail.it',
  'gmail.je',
  'gmail.com.jm',
  'gmail.jo',
  'gmail.jp',
  'gmail.co.ke',
  'gmail.kg',
  'gmail.com.kh',
  'gmail.ki',
  'gmail.km',
  'gmail.kn',
  'gmail.kp',
  'gmail.com.kw',
  'gmail.ky',
  'gmail.kz',
  'gmail.la',
  'gmail.com.lb',
  'gmail.lc',
  'gmail.li',
  'gmail.lk',
  'gmail.lr',
  'gmail.co.ls',
  'gmail.lt',
  'gmail.lu',
  'gmail.lv',
  'gmail.com.ly',
  'gmail.co.ma',
  'gmail.mc',
  'gmail.md',
  'gmail.mg',
  'gmail.mh',
  'gmail.mk',
  'gmail.ml',
  'gmail.mm',
  'gmail.mn',
  'gmail.mo',
  'gmail.mp',
  'gmail.mq',
  'gmail.mr',
  'gmail.ms',
  'gmail.com.mt',
  'gmail.mu',
  'gmail.mv',
  'gmail.mw',
  'gmail.com.my',
  'gmail.co.mz',
  'gmail.na',
  'gmail.ncsu.edu',
  'gmail.ne',
  'gmail.com.nf',
  'gmail.com.ng',
  'gmail.nl',
  'gmail.no',
  'gmail.com.np',
  'gmail.nr',
  'gmail.co.nz',
  'gmail.om',
  'gmail.pa',
  'gmail.com.pe',
  'gmaill.pf',
  'gmail.pg',
  'gmail.com.ph',
  'gmail.com.pk',
  'gmail.pl',
  'gmail.pm',
  'gmail.pn',
  'gmail.com.pr',
  'gmail.ps',
  'gmail.pt',
  'gmail.pw',
  'gmail.com.py',
  'gmail.com.qa',
  'gmail.re',
  'gmail.ro',
  'gmail.rs',
  'gmail.ru',
  'gmail.rw',
  'gmail.com.sa',
  'gmail.com.sb',
  'gmail.sc',
  'gmail.sd',
  'gmail.se',
  'gmail.com.sg',
  'gmail.sh',
  'gmail.si',
  'gmail.sk',
  'gmail.sl',
  'gmail.sm',
  'gmail.sn',
  'gmail.so',
  'gmail.ss',
  'gmail.st',
  'gmail.com.sv',
  'gmail.sy',
  'gmail.sz',
  'gmail.tc',
  'gmail.td',
  'gmail.tg',
  'gmail.th',
  'gmail.com.tj',
  'gmail.tk',
  'gmail.tl',
  'gmail.tm',
  'gmail.tn',
  'gmail.to',
  'gmail.tp',
  'gmail.tr',
  'gmail.tt',
  'gmail.tv',
  'gmail.tw',
  'gmail.co.tz',
  'gmail.com.ua',
  'gmail.co.ug',
  'gmail.us',
  'gmail.com.uy',
  'gmail.uz',
  'gmail.vc',
  'gmail.co.ve',
  'gmail.vg',
  'gmail.vi',
  'gmail.com.vn',
  'gmail.vu',
  'gmail.wf',
  'gmail.ws',
  'gmail.ye',
  'gmail.yt',
  'gmail.co.za',
  'gmail.co.zm',
  'gmail.co.zw',
  'aol.ac',
  'aol.ad',
  'aol.ae',
  'aol.com.af',
  'aol.ag',
  'aol.ai',
  'aol.al',
  'aol.am',
  'aol.an',
  'aol.ao',
  'aol.ca',
  'aol.as',
  'aol.at',
  'aol.com.au',
  'aol.aw',
  'aol.ax',
  'aol.az',
  'aol.ba',
  'aol.bb',
  'aol.com.bd',
  'aol.be',
  'aol.bf',
  'aol.bg',
  'aol.com.bh',
  'aol.bi',
  'aol.bj',
  'aol.bm',
  'aol.com.bn',
  'aol.bo',
  'aol.com.br',
  'aol.bs',
  'aol.bt',
  'aol.bv',
  'aol.co.bw',
  'aol.by',
  'aol.bz',
  'aol.cc',
  'aol.cd',
  'aol.cf',
  'aol.cg',
  'aol.ch',
  'aol.ci',
  'aol.ck',
  'aol.cl',
  'aol.cm',
  'aol.co',
  'aol.cr',
  'aol.cs',
  'aol.com.cu',
  'aol.cv',
  'aol.cx',
  'aol.cy',
  'aol.cz',
  'aol.de',
  'aol.dj',
  'aol.dk',
  'aol.dm',
  'aol.do',
  'aol.dz',
  'aol.ec',
  'aol.ee',
  'aol.com.eg',
  'aol.eh',
  'aol.er',
  'aol.es',
  'aol.com.et',
  'aol.eu',
  'aol.fi',
  'aol.com.fj',
  'aol.fk',
  'aol.fm',
  'aol.fo',
  'aol.fr',
  'aol.ga',
  'aol.co.uk',
  'aol.gd',
  'aol.ge',
  'aol.gg',
  'aol.com.gh',
  'aol.com.gi',
  'aol.gl',
  'aol.gm',
  'aol.gn',
  'aol.gp',
  'aol.gq',
  'aol.gr',
  'aol.gs',
  'aol.hn',
  'aol.gu',
  'aol.gw',
  'aol.gy',
  'aol.com.hk',
  'aol.hr',
  'aol.ht',
  'aol.hu',
  'aol.co.id',
  'aol.ie',
  'aol.com.ni',
  'aol.im',
  'aol.in',
  'aol.io',
  'aol.iq',
  'aol.ir',
  'aol.is',
  'aol.it',
  'aol.je',
  'aol.com.jm',
  'aol.jo',
  'aol.jp',
  'aol.co.ke',
  'aol.kg',
  'aol.com.kh',
  'aol.ki',
  'aol.km',
  'aol.kn',
  'aol.kp',
  'aol.com.kw',
  'aol.ky',
  'aol.kz',
  'aol.la',
  'aol.com.lb',
  'aol.lc',
  'aol.li',
  'aol.lk',
  'aol.lr',
  'aol.co.ls',
  'aol.lt',
  'aol.lu',
  'aol.lv',
  'aol.com.ly',
  'aol.co.ma',
  'aol.mc',
  'aol.md',
  'aol.mg',
  'aol.mh',
  'aol.mk',
  'aol.ml',
  'aol.mm',
  'aol.mn',
  'aol.mo',
  'aol.mp',
  'aol.mq',
  'aol.mr',
  'aol.ms',
  'aol.com.mt',
  'aol.mu',
  'aol.mv',
  'aol.mw',
  'aol.com.my',
  'aol.co.mz',
  'aol.na',
  'aol.ncsu.edu',
  'aol.ne',
  'aol.com.nf',
  'aol.com.ng',
  'aol.nl',
  'aol.no',
  'aol.com.np',
  'aol.nr',
  'aol.co.nz',
  'aol.om',
  'aol.pa',
  'aol.com.pe',
  'aoll.pf',
  'aol.pg',
  'aol.com.ph',
  'aol.com.pk',
  'aol.pl',
  'aol.pm',
  'aol.pn',
  'aol.com.pr',
  'aol.ps',
  'aol.pt',
  'aol.pw',
  'aol.com.py',
  'aol.com.qa',
  'aol.re',
  'aol.ro',
  'aol.rs',
  'aol.ru',
  'aol.rw',
  'aol.com.sa',
  'aol.com.sb',
  'aol.sc',
  'aol.sd',
  'aol.se',
  'aol.com.sg',
  'aol.sh',
  'aol.si',
  'aol.sk',
  'aol.sl',
  'aol.sm',
  'aol.sn',
  'aol.so',
  'aol.ss',
  'aol.st',
  'aol.com.sv',
  'aol.sy',
  'aol.sz',
  'aol.tc',
  'aol.td',
  'aol.tg',
  'aol.th',
  'aol.com.tj',
  'aol.tk',
  'aol.tl',
  'aol.tm',
  'aol.tn',
  'aol.to',
  'aol.tp',
  'aol.tr',
  'aol.tt',
  'aol.tv',
  'aol.tw',
  'aol.co.tz',
  'aol.com.ua',
  'aol.co.ug',
  'aol.us',
  'aol.com.uy',
  'aol.uz',
  'aol.vc',
  'aol.co.ve',
  'aol.vg',
  'aol.vi',
  'aol.com.vn',
  'aol.vu',
  'aol.wf',
  'aol.ws',
  'aol.ye',
  'aol.yt',
  'aol.co.za',
  'aol.co.zm',
  'aol.co.zw',
  'husmail.ac',
  'husmail.ad',
  'husmail.ae',
  'husmail.com.af',
  'husmail.ag',
  'husmail.ai',
  'husmail.al',
  'husmail.am',
  'husmail.an',
  'husmail.ao',
  'husmail.ca',
  'husmail.com.ar',
  'husmail.as',
  'husmail.at',
  'husmail.com.au',
  'husmail.aw',
  'husmail.ax',
  'husmail.az',
  'husmail.ba',
  'husmail.bb',
  'husmail.com.bd',
  'husmail.be',
  'husmail.bf',
  'husmail.bg',
  'husmail.com.bh',
  'husmail.bi',
  'husmail.bj',
  'husmail.bm',
  'husmail.com.bn',
  'husmail.bo',
  'husmail.com.br',
  'husmail.bs',
  'husmail.bt',
  'husmail.bv',
  'husmail.co.bw',
  'husmail.by',
  'husmail.bz',
  'husmail.cc',
  'husmail.cd',
  'husmail.cf',
  'husmail.cg',
  'husmail.ch',
  'husmail.ci',
  'husmail.ck',
  'husmail.cl',
  'husmail.cm',
  'husmail.cn',
  'husmail.co',
  'husmail.cr',
  'husmail.cs',
  'husmail.com.cu',
  'husmail.cv',
  'husmail.cx',
  'husmail.cy',
  'husmail.cz',
  'husmail.de',
  'husmail.dj',
  'husmail.dk',
  'husmail.dm',
  'husmail.do',
  'husmail.dz',
  'husmail.ec',
  'husmail.ee',
  'husmail.com.eg',
  'husmail.eh',
  'husmail.er',
  'husmail.es',
  'husmail.com.et',
  'husmail.eu',
  'husmail.fi',
  'husmail.com.fj',
  'husmail.fk',
  'husmail.fm',
  'husmail.fo',
  'husmail.fr',
  'husmail.ga',
  'husmail.co.uk',
  'husmail.gd',
  'husmail.ge',
  'husmail.gg',
  'husmail.com.gh',
  'husmail.com.gi',
  'husmail.gl',
  'husmail.gm',
  'husmail.gn',
  'husmail.gp',
  'husmail.gq',
  'husmail.gr',
  'husmail.gs',
  'husmail.hn',
  'husmail.gu',
  'husmail.gw',
  'husmail.gy',
  'husmail.com.hk',
  'husmail.hr',
  'husmail.ht',
  'husmail.hu',
  'husmail.co.id',
  'husmail.ie',
  'husmail.com.ni',
  'husmail.co.il',
  'husmail.im',
  'husmail.in',
  'husmail.io',
  'husmail.iq',
  'husmail.ir',
  'husmail.is',
  'husmail.it',
  'husmail.je',
  'husmail.com.jm',
  'husmail.jo',
  'husmail.jp',
  'husmail.co.ke',
  'husmail.kg',
  'husmail.com.kh',
  'husmail.ki',
  'husmail.km',
  'husmail.kn',
  'husmail.kp',
  'husmail.kr',
  'husmail.com.kw',
  'husmail.ky',
  'husmail.kz',
  'husmail.la',
  'husmail.com.lb',
  'husmail.lc',
  'husmail.li',
  'husmail.lk',
  'husmail.lr',
  'husmail.co.ls',
  'husmail.lt',
  'husmail.lu',
  'husmail.lv',
  'husmail.com.ly',
  'husmail.co.ma',
  'husmail.mc',
  'husmail.md',
  'husmail.com.mx',
  'husmail.mg',
  'husmail.mh',
  'husmail.mk',
  'husmail.ml',
  'husmail.mm',
  'husmail.mn',
  'husmail.mo',
  'husmail.mp',
  'husmail.mq',
  'husmail.mr',
  'husmail.ms',
  'husmail.com.mt',
  'husmail.mu',
  'husmail.mv',
  'husmail.mw',
  'husmail.com.my',
  'husmail.co.mz',
  'husmail.na',
  'husmail.ncsu.edu',
  'husmail.ne',
  'husmail.com.nf',
  'husmail.com.ng',
  'husmail.nl',
  'husmail.no',
  'husmail.com.np',
  'husmail.nr',
  'husmail.co.nz',
  'husmail.om',
  'husmail.pa',
  'husmail.com.pe',
  'husmaill.pf',
  'husmail.pg',
  'husmail.com.ph',
  'husmail.com.pk',
  'husmail.pl',
  'husmail.pm',
  'husmail.pn',
  'husmail.com.pr',
  'husmail.ps',
  'husmail.pt',
  'husmail.pw',
  'husmail.com.py',
  'husmail.com.qa',
  'husmail.re',
  'husmail.ro',
  'husmail.rs',
  'husmail.ru',
  'husmail.rw',
  'husmail.com.sa',
  'husmail.com.sb',
  'husmail.sc',
  'husmail.sd',
  'husmail.se',
  'husmail.com.sg',
  'husmail.sh',
  'husmail.si',
  'husmail.sk',
  'husmail.sl',
  'husmail.sm',
  'husmail.sn',
  'husmail.so',
  'husmail.ss',
  'husmail.st',
  'husmail.com.sv',
  'husmail.sy',
  'husmail.sz',
  'husmail.tc',
  'husmail.td',
  'husmail.tg',
  'husmail.th',
  'husmail.com.tj',
  'husmail.tk',
  'husmail.tl',
  'husmail.tm',
  'husmail.tn',
  'husmail.to',
  'husmail.tp',
  'husmail.tr',
  'husmail.tt',
  'husmail.tv',
  'husmail.tw',
  'husmail.co.tz',
  'husmail.com.ua',
  'husmail.co.ug',
  'husmail.us',
  'husmail.com.uy',
  'husmail.uz',
  'husmail.vc',
  'husmail.co.ve',
  'husmail.vg',
  'husmail.vi',
  'husmail.com.vn',
  'husmail.vu',
  'husmail.wf',
  'husmail.ws',
  'husmail.ye',
  'husmail.yt',
  'husmail.co.za',
  'husmail.co.zm',
  'husmail.co.zw',
  'inbox',
  'inbox.ac',
  'inbox.ad',
  'inbox.ae',
  'inbox.com.af',
  'inbox.ag',
  'inbox.ai',
  'inbox.al',
  'inbox.am',
  'inbox.an',
  'inbox.ao',
  'inbox.ca',
  'inbox.com.ar',
  'inbox.as',
  'inbox.at',
  'inbox.com.au',
  'inbox.aw',
  'inbox.ax',
  'inbox.az',
  'inbox.ba',
  'inbox.bb',
  'inbox.com.bd',
  'inbox.be',
  'inbox.bf',
  'inbox.bg',
  'inbox.com.bh',
  'inbox.bi',
  'inbox.bj',
  'inbox.bm',
  'inbox.com.bn',
  'inbox.bo',
  'inbox.com.br',
  'inbox.bs',
  'inbox.bt',
  'inbox.bv',
  'inbox.co.bw',
  'inbox.by',
  'inbox.bz',
  'inbox.cc',
  'inbox.cd',
  'inbox.cf',
  'inbox.cg',
  'inbox.ch',
  'inbox.ci',
  'inbox.ck',
  'inbox.cl',
  'inbox.cm',
  'inbox.cn',
  'inbox.co',
  'inbox.cr',
  'inbox.cs',
  'inbox.com.cu',
  'inbox.cv',
  'inbox.cx',
  'inbox.cy',
  'inbox.cz',
  'inbox.de',
  'inbox.dj',
  'inbox.dk',
  'inbox.dm',
  'inbox.do',
  'inbox.dz',
  'inbox.ec',
  'inbox.ee',
  'inbox.com.eg',
  'inbox.eh',
  'inbox.er',
  'inbox.es',
  'inbox.com.et',
  'inbox.eu',
  'inbox.fi',
  'inbox.com.fj',
  'inbox.fk',
  'inbox.fm',
  'inbox.fo',
  'inbox.fr',
  'inbox.ga',
  'inbox.co.uk',
  'inbox.gd',
  'inbox.ge',
  'inbox.gg',
  'inbox.com.gh',
  'inbox.com.gi',
  'inbox.gl',
  'inbox.gm',
  'inbox.gn',
  'inbox.gp',
  'inbox.gq',
  'inbox.gr',
  'inbox.gs',
  'inbox.hn',
  'inbox.gu',
  'inbox.gw',
  'inbox.gy',
  'inbox.com.hk',
  'inbox.hr',
  'inbox.ht',
  'inbox.hu',
  'inbox.co.id',
  'inbox.ie',
  'inbox.com.ni',
  'inbox.co.il',
  'inbox.im',
  'inbox.in',
  'inbox.io',
  'inbox.iq',
  'inbox.ir',
  'inbox.is',
  'inbox.it',
  'inbox.je',
  'inbox.com.jm',
  'inbox.jo',
  'inbox.jp',
  'inbox.co.ke',
  'inbox.kg',
  'inbox.com.kh',
  'inbox.ki',
  'inbox.km',
  'inbox.kn',
  'inbox.kp',
  'inbox.kr',
  'inbox.com.kw',
  'inbox.ky',
  'inbox.kz',
  'inbox.la',
  'inbox.com.lb',
  'inbox.lc',
  'inbox.li',
  'inbox.lk',
  'inbox.lr',
  'inbox.co.ls',
  'inbox.lt',
  'inbox.lu',
  'inbox.lv',
  'inbox.com.ly',
  'inbox.co.ma',
  'inbox.mc',
  'inbox.md',
  'inbox.com.mx',
  'inbox.mg',
  'inbox.mh',
  'inbox.mk',
  'inbox.ml',
  'inbox.mm',
  'inbox.mn',
  'inbox.mo',
  'inbox.mp',
  'inbox.mq',
  'inbox.mr',
  'inbox.ms',
  'inbox.com.mt',
  'inbox.mu',
  'inbox.mv',
  'inbox.mw',
  'inbox.com.my',
  'inbox.co.mz',
  'inbox.na',
  'inbox.ncsu.edu',
  'inbox.ne',
  'inbox.com.nf',
  'inbox.com.ng',
  'inbox.nl',
  'inbox.no',
  'inbox.com.np',
  'inbox.nr',
  'inbox.co.nz',
  'inbox.om',
  'inbox.pa',
  'inbox.com.pe',
  'inboxl.pf',
  'inbox.pg',
  'inbox.com.ph',
  'inbox.com.pk',
  'inbox.pl',
  'inbox.pm',
  'inbox.pn',
  'inbox.com.pr',
  'inbox.ps',
  'inbox.pt',
  'inbox.pw',
  'inbox.com.py',
  'inbox.com.qa',
  'inbox.re',
  'inbox.ro',
  'inbox.rs',
  'inbox.ru',
  'inbox.rw',
  'inbox.com.sa',
  'inbox.com.sb',
  'inbox.sc',
  'inbox.sd',
  'inbox.se',
  'inbox.com.sg',
  'inbox.sh',
  'inbox.si',
  'inbox.sk',
  'inbox.sl',
  'inbox.sm',
  'inbox.sn',
  'inbox.so',
  'inbox.ss',
  'inbox.st',
  'inbox.com.sv',
  'inbox.sy',
  'inbox.sz',
  'inbox.tc',
  'inbox.td',
  'inbox.tg',
  'inbox.th',
  'inbox.com.tj',
  'inbox.tk',
  'inbox.tl',
  'inbox.tm',
  'inbox.tn',
  'inbox.to',
  'inbox.tp',
  'inbox.tr',
  'inbox.tt',
  'inbox.tv',
  'inbox.tw',
  'inbox.co.tz',
  'inbox.com.ua',
  'inbox.co.ug',
  'inbox.us',
  'inbox.com.uy',
  'inbox.uz',
  'inbox.vc',
  'inbox.co.ve',
  'inbox.vg',
  'inbox.vi',
  'inbox.com.vn',
  'inbox.vu',
  'inbox.wf',
  'inbox.ws',
  'inbox.ye',
  'inbox.yt',
  'inbox.co.za',
  'inbox.co.zm',
  'inbox.co.zw',
  'netzero.com',
  'juno',
  'juno.ac',
  'juno.ad',
  'juno.ae',
  'juno.com.af',
  'juno.ag',
  'juno.ai',
  'juno.al',
  'juno.am',
  'juno.an',
  'juno.ao',
  'juno.ca',
  'juno.com.ar',
  'juno.as',
  'juno.at',
  'juno.com.au',
  'juno.aw',
  'juno.ax',
  'juno.az',
  'juno.ba',
  'juno.bb',
  'juno.com.bd',
  'juno.be',
  'juno.bf',
  'juno.bg',
  'juno.com.bh',
  'juno.bi',
  'juno.bj',
  'juno.bm',
  'juno.com.bn',
  'juno.bo',
  'juno.com.br',
  'juno.bs',
  'juno.bt',
  'juno.bv',
  'juno.co.bw',
  'juno.by',
  'juno.bz',
  'juno.cc',
  'juno.cd',
  'juno.cf',
  'juno.cg',
  'juno.ch',
  'juno.ci',
  'juno.ck',
  'juno.cl',
  'juno.cm',
  'juno.cn',
  'juno.co',
  'juno.cr',
  'juno.cs',
  'juno.com.cu',
  'juno.cv',
  'juno.cx',
  'juno.cy',
  'juno.cz',
  'juno.de',
  'juno.dj',
  'juno.dk',
  'juno.dm',
  'juno.do',
  'juno.dz',
  'juno.ec',
  'juno.ee',
  'juno.com.eg',
  'juno.eh',
  'juno.er',
  'juno.es',
  'juno.com.et',
  'juno.eu',
  'juno.fi',
  'juno.com.fj',
  'juno.fk',
  'juno.fm',
  'juno.fo',
  'juno.fr',
  'juno.ga',
  'juno.co.uk',
  'juno.gd',
  'juno.ge',
  'juno.gg',
  'juno.com.gh',
  'juno.com.gi',
  'juno.gl',
  'juno.gm',
  'juno.gn',
  'juno.gp',
  'juno.gq',
  'juno.gr',
  'juno.gs',
  'juno.hn',
  'juno.gu',
  'juno.gw',
  'juno.gy',
  'juno.com.hk',
  'juno.hr',
  'juno.ht',
  'juno.hu',
  'juno.co.id',
  'juno.ie',
  'juno.com.ni',
  'juno.co.il',
  'juno.im',
  'juno.in',
  'juno.io',
  'juno.iq',
  'juno.ir',
  'juno.is',
  'juno.it',
  'juno.je',
  'juno.com.jm',
  'juno.jo',
  'juno.jp',
  'juno.co.ke',
  'juno.kg',
  'juno.com.kh',
  'juno.ki',
  'juno.km',
  'juno.kn',
  'juno.kp',
  'juno.kr',
  'juno.com.kw',
  'juno.ky',
  'juno.kz',
  'juno.la',
  'juno.com.lb',
  'juno.lc',
  'juno.li',
  'juno.lk',
  'juno.lr',
  'juno.co.ls',
  'juno.lt',
  'juno.lu',
  'juno.lv',
  'juno.com.ly',
  'juno.co.ma',
  'juno.mc',
  'juno.md',
  'juno.com.mx',
  'juno.mg',
  'juno.mh',
  'juno.mk',
  'juno.ml',
  'juno.mm',
  'juno.mn',
  'juno.mo',
  'juno.mp',
  'juno.mq',
  'juno.mr',
  'juno.ms',
  'juno.com.mt',
  'juno.mu',
  'juno.mv',
  'juno.mw',
  'juno.com.my',
  'juno.co.mz',
  'juno.na',
  'juno.ncsu.edu',
  'juno.ne',
  'juno.com.nf',
  'juno.com.ng',
  'juno.nl',
  'juno.no',
  'juno.com.np',
  'juno.nr',
  'juno.co.nz',
  'juno.om',
  'juno.pa',
  'juno.com.pe',
  'junol.pf',
  'juno.pg',
  'juno.com.ph',
  'juno.com.pk',
  'juno.pl',
  'juno.pm',
  'juno.pn',
  'juno.com.pr',
  'juno.ps',
  'juno.pt',
  'juno.pw',
  'juno.com.py',
  'juno.com.qa',
  'juno.re',
  'juno.ro',
  'juno.rs',
  'juno.ru',
  'juno.rw',
  'juno.com.sa',
  'juno.com.sb',
  'juno.sc',
  'juno.sd',
  'juno.se',
  'juno.com.sg',
  'juno.sh',
  'juno.si',
  'juno.sk',
  'juno.sl',
  'juno.sm',
  'juno.sn',
  'juno.so',
  'juno.ss',
  'juno.st',
  'juno.com.sv',
  'juno.sy',
  'juno.sz',
  'juno.tc',
  'juno.td',
  'juno.tg',
  'juno.th',
  'juno.com.tj',
  'juno.tk',
  'juno.tl',
  'juno.tm',
  'juno.tn',
  'juno.to',
  'juno.tp',
  'juno.tr',
  'juno.tt',
  'juno.tv',
  'juno.tw',
  'juno.co.tz',
  'juno.com.ua',
  'juno.co.ug',
  'juno.us',
  'juno.com.uy',
  'juno.uz',
  'juno.vc',
  'juno.co.ve',
  'juno.vg',
  'juno.vi',
  'juno.com.vn',
  'juno.vu',
  'juno.wf',
  'juno.ws',
  'juno.ye',
  'juno.yt',
  'juno.co.za',
  'juno.co.zm',
  'juno.co.zw',
  'lycos',
  'lycos.ac',
  'lycos.ad',
  'lycos.ae',
  'lycos.com.af',
  'lycos.ag',
  'lycos.ai',
  'lycos.al',
  'lycos.am',
  'lycos.an',
  'lycos.ao',
  'lycos.ca',
  'lycos.com.ar',
  'lycos.as',
  'lycos.at',
  'lycos.com.au',
  'lycos.aw',
  'lycos.ax',
  'lycos.az',
  'lycos.ba',
  'lycos.bb',
  'lycos.com.bd',
  'lycos.be',
  'lycos.bf',
  'lycos.bg',
  'lycos.com.bh',
  'lycos.bi',
  'lycos.bj',
  'lycos.bm',
  'lycos.com.bn',
  'lycos.bo',
  'lycos.com.br',
  'lycos.bs',
  'lycos.bt',
  'lycos.bv',
  'lycos.co.bw',
  'lycos.by',
  'lycos.bz',
  'lycos.cc',
  'lycos.cd',
  'lycos.cf',
  'lycos.cg',
  'lycos.ch',
  'lycos.ci',
  'lycos.ck',
  'lycos.cl',
  'lycos.cm',
  'lycos.cn',
  'lycos.co',
  'lycos.cr',
  'lycos.cs',
  'lycos.com.cu',
  'lycos.cv',
  'lycos.cx',
  'lycos.cy',
  'lycos.cz',
  'lycos.de',
  'lycos.dj',
  'lycos.dk',
  'lycos.dm',
  'lycos.do',
  'lycos.dz',
  'lycos.ec',
  'lycos.ee',
  'lycos.com.eg',
  'lycos.eh',
  'lycos.er',
  'lycos.es',
  'lycos.com.et',
  'lycos.eu',
  'lycos.fi',
  'lycos.com.fj',
  'lycos.fk',
  'lycos.fm',
  'lycos.fo',
  'lycos.fr',
  'lycos.ga',
  'lycos.co.uk',
  'lycos.gd',
  'lycos.ge',
  'lycos.gg',
  'lycos.com.gh',
  'lycos.com.gi',
  'lycos.gl',
  'lycos.gm',
  'lycos.gn',
  'lycos.gp',
  'lycos.gq',
  'lycos.gr',
  'lycos.gs',
  'lycos.hn',
  'lycos.gu',
  'lycos.gw',
  'lycos.gy',
  'lycos.com.hk',
  'lycos.hr',
  'lycos.ht',
  'lycos.hu',
  'lycos.co.id',
  'lycos.ie',
  'lycos.com.ni',
  'lycos.co.il',
  'lycos.im',
  'lycos.in',
  'lycos.io',
  'lycos.iq',
  'lycos.ir',
  'lycos.is',
  'lycos.it',
  'lycos.je',
  'lycos.com.jm',
  'lycos.jo',
  'lycos.jp',
  'lycos.co.ke',
  'lycos.kg',
  'lycos.com.kh',
  'lycos.ki',
  'lycos.km',
  'lycos.kn',
  'lycos.kp',
  'lycos.kr',
  'lycos.com.kw',
  'lycos.ky',
  'lycos.kz',
  'lycos.la',
  'lycos.com.lb',
  'lycos.lc',
  'lycos.li',
  'lycos.lk',
  'lycos.lr',
  'lycos.co.ls',
  'lycos.lt',
  'lycos.lu',
  'lycos.lv',
  'lycos.com.ly',
  'lycos.co.ma',
  'lycos.mc',
  'lycos.md',
  'lycos.com.mx',
  'lycos.mg',
  'lycos.mh',
  'lycos.mk',
  'lycos.ml',
  'lycos.mm',
  'lycos.mn',
  'lycos.mo',
  'lycos.mp',
  'lycos.mq',
  'lycos.mr',
  'lycos.ms',
  'lycos.com.mt',
  'lycos.mu',
  'lycos.mv',
  'lycos.mw',
  'lycos.com.my',
  'lycos.co.mz',
  'lycos.na',
  'lycos.ncsu.edu',
  'lycos.ne',
  'lycos.com.nf',
  'lycos.com.ng',
  'lycos.nl',
  'lycos.no',
  'lycos.com.np',
  'lycos.nr',
  'lycos.co.nz',
  'lycos.om',
  'lycos.pa',
  'lycos.com.pe',
  'lycosl.pf',
  'lycos.pg',
  'lycos.com.ph',
  'lycos.com.pk',
  'lycos.pl',
  'lycos.pm',
  'lycos.pn',
  'lycos.com.pr',
  'lycos.ps',
  'lycos.pt',
  'lycos.pw',
  'lycos.com.py',
  'lycos.com.qa',
  'lycos.re',
  'lycos.ro',
  'lycos.rs',
  'lycos.ru',
  'lycos.rw',
  'lycos.com.sa',
  'lycos.com.sb',
  'lycos.sc',
  'lycos.sd',
  'lycos.se',
  'lycos.com.sg',
  'lycos.sh',
  'lycos.si',
  'lycos.sk',
  'lycos.sl',
  'lycos.sm',
  'lycos.sn',
  'lycos.so',
  'lycos.ss',
  'lycos.st',
  'lycos.com.sv',
  'lycos.sy',
  'lycos.sz',
  'lycos.tc',
  'lycos.td',
  'lycos.tg',
  'lycos.th',
  'lycos.com.tj',
  'lycos.tk',
  'lycos.tl',
  'lycos.tm',
  'lycos.tn',
  'lycos.to',
  'lycos.tp',
  'lycos.tr',
  'lycos.tt',
  'lycos.tv',
  'lycos.tw',
  'lycos.co.tz',
  'lycos.com.ua',
  'lycos.co.ug',
  'lycos.us',
  'lycos.com.uy',
  'lycos.uz',
  'lycos.vc',
  'lycos.co.ve',
  'lycos.vg',
  'lycos.vi',
  'lycos.com.vn',
  'lycos.vu',
  'lycos.wf',
  'lycos.ws',
  'lycos.ye',
  'lycos.yt',
  'lycos.co.za',
  'lycos.co.zm',
  'lycos.co.zw',
  'msn',
  'msn.ac',
  'msn.ad',
  'msn.ae',
  'msn.com.af',
  'msn.ag',
  'msn.ai',
  'msn.al',
  'msn.am',
  'msn.an',
  'msn.ao',
  'msn.ca',
  'msn.as',
  'msn.at',
  'msn.com.au',
  'msn.aw',
  'msn.ax',
  'msn.az',
  'msn.ba',
  'msn.bb',
  'msn.com.bd',
  'msn.be',
  'msn.bf',
  'msn.bg',
  'msn.com.bh',
  'msn.bi',
  'msn.bj',
  'msn.bm',
  'msn.com.bn',
  'msn.bo',
  'msn.com.br',
  'msn.bs',
  'msn.bt',
  'msn.bv',
  'msn.co.bw',
  'msn.by',
  'msn.bz',
  'msn.cc',
  'msn.cd',
  'msn.cf',
  'msn.cg',
  'msn.ch',
  'msn.ci',
  'msn.ck',
  'msn.cl',
  'msn.cm',
  'msn.co',
  'msn.cr',
  'msn.cs',
  'msn.com.cu',
  'msn.cv',
  'msn.cx',
  'msn.cy',
  'msn.cz',
  'msn.de',
  'msn.dj',
  'msn.dk',
  'msn.dm',
  'msn.do',
  'msn.dz',
  'msn.ec',
  'msn.ee',
  'msn.com.eg',
  'msn.eh',
  'msn.er',
  'msn.es',
  'msn.com.et',
  'msn.eu',
  'msn.fi',
  'msn.com.fj',
  'msn.fk',
  'msn.fm',
  'msn.fo',
  'msn.fr',
  'msn.ga',
  'msn.co.uk',
  'msn.gd',
  'msn.ge',
  'msn.gg',
  'msn.com.gh',
  'msn.com.gi',
  'msn.gl',
  'msn.gm',
  'msn.gn',
  'msn.gp',
  'msn.gq',
  'msn.gr',
  'msn.gs',
  'msn.hn',
  'msn.gu',
  'msn.gw',
  'msn.gy',
  'msn.com.hk',
  'msn.hr',
  'msn.ht',
  'msn.hu',
  'msn.co.id',
  'msn.ie',
  'msn.com.ni',
  'msn.im',
  'msn.in',
  'msn.io',
  'msn.iq',
  'msn.ir',
  'msn.is',
  'msn.it',
  'msn.je',
  'msn.com.jm',
  'msn.jo',
  'msn.jp',
  'msn.co.ke',
  'msn.kg',
  'msn.com.kh',
  'msn.ki',
  'msn.km',
  'msn.kn',
  'msn.kp',
  'msn.com.kw',
  'msn.ky',
  'msn.kz',
  'msn.la',
  'msn.com.lb',
  'msn.lc',
  'msn.li',
  'msn.lk',
  'msn.lr',
  'msn.co.ls',
  'msn.lt',
  'msn.lu',
  'msn.lv',
  'msn.com.ly',
  'msn.co.ma',
  'msn.mc',
  'msn.md',
  'msn.mg',
  'msn.mh',
  'msn.mk',
  'msn.ml',
  'msn.mm',
  'msn.mn',
  'msn.mo',
  'msn.mp',
  'msn.mq',
  'msn.mr',
  'msn.ms',
  'msn.com.mt',
  'msn.mu',
  'msn.mv',
  'msn.mw',
  'msn.com.my',
  'msn.co.mz',
  'msn.na',
  'msn.ncsu.edu',
  'msn.ne',
  'msn.com.nf',
  'msn.com.ng',
  'msn.nl',
  'msn.no',
  'msn.com.np',
  'msn.nr',
  'msn.co.nz',
  'msn.om',
  'msn.pa',
  'msn.com.pe',
  'msnl.pf',
  'msn.pg',
  'msn.com.ph',
  'msn.com.pk',
  'msn.pl',
  'msn.pm',
  'msn.pn',
  'msn.com.pr',
  'msn.ps',
  'msn.pt',
  'msn.pw',
  'msn.com.py',
  'msn.com.qa',
  'msn.re',
  'msn.ro',
  'msn.rs',
  'msn.ru',
  'msn.rw',
  'msn.com.sa',
  'msn.com.sb',
  'msn.sc',
  'msn.sd',
  'msn.se',
  'msn.com.sg',
  'msn.sh',
  'msn.si',
  'msn.sk',
  'msn.sl',
  'msn.sm',
  'msn.sn',
  'msn.so',
  'msn.ss',
  'msn.st',
  'msn.com.sv',
  'msn.sy',
  'msn.sz',
  'msn.tc',
  'msn.td',
  'msn.tg',
  'msn.th',
  'msn.com.tj',
  'msn.tk',
  'msn.tl',
  'msn.tm',
  'msn.tn',
  'msn.to',
  'msn.tp',
  'msn.tr',
  'msn.tt',
  'msn.tv',
  'msn.tw',
  'msn.co.tz',
  'msn.com.ua',
  'msn.co.ug',
  'msn.us',
  'msn.com.uy',
  'msn.uz',
  'msn.vc',
  'msn.co.ve',
  'msn.vg',
  'msn.vi',
  'msn.com.vn',
  'msn.vu',
  'msn.wf',
  'msn.ws',
  'msn.ye',
  'msn.yt',
  'msn.co.za',
  'msn.co.zm',
  'msn.co.zw',
  'yahoo',
  'yahoo.ac',
  'yahoo.ad',
  'yahoo.ae',
  'yahoo.com.af',
  'yahoo.ag',
  'yahoo.ai',
  'yahoo.al',
  'yahoo.am',
  'yahoo.an',
  'yahoo.ao',
  'yahoo.ca',
  'yahoo.as',
  'yahoo.at',
  'yahoo.com.au',
  'yahoo.aw',
  'yahoo.ax',
  'yahoo.az',
  'yahoo.ba',
  'yahoo.bb',
  'yahoo.com.bd',
  'yahoo.be',
  'yahoo.bf',
  'yahoo.bg',
  'yahoo.com.bh',
  'yahoo.bi',
  'yahoo.bj',
  'yahoo.bm',
  'yahoo.com.bn',
  'yahoo.bo',
  'yahoo.com.br',
  'yahoo.bs',
  'yahoo.bt',
  'yahoo.bv',
  'yahoo.co.bw',
  'yahoo.by',
  'yahoo.bz',
  'yahoo.cc',
  'yahoo.cd',
  'yahoo.cf',
  'yahoo.cg',
  'yahoo.ch',
  'yahoo.ci',
  'yahoo.ck',
  'yahoo.cl',
  'yahoo.cm',
  'yahoo.co',
  'yahoo.cr',
  'yahoo.cs',
  'yahoo.com.cu',
  'yahoo.cv',
  'yahoo.cx',
  'yahoo.cy',
  'yahoo.cz',
  'yahoo.de',
  'yahoo.dj',
  'yahoo.dk',
  'yahoo.dm',
  'yahoo.do',
  'yahoo.dz',
  'yahoo.ec',
  'yahoo.ee',
  'yahoo.com.eg',
  'yahoo.eh',
  'yahoo.er',
  'yahoo.es',
  'yahoo.com.et',
  'yahoo.eu',
  'yahoo.fi',
  'yahoo.com.fj',
  'yahoo.fk',
  'yahoo.fm',
  'yahoo.fo',
  'yahoo.fr',
  'yahoo.ga',
  'yahoo.co.uk',
  'yahoo.gd',
  'yahoo.ge',
  'yahoo.gg',
  'yahoo.com.gh',
  'yahoo.com.gi',
  'yahoo.gl',
  'yahoo.gm',
  'yahoo.gn',
  'yahoo.gp',
  'yahoo.gq',
  'yahoo.gr',
  'yahoo.gs',
  'yahoo.hn',
  'yahoo.gu',
  'yahoo.gw',
  'yahoo.gy',
  'yahoo.com.hk',
  'yahoo.hr',
  'yahoo.ht',
  'yahoo.hu',
  'yahoo.co.id',
  'yahoo.ie',
  'yahoo.com.ni',
  'yahoo.im',
  'yahoo.in',
  'yahoo.io',
  'yahoo.iq',
  'yahoo.ir',
  'yahoo.is',
  'yahoo.it',
  'yahoo.je',
  'yahoo.com.jm',
  'yahoo.jo',
  'yahoo.jp',
  'yahoo.co.ke',
  'yahoo.kg',
  'yahoo.com.kh',
  'yahoo.ki',
  'yahoo.km',
  'yahoo.kn',
  'yahoo.kp',
  'yahoo.com.kw',
  'yahoo.ky',
  'yahoo.kz',
  'yahoo.la',
  'yahoo.com.lb',
  'yahoo.lc',
  'yahoo.li',
  'yahoo.lk',
  'yahoo.lr',
  'yahoo.co.ls',
  'yahoo.lt',
  'yahoo.lu',
  'yahoo.lv',
  'yahoo.com.ly',
  'yahoo.co.ma',
  'yahoo.mc',
  'yahoo.md',
  'yahoo.mg',
  'yahoo.mh',
  'yahoo.mk',
  'yahoo.ml',
  'yahoo.mm',
  'yahoo.mn',
  'yahoo.mo',
  'yahoo.mp',
  'yahoo.mq',
  'yahoo.mr',
  'yahoo.ms',
  'yahoo.com.mt',
  'yahoo.mu',
  'yahoo.mv',
  'yahoo.mw',
  'yahoo.com.my',
  'yahoo.co.mz',
  'yahoo.na',
  'yahoo.ncsu.edu',
  'yahoo.ne',
  'yahoo.com.nf',
  'yahoo.com.ng',
  'yahoo.nl',
  'yahoo.no',
  'yahoo.com.np',
  'yahoo.nr',
  'yahoo.co.nz',
  'yahoo.om',
  'yahoo.pa',
  'yahoo.com.pe',
  'yahool.pf',
  'yahoo.pg',
  'yahoo.com.ph',
  'yahoo.com.pk',
  'yahoo.pl',
  'yahoo.pm',
  'yahoo.pn',
  'yahoo.com.pr',
  'yahoo.ps',
  'yahoo.pt',
  'yahoo.pw',
  'yahoo.com.py',
  'yahoo.com.qa',
  'yahoo.re',
  'yahoo.ro',
  'yahoo.rs',
  'yahoo.ru',
  'yahoo.rw',
  'yahoo.com.sa',
  'yahoo.com.sb',
  'yahoo.sc',
  'yahoo.sd',
  'yahoo.se',
  'yahoo.com.sg',
  'yahoo.sh',
  'yahoo.si',
  'yahoo.sk',
  'yahoo.sl',
  'yahoo.sm',
  'yahoo.sn',
  'yahoo.so',
  'yahoo.ss',
  'yahoo.st',
  'yahoo.com.sv',
  'yahoo.sy',
  'yahoo.sz',
  'yahoo.tc',
  'yahoo.td',
  'yahoo.tg',
  'yahoo.th',
  'yahoo.com.tj',
  'yahoo.tk',
  'yahoo.tl',
  'yahoo.tm',
  'yahoo.tn',
  'yahoo.to',
  'yahoo.tp',
  'yahoo.tr',
  'yahoo.tt',
  'yahoo.tv',
  'yahoo.tw',
  'yahoo.co.tz',
  'yahoo.com.ua',
  'yahoo.co.ug',
  'yahoo.us',
  'yahoo.com.uy',
  'yahoo.uz',
  'yahoo.vc',
  'yahoo.co.ve',
  'yahoo.vg',
  'yahoo.vi',
  'yahoo.com.vn',
  'yahoo.vu',
  'yahoo.wf',
  'yahoo.ws',
  'yahoo.ye',
  'yahoo.yt',
  'yahoo.co.za',
  'yahoo.co.zm',
  'yahoo.co.zw',
  'hotmail',
  'hotmail.ac',
  'hotmail.ad',
  'hotmail.ae',
  'hotmail.com.af',
  'hotmail.ag',
  'hotmail.ai',
  'hotmail.al',
  'hotmail.am',
  'hotmail.an',
  'hotmail.ao',
  'hotmail.ca',
  'hotmail.as',
  'hotmail.at',
  'hotmail.com.au',
  'hotmail.aw',
  'hotmail.ax',
  'hotmail.az',
  'hotmail.ba',
  'hotmail.bb',
  'hotmail.com.bd',
  'hotmail.be',
  'hotmail.bf',
  'hotmail.bg',
  'hotmail.com.bh',
  'hotmail.bi',
  'hotmail.bj',
  'hotmail.bm',
  'hotmail.com.bn',
  'hotmail.bo',
  'hotmail.com.br',
  'hotmail.bs',
  'hotmail.bt',
  'hotmail.bv',
  'hotmail.co.bw',
  'hotmail.by',
  'hotmail.bz',
  'hotmail.cc',
  'hotmail.cd',
  'hotmail.cf',
  'hotmail.cg',
  'hotmail.ch',
  'hotmail.ci',
  'hotmail.ck',
  'hotmail.cl',
  'hotmail.cm',
  'hotmail.co',
  'hotmail.cr',
  'hotmail.cs',
  'hotmail.com.cu',
  'hotmail.cv',
  'hotmail.cx',
  'hotmail.cy',
  'hotmail.cz',
  'hotmail.de',
  'hotmail.dj',
  'hotmail.dk',
  'hotmail.dm',
  'hotmail.do',
  'hotmail.dz',
  'hotmail.ec',
  'hotmail.ee',
  'hotmail.com.eg',
  'hotmail.eh',
  'hotmail.er',
  'hotmail.es',
  'hotmail.com.et',
  'hotmail.eu',
  'hotmail.fi',
  'hotmail.com.fj',
  'hotmail.fk',
  'hotmail.fm',
  'hotmail.fo',
  'hotmail.fr',
  'hotmail.ga',
  'hotmail.co.uk',
  'hotmail.gd',
  'hotmail.ge',
  'hotmail.gg',
  'hotmail.com.gh',
  'hotmail.com.gi',
  'hotmail.gl',
  'hotmail.gm',
  'hotmail.gn',
  'hotmail.gp',
  'hotmail.gq',
  'hotmail.gr',
  'hotmail.gs',
  'hotmail.hn',
  'hotmail.gu',
  'hotmail.gw',
  'hotmail.gy',
  'hotmail.com.hk',
  'hotmail.hr',
  'hotmail.ht',
  'hotmail.hu',
  'hotmail.co.id',
  'hotmail.ie',
  'hotmail.com.ni',
  'hotmail.im',
  'hotmail.in',
  'hotmail.io',
  'hotmail.iq',
  'hotmail.ir',
  'hotmail.is',
  'hotmail.it',
  'hotmail.je',
  'hotmail.com.jm',
  'hotmail.jo',
  'hotmail.jp',
  'hotmail.co.ke',
  'hotmail.kg',
  'hotmail.com.kh',
  'hotmail.ki',
  'hotmail.km',
  'hotmail.kn',
  'hotmail.kp',
  'hotmail.com.kw',
  'hotmail.ky',
  'hotmail.kz',
  'hotmail.la',
  'hotmail.com.lb',
  'hotmail.lc',
  'hotmail.li',
  'hotmail.lk',
  'hotmail.lr',
  'hotmail.co.ls',
  'hotmail.lt',
  'hotmail.lu',
  'hotmail.lv',
  'hotmail.com.ly',
  'hotmail.co.ma',
  'hotmail.mc',
  'hotmail.md',
  'hotmail.mg',
  'hotmail.mh',
  'hotmail.mk',
  'hotmail.ml',
  'hotmail.mm',
  'hotmail.mn',
  'hotmail.mo',
  'hotmail.mp',
  'hotmail.mq',
  'hotmail.mr',
  'hotmail.ms',
  'hotmail.com.mt',
  'hotmail.mu',
  'hotmail.mv',
  'hotmail.mw',
  'hotmail.com.my',
  'hotmail.co.mz',
  'hotmail.na',
  'hotmail.ncsu.edu',
  'hotmail.ne',
  'hotmail.com.nf',
  'hotmail.com.ng',
  'hotmail.nl',
  'hotmail.no',
  'hotmail.com.np',
  'hotmail.nr',
  'hotmail.co.nz',
  'hotmail.om',
  'hotmail.pa',
  'hotmail.com.pe',
  'hotmaill.pf',
  'hotmail.pg',
  'hotmail.com.ph',
  'hotmail.com.pk',
  'hotmail.pl',
  'hotmail.pm',
  'hotmail.pn',
  'hotmail.com.pr',
  'hotmail.ps',
  'hotmail.pt',
  'hotmail.pw',
  'hotmail.com.py',
  'hotmail.com.qa',
  'hotmail.re',
  'hotmail.ro',
  'hotmail.rs',
  'hotmail.rw',
  'hotmail.com.sa',
  'hotmail.com.sb',
  'hotmail.sc',
  'hotmail.sd',
  'hotmail.se',
  'hotmail.com.sg',
  'hotmail.sh',
  'hotmail.si',
  'hotmail.sk',
  'hotmail.sl',
  'hotmail.sm',
  'hotmail.sn',
  'hotmail.so',
  'hotmail.ss',
  'hotmail.st',
  'hotmail.com.sv',
  'hotmail.sy',
  'hotmail.sz',
  'hotmail.tc',
  'hotmail.td',
  'hotmail.tg',
  'hotmail.th',
  'hotmail.com.tj',
  'hotmail.tk',
  'hotmail.tl',
  'hotmail.tm',
  'hotmail.tn',
  'hotmail.to',
  'hotmail.tp',
  'hotmail.tr',
  'hotmail.tt',
  'hotmail.tv',
  'hotmail.tw',
  'hotmail.co.tz',
  'hotmail.com.ua',
  'hotmail.co.ug',
  'hotmail.us',
  'hotmail.com.uy',
  'hotmail.uz',
  'hotmail.vc',
  'hotmail.co.ve',
  'hotmail.vg',
  'hotmail.vi',
  'hotmail.com.vn',
  'hotmail.vu',
  'hotmail.wf',
  'hotmail.ws',
  'hotmail.ye',
  'hotmail.yt',
  'hotmail.co.za',
  'hotmail.co.zm',
  'hotmail.co.zw',
  'gmail.co.in',
  'aol.co.in',
  'husmail.co.in',
  'inbox.co.in',
  'juno.co.in',
  'lycos.co.in',
  'mail.co.in',
  'msn.co.in',
  'yahoo.co.in',
  'hotmail.co.in',
  'yaho.com',
  'hotmail.co.in',
])
