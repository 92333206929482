import api from './Api'
import { convertBlobToMP3 } from '@/utils/helpers'
import { getTablePaginationParams } from './helpers'

const ENDPOINTS = {
  forgotPassword: `/user/actions/forgotPassword`,
  updatePassword: `/user/actions/updatePassword`,
  uploadVoicemailGreeting: `/user/actions/uploadVoicemailGreeting`,
  voicemailGreeting: `/user/actions/voicemailGreeting`,
  uploadAvatarImage: `/user/actions/uploadAvatarImage`,
  login: `/login`,
  user: `/userProfile`,
  otherOrgUsers: `/otherOrgUsers`,
  unsubscribe: (token = '') => (token ? `/unsubscribe/${token}` : ''),
  userSettings: (id = '') => (id ? `/users/${id}/settings` : ''),
}

export default {
  login(user) {
    return api.post(ENDPOINTS.login, user)
  },

  getOtherOrgUsers(options) {
    let path = ENDPOINTS.otherOrgUsers
    let pathParams = []

    const { searchByName, selectedOrg, subscriptionStatus } = options

    // Table search input
    if (searchByName) pathParams.push(`search=${searchByName}`)

    // Selected org
    if (selectedOrg) pathParams.push(`organizationId=${selectedOrg}`)

    // Selected subscription status
    if (subscriptionStatus)
      pathParams.push(`organizationSubscriptionStatus=${subscriptionStatus}`)

    // Pagination
    const pagParams = getTablePaginationParams(options)
    if (pagParams) pathParams.push(pagParams)

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.get(path)
  },

  getLoggedInUser(forceFetch = false) {
    return api.get(ENDPOINTS.user, forceFetch ? {} : { abortKey: 'GET_LOGGED_IN_USER' })
  },

  forgotPasswordRequest(email) {
    const path = ENDPOINTS.forgotPassword + '?' + `email=${email}`
    return api.get(path)
  },

  // for use with forgotPasswordRequest
  updatePassword(payload) {
    return api.post(ENDPOINTS.forgotPassword, payload)
  },

  changePassword(payload) {
    return api.post(ENDPOINTS.updatePassword, payload)
  },

  unsubscribeEmailAddress(mailingToken) {
    return api.post(ENDPOINTS.unsubscribe(mailingToken))
  },

  uploadUserAvatarImage(form, onUploadProgress = null) {
    return api.post(ENDPOINTS.uploadAvatarImage, form, {
      headers: { 'Content-Type': 'multipart/form-data' },
      ...(onUploadProgress && { onUploadProgress }),
    })
  },

  updateUserProfile(payload) {
    return api.put(ENDPOINTS.user, payload)
  },

  saveUserVMGreeting(payload) {
    return new Promise((resolve, reject) => {
      convertBlobToMP3(payload.recording).then((mp3File) => {
        const form = new FormData()
        form.append('file', mp3File)

        api
          .post(ENDPOINTS.uploadVoicemailGreeting, form, {
            headers: {
              'Content-Type': `multipart/form-data`,
            },
          })
          .then((resp) => resolve(resp))
          .catch(() => reject())
      })
    })
  },

  deleteUserVMGreeting() {
    return api.delete(ENDPOINTS.voicemailGreeting)
  },

  /**
   * payload structure can be an array of settings objects (key/value)
   * or a single setting object (settings_key/settings_value)
   * 
   * {
   *    userId: Id,
   *    settings: {
   *      settings: [
   *        {
   *          key: ,
   *          value: ,
   *        }
   *      ],
   *    },
   *  }
      or
   * {
   *    userId: Id,
   *    settings: {
   *      settings_key: ,
   *      settings_value: ,
   *    },
   *  }
   * @param {*} id
   * @param {*} payload
   * @return {*}
   */
  updateSetting(id, payload) {
    return api.put(ENDPOINTS.userSettings(id), payload)
  },
}
