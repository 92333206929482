import DispositionsApi from '@/services/api/Dispositions'
import { getField, updateField } from 'vuex-map-fields'
import { generateUuid } from '@/utils/helpers'
const _find = require('lodash/find')

// initial state
const defaultState = () => ({
  status: '',
  disposition: {},
})

const state = defaultState()

// getters
const getters = {
  getField,
  disposition: (state) => state.disposition,
  dispositionLoading: (state) => state.status === 'loading',
  dispositionSteps: (state) => state.disposition?.dispositionSteps,
  getDispositionStepById: (state) => (id) => {
    return _find(state.disposition?.dispositionSteps, { id: id })
  },
}

// actions
const actions = {
  async fetchDisposition({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit('request')
      DispositionsApi.getDispositionById(id)
        .then((resp) => {
          commit('setDisposition', resp)
          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          commit('error')
          reject(err)
        })
    })
  },

  async postBatchDispositionWithDispositionSteps({ commit, getters }) {
    commit('setActionData')
    return new Promise((resolve, reject) => {
      commit('request')
      DispositionsApi.postBatchDispositionWithDispositionSteps(
        getters.disposition
      )
        .then((resp) => {
          commit('setDisposition', resp)
          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          commit('error')
          reject(err)
        })
    })
  },

  async updateBatchDispositionWithDispositionSteps({ commit, getters }) {
    return new Promise((resolve, reject) => {
      commit('request')
      DispositionsApi.updateBatchDispositionWithDispositionSteps(
        getters.disposition.id,
        getters.disposition
      )
        .then((resp) => {
          commit('setDisposition', resp)
          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          commit('error')
          reject(err)
        })
    })
  },

  cloneDisposition({ commit }) {
    commit('cloneDispositionMutation')
  },

  async setDisposition({ commit }, disposition) {
    commit('setDisposition', disposition)
  },
}

// mutations
const mutations = {
  updateField,
  setDisposition(state, disposition) {
    state.disposition = disposition
  },

  request(state) {
    state.status = 'loading'
  },

  success(state) {
    state.status = 'success'
  },

  error(state) {
    state.status = 'error'
  },

  // set action_data json to send to API
  setActionData(state, { dispositionStep, data }) {
    _find(state.disposition?.dispositionSteps, { id: dispositionStep.id })[
      'action_data'
    ] = data
  },

  addDispositionStep(state, step) {
    state.disposition?.dispositionSteps.push({
      id: generateUuid(),
      action_type: step.type,
      action_data: step.data,
    })
  },

  removeDispositionStep(state, index) {
    state.disposition?.dispositionSteps.splice(index, 1)
  },

  cloneDispositionMutation(state) {
    state.disposition.id = 1
    state.disposition.name += ' - Copy'
    state.disposition.state = 'draft'
  },

  resetState(state) {
    Object.assign(state, defaultState())
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
