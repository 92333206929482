const formatDistanceLocale = {
  lessThanXSeconds: () => 'just now',

  lessThanXMinutes: (m) =>
    m > 1 ? `less than ${m} minutes ago` : 'less than a minute ago',
  xMinutes: (m) => (m > 1 ? `about ${m} minutes ago` : 'about a minute ago'),

  lessThanXHours: (h) =>
    h > 1 ? `less than ${h} hours ago` : 'less than an hour ago',
  xHours: (h) => (h > 1 ? `about ${h} hours ago` : 'about an hour ago'),

  lessThanXDays: (d) =>
    d > 1 ? `less than ${d} days ago` : 'less than a day ago',
  xDays: (d) => (d > 1 ? `about ${d} days ago` : 'about a day ago'),

  lessThanXWeeks: (w) =>
    w > 1 ? `less than ${w} weeks ago` : 'less than a week ago',
  xWeeks: (w) => (w > 1 ? `about ${w} weeks ago` : 'about a week ago'),

  lessThanXMonths: (m) =>
    m > 1 ? `less than ${m} months ago` : 'less than a month ago',
  xMonths: (m) => (m > 1 ? `about ${m} months ago` : 'about a month ago'),

  lessThanXYears: (y) =>
    y > 1 ? `less than ${y} years ago` : 'less than a year ago',
  xYears: (y) => (y > 1 ? `about ${y} years ago` : 'about a year ago'),

  overXYears: (y) => `Over ${y} years ago`,
}

const format = (token, value) => {
  return formatDistanceLocale[token](value)
}

export const formatDistance = (duration) => {
  let seconds = Math.abs(duration.as('seconds'))
  let minutes = Math.abs(duration.as('minutes'))
  let hours = Math.abs(duration.as('hours'))
  let days = Math.abs(duration.as('days'))
  let weeks = Math.abs(duration.as('weeks'))
  let months = Math.abs(duration.as('months'))
  let years = Math.abs(duration.as('years'))

  if (seconds < 30) return format('lessThanXSeconds', seconds)

  // Minutes rounding
  if (minutes % 1 <= 0.25) minutes = parseInt(minutes)
  else if (minutes % 1 >= 0.75) minutes = Math.ceil(minutes)
  else minutes = parseInt(minutes)
  // For 1 to 50 minutes use xMinutes
  if (minutes >= 1 && minutes <= 50) return format('xMinutes', minutes)

  // Hours rounding
  if (hours % 1 <= 0.25) hours = parseInt(hours)
  else if (hours % 1 >= 0.75) hours = Math.ceil(hours)
  else hours = parseInt(hours)
  // For 50 to 60 minutes use lessThanXHours
  if (minutes > 50 && minutes <= 60) return format('lessThanXHours', hours)

  // For 1 to 20 hours use xHours
  if (hours >= 1 && hours <= 20) return format('xHours', hours)

  // Days Rounding
  if (days % 1 <= 0.25) days = parseInt(days)
  else if (days % 1 >= 0.75) days = Math.ceil(days)
  else days = parseInt(days)
  // For 20 to 24 hours use lessThanXDays
  if (hours > 20 && hours <= 24) return format('lessThanXDays', days)

  // For 1 to 6 days use xDays
  if (days >= 1 && days <= 6) return format('xDays', days)

  //Weeks Rouding
  if (weeks % 1 <= 0.25) weeks = parseInt(weeks)
  else if (weeks % 1 >= 0.75) weeks = Math.ceil(weeks)
  else weeks = parseInt(weeks)
  // For 6 to 7 days use lessThanXWeeks
  if (days > 6 && days <= 7) return format('lessThanXWeeks', weeks)

  // For 1 to 3 weeks use xWeeks
  if (weeks >= 1 && weeks <= 3) return format('xWeeks', weeks)

  //Months Rounding
  if (months % 1 <= 0.25) months = parseInt(months)
  else if (months % 1 >= 0.75) months = Math.ceil(months)
  else months = parseInt(months)
  // For 3 to 4 weeks use lessThanXMonths
  if (weeks > 3 && weeks <= 4) return format('lessThanXMonths', months)

  // For 1 to 11 months use xMonths
  if (months >= 1 && months <= 11) return format('xMonths', months)

  // Years Rounding
  if (years % 1 <= 0.25) years = parseInt(years)
  else if (years % 1 >= 0.75) years = Math.ceil(years)
  else years = parseInt(years)
  // For 11 to 12 months use lessThanXYears
  if (months > 11 && months <= 12) return format('lessThanXYears', years)

  // For 1 to 2 years use xYears
  if (years >= 1 && years <= 2) return format('xYears', years)

  // For over 2 years use overXYears
  if (years > 2) return format('overXYears', years)
}
