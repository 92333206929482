import {
  getDefaultFilters,
  getPinnedDefaultTabs,
} from '@/components/filter-menu/defaultFilterTemplates'

import {
  getAllSavedFilters,
  updateSavedFilter,
} from '@/services/api/SavedFilters'

import { UserSettings } from '@/constants'
import { getLSItem } from '@/utils/localStorage'
import { setLSItem } from '../../utils/localStorage'

const _unionBy = require('lodash/unionBy')

const savedActiveTabs = getLSItem('activeTabs')

// initial state
const state = () => ({
  activeTabs: savedActiveTabs || {
    contacts: null,
    'manual-actions': null,
  },
  tabs: {},
  tabsLoading: false,
})

// getters
const getters = {
  getFilterTabs: (state) => (tableName) => state.tabs[tableName] || [],
  getActiveTabIndex: (state) => (tableName) => state.activeTabs[tableName],
  getActiveTab: (state, getters) => (tableName) =>
    getters.getFilterTabs(tableName)[getters.getActiveTabIndex(tableName)],
  getTabsLoading: (state) => state.tabsLoading,
}

// actions
const actions = {
  fetchTabs({ commit, state }, tableName) {
    if (!state.tabsLoading) {
      commit('request')
      getAllSavedFilters({ isPinned: 1, type: tableName })
        .then((resp) => commit('appendTabs', { tableName, tabs: resp.data }))
        .finally(() => commit('requestFinished'))
    }
  },

  setDefaultTabs({ dispatch, commit, rootGetters }, tableName) {
    const defaultTabsConfig = rootGetters['user/getSettingByKey'](
      UserSettings.DEFAULT_FILTER_TABS_CONFIG
    )
    let tabsConfigForTable = defaultTabsConfig
      ? defaultTabsConfig[tableName]
      : []
    if (tabsConfigForTable == null) {
      tabsConfigForTable = getDefaultFilters(tableName).map((tab) => tab.id)
      dispatch('setDefaultTabsConfig', {
        tableName,
        tabsConfig: tabsConfigForTable,
      })
    }

    commit('appendTabs', {
      tableName,
      tabs: getPinnedDefaultTabs(tableName, tabsConfigForTable),
      addToStart: true,
    })
  },

  createTab({ commit, dispatch, getters }, payload) {
    const { tableName, tab } = payload
    commit('appendTabs', {
      tableName,
      tabs: [tab],
    })
    commit('setActiveTab', {
      tableName,
      tabIndex: getters.getFilterTabs(tableName).length - 1,
    })
    if (tab.isDefault) dispatch('toggleDefaultTabConfig', { tableName, tab })
    else
      updateSavedFilter({
        ...tab,
        is_pinned: 1,
      })
  },

  deleteTab({ commit, dispatch, getters }, payload) {
    const { tableName, tab } = payload
    const tabId = tab.id
    const tabIndex = getters
      .getFilterTabs(tableName)
      .findIndex((tab) => tabId === tab.id)
    commit('removeTab', { tableName, tabIndex })
    if (tab.isDefault)
      dispatch('toggleDefaultTabConfig', {
        tableName,
        tab,
        remove: true,
      })
    else
      updateSavedFilter({
        ...tab,
        is_pinned: 0,
      })
  },

  toggleDefaultTabConfig({ rootGetters, dispatch }, payload) {
    const { tableName, tab, remove } = payload
    const getSettingByKey = rootGetters['user/getSettingByKey']
    const user = rootGetters['user/user']
    const defaultTabsConfig =
      getSettingByKey(UserSettings.DEFAULT_FILTER_TABS_CONFIG) || {}
    const tabsConfigForTable = defaultTabsConfig[tableName] || []
    const newConfig = {
      ...defaultTabsConfig,
      [tableName]: remove
        ? tabsConfigForTable.filter((id) => id !== tab.id)
        : [...tabsConfigForTable, tab.id],
    }
    dispatch(
      'user/updateSetting',
      {
        userId: user.id,
        settings: {
          settings_key: UserSettings.DEFAULT_FILTER_TABS_CONFIG,
          settings_value: newConfig,
        },
      },
      { root: true }
    )
  },

  setDefaultTabsConfig({ rootGetters, dispatch }, payload) {
    const { tableName, tabsConfig } = payload
    const getSettingByKey = rootGetters['user/getSettingByKey']
    const user = rootGetters['user/user']
    const defaultTabsConfig =
      getSettingByKey(UserSettings.DEFAULT_FILTER_TABS_CONFIG) || {}
    const newConfig = {
      ...defaultTabsConfig,
      [tableName]: tabsConfig,
    }
    dispatch(
      'user/updateSetting',
      {
        userId: user.id,
        settings: {
          settings_key: UserSettings.DEFAULT_FILTER_TABS_CONFIG,
          settings_value: newConfig,
        },
      },
      { root: true }
    )
  },
}

// mutations
const mutations = {
  request(state) {
    state.tabsLoading = true
  },
  requestFinished(state) {
    state.tabsLoading = false
  },
  setActiveTab(state, payload) {
    const { tableName, tabIndex } = payload

    if (state.activeTabs[tableName] === tabIndex)
      state.activeTabs = {
        ...state.activeTabs,
        [tableName]: null,
      }
    else
      state.activeTabs = {
        ...state.activeTabs,
        [tableName]: tabIndex,
      }

    setLSItem('activeTabs', JSON.stringify(state.activeTabs))
  },
  removeActiveTab(state, tableName) {
    state.activeTabs = {
      ...state.activeTabs,
      [tableName]: null,
    }
    setLSItem('activeTabs', JSON.stringify(state.activeTabs))
  },
  appendTabs(state, payload) {
    const { tableName, tabs, addToStart } = payload
    const tabsAfterAdding = addToStart
      ? _unionBy(tabs, state.tabs[tableName], 'id')
      : _unionBy(state.tabs[tableName], tabs, 'id')
    state.tabs = {
      ...state.tabs,
      [tableName]: tabsAfterAdding,
    }
  },
  removeTab(state, payload) {
    const { tableName, tabIndex } = payload
    const currentTabs = state.tabs[tableName]
    state.tabs = {
      ...state.tabs,
      [tableName]: [
        ...currentTabs.slice(0, tabIndex),
        ...currentTabs.slice(tabIndex + 1),
      ],
    }
  },
  updateTab(state, payload) {
    const { tableName, tab } = payload
    const tabs = state.tabs[tableName]
    const index = tabs.findIndex((filterTab) => filterTab.id === tab.id)
    if (index !== -1) {
      state.tabs = {
        ...state.tabs,
        [tableName]: [...tabs.slice(0, index), tab, ...tabs.slice(index + 1)],
      }
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
