<template>
  <div class="h-100 w-100 overflow-y-auto">
    <v-calendar
      v-model="dateViewing"
      ref="calendar"
      color="primary"
      class="day-calendar"
      :events="filteredEvents"
      :event-color="getEventColor"
      :type="type"
      :interval-format="intervalLabelFormatter"
      :class="
        secondaryTimezone && showSecondaryTimezone
          ? 'should-show-secondary-timezone'
          : ''
      "
      @click:event="editEvent"
      @click:more="viewDay"
      @click:date="viewDay"
      @change="onDateChange"
      @mousedown:event="eventMouseDown"
      @mousedown:time="timeMouseDown"
      @mousemove:time="mouseMove"
      @mouseup:time="timeMouseUp"
      @mouseleave.native="mouseLeave"
    >
      <template v-slot:event="{ event, timed }">
        <v-tooltip
          left
          open-delay="500"
          max-width="600"
          :disabled="!!eventCurrentlyMouseDown"
        >
          <template v-slot:activator="{ on }">
            <div v-on="on" :id="`ev-${event.id}`" class="v-event-draggable">
              <div class="d-flex align-start">
                <user-avatar
                  v-if="event.user"
                  elevated
                  readonly
                  disableTooltip
                  disableHover
                  class="mr-2"
                  :size="16"
                  :value="event.user"
                />

                <div
                  class="ml-2"
                  style="width: calc(100% - 40px)"
                  :style="`color: ${getTextColorFromBG(event.color)}`"
                >
                  <template v-if="isGreaterThan30Mins(event)">
                    <div class="font-weight-bold text-truncate mxw-100">
                      {{ event.name }}
                    </div>
                    <div class="text-caption mt-n1 d-flex align-center">
                      {{ timeStampToAmPmTime(event.start) }} -
                      {{ timeStampToAmPmTime(event.end) }}
                      <span class="text-xs ml-1">({{ primaryTimezone }})</span>
                    </div>
                  </template>

                  <div v-else class="mxw-100 font-weight-bold text-truncate">
                    {{ event.name }}
                  </div>
                </div>

                <v-spacer />
                <v-tooltip
                  v-if="event && shouldShowFinishEditing(event)"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="red" class="mr-2">
                      mdi-information
                    </v-icon>
                  </template>
                  Finish editing event
                </v-tooltip>
              </div>
              <div
                v-if="timed && !event.read_only"
                class="v-event-drag-bottom"
                @mousedown.stop="extendBottom(event)"
              ></div>
            </div>
          </template>
          <div>
            <!-- Name -->
            <div class="text-body-1 font-weight-bold">{{ event.name }}</div>
            <!-- Description -->
            <div class="d-flex align-center text-caption">
              <div class="font-weight-bold mr-1">Description:</div>
              <div>{{ event.description }}</div>
            </div>
            <!-- Time -->
            <div class="d-flex align-start text-caption">
              <div class="font-weight-bold mr-1">Time:</div>
              <div>
                <div class="d-flex align-center">
                  {{ timeStampToAmPmTime(event.start) }} -
                  {{ timeStampToAmPmTime(event.end) }}
                  <div class="text-xs ml-1">
                    ({{ viewProspectDay ? "Prospect's time: " : ''
                    }}{{ primaryTimezone }})
                  </div>
                </div>
                <div
                  v-if="showSecondaryTimezone && secondaryTimezone?.value"
                  class="d-flex align-center"
                >
                  {{ prospectTime(event.start) }} -
                  {{ prospectTime(event.end) }}
                  <div class="text-xs ml-1">
                    ({{ viewProspectDay ? '' : "Prospect's time: "
                    }}{{ secondaryTimezone?.label }})
                  </div>
                </div>
              </div>
            </div>
            <!-- Participants -->
            <div
              v-if="event.participants"
              class="d-flex align-start text-caption"
            >
              <div class="font-weight-bold mr-1">Participants:</div>
              <ul>
                <li
                  v-for="(participant, index) in event.participants.slice(0, 3)"
                  :key="index"
                >
                  {{ participant.name }}
                </li>
                <li v-if="event.participants.length > 3">
                  + {{ event.participants.length - 3 }} more
                </li>
              </ul>
            </div>
          </div>
        </v-tooltip>
      </template>

      <template v-slot:day-label-header="{ date, weekday }">
        <div class="d-flex align-center py-1 px-1 w-100">
          <v-btn small icon @click="prev">
            <v-icon size="18">mdi-chevron-left </v-icon>
          </v-btn>
          <v-spacer />
          <v-menu v-if="!fetchEventsLoader" bottom>
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <div class="d-flex align-center">
                  {{ monthDayFormat(date) }}
                  <span
                    class="ml-1 primary--text font-weight-medium text-caption"
                  >
                    ({{ weekdays[weekday] }})
                  </span>
                </div>
                <div
                  class="text-caption grey--text text--darken-2 mt-n1 font-weight-medium"
                >
                  {{ primaryTimezone }}
                </div>
              </div>
            </template>
            <v-card height="350" width="250">
              <v-date-picker
                v-model="dateViewing"
                width="250"
                class="w-100 rounded-0 custom-picker"
              />
            </v-card>
          </v-menu>

          <div v-else class="d-flex align-center text-caption primary--text">
            <v-progress-circular
              indeterminate
              width="2"
              size="13"
              color="primary"
              class="mr-2"
            />
            Loading events ...
          </div>

          <v-spacer />
          <v-btn small icon @click="next">
            <v-icon size="18">mdi-chevron-right </v-icon>
          </v-btn>
        </div>
        <select-calendar-email
          onlyUsers
          placeholder="Select user calendar"
          class="pb-2 px-4"
          :selectedItems="selectedUsers"
          @selection-updated="selectedUsers = $event"
        />
        <div class="d-flex align-center">
          <v-checkbox
            v-model="hideMyEvents"
            dense
            hide-details
            class="mt-0 pt-0 px-4 mt-n1 hide-events-checkbox"
          >
            <template v-slot:label>
              <div class="text-caption font-weight-medium">Hide my events</div>
            </template>
          </v-checkbox>
          <v-checkbox
            v-model="viewProspectDay"
            dense
            hide-details
            class="mt-0 pt-0 px-4 mt-n1 hide-events-checkbox"
          >
            <template v-slot:label>
              <div class="text-caption font-weight-medium">Prospect's day</div>
            </template>
          </v-checkbox>
        </div>
      </template>
    </v-calendar>
    <v-menu
      v-model="isEditingEvent"
      offset-x
      :close-on-content-click="false"
      :activator="`#ev-${selectedEvent?.id}`"
    >
      <v-card
        v-if="selectedEvent"
        outlined
        :min-width="`${selectedEvent?.read_only ? '290px' : '350px'}`"
        :max-width="`${selectedEvent?.read_only ? '290px' : '350px'}`"
        :width="`${selectedEvent?.read_only ? '290px' : '350px'}`"
      >
        <v-card-title class="py-1 border-b-1">
          <div class="d-flex align-center w-100">
            <div>Event:</div>
            <div
              class="ml-2 text-truncate"
              style="max-width: calc(100% - 100px)"
            >
              {{ selectedEvent.name || 'No name' }}
            </div>
            <template v-if="selectedEvent?.read_only">
              <v-spacer />
              <v-btn
                small
                icon
                height="15"
                min-height="15"
                min-width="15"
                width="15"
                class="pa-0"
                @click="isEditingEvent = false"
              >
                <v-icon size="14">mdi-close</v-icon>
              </v-btn>
            </template>
          </div>
        </v-card-title>
        <v-card-text class="pt-0">
          <template v-if="!selectedEvent?.read_only">
            <!-- Start & End time -->
            <div class="mb-5 mt-3">
              <div class="d-flex align-start extra-dense-fields">
                <v-icon small class="mr-4 mt-2">mdi-clock-outline</v-icon>
                <div style="width: 80px" class="mt-0">
                  <v-select
                    v-model="selectedEventStartTime"
                    dense
                    filled
                    hide-details
                    placeholder="Start time"
                    append-icon=""
                    item-text="text"
                    item-value="value"
                    class="mt-0 text-caption"
                    :items="startTimeOptions"
                    :menu-props="{
                      offsetY: true,
                      maxHeight: 200,
                    }"
                    @change="onStartTimeChange"
                  />
                  <div style="margin-top: 2px" class="text-caption">
                    {{ startTimeHint }}
                  </div>
                </div>
                <div style="height: 32px" class="mx-2 pt-2">-</div>
                <div style="width: 80px" class="mt-0">
                  <v-select
                    v-model="selectedEventEndTime"
                    dense
                    flat
                    filled
                    hide-details
                    placeholder="End time"
                    append-icon=""
                    item-text="text"
                    item-value="value"
                    class="mt-0 text-caption"
                    :items="endTimeOptions"
                    :menu-props="{
                      offsetY: true,
                      maxHeight: 200,
                    }"
                    @change="onEndTimeChange"
                  >
                    <template v-slot:item="{ item, on, attrs }">
                      <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.text }} ({{ item.eventDuration }})
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                  <div style="margin-top: 2px" class="text-caption">
                    {{ endTimeHint }}
                  </div>
                </div>

                <v-menu bottom>
                  <template v-slot:activator="{ on }">
                    <div
                      v-on="on"
                      class="d-flex align-center ml-2"
                      style="height: 32px"
                    >
                      <v-icon small class="mr-1" color="primary"
                        >mdi-calendar</v-icon
                      >
                      <div class="text-caption link">Change date</div>
                    </div>
                  </template>
                  <v-card>
                    <v-date-picker
                      v-model="dateViewing"
                      width="250"
                      class="w-100 rounded-0 custom-picker"
                    />
                  </v-card>
                </v-menu>
              </div>
            </div>
            <!-- Schedule as -->
            <div v-if="isNewEvent" class="extra-dense-fields mb-4">
              <v-select
                v-model="scheduleAs"
                outlined
                dense
                hide-details
                label="Schedule as"
                item-value="id"
                :items="scheduleAsOptions"
                :menu-props="{
                  bottom: true,
                  offsetY: true,
                }"
              >
                <template v-slot:item="{ item }">
                  <div class="py-1">
                    <div class="font-weight-medium text-body-2">
                      {{ item.full_name }}
                    </div>
                    <div class="text-caption mt-n1">{{ item.email }}</div>
                  </div>
                </template>
                <template v-slot:selection="{ item }">
                  <div class="font-weight-medium text-body-2">
                    {{ item.full_name }}
                    <span
                      v-if="item.id === mailbox?.id"
                      class="ml-1 text-caption"
                    >
                      (yourself)
                    </span>
                  </div>
                </template>
              </v-select>
            </div>
            <!-- Title -->
            <div class="extra-dense-fields mb-4">
              <v-text-field
                v-model="selectedEvent.name"
                dense
                hide-details
                outlined
                label="Title"
              />
            </div>
            <!-- Members -->
            <select-calendar-email
              class="mb-4"
              :selectedItems="selectedEventParticipants"
              @selection-updated="participantSelectionUpdated"
            />
            <!-- Description -->
            <v-textarea
              v-model="selectedEvent.description"
              hide-details
              dense
              outlined
              label="Description"
              rows="3"
              class="mb-2"
            />
            <!-- Use google meet -->
            <v-btn
              v-if="hasGmailProvider"
              text
              class="d-flex align-center px-2"
              height="28"
              @click="useGoogleMeet = !useGoogleMeet"
            >
              <v-img
                v-model="useGoogleMeet"
                height="18"
                width="18"
                class="mr-2"
                src="@/assets/img/icons/google-meet-icon.svg"
              />
              Use Google Meet
              <v-icon size="16" class="ml-2" color="primary"
                >mdi-checkbox-{{
                  useGoogleMeet ? 'marked' : 'blank-outline'
                }}</v-icon
              >
            </v-btn>
          </template>

          <!-- Readonly -->
          <div v-else class="mt-3">
            <div class="font-weight-bold">
              {{ selectedEvent?.user?.full_name || 'User' }} is busy in this
              slot
            </div>
            <div class="text-caption mt-n1">
              You don't have permissions to edit this event
            </div>
          </div>
        </v-card-text>
        <v-card-actions
          v-if="!selectedEvent?.read_only"
          class="d-flex justify-end"
        >
          <v-btn
            small
            class="mr-2 ml-0 white--text"
            color="red darken-1"
            :loading="deleteEventLoader"
            @click="deleteSelectedEvent"
          >
            Delete
          </v-btn>
          <v-btn
            small
            class="mr-2 ml-0"
            color="grey lighten-1"
            @click="isEditingEvent = false"
          >
            Back
          </v-btn>
          <v-tooltip top :disabled="!shouldDisableSaveEvent">
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn
                  small
                  color="primary"
                  class="ml-0"
                  :disabled="shouldDisableSaveEvent"
                  :loading="saveEventLoader"
                  @click="saveCalendarEvent"
                >
                  Save
                </v-btn>
              </div>
            </template>
            {{
              isSelectedEventWithinBlockedTime
                ? 'Selected event is conflicting with a blocked time'
                : 'Title & member required'
            }}
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import UserAvatar from '@/components/utilities/UserAvatar.vue'
import SelectCalendarEmail from '@/components/utilities/SelectCalendarEmail.vue'

import CalendarEventsApi from '@/services/api/CalendarEvents'

import datesFormatter from '@/utils/formatters/dates'
import {
  getLuxonObj,
  friendlyTimeDiff,
  dateTimeRangeInUTC,
  convertToTimeZone,
  convertToUserTimeZone,
  convertToUTC,
} from '@/utils/dateHelpers'
import {
  generateUuid,
  generateTimeOptionsWithDate,
  getTextColorFromBG,
} from '@/utils/helpers'
import { UserSettings } from '@/constants'

const _cloneDeep = require('lodash/cloneDeep')
const _isEqual = require('lodash/isEqual')

export default {
  name: 'Calendar',
  components: { UserAvatar, SelectCalendarEmail },
  mixins: [datesFormatter],
  props: {
    currentProspect: {
      type: Object,
    },
    secondaryTimezone: {
      type: Object,
    },
    primaryTimezone: {
      type: String,
    },
    showSecondaryTimezone: {
      type: Boolean,
    },
  },
  data: () => ({
    viewProspectDay: false,
    enableTZChangeWatcher: false,
    hideMyEvents: false,
    scheduleAs: null,
    userSearchQuery: '',
    selectedUsers: [],
    selectedEmails: [],
    useGoogleMeet: false,
    dateViewing: '',
    type: 'day',
    fetchEventsLoader: false,
    startTimeOptions: generateTimeOptionsWithDate(),
    weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    selectedEvent: null,
    saveEventLoader: false,
    deleteEventLoader: false,
    selectedElement: null,
    isEditingEvent: false,
    dragTime: null,
    events: [],
    dateRange: [],
    eventCurrentlyMouseDown: null,
    eventClickedOriginalStartTime: null,
    eventClickedOriginalEndTime: null,
    dragStart: null,
    eventToCreate: null,
    eventToCreateStartTime: null,
    selectedEventStartTime: '', // yyyy-LL-dd HH:mm
    selectedEventEndTime: '', // yyyy-LL-dd HH:mm
    extendOriginal: null,
    selectedEventParticipants: [],
  }),
  mounted() {
    this.$refs.calendar.checkChange()
  },
  computed: {
    ...mapGetters('user', ['getSettingByKey', 'mailbox', 'user']),
    ...mapGetters('orgUsers', ['scheduleAsOptions']),

    // Tooltip that appears if you hover over prospect label in interval header
    // This tooltip explains the day span in prospect timezone
    prospectTZDaySpan() {
      const startOfDayInPropsectTZ = getLuxonObj(
        this.dateViewing,
        false,
        this.primaryTimezone
      )
        .startOf('day')
        .setZone(this.secondaryTimezone?.value)

      const endOfDayInPropsectTZ = getLuxonObj(
        this.dateViewing,
        false,
        this.primaryTimezone
      )
        .endOf('day')
        .setZone(this.secondaryTimezone?.value)

      return `
      ${startOfDayInPropsectTZ.toFormat('LLL d, h:mm a')}
      <div class="d-flex justify-center w-100">to</div>
      ${endOfDayInPropsectTZ.toFormat('LLL d, h:mm a')}`
    },
    hasGmailProvider() {
      const scheduleAsObject = this.scheduleAsOptions.find(
        (item) => item.id === this.scheduleAs
      )
      if (scheduleAsObject) return scheduleAsObject.email_provider === 'gmail'
      return false
    },
    filteredEvents() {
      return this.events.filter((e) => {
        if (!this.hideMyEvents) return true
        return e.user?.id !== this.user.id
      })
    },
    readOnlyEvents() {
      return this.filteredEvents.filter((e) => e.read_only)
    },
    isNewEvent() {
      return this.selectedEvent && this.selectedEvent.id?.includes('new')
    },
    timezone() {
      return this.getSettingByKey(UserSettings.TIMEZONE)
    },
    startTimeHint() {
      return getLuxonObj(this.selectedEventStartTime).toFormat('LLL dd, yyyy')
    },
    endTimeHint() {
      return getLuxonObj(this.selectedEventEndTime).toFormat('LLL dd, yyyy')
    },
    endTimeOptions() {
      const options = []
      if (!this.selectedEventStartTime) return options

      // Parse the selectedEventStartTime using Luxon
      let optionStartTime = getLuxonObj(this.selectedEventStartTime).plus({
        minutes: 15,
      })
      const endTimeLimit = getLuxonObj(this.selectedEventStartTime).plus({
        hours: 23,
        minutes: 30,
      })

      const eventStartTimeObj = getLuxonObj(this.selectedEventStartTime)

      while (optionStartTime <= endTimeLimit) {
        // Luxon formatting for the text
        const eventDuration = friendlyTimeDiff(
          optionStartTime,
          eventStartTimeObj
        )
        const textFormat = `${optionStartTime.toFormat('h:mma')?.toLowerCase()}`

        const valueFormat = optionStartTime.toFormat('yyyy-LL-dd HH:mm')

        options.push({ text: textFormat, value: valueFormat, eventDuration })

        // Move to the next 15-minute interval
        optionStartTime = optionStartTime.plus({ minutes: 15 })
      }

      return options
    },
    isSelectedEventWithinBlockedTime() {
      const selectedEventStartMillis = getLuxonObj(
        this.selectedEventStartTime
      ).ts
      const selectedEventEndMillis = getLuxonObj(this.selectedEventEndTime).ts
      return (
        this.isWithinBlockedTime(selectedEventStartMillis) ||
        this.isWithinBlockedTime(selectedEventEndMillis)
      )
    },
    shouldDisableSaveEvent() {
      return (
        !this.selectedEventParticipants?.length ||
        !this.selectedEvent.name ||
        this.isSelectedEventWithinBlockedTime
      )
    },
    pdCalendarConfig() {
      return this.getSettingByKey(UserSettings.PD_CALENDAR_CONFIG)
    },
  },
  watch: {
    viewProspectDay(val) {
      this.$emit('viewing-prospect-day', val)
    },
    primaryTimezone() {
      if (!this.enableTZChangeWatcher) return

      this.dateViewing = convertToTimeZone(
        getLuxonObj(),
        this.primaryTimezone
      ).toFormat('yyyy-LL-dd')
      this.resetCalendarState()
      this.fetchEvents()
    },
    selectedUsers: {
      handler(val) {
        if (!_isEqual(val, this.pdCalendarConfig?.selectedPeople))
          this.updateCalendarConfig({
            scheduleAs: this.pdCalendarConfig?.scheduleAs,
            selectedPeople: val,
          })
        this.fetchEvents()
      },
      deep: true,
    },
    scheduleAs(val) {
      if (!_isEqual(val, this.pdCalendarConfig?.scheduleAs))
        this.updateCalendarConfig({
          scheduleAs: val,
          selectedPeople: this.pdCalendarConfig?.selectedPeople,
        })
    },
    selectedEvent(val) {
      if (val) {
        this.selectedEventStartTime = getLuxonObj(val.start, true).toFormat(
          'yyyy-LL-dd HH:mm'
        )
        this.selectedEventEndTime = getLuxonObj(val.end, true).toFormat(
          'yyyy-LL-dd HH:mm'
        )
      }
    },
    dateViewing(newVal) {
      if (this.selectedEvent && this.isEditingEvent) {
        // need to change the start and end of the selected event
        const start = getLuxonObj(this.selectedEvent.start, true).toFormat(
          'HH:mm'
        )
        const end = getLuxonObj(this.selectedEvent.end, true).toFormat('HH:mm')

        const newStart = `${newVal} ${start}`
        const newEnd = `${newVal} ${end}`

        this.updateSelectedEvent({
          ...this.selectedEvent,
          start: getLuxonObj(newStart)?.ts,
          end: getLuxonObj(newEnd)?.ts,
        })
      }
    },
    isEditingEvent(val) {
      if (!val) {
        this.selectedElement = null
        if (this.isNewEvent) {
          this.selectedEvent = null
          this.eventClickedOriginalStartTime = null
          this.eventClickedOriginalEndTime = null
        } else if (
          this.eventClickedOriginalStartTime != null &&
          this.eventClickedOriginalEndTime != null &&
          this.selectedEvent
        ) {
          this.updateSelectedEvent({
            ...this.selectedEvent,
            start: this.eventClickedOriginalStartTime,
            end: this.eventClickedOriginalEndTime,
          })
        }
      } else
        this.scheduleAs =
          this.pdCalendarConfig?.scheduleAs ||
          this.mailbox?.id ||
          this.scheduleAsOptions[0]?.id
    },
  },
  methods: {
    ...mapActions('user', ['updateSetting']),
    resetCalendarState() {
      this.useGoogleMeet = false
      this.startTimeOptions = generateTimeOptionsWithDate()
      this.selectedEvent = null
      this.saveEventLoader = false
      this.deleteEventLoader = false
      this.selectedElement = null
      this.isEditingEvent = false
      this.dragTime = null
      this.events = []
      this.eventCurrentlyMouseDown = null
      this.eventClickedOriginalStartTime = null
      this.eventClickedOriginalEndTime = null
      this.dragStart = null
      this.eventToCreate = null
      this.eventToCreateStartTime = null
      this.selectedEventStartTime = ''
      this.selectedEventEndTime = ''
      this.extendOriginal = null
      this.selectedEventParticipants = []
    },
    updateCalendarConfig(newConfig) {
      this.updateSetting({
        userId: this.user.id,
        settings: {
          settings_key: UserSettings.PD_CALENDAR_CONFIG,
          settings_value: newConfig,
        },
      })
    },
    participantSelectionUpdated(val) {
      if (this.selectedEvent) this.selectedEvent.participants = _cloneDeep(val)
      this.selectedEventParticipants = _cloneDeep(val)
    },
    prospectTime(timestamp) {
      const dateTime = getLuxonObj(timestamp, true, this.primaryTimezone)
      return dateTime.setZone(this.secondaryTimezone?.value).toFormat('h:mm a')
    },
    intervalLabelFormatter(data) {
      // Change header for intervals
      // Add timezone labels
      const header = document.getElementsByClassName(
        'v-calendar-daily__intervals-head'
      )[0]
      if (header) {
        header.innerHTML =
          this.showSecondaryTimezone && this.secondaryTimezone?.value
            ? `
            <div
              style="
                font-weight: bold;
                position: absolute;
                bottom: 24px;
                width: 110px;
                display: flex;
                justify-content: center;
              "
            >
              Timezones
            </div>
            <div
              style="
                display: flex;
                position: absolute;
                bottom: 6px;
                font-size: 10px;
                width: 110px;
                padding-left: ${this.viewProspectDay ? '28px' : '16px'};
              "
            >
              <span id="prospect-label" style="margin-right: ${
                this.viewProspectDay ? '16px' : '24px'
              }">${this.viewProspectDay ? 'You' : 'Prospect'}</span>
              <div class="prospect-tooltip">
                <div class="font-weight-bold ">${
                  this.secondaryTimezone?.label
                }</div>
                <div class="d-flex justify-center w-100">
                  <div>
                    ${this.prospectTZDaySpan}
                  </div>
                </div>
              </div>
              <span id="you-label">${
                this.viewProspectDay ? 'Prospect' : 'You'
              }</span>
              <div class="you-tooltip">
                <div class="font-weight-bold">${this.primaryTimezone}</div>
                ${this.monthDayFormat(this.dateViewing)}
              </div>
            </div>
        `
            : ''
      }

      // Calculate and return label for every interval in both timezones
      const { date, time } = data
      const dateTimeStr = `${date} ${time}`
      const yourDateTime = getLuxonObj(dateTimeStr, false, this.primaryTimezone)

      if (!this.secondaryTimezone?.value || !this.showSecondaryTimezone)
        return yourDateTime.toFormat('h a')

      const prospectDateTime = getLuxonObj(
        dateTimeStr,
        false,
        this.primaryTimezone
      ).setZone(this.secondaryTimezone?.value)
      return (
        prospectDateTime.toFormat('h a') +
        '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
        yourDateTime.toFormat('h a')
      )
    },
    getTextColorFromBG,
    transformParticipants(participants = []) {
      return participants.map((item) => ({
        [item.type]: item.id,
      }))
    },
    updateSelectedEvent(newEvent) {
      if (newEvent.read_only) return

      this.selectedEvent = newEvent
      const index = this.events.findIndex((e) => e.id === newEvent.id)
      if (index !== -1) {
        this.events = [
          ...this.events.slice(0, index),
          newEvent,
          ...this.events.slice(index + 1),
        ]
      }
    },
    saveCalendarEvent() {
      if (!this.selectedEvent) return

      this.eventClickedOriginalStartTime = null
      this.eventClickedOriginalEndTime = null

      this.saveEventLoader = true
      const userTZStart = getLuxonObj(
        this.selectedEvent.start,
        true,
        this.primaryTimezone
      )
      const userTZEnd = getLuxonObj(
        this.selectedEvent.end,
        true,
        this.primaryTimezone
      )

      const payload = {
        title: this.selectedEvent.name,
        description: this.selectedEvent.description,
        use_google_meet: this.useGoogleMeet,
        schedule_as: this.scheduleAs,
        start: convertToUTC(userTZStart).toFormat('yyyy-LL-dd HH:mm'),
        end: convertToUTC(userTZEnd, true).toFormat('yyyy-LL-dd HH:mm'),
        participants: this.transformParticipants(
          this.selectedEventParticipants
        ),
      }
      const isNew = this.selectedEvent.id?.includes('new')
      //create
      if (isNew) {
        CalendarEventsApi.createCalendarEvent(payload).then(() => {
          this.fetchEvents()
            .then(() => {
              this.saveEventLoader = false
              this.eventToCreate = null
              this.events = this.events.filter(
                (event) => event.id !== this.selectedEvent?.id
              )
              this.isEditingEvent = false
            })
            .catch(() => {
              this.saveEventLoader = false
            })
        })
      }
      // edit
      else {
        delete payload.schedule_as
        payload.id = this.selectedEvent.id
        CalendarEventsApi.editCalendarEvent(payload).then(() => {
          this.fetchEvents()
            .then(() => (this.isEditingEvent = false))
            .finally(() => (this.saveEventLoader = false))
        })
      }
    },
    isGreaterThan30Mins(event) {
      const start = getLuxonObj(event.start, true)
      const end = getLuxonObj(event.end, true)
      return Math.abs(end.diff(start).as('minutes')) > 30
    },
    onStartTimeChange() {
      this.selectedEvent.start =
        getLuxonObj(this.selectedEventStartTime)?.ts || null
    },
    onEndTimeChange() {
      this.selectedEvent.end =
        getLuxonObj(this.selectedEventEndTime)?.ts || null
    },
    shouldShowFinishEditing(event) {
      const isNewEvent = event.id?.includes('new')
      return isNewEvent && this.selectedEvent?.id !== event.id
    },

    deleteSelectedEvent() {
      if (!this.selectedEvent) return

      this.eventClickedOriginalStartTime = null
      this.eventClickedOriginalEndTime = null

      if (!this.isNewEvent) {
        this.deleteEventLoader = true
        CalendarEventsApi.deleteCalendarEvent(this.selectedEvent.id)
          .then(() => {
            this.events = this.events.filter(
              (ev) => ev.id !== this.selectedEvent.id
            )
            this.isEditingEvent = false
          })
          .finally(() => (this.deleteEventLoader = false))
      } else {
        this.events = this.events.filter(
          (ev) => ev.id !== this.selectedEvent.id
        )
        this.isEditingEvent = false
      }
    },

    viewDay({ date }) {
      this.dateViewing = date
      this.type = 'day'
    },
    toTime(tms) {
      return getLuxonObj(`${tms.date} ${tms.time}`).ts
    },
    getEventColor(event) {
      return event.color
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    onDateChange(data) {
      const { start, end } = data
      this.dateRange = dateTimeRangeInUTC(
        getLuxonObj(start.date, false, this.primaryTimezone),
        getLuxonObj(end.date, false, this.primaryTimezone),
        true
      )
      if (start)
        this.startTimeOptions = generateTimeOptionsWithDate(
          new Date(start.date)
        )
      this.fetchEvents()
    },
    fetchEvents() {
      this.fetchEventsLoader = true
      const payload = {
        start: this.dateRange[0],
        end: this.dateRange[1],
        userIds: this.selectedUsers.map((user) => user.id),
        mailboxIds: this.selectedEmails,
      }
      return new Promise((resolve, reject) => {
        CalendarEventsApi.getCalendarEvents(payload)
          .then((events) => {
            const transformedEvents = Object.values(events)
              .flat()
              .map((event) => {
                const { start, end } = event
                let userTZStart, userTZEnd
                if (!event.timed) {
                  userTZStart = getLuxonObj(this.dateViewing).startOf('day').ts
                  userTZEnd = getLuxonObj(this.dateViewing).endOf('day').ts
                } else {
                  const browserTZ = getLuxonObj().zoneName
                  // Calculate DateTime in primary TZ but consider it in browser TZ because v-calendar only works on browser TZ
                  userTZStart = convertToTimeZone(
                    getLuxonObj(start),
                    this.primaryTimezone
                  ).setZone(browserTZ, { keepLocalTime: true }).ts
                  userTZEnd = convertToTimeZone(
                    getLuxonObj(end),
                    this.primaryTimezone
                  ).setZone(browserTZ, { keepLocalTime: true }).ts
                }

                this.enableTZChangeWatcher = true

                return {
                  ...event,
                  name: event.title,
                  color: event.user.avatar_color || 'primary',
                  start: userTZStart,
                  end: userTZEnd,
                }
              })
            const unsavedEvents = this.events.filter((event) =>
              event.id?.includes('new')
            )
            this.events = [...unsavedEvents, ...transformedEvents]
            resolve(events)
          })
          .catch((err) => reject(err))
          .finally(() => (this.fetchEventsLoader = false))
      })
    },

    // ----- Interactions with calendar ----- //

    // Event click
    editEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedEventParticipants = _cloneDeep(event.participants) || []
        this.selectedElement = nativeEvent.target
        setTimeout(() => (this.isEditingEvent = true), 200)
      }

      if (this.isEditingEvent) {
        this.selectedEvent = null
        this.eventClickedOriginalStartTime = null
        this.eventClickedOriginalEndTime = null
        this.isEditingEvent = false
      } else open()

      if (nativeEvent) nativeEvent.stopPropagation()
    },

    // Event mouse down
    eventMouseDown(data) {
      const { event, timed } = data
      if (event && timed) {
        this.eventCurrentlyMouseDown = event
        if (!event.id?.includes('new')) {
          this.eventClickedOriginalStartTime = event.start
          this.eventClickedOriginalEndTime = event.end
        }
        this.dragTime = null
        this.extendOriginal = null
      }
    },

    // Time mouse down
    timeMouseDown(data) {
      if (this.isEditingEvent) return

      const { nativeEvent } = data
      const mouse = this.toTime(data)
      const roundedStartTime = this.roundTime(mouse)
      const roundedEndTime = roundedStartTime + 60 * 1000 * 15
      const isWithinBlockedTime =
        this.isWithinBlockedTime(roundedStartTime) ||
        this.isWithinBlockedTime(roundedEndTime)
      if (this.eventCurrentlyMouseDown && this.dragTime === null) {
        const start = this.eventCurrentlyMouseDown.start
        this.dragTime = mouse - start
      } else if (!isWithinBlockedTime) {
        this.eventToCreateStartTime = roundedStartTime
        this.eventToCreate = {
          id: `new-${generateUuid()}`,
          name: `New event`,
          color: 'primary',
          start: this.eventToCreateStartTime,
          end: roundedEndTime,
          timed: true,
        }
        const { emailAddresses } = this.currentProspect || {}
        if (emailAddresses?.length) {
          this.selectedEventParticipants = [
            {
              id: emailAddresses[0].id,
              email: emailAddresses[0].email,
              full_name:
                this.currentProspect?.full_name || emailAddresses[0].email,
              type: 'emailAddressId',
            },
          ]
          this.eventToCreate.participants = [
            {
              id: emailAddresses[0].id,
              email: emailAddresses[0].email,
              full_name:
                this.currentProspect?.full_name || emailAddresses[0].email,
              type: 'emailAddressId',
            },
          ]
        }
        this.events.push(this.eventToCreate)
        this.editEvent({ nativeEvent, event: this.eventToCreate })
      }
    },

    // Time mouse up
    timeMouseUp() {
      this.dragTime = null
      this.eventCurrentlyMouseDown = null
      this.eventToCreate = null
      this.eventToCreateStartTime = null
      this.extendOriginal = null
    },

    // Mouse move for both all (may it be event or time)
    mouseMove(tms) {
      const mouse = this.toTime(tms)

      // If an event was clicked and is being dragged
      if (
        this.eventCurrentlyMouseDown &&
        this.dragTime !== null &&
        !this.eventCurrentlyMouseDown?.read_only
      ) {
        const start = this.eventCurrentlyMouseDown.start
        const end = this.eventCurrentlyMouseDown.end
        const duration = end - start
        const newStartTime = mouse - this.dragTime
        const newStart = this.roundTime(newStartTime)
        const newEnd = newStart + duration

        this.eventCurrentlyMouseDown.start = newStart
        this.eventCurrentlyMouseDown.end = newEnd
      } else if (this.eventToCreate && this.eventToCreateStartTime !== null) {
        const mouseRounded = this.roundTime(mouse, false)
        const min = Math.min(mouseRounded, this.eventToCreateStartTime)
        const max = Math.max(mouseRounded, this.eventToCreateStartTime)

        this.eventToCreate.start = min
        this.eventToCreate.end = max
        if (this.selectedEvent?.id === this.eventToCreate?.id) {
          this.selectedEventStartTime = getLuxonObj(min, true).toFormat(
            'yyyy-LL-dd HH:mm'
          )
          this.selectedEventEndTime = getLuxonObj(max, true).toFormat(
            'yyyy-LL-dd HH:mm'
          )
        }
      }
    },

    // This method is called when event extender is clicked
    extendBottom(event) {
      this.eventToCreate = event
      this.eventToCreateStartTime = event.start
      this.extendOriginal = event.end
    },

    // This method is to check if time is in a blocked time
    isWithinBlockedTime(time) {
      return this.readOnlyEvents.some(
        (event) => time > event.start && time < event.end
      )
    },

    // Mouse leave of calendar
    mouseLeave() {
      if (this.eventToCreate) {
        if (this.extendOriginal) {
          this.eventToCreate.end = this.extendOriginal
        } else {
          const i = this.events.indexOf(this.eventToCreate)
          if (i !== -1) {
            this.events.splice(i, 1)
          }
        }
      }

      this.eventToCreate = null
      this.eventToCreateStartTime = null
      this.dragTime = null
      this.eventCurrentlyMouseDown = null
    },

    roundTime(time, down = true) {
      const roundTo = 15 // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime))
    },
  },
  created() {
    this.selectedUsers = _cloneDeep(this.pdCalendarConfig?.selectedPeople) || []
    this.dateViewing = convertToUserTimeZone(
      getLuxonObj(),
      false,
      true
    ).toFormat('yyyy-LL-dd')
    this.enableTZChangeWatcher = false
  },
}
</script>

<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}
</style>

.
<style scoped>
.day-calendar >>> .v-calendar-daily_head-weekday {
  display: none;
}

.day-calendar >>> .v-calendar-daily_head-day .v-event {
  width: calc(100% - 32px);
  min-width: calc(100% - 32px);
  max-width: calc(100% - 32px);
  margin-left: 16px;
  margin-bottom: 8px !important;
}

.day-calendar >>> .v-event-timed * {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.should-show-secondary-timezone >>> .v-calendar-daily__intervals-body,
.should-show-secondary-timezone >>> .v-calendar-daily__intervals-head {
  width: 110px !important;
}
.day-calendar .v-calendar-daily__interval-text >>> {
  padding-inline: 6px !important;
}

.hide-events-checkbox >>> .v-input__slot .v-input--selection-controls__input {
  margin-right: 4px !important;
}
</style>

<style>
.prospect-tooltip {
  background: #747474;
  color: white;
  display: none;
  position: absolute;
  top: 20px;
  left: 4px;
  padding-inline: 10px;
  padding-block: 4px;
  border-radius: 8px;
  font-size: 12px;
  z-index: 999;
  min-width: 140px;
  max-width: 250px;
}
.you-tooltip {
  background: #747474;
  color: white;
  display: none;
  position: absolute;
  top: 20px;
  left: 50px;
  padding-inline: 10px;
  padding-block: 4px;
  border-radius: 8px;
  font-size: 12px;
  z-index: 999;
  min-width: 90px;
  max-width: 150px;
}
#prospect-label:hover + .prospect-tooltip {
  display: block;
}
#you-label:hover + .you-tooltip {
  display: block;
}
</style>
