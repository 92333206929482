/**
 * Will return API query string to request resources linked to results
 * Follows Bumblebee transformer include logic
 *
 * For example:
 * ['driveSteps',
 * 'driveSteps.emailTemplate']
 *
 * returns: 'include=driveSteps,driveSteps.emailTemplate'
 *
 * @param {array} resources
 * @return {string}
 */
export function getResourceRequestQuery(resources) {
  let queryString = 'include='
  if (!Array.isArray(resources) || !resources.length) return ''

  queryString += resources.join(',')

  return queryString
}
