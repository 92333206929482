// Email address status types
const SAFE = {
  label: 'Safe',
  value: 'safe',
  icon: 'mdi-check-decagram',
  color: 'green',
  tooltip: 'Safe',
}

const UNKNOWN = {
  label: 'Unknown',
  value: 'unknown',
  icon: 'mdi-help-circle',
  color: 'yellow darken-2',
  tooltip: 'Unknown',
}

const RISKY = {
  label: 'Risky',
  value: 'risky',
  icon: 'mdi-alert-octagram',
  color: 'orange darken-1',
  tooltip: 'Risky',
}

const INVALID = {
  label: 'Invalid',
  value: 'invalid',
  icon: 'mdi-close-octagon',
  color: 'red',
  tooltip: 'Invalid',
}

const EMAIL_ADDRESS_STATUSES = [SAFE, UNKNOWN, RISKY, INVALID]

export default EMAIL_ADDRESS_STATUSES

/**
 * Returns email address status object
 *
 * @export
 * @param {string} value - The `value` of status to match
 * @return {object}
 */
export function getEmailAddressStatus(value) {
  if (!value) return

  const match = EMAIL_ADDRESS_STATUSES.find((i) => i.value === value)
  return match || ''
}
