const CRM_OPTIONS = Object.freeze({
  HUBSPOT: {
    label: 'HubSpot',
    value: 'hubspot',
    icon: 'mdi-hubspot',
    color: '#f57722',
  },
  SALESFORCE: {
    label: 'Salesforce',
    value: 'salesforce',
    icon: 'mdi-salesforce',
    color: 'light-blue',
  },
  PIPEDRIVE: {
    label: 'Pipedrive',
    value: 'pipedrive',
    icon: '$vuetify.icons.pipedrive',
    color: '#0E5236',
  },
})

export default CRM_OPTIONS

export function getCrmLabel(value) {
  const match = Object.values(CRM_OPTIONS).find((i) => i.value === value)
  return match?.label || ''
}

export function getCrmIcon(value) {
  const match = Object.values(CRM_OPTIONS).find((i) => i.value === value)
  return match?.icon || ''
}

export function getCrmColor(value) {
  const match = Object.values(CRM_OPTIONS).find((i) => i.value === value)
  return match?.color || ''
}
