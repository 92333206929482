import { dateTimeRangeInUTC, getUserTimeZoneDate } from '../utils/dateHelpers'

const ACTION_TIMELINE_FILTERS = Object.freeze([
  {
    id: 1,
    name: 'Today',
    filters: [
      {
        value: dateTimeRangeInUTC(getUserTimeZoneDate(), getUserTimeZoneDate()),
        name: 'dueAt[range]',
      },
    ],
    isFixed: true,
  },
  {
    id: 2,
    name: 'Overdue',
    filters: [
      {
        value: dateTimeRangeInUTC(null, getUserTimeZoneDate(), false, true),
        name: 'dueAt[range]',
      },
    ],
    isFixed: true,
  },
  {
    id: 3,
    name: 'Future',
    filters: [
      {
        value: dateTimeRangeInUTC(getUserTimeZoneDate(), null, false, true),
        name: 'dueAt[range]',
      },
    ],
    isFixed: true,
  },
])

export default ACTION_TIMELINE_FILTERS
