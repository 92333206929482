<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    color="grey lighten-2"
    class="primary--text text--darken-2"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {}
</script>

<style></style>
