import api from './Api'

const ENDPOINTS = {
  accountFields: (id = '') => (id ? `/accountFields/${id}` : `/accountFields`),
}

export default {
  getAccountFields() {
    return api.get(ENDPOINTS.accountFields())
  },

  createAccountField(payload) {
    return api.post(ENDPOINTS.accountFields(), payload)
  },

  updateAccountField(payload) {
    const { id } = payload
    return api.put(ENDPOINTS.accountFields(id), payload)
  },

  deleteAccountField(id) {
    return api.delete(ENDPOINTS.accountFields(id))
  },
}
