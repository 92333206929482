import DriveStatesApi from '@/services/api/DriveStates'
import { EventBus } from '@/services/event-bus'

// initial state
const state = () => ({
  status: '',
  driveStates: {},
  activeDrive: {},
  activeDriveStep: {},
  activeAction: {},
})

// getters
const getters = {
  driveStates: (state) => state.driveStates,
  activeDrive: (state) => state.activeDrive,
  activeDriveStep: (state) => state.activeDriveStep,
  activeAction: (state) => state.activeAction,
  driveStatesLoading: (state) => state.status === 'loading',
}

// actions
const actions = {
  fetchDriveStatesByProspectId({ commit, dispatch }, { id, hideLoader }) {
    if (!hideLoader) commit('request')

    return new Promise((resolve, reject) => {
      DriveStatesApi.getDriveStatesByProspectId(id)
        .then((resp) => {
          if (!hideLoader) commit('success')
          commit('setDriveState', resp)
          dispatch('getActiveAction')
          resolve(resp)
        })
        .catch((err) => {
          commit('error')
          console.log(err)
          reject(err)
        })
    })
  },

  async getActiveAction({ commit, getters }) {
    if (getters.driveStates.length) {
      const actions = getters.driveStates[0].actions
      const driveSteps = getters.driveStates[0].drive.driveSteps
      actions.reverse().forEach((action) => {
        // TODO: update to action == in_progress
        if (!action.completed) {
          const activeAction = action
          let driveStep = driveSteps.find(
            (step) => step.id == action.drive_step_id
          )
          if (driveStep.reply_to_previous_thread_id) {
            let subject =
              actions.find(
                (action) =>
                  action.drive_step_id == driveStep.reply_to_previous_thread_id
              )?.mailing?.subject ||
              driveSteps.find(
                (step) => step.id == driveStep.reply_to_previous_thread_id
              )?.subject
            if (subject && !subject.includes('Re: ')) subject = 'Re: ' + subject
            driveStep = { ...driveStep, subject }
          }
          commit('setActiveDrive', getters.driveStates[0].drive)
          commit('setActiveDriveStep', driveStep)
          commit('setActiveAction', activeAction)
        }
      })
    }
  },

  async pauseDriveState({ commit, dispatch }, { id, payload }) {
    await DriveStatesApi.pauseDriveState(id, payload)
      .then((resp) => {
        const respArray = [resp]
        commit('setDriveState', respArray)
        commit('success')
        dispatch('getActiveAction')
      })
      .catch((err) => {
        commit('error')
        console.log(err)
      })
  },

  async bulkPauseDriveStates({ commit }, { payload }) {
    return new Promise((resolve, reject) => {
      DriveStatesApi.pauseBulkDriveStates(payload)
        .then((resp) => {
          const errorCount = resp.errorCount
          const successCount = resp.successCount

          if (successCount != 0 && errorCount == 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: `Sequence${successCount > 1 ? 's' : ''} paused`,
                snackType: 'success',
              },
              { root: true }
            )
          } else if (successCount != 0 && errorCount != 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: `${successCount} sequence${
                  successCount > 1 ? 's' : ''
                } paused. Unable to pause ${errorCount}.`,
                snackType: 'warning',
              },
              { root: true }
            )
          } else if (successCount == 0 && errorCount != 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: `Unable to pause sequences, try again.`,
                snackType: 'error',
              },
              { root: true }
            )
          }
          EventBus.$emit('people-fetch-contacts', true)
          resolve(resp)
        })
        .catch((err) => {
          console.log(err)
          commit(
            'snackbar/setSnack',
            {
              snack: `Unable to pause sequences, try again.`,
              snackType: 'error',
            },
            { root: true }
          )
          reject(err)
        })
    })
  },

  async resumeDriveState({ commit, dispatch }, driveId) {
    commit('request')
    await DriveStatesApi.resumeDriveState(driveId)
      .then((resp) => {
        const respArray = [resp]
        commit('setDriveState', respArray)
        commit('success')
        dispatch('getActiveAction')
      })
      .catch((err) => {
        commit('error')
        console.log(err)
      })
  },

  async bulkResumeDriveStates({ commit }, { payload }) {
    return new Promise((resolve, reject) => {
      DriveStatesApi.resumeBulkDriveStates(payload)
        .then((resp) => {
          const errorCount = resp.errorCount
          const successCount = resp.successCount

          if (successCount != 0 && errorCount == 0) {
            commit(
              'snackbar/setSnack',

              {
                snack: `Sequence${successCount > 1 ? 's' : ''} resumed`,
                snackType: 'success',
              },

              { root: true }
            )
          } else if (successCount != 0 && errorCount != 0) {
            commit(
              'snackbar/setSnack',

              {
                snack: `${successCount} sequence${
                  successCount > 1 ? 's' : ''
                } resumed. Unable to resume ${errorCount}.`,
                snackType: 'warning',
              },

              { root: true }
            )
          } else if (successCount == 0 && errorCount != 0) {
            commit(
              'snackbar/setSnack',

              {
                snack: `Unable to resume sequences, try again.`,
                snackType: 'error',
              },

              { root: true }
            )
          }
          EventBus.$emit('people-fetch-contacts', true)
          resolve(resp)
        })
        .catch((err) => {
          console.log(err)
          commit(
            'snackbar/setSnack',

            {
              snack: `Unable to resume sequences, try again.`,
              snackType: 'error',
            },

            { root: true }
          )
          reject(err)
        })
    })
  },
}

// mutations
const mutations = {
  addDriveDispositions(state, dispositions) {
    if (!state.driveStates[0].drive) return
    state.driveStates[0].drive.dispositions = [
      ...state.driveStates[0].drive.dispositions,
      ...dispositions,
    ]
  },
  setDriveState(state, driveStates) {
    state.driveStates = driveStates
  },
  setActiveDrive(state, activeDrive) {
    state.activeDrive = activeDrive
  },
  setActiveDriveStep(state, activeDriveStep) {
    state.activeDriveStep = activeDriveStep
  },
  setActiveAction(state, activeAction) {
    state.activeAction = activeAction
  },
  request(state) {
    state.driveStates = {}
    state.status = 'loading'
  },
  success(state) {
    state.status = 'success'
  },
  error(state) {
    state.status = 'error'
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
