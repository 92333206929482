export const CALL_FEEDBACK_ISSUE_TYPES = Object.freeze([
  { value: 'AUDIO_LAG', text: 'Audio lag' },
  { value: 'BROKEN_AUDIO', text: 'Broken audio' },
  { value: 'CALL_DROPPED', text: 'Call dropped' },
  { value: 'CALLERID_ISSUE', text: 'Caller ID issue' },
  { value: 'DIGITS_NOT_CAPTURED', text: 'Digits not captured' },
  { value: 'ECHO', text: 'Echo' },
  { value: 'HIGH_CONNECT_TIME', text: 'High connect time' },
  { value: 'LOW_AUDIO_LEVEL', text: 'Low audio level' },
  { value: 'ONE_WAY_AUDIO', text: 'One way audio' },
  { value: 'ROBOTIC_AUDIO', text: 'Robotic audio' },
  { value: 'OTHERS', text: 'Others' },
])

export default CALL_FEEDBACK_ISSUE_TYPES

/**
 * Returns call feedback issue type object based
 *
 * @export
 * @param {string} value - The `value` of StepType to match
 * @return {object}
 */
export function getCallFeedbackIssueObj(value) {
  if (!value) return

  return Object.values(CALL_FEEDBACK_ISSUE_TYPES).find((i) => i.value === value)
}

export function getCallFeedbackIssueText(value) {
  if (!value) return ''

  const issueObject = getCallFeedbackIssueObj(value)

  if (issueObject) return issueObject.text

  return ''
}
