<template>
  <div>
    <div class="d-flex flex-column notif-icon">
      <v-tooltip :disabled="isOpen" open-delay="100" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            color="symbo-pink"
            offset-x="25"
            offset-y="16"
            :value="hasUnreadNotifs"
          >
            <template v-slot:badge
              ><div class="font-weight-bold" @click="isOpen = !isOpen">
                {{ unreadCount > 99 ? '99+' : unreadCount }}
              </div>
            </template>
            <v-hover v-slot="{ hover }">
              <v-avatar
                v-on="on"
                v-bind="attrs"
                size="30"
                class="mr-2"
                id="notifs-bell"
                :color="hover ? 'grey lighten-2' : 'white'"
              >
                <span class="grey--text text--darken-2 text-h7">
                  <v-icon class="text--darken-2" size="22">mdi-bell</v-icon>
                </span>
              </v-avatar>
            </v-hover>
          </v-badge>
        </template>
        Notifications
      </v-tooltip>
      <div v-if="isOpen" class="menu-carrot"></div>
    </div>

    <v-menu
      v-model="isOpen"
      bottom
      offset-y
      nudge-bottom="11"
      activator="#notifs-bell"
      :close-on-content-click="false"
    >
      <v-card outlined width="375" height="465" class="overflow-hidden">
        <v-card-title class="border-b-1 py-2">
          <template v-if="!viewingSettings">
            Notifications
            <v-spacer />
            <v-btn icon small @click="viewSettings">
              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </template>
          <template v-else>
            <v-btn icon small class="mr-2" @click="viewingSettings = false">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            Notification settings
          </template>
        </v-card-title>
        <v-card-text class="px-0 pt-0">
          <!-- Notifs -->
          <template v-if="!viewingSettings">
            <skeleton-loader
              v-if="mainLoader"
              class="chips-loader d-flex align-center px-5 pt-3 pb-1"
              loaderType="chip@3"
              :isLoading="mainLoader"
            />
            <skeleton-loader
              class="notifs-skeleton-loader"
              loaderType="list-item-avatar-three-line@5"
              :isLoading="mainLoader"
            >
              <div class="d-flex align-center px-4 py-2 border-b-1">
                <v-chip
                  small
                  class="mr-2"
                  :color="selectedChip === 'all' ? 'primary' : 'bg-selected'"
                  @click="selectedChip = 'all'"
                >
                  All
                </v-chip>
                <v-chip
                  small
                  class="mr-2"
                  :color="selectedChip === 'unread' ? 'primary' : 'bg-selected'"
                  @click="selectedChip = 'unread'"
                >
                  Unread
                </v-chip>
                <v-chip
                  small
                  class="mr-2"
                  :color="selectedChip === 'read' ? 'primary' : 'bg-selected'"
                  @click="selectedChip = 'read'"
                >
                  Read
                </v-chip>
              </div>
              <template v-if="notifs.length">
                <v-list class="pa-0" style="overflow: scroll" height="375px">
                  <v-list-item
                    v-for="notif in notifs"
                    class="d-flex align-start pl-3 pr-2 py-2 border-b-1 cursor-pointer"
                    style="height: 80px"
                    :key="notif.id"
                    :class="{ 'bg-selected': !notif.read_at }"
                    @click="onNotifClick(notif)"
                  >
                    <!-- Avatar -->
                    <v-avatar
                      overlap
                      size="48"
                      :style="getNotifBorder(notif)"
                      :color="notif.data.from?.avatar_color"
                    >
                      <v-img
                        v-if="notif.data.from?.avatar_url"
                        :src="notif.data.from?.avatar_url"
                      />
                      <span
                        v-else-if="notif.data.from"
                        class="text-h5 font-weigh-medium"
                        :style="`color: ${getTextColorFromBG(
                          notif.data.from?.avatar_color
                        )}`"
                      >
                        {{
                          getUserInitials(
                            null,
                            null,
                            notif.data.from?.full_name
                          )
                        }}
                      </span>
                      <v-icon v-else>{{ getNotifIcon(notif) }}</v-icon>
                    </v-avatar>

                    <!-- Notif Badge -->
                    <div
                      class="notif-type d-flex align-center justify-center mt-8 ml-n3"
                      :class="getBgColorClass(notif)"
                    >
                      <v-icon :size="getNotifIconSize(notif)" color="white">{{
                        getNotifIcon(notif)
                      }}</v-icon>
                    </div>

                    <!-- Notif content -->
                    <div
                      class="d-flex flex-column ml-2"
                      :style="notif.read_at ? 'opacity: 0.6' : 'opacity: 1'"
                    >
                      <!-- Notif summary -->
                      <div>
                        <span class="font-weight-bold">
                          {{
                            notif.data.from?.full_name ||
                            getFullName(
                              notif.data.prospect?.first_name,
                              notif.data.prospect?.last_name
                            ) ||
                            notif.data.phoneMessage?.from
                          }}
                        </span>
                        <span v-html="getNotifSnippet(notif)"></span>
                      </div>
                      <!-- Date / Time -->
                      <div
                        class="primary--text text-caption font-weight-medium ml-1"
                      >
                        {{ getTimeDistance(notif.created_at, true, true) }}
                      </div>
                    </div>

                    <v-spacer />

                    <!-- Read / Unread -->
                    <div class="h-100 d-flex flex-column align-center">
                      <v-menu bottom offset-y>
                        <template v-slot:activator="{ on }">
                          <v-btn icon small v-on="on" class="mt-n1">
                            <v-icon size="18">mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list dense class="py-0">
                          <v-list-item
                            @click="updateRead(notif, !notif.read_at)"
                          >
                            Mark as
                            {{ notif.read_at ? 'unread' : 'read' }}
                          </v-list-item>
                        </v-list>
                      </v-menu>

                      <v-spacer />
                      <div
                        v-if="!notif.read_at"
                        class="bg-primary mt-n2"
                        style="height: 10px; width: 10px; border-radius: 50%"
                      ></div>
                      <v-spacer />
                    </div>
                  </v-list-item>
                  <v-card
                    v-if="!pageLoader"
                    v-intersect.quiet="getNextPage"
                    flat
                    height="2"
                    style="background: transparent"
                  />
                  <div
                    v-else
                    class="w-100 my-4 d-flex align-center justify-center"
                  >
                    <v-progress-circular
                      indeterminate
                      size="30"
                      width="2"
                      color="primary"
                    />
                  </div>
                </v-list>
              </template>
              <div
                v-if="!notifs.length"
                class="d-flex flex-column align-center h-100 w-100"
              >
                <v-img
                  src="@/assets/img/illustrations/no-notifs.svg"
                  class="ml-n3 mt-3"
                />
                <div class="d-flex flex-column align-center mt-n2 ml-2">
                  <div class="text-h6 font-weight-bold grey--text">
                    No new notifications
                  </div>
                  <div class="text-caption grey--text mt-n1">
                    You're all caught up!
                  </div>
                </div>
              </div>
            </skeleton-loader>
          </template>

          <!-- Settings -->
          <div v-else class="px-5">
            <v-switch
              v-model="notifSoundEnabled"
              dense
              hide-details
              label="Hear notification sounds"
              :loading="notifSoundToggleLoader"
              @change="toggleNotifSound"
            />
            <v-switch
              v-model="notifEmailsEnabled"
              dense
              hide-details
              label="Get email notifications"
              :loading="notifEmailsToggleLoader"
              @change="toggleNotifEmails"
            />
            <v-switch dense disabled hide-details>
              <template v-slot:label>
                <div class="d-flex flex-column">
                  <div>Turn on offline notifications</div>
                  <div class="text-xs mt-n1 black--text">
                    (Feature coming soon)
                  </div>
                </div>
              </template>
            </v-switch>
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import SkeletonLoader from '@/components/utilities/SkeletonLoader.vue'

import NotifsApi from '@/services/api/Notifications'
import { EventBus } from '@/services/event-bus'

import {
  getFullName,
  htmlToText,
  truncate,
  getUserInitials,
  getTextColorFromBG,
} from '@/utils/helpers'
import { UserSettings } from '@/constants'
import datesFormatter from '@/utils/formatters/dates'
import { convertToUserTimeZone, getLuxonObj } from '@/utils/dateHelpers'
import { DATE_FORMAT } from '../../utils/dateHelpers'

const _unionBy = require('lodash/unionBy')

export default {
  name: 'NotificationCenter',
  components: { SkeletonLoader },
  mixins: [datesFormatter],
  data: () => ({
    notifEmailsToggleLoader: false,
    notifEmailsEnabled: false,
    notifSoundToggleLoader: false,
    notifSoundEnabled: false,
    viewingSettings: false,
    notifAlert: null,
    selectedChip: 'all',
    isOpen: false,

    mainLoader: false,
    pageLoader: false,
    notifs: [],
    unreadCount: 0,
    pagination: {
      itemsPerPage: 10,
      total: 0,
      page: 1,
      lastPage: 1,
    },
  }),
  computed: {
    ...mapGetters('pusher', ['channel']),
    ...mapGetters('user', ['getSettingByKey', 'user']),
    hasUnreadNotifs() {
      return !!this.unreadCount
    },
  },
  methods: {
    getFullName,
    getUserInitials,
    getTextColorFromBG,
    ...mapActions('user', ['updateSetting']),
    getBgColorClass(notif) {
      switch (notif.type) {
        case 'call:mentioned-in-note':
          return 'bg-primary'
        case 'phone-message:inbound':
          return 'bg-call-blue'
        case 'call:missed':
          return 'bg-red-dark'
        case 'call:new-voicemail':
          return 'bg-amber'
        case 'action:due':
          return 'bg-green'
        case 'mailing:clicked':
          return 'bg-email-orange'
        case 'crm:reauth-required':
          return 'bg-red-dark'
        case 'mailbox:reauth-required':
          return 'bg-red-dark'
        case 'user:data-export-download-ready':
          return 'bg-primary'
        default:
          return 'bg-primary'
      }
    },
    getNotifBorder(notif) {
      switch (notif.type) {
        case 'call:mentioned-in-note':
          return `border: 2px solid #324499 !important;`
        case 'phone-message:inbound':
          return `border: 2px solid #46bcff !important;`
        case 'call:missed':
          return `border: 2px solid #C62828 !important;`
        case 'call:new-voicemail':
          return `border: 2px solid #ffa000  !important;`
        case 'action:due':
          return 'border: 2px solid green !important;'
        case 'mailing:clicked':
          return 'border: 2px solid #ff9054 !important;'
        case 'crm:reauth-required':
          return `border: 2px solid #C62828 !important;`
        case 'mailbox:reauth-required':
          return `border: 2px solid #C62828 !important;`
        case 'user:data-export-download-ready':
          return `border: 2px solid #324499 !important;`
        default:
          return `border: 2px solid #46bcff !important;`
      }
    },
    getNotifIcon(notif) {
      switch (notif.type) {
        case 'call:mentioned-in-note':
          return 'mdi-phone'
        case 'phone-message:inbound':
          return 'mdi-message-badge'
        case 'call:missed':
          return 'mdi-phone-missed'
        case 'call:new-voicemail':
          return 'mdi-voicemail'
        case 'action:due':
          return 'mdi-checkbox-marked-circle-outline'
        case 'mailing:clicked':
          return 'mdi-email'
        case 'crm:reauth-required':
          return 'mdi-link-variant'
        case 'mailbox:reauth-required':
          return 'mdi-link-variant'
        case 'user:data-export-download-ready':
          return 'mdi-download'
        default:
          return 'mdi-bell'
      }
    },
    getNotifIconSize(notif) {
      switch (notif.type) {
        case 'call:mentioned-in-note':
          return 12
        case 'phone-message:inbound':
          return 10
        case 'call:missed':
          return 11
        case 'call:new-voicemail':
          return 11
        default:
          return 12
      }
    },
    onNotifClick(notif) {
      if (!notif.read_at) this.updateRead(notif, true)
      this.isOpen = false
      if (
        [
          'call:mentioned-in-note',
          'call:missed',
          'call:new-voicemail',
        ].includes(notif.type)
      )
        this.$router.push(`/calls/call-details/${notif.data.call.id}`)

      if (notif.type === 'mailing:clicked')
        this.$router.push(`/analytics/email-details/${notif.data?.mailing?.id}`)

      if (notif.type === 'phone-message:inbound')
        EventBus.$emit('open-sms-thread', notif.data.phoneMessage?.thread_id)

      if (notif.type === 'crm:reauth-required')
        this.$router.push('/settings?type=crm-connection')

      if (notif.type === 'mailbox:reauth-required')
        this.$router.push('/settings?type=email-connection')

      if (notif.type === 'user:data-export-download-ready') {
        // Need to open the download link in a new tab
        window.open(notif.data.url, '_blank')
      }

      if (notif.type === 'action:due') {
        if (notif.data?.is_exact)
          this.$router.push({
            path: '/actions/manual',
            query: { ids: notif.data?.ids },
          })
        else {
          const dateTimeInUTC = getLuxonObj(notif.created_at, false, 'utc')
          const convertedToUserTZ = convertToUserTimeZone(dateTimeInUTC)
          const rangeInDateOnly = [
            convertedToUserTZ.startOf('day').toFormat(DATE_FORMAT),
            convertedToUserTZ.endOf('day').toFormat(DATE_FORMAT),
          ]
          this.$router.push({
            path: '/actions/manual',
            query: {
              dateRange: rangeInDateOnly,
            },
          })
        }
      }
    },

    actionNotifSnippet(notif) {
      const { call_count, task_count, email_count, is_exact } = notif.data
      let parts = []

      if (call_count > 0)
        parts.push(`${call_count} call${call_count > 1 ? 's' : ''}`)

      if (email_count > 0)
        parts.push(`${email_count} email${email_count > 1 ? 's' : ''}`)

      if (task_count > 0)
        parts.push(`${task_count} pending task${task_count > 1 ? 's' : ''}`)

      if (parts.length === 1)
        return `You have ${parts[0]} ${
          is_exact ? 'within next 10 minutes' : 'today'
        }`
      else {
        const lastPart = parts.pop()
        return `You have ${parts.join(', ')} and ${lastPart} ${
          is_exact ? 'within next 10 minutes' : 'today'
        }.`
      }
    },

    getNotifSnippet(notif) {
      switch (notif.type) {
        case 'call:mentioned-in-note':
          return `<span class="text-caption">mentioned you in a comment </span> &nbsp;
        "<span class="mt-n2 text-caption">${truncate(
          htmlToText(notif.data.call.coaching_note),
          40
        )}</span>"`
        case 'phone-message:inbound':
          return `<span class="text-caption">Sent you a message </span> &nbsp;
        "<span class="mt-n2 text-caption">${truncate(
          notif.data?.phoneMessage?.body,
          40
        )}</span>"`
        case 'call:missed':
          return `<span class="text-caption">Called you </span> &nbsp`
        case 'call:new-voicemail':
          return `<span class="text-caption">Left you a voicemail </span> &nbsp`
        case 'action:due':
          return this.actionNotifSnippet(notif)
        case 'mailing:clicked':
          return `<span class="text-caption">Clicked on your email </span> &nbsp;
        "<span class="text-xs font-weight-bold mr-1">SUBJECT:</span><span class="mt-n2 text-caption">${truncate(
          notif.data?.mailing?.subject,
          40
        )}</span>"`
        case 'crm:reauth-required':
          return `<span class="text-caption">Your ${truncate(
            notif.data?.crm?.crm_friendly_name,
            40
          )} CRM needs to be reauthorized</span>`
        case 'mailbox:reauth-required':
          return `<span class="text-caption">Your mailbox needs to be reauthorized</span>`
        case 'user:data-export-download-ready':
          return `<span class="text-caption">Your data export is ready for download. Click here to download.</span>`
        default:
          return 12
      }
    },

    toggleNotifSound(val) {
      this.notifSoundToggleLoader = true
      this.updateSetting({
        userId: this.user.id,
        settings: {
          settings_key: UserSettings.NOTIFICATION_SOUND_ENABLED,
          settings_value: val,
        },
      }).finally(() => (this.notifSoundToggleLoader = false))
    },

    toggleNotifEmails(val) {
      this.notifEmailsToggleLoader = true
      this.updateSetting({
        userId: this.user.id,
        settings: {
          settings_key: UserSettings.NOTIFICATION_EMAILS_ENABLED,
          settings_value: val,
        },
      }).finally(() => (this.notifEmailsToggleLoader = false))
    },

    viewSettings() {
      this.viewingSettings = true
    },

    bindPusherObserver() {
      if (!this.channel) return

      this.channel.bind('notification', (notif) => {
        console.log('notification', notif)
        this.notifs = [notif, ...this.notifs]
        this.unreadCount++
        if (this.notifSoundEnabled) this.notifAlert.play()
      })
    },

    unbindPusherObserver() {
      if (!this.channel) return

      this.channel.unbind('notification')
    },

    updateRead(notif, newVal) {
      const index = this.notifs.findIndex((item) => item.id === notif.id)
      if (index === -1) return

      this.notifs = [
        ...this.notifs.slice(0, index),
        { ...this.notifs[index], read_at: newVal },
        ...this.notifs.slice(index + 1),
      ]
      NotifsApi.updateRead(notif.id, newVal).then((res) => {
        if (typeof res === 'object')
          this.notifs = [
            ...this.notifs.slice(0, index),
            res,
            ...this.notifs.slice(index + 1),
          ]
        newVal ? this.unreadCount-- : this.unreadCount++
      })
    },

    resetData() {
      this.notifs = []
      this.pagination = {
        itemsPerPage: 10,
        total: 0,
        page: 1,
        lastPage: 1,
      }
    },
    getNextPage(_, __, isIntersecting) {
      if (isIntersecting && this.pagination.page < this.pagination.lastPage) {
        this.pagination.page++
        this.fetchNotifs({ append: true })
      }
    },
    fetchNotifs(options = {}) {
      if (options.reset) this.resetData()

      if (this.notifs.length) this.pageLoader = true
      else this.mainLoader = true

      const payload = { ...this.pagination }
      if (this.selectedChip === 'unread') payload.isRead = false
      else if (this.selectedChip === 'read') payload.isRead = true

      NotifsApi.getNotifications(payload)
        .then((res) => {
          const { data, pagination } = res
          this.pagination = pagination
          this.unreadCount = res.unread_count

          if (options.append) this.notifs = _unionBy(this.notifs, data, 'id')
          else this.notifs = data
        })
        .finally(() => {
          this.pageLoader = false
          this.mainLoader = false
        })
    },
  },
  watch: {
    isOpen(val) {
      if (val) this.fetchNotifs({ reset: true })
    },
    channel: {
      handler(newVal) {
        if (newVal != null) {
          this.unbindPusherObserver()
          this.bindPusherObserver()
        }
      },
      immediate: true,
    },
    selectedChip() {
      this.fetchNotifs({ reset: true })
    },
  },
  created() {
    this.fetchNotifs()

    // Setup user settings
    this.notifEmailsEnabled = this.getSettingByKey(
      UserSettings.NOTIFICATION_EMAILS_ENABLED
    )
    this.notifSoundEnabled = this.getSettingByKey(
      UserSettings.NOTIFICATION_SOUND_ENABLED
    )
    // Setup notif sound
    this.notifAlert = new Audio()
    this.notifAlert.src = require('@/assets/sounds/notif-sound.mp3')
  },
  beforeDestroy() {
    this.unbindPusherObserver()
  },
}
</script>

<style scoped>
.menu-carrot {
  position: absolute;
  top: 41px;
  margin-left: 1px;
  background-color: transparent !important;
  z-index: 999 !important;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-bottom: 12px solid rgba(0, 0, 0, 0.12);
}
.notifs-skeleton-loader >>> .v-skeleton-loader__list-item-avatar-three-line {
  height: 73px;
}
.chips-loader >>> .v-skeleton-loader__chip {
  margin-right: 12px !important;
  border-radius: 12px !important;
  height: 24px;
  width: 60px;
}

.notif-type {
  height: 16px;
  width: 16px;
  min-width: 16px;
  border-radius: 50%;
  z-index: 99;
}

.notif-icon >>> .v-badge__badge {
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px !important;
  height: 18px !important;
  max-height: 18px !important;
  min-height: 18px !important;
  padding: 3px !important;
  pointer-events: auto;
  position: absolute;
}
</style>
