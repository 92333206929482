import CrmApi from '@/services/api/Crm'
import BulkAccountExclusionApi from '@/services/api/BulkAccountExclusion'

const _unionBy = require('lodash/unionBy')

// initial state
const state = () => ({
  crmAccounts: [],
  status: '',
  selectedCrmAccounts: [],
  excludedCrmAccountIds: [],
  selectedReport: null,
})

// getters
const getters = {
  crmAccounts: (state) => state.crmAccounts,
  accountsLoading: (state) => state.status === 'loading',
  selectedCrmAccounts: (state) => state.selectedCrmAccounts,
  excludedCrmAccountIds: (state) => state.excludedCrmAccountIds,
  selectedReport: (state) => state.selectedReport,
}

// actions
const actions = {
  async fetchCrmAccounts({ state, dispatch, commit }, options = {}) {
    return new Promise((resolve, reject) => {
      commit('request')
      CrmApi.getCrmAccounts({
        ...options,
        listId: state.selectedReport?.external_id,
      })
        .then((resp) => {
          if (resp.data) {
            dispatch('markSelections', { options, data: resp.data })
            if (options.useInfScroll && options.infScrollOps?.start !== 0)
              commit(
                'setCrmAccounts',
                _unionBy(
                  state.crmAccounts,
                  resp.data.map((item, index) => ({
                    ...item,
                    index,
                    driveStates: item.driveStates || [],
                  })),
                  'external_id'
                )
              )
            else
              commit(
                'setCrmAccounts',
                resp.data.map((item, index) => ({
                  ...item,
                  index,
                  driveStates: item.driveStates || [],
                }))
              )
          }

          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          // ignore error if the req. was aborted
          if (!err.aborted) {
            commit('error')
            reject(err)
          }
        })
    })
  },
  updateCrmAccount({ commit }, account) {
    return new Promise((resolve, reject) => {
      CrmApi.updateCrmAccount(account)
        .then((resp) => {
          commit('setCrmAccount', resp)
          resolve(resp)
        })
        .catch((err) => reject(err))
    })
  },
  bulkImportExclusion(
    { commit },
    { exclusions, reportId, tableSearch, orderBy }
  ) {
    return new Promise((resolve, reject) => {
      BulkAccountExclusionApi.importAccounts(
        exclusions,
        reportId,
        tableSearch,
        orderBy
      )
        .then((resp) => {
          const errorCount = resp.errorCount
          const successCount = resp.successCount

          if (successCount != 0 && errorCount == 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: 'Successfully imported',
                snackType: 'success',
              },
              {
                root: true,
              }
            )
          } else if (successCount != 0 && errorCount != 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: `${successCount} impored. Unable to import ${errorCount}.`,
                snackType: 'warning',
              },
              {
                root: true,
              }
            )
          } else if (successCount == 0 && errorCount != 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: `There was an issue importing, try again.`,
                snackType: 'error',
              },
              {
                root: true,
              }
            )
          }

          resolve(resp)
        })
        .catch((err) => {
          commit('contactError')
          commit(
            'snackbar/setSnack',
            {
              snack: `There was an issue importing, try again.`,
              snackType: 'error',
            },
            {
              root: true,
            }
          )
          reject(err)
        })
    })
  },
  markSelections({ state, commit }, payload) {
    const { options, data } = payload
    const newData =
      options.useInfScroll && options.infScrollOps?.start !== 0
        ? _unionBy(state.crmAccounts, data, 'external_id')
        : data
    if (options.selectAll) commit('setSelectedAccounts', newData)
    else if (options.selectAllExclude && state.excludedCrmAccountIds.length) {
      const selectAllRespectExclude = newData.filter((resPerson) => {
        return !state.excludedCrmAccountIds.some(
          (excPerson) => excPerson === resPerson.external_id
        )
      })
      commit('setSelectedAccounts', selectAllRespectExclude)
    }
  },
}

// mutations
const mutations = {
  setCrmAccounts(state, data) {
    state.crmAccounts = data.length > 0 ? data : []
  },
  setCrmAccount(state, newAccount) {
    const index = state.crmAccounts.findIndex(
      (contact) => contact.external_id === newAccount.external_id
    )
    if (index !== -1)
      state.crmAccounts = [
        ...state.crmAccounts.slice(0, index),
        newAccount,
        ...state.crmAccounts.slice(index + 1),
      ]
  },
  setSelectedAccounts(state, contacts) {
    state.selectedCrmAccounts = contacts
  },
  setExcludedAccountIds(state, excludedCrmAccountIds) {
    state.excludedCrmAccountIds = excludedCrmAccountIds
  },
  resetAccountSelections(state) {
    state.selectedCrmAccounts = []
    state.excludedCrmAccountIds = []
  },
  setSelectedReport(state, report) {
    state.selectedReport = report
  },
  markAccountsImported(state, data) {
    state.crmAccounts = state.crmAccounts.map((account) => {
      const mapping = data.find(
        (item) => item.external_id === account.external_id
      )
      if (mapping) return { ...account, id: mapping.id }

      return account
    })
  },
  request(state) {
    state.status = 'loading'
  },
  success(state) {
    state.status = 'success'
  },
  error(state) {
    state.status = 'error'
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
