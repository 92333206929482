export const ACTION_PRIORITY_TYPES = Object.freeze([
  {
    text: 'None',
    value: 0,
    icon: 'mdi-radiobox-blank',
    color: 'grey',
  },
  {
    text: 'Low',
    value: 1,
    icon: 'mdi-arrow-down-drop-circle',
    color: 'call-blue',
  },
  {
    text: 'Medium',
    value: 2,
    icon: 'mdi-minus-circle',
    color: 'email-orange',
  },
  {
    text: 'High',
    value: 3,
    icon: 'mdi-arrow-up-drop-circle',
    color: 'red',
  },
])

export default ACTION_PRIORITY_TYPES

/**
 * Returns action priority object
 *
 * @export
 * @param {string} value - The `value` of priority type to match
 * @return {object}
 */
export function getActionPriorityObj(value) {
  if (!value && value !== 0) return

  return Object.values(ACTION_PRIORITY_TYPES).find((i) => i.value === value)
}

export function getActionPriorityIcon(value) {
  if (!value && value !== 0) return ''

  const priorityObj = getActionPriorityObj(value)

  if (priorityObj) return priorityObj.icon

  return ''
}

export function getActionPriorityColor(value) {
  if (!value && value !== 0) return ''

  const priorityObj = getActionPriorityObj(value)

  if (priorityObj) return priorityObj.color

  return ''
}

export function getActionPriorityText(value) {
  if (!value && value !== 0) return ''

  const priorityObj = getActionPriorityObj(value)

  if (priorityObj) return priorityObj.text

  return ''
}
