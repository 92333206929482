/**
 * Will return API query string based on a table pagination object
 *
 * For example:
 * tableOptions: {
 *    page: 1,
 *    itemsPerPage: 10,
 *    sortBy: [],
 *    sortDesc: [],
 *    multiSort: false,
 *    mustSort: false,
 *  },
 *
 * @param {object} options
 * @return {string}
 */
export function getTablePaginationParams(options = {}, paramNameToReturn = '') {
  if (!options) return ''

  let params = new URLSearchParams()
  let { page, itemsPerPage, sortBy, sortDesc } = options
  page = page ?? 1
  itemsPerPage = itemsPerPage ?? 10

  // sort
  if (sortBy?.length) {
    let orderByClean = sortBy.map((sortItem, index) => {
      // remove square brackets and any text between,
      // used in tables to pull data from arrays
      let sortClean = sortItem.replace(/ *\[[^\]]*]/g, '')

      if (sortDesc?.[index]) sortClean = '-' + sortClean
      return sortClean
    })

    params.append('orderBy', orderByClean.join('&'))
  }

  // page & count
  if (page) params.append('page', page.toString())
  if (itemsPerPage) params.append('count', itemsPerPage.toString())

  if (paramNameToReturn) return params.get(paramNameToReturn)

  return params.toString()
}

export function getInfScrollPaginationParams(options) {
  let params = new URLSearchParams()
  let { start, limit } = options
  start = start ?? 0
  limit = limit ?? 10

  // statr & limit
  if (start != null) params.append('start', start.toString())
  if (limit != null) params.append('limit', limit.toString())

  return params.toString()
}
