import api from './Api'
import store from '@/store'
import { getFiltersQuery } from '@/services/stateful/FilterService'

const ENDPOINTS = {
  base: () => `/bulkExclusion/accounts`,
  importAccounts: `/bulkExclusion/crm/accounts/import`,
  importAccountsFromReport: (id) => `/bulkExclusion/crm/reports/${id}/import`,
  opts: {
    exclusions: (ids = []) => (ids.length ? `exclusions=${ids.join(',')}` : ``),
    wildcardTableSearch: (text) => `tableSearch=${text}`,
    orderBy: (val) => `orderBy=${val}`,
  },
}

function getSearchParams() {
  let search = store.getters['accounts/accountSearch']
  return ENDPOINTS.opts.wildcardTableSearch(search)
}

export default {
  importAccounts(
    exclusionIds = [],
    reportId = null,
    tableSearch = '',
    orderBy = null
  ) {
    let path = reportId
      ? ENDPOINTS.importAccountsFromReport(reportId)
      : ENDPOINTS.importAccounts
    let pathParams = []

    const filtersQuery = getFiltersQuery.get()
    if (filtersQuery) pathParams.push(filtersQuery)

    if (tableSearch)
      pathParams.push(ENDPOINTS.opts.wildcardTableSearch(tableSearch))

    if (orderBy) pathParams.push(ENDPOINTS.opts.orderBy(orderBy))

    if (exclusionIds.length) {
      pathParams.push(ENDPOINTS.opts.exclusions(exclusionIds))
    }

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.post(path)
  },
  deleteAccounts(exclusionIds = [], orderBy = null) {
    let path = ENDPOINTS.base()
    let pathParams = []

    const filtersQuery = getFiltersQuery.get()
    if (filtersQuery) pathParams.push(filtersQuery)

    let searchParams = getSearchParams()
    if (searchParams) pathParams.push(searchParams)

    if (exclusionIds.length)
      pathParams.push(ENDPOINTS.opts.exclusions(exclusionIds))

    if (orderBy) pathParams.push(ENDPOINTS.opts.orderBy(orderBy))

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.delete(path)
  },
}
