<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  watch: {
    title: {
      immediate: true,
      handler() {
        document.title = this.title
      },
    },
  },
  render() {
    return null
  },
}
</script>
