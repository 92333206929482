import AccountFieldsApi from '@/services/api/AccountFields'

// initial state
const state = () => ({
  accountFields: [],
  accountFieldsLoading: false,
})

// getters
const getters = {
  accountFields: (state) => state.accountFields || [],
  customAccountFields: (state) =>
    state.accountFields.filter((field) => !field.default_field) || [],
  customAccountCols: (state, getters) =>
    getters.customAccountFields.map((field) => ({
      text: field.name,
      value: field.key,
      type: field.type,
      sortable: false,
      show: true,
    })) || [],
  accountFieldsLoading: (state) => state.accountFieldsLoading,
}

// actions
const actions = {
  createAccountField({ state, commit }, fieldToCreate) {
    return new Promise((resolve, reject) => {
      AccountFieldsApi.createAccountField(fieldToCreate)
        .then((res) => {
          commit('setAccountFields', [...state.accountFields, res])
          resolve(res)
        })
        .catch((err) => reject(err))
    })
  },

  fetchAccountFields({ commit }) {
    commit('request')
    return new Promise((resolve, reject) => {
      AccountFieldsApi.getAccountFields()
        .then((res) => {
          commit('setAccountFields', res)
          commit('success')
          resolve(res)
        })
        .catch((err) => {
          commit('error')
          commit(
            'snackbar/setSnack',
            {
              snack: 'Error fetching account fields. Try again.',
              snackType: 'error',
            },
            {
              root: true,
            }
          )
          reject(err)
        })
    })
  },

  updateAccountField({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      AccountFieldsApi.updateAccountField(payload)
        .then((res) => {
          const index = state.accountFields.findIndex(
            (field) => field.id === payload.id
          )
          if (index !== -1)
            commit('setAccountFields', [
              ...state.accountFields.slice(0, index),
              res,
              ...state.accountFields.slice(index + 1),
            ])
          resolve(res)
        })
        .catch((err) => reject(err))
    })
  },

  deleteAccountField({ state, commit }, id) {
    return new Promise((resolve, reject) =>
      AccountFieldsApi.deleteAccountField(id)
        .then(() => {
          const index = state.accountFields.findIndex(
            (field) => field.id === id
          )
          if (index !== -1)
            commit('setAccountFields', [
              ...state.accountFields.slice(0, index),
              ...state.accountFields.slice(index + 1),
            ])
          resolve()
        })
        .catch((err) => reject(err))
    )
  },
}

// mutations
const mutations = {
  setAccountFields(state, fields) {
    state.accountFields = fields
  },
  request(state) {
    state.accountFieldsLoading = true
  },
  success(state) {
    state.accountFieldsLoading = false
  },
  error(state) {
    state.accountFieldsLoading = false
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
