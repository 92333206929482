<template>
  <v-btn v-bind="$attrs" v-on="$listeners" color="primary" class="white--text">
    <slot />
  </v-btn>
</template>

<script>
export default {}
</script>

<style></style>
