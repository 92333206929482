<template>
  <v-app>
    <!-- Messaging components -->
    <Snackbar />
    <AppModal />

    <!-- Help chat -->
    <HelpChat v-if="isLoggedIn && intercomScriptLoaded" />

    <template v-if="isLoggedIn && isVerifiedUser">
      <!-- || Service based components || -->

      <!-- Email -->
      <test-email />
      <email-composer />

      <!-- Dialer -->
      <Dialer
        v-if="$route.path !== '/dial' && (hasCalling || adminCanManageCalls)"
      />
      <power-dial-ongoing-call v-if="hasPowerDialing && ongoingPowerDialCall" />

      <!-- Contact components -->
      <CSVUploader ref="csvUploader" :sheetLisence="peopleSheetLicense" />
      <create-person />

      <!-- Account components  -->
      <create-account />

      <!-- Other -->
      <CRMReauthSnackbar />
      <CustomizeColumnsDrawer
        v-if="getSettingByKey(userSettingsKeys.TABLE_COLUMNS_CONFIG)"
      />
      <AIDrawer />
      <Paywall v-if="organization" />
      <ResumeSubscription v-if="organization" />

      <v-navigation-drawer
        v-if="$route.meta.showNav"
        v-model="drawerNav"
        app
        dark
        class="hide-scrollbar"
        color="primary darken-1"
        :mini-variant="true"
        :permanent="true"
      >
        <v-list-item dense class="px-2">
          <v-list-item-avatar tile>
            <v-img :src="require('@/assets/img/brand/Symbo_Icon_White.svg')" />
          </v-list-item-avatar>
        </v-list-item>
        <v-list dense nav>
          <v-list-item-group v-for="(group, i) in leftNav" :key="i">
            <div v-for="(item, j) in group.data" :key="j">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-list-item
                    v-on="on"
                    link
                    style="max-width: 40px"
                    :to="item.getLink?.()"
                    :class="{ 'v-list-item--active': isChildActive(item) }"
                  >
                    <v-list-item-icon>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <span>{{ item.title }}</span>
              </v-tooltip>
              <v-divider v-if="item.divider" class="my-2 bg-white" />
            </div>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </template>

    <phone-verification v-if="isLoggedIn" />
    <vue-progress-bar />

    <v-app-bar
      v-if="$route.meta.showNav && isVerifiedUser"
      app
      :height="appWarningBanner ? 72 : 52"
      color="white"
      class="d-flex flex-column symbo-app-bar"
      elevation="1"
    >
      <app-warning v-if="isLoggedIn && appWarning" />
      <div class="d-flex align-center w-100 py-1 px-4" style="height: 52px">
        <nav-title />
        <v-spacer />
        <universal-search />
        <universal-add-btn />

        <v-spacer />
        <div v-for="(group, i) in quickNavIcons" :key="i">
          <div v-for="(item, j) in group.data" :key="j" class="cursor-pointer">
            <component v-if="item.component" :is="item.component" />
            <v-tooltip v-else open-delay="100" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                  <v-avatar
                    class="mr-2"
                    v-on="on"
                    v-bind="attrs"
                    :color="hover ? 'grey lighten-2' : 'white'"
                    size="30"
                    @click="handleSecondaryNavClick(item)"
                  >
                    <span class="grey--text text--darken-2 text-h7">
                      <v-icon class="text--darken-2" size="22">{{
                        item.icon
                      }}</v-icon>
                    </span>
                  </v-avatar>
                </v-hover>
              </template>
              {{ item.title }}
            </v-tooltip>
          </div>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-tooltip open-delay="100" bottom>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <user-avatar
                      :disableTooltip="true"
                      :readonly="true"
                      :elevated="true"
                      :value="user"
                    />
                  </div>
                </template>
                <v-col>
                  <v-row class="font-weight-bold">
                    {{ userFullName }}
                  </v-row>
                  <v-row>{{ (user || {}).email || null }}</v-row>
                </v-col>
              </v-tooltip>
            </div>
          </template>
          <v-card class="mx-auto" max-width="300" tile>
            <v-list dense>
              <v-subheader class="text-body-2 font-weight-bold text-uppercase">
                {{ userFullName }}
              </v-subheader>
              <v-list-item-group v-for="(group, i) in secondaryNav" :key="i">
                <v-list-item
                  v-for="(item, j) in group.data"
                  :v-if="group.display"
                  :key="j"
                  :class="$route.path === item.getLink?.() ? 'bg-selected' : ''"
                  @click="handleSecondaryNavClick(item)"
                  active-class="secondary-nav"
                >
                  <v-list-item-icon>
                    <v-icon
                      class="grey--text text--darken-3"
                      active-class="grey--text text--darken-3"
                    >
                      {{ item.icon }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="grey--text text--darken-3"
                      active-class="grey--text text--darken-3"
                    >
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-menu>
        <version-refresh-chip />
      </div>
    </v-app-bar>
    <v-main
      v-if="shouldShowRouterView"
      :style="`height: ${allowScroll ? 'auto' : appContentHeight}`"
      :class="!allowScroll ? 'overflow-hidden' : ''"
    >
      <quick-actions-bar v-if="quickActionServiceActive"></quick-actions-bar>
      <router-view />
    </v-main>

    <!-- Refresh screen dialog (If current user's plan gets updated) -->
    <v-dialog v-model="refreshScreenDialog" persistent width="450">
      <v-card>
        <v-card-title class="text-h5 border-b-1"
          >Your plan has been updated</v-card-title
        >
        <v-card-text class="pt-4">
          <div class="w-100 d-flex justify-center text-h6">
            Your screen will refresh in
          </div>
          <div class="w-100 d-flex justify-center text-h5 mb-3">
            {{ refreshScreenCountDown }} seconds
          </div>
          <div>
            Your plan update will change your access to Symbo features. If this
            is unexpected, please contact your admin.
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            color="primary"
            class="d-flex align-center"
            @click="refreshNow"
          >
            <v-icon class="mr-2">mdi-refresh</v-icon>
            Refresh now
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<style>
.secondary-nav.v-list-item--active:before {
  opacity: 0 !important;
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import * as Sentry from '@sentry/vue'

import { quickMenuServiceComputed } from '@/services/stateful/QuickMenuService'
import {
  powerDialComputed,
  powerDialActions,
} from '@/services/stateful/dialer/PowerDialService'
import { EventBus } from '@/services/event-bus'
import FeatureFlags from '@/services/feature-flags/FeatureFlags'
import {
  dialerServiceGetters,
  dialerServiceComputed,
} from '@/services/stateful/dialer/DialerService'
import cubejsApi from '@/services/cubejs/index'

import { UserSettings } from '@/constants'
import { removeLSItem } from '@/utils/localStorage'
import { HIJACK_LS_KEY, isHijackMode } from '@/utils/localStorage'
import { getLSItem } from './utils/localStorage'

export default {
  name: 'App',
  components: {
    Snackbar: () => import('@/components/utilities/Snackbar'),
    CRMReauthSnackbar: () => import('@/components/utilities/CRMReauthSnackbar'),
    AppModal: () => import('@/components/utilities/AppModal'),
    AIDrawer: () => import('@/components/ai/AIDrawer'),
    CustomizeColumnsDrawer: () =>
      import('@/components/utilities/CustomizeColumnsDrawer'),
    QuickActionsBar: () => import('@/components/quick-menu/QuickActionsBar'),
    QuickActionsDrawer: () =>
      import('@/components/quick-menu/QuickActionsDrawer'),
    NavTitle: () => import('@/components/nav/NavTitle.vue'),
    HelpChat: () => import('@/components/help/HelpChat'),
    Dialer: () => import('@/components/dialer/Dialer'),
    DialerIcon: () => import('@/components/dialer/DialerIcon'),
    NotificationCenter: () => import('@/components/global/NotificationCenter'),
    HelpIcon: () => import('@/components/help/HelpIcon'),
    InviteUser: () => import('@/components/help/InviteUser'),
    EmailIcon: () => import('@/components/emailComposer/EmailIcon.vue'),
    Paywall: () => import('@/components/utilities/Paywall'),
    ResumeSubscription: () =>
      import('@/components/utilities/ResumeSubscription'),
    EmailComposer: () => import('@/components/emailComposer/EmailComposer.vue'),
    TestEmail: () => import('@/components/utilities/TestEmail.vue'),
    VersionRefreshChip: () =>
      import('@/components/utilities/VersionRefreshChip.vue'),
    UserAvatar: () => import('@/components/utilities/UserAvatar.vue'),
    UniversalSearch: () =>
      import('@/components/universal-search/UniversalSearch.vue'),
    AppWarning: () => import('@/components/app/AppWarning.vue'),
    PowerDialOngoingCall: () =>
      import('@/components/power-dialing/PowerDialOngoingCall.vue'),
    CSVUploader: () => import('@/components/utilities/CSVUploader.vue'),
    UniversalAddBtn: () =>
      import('@/components/universal-search/UniversalAddBtn.vue'),
    CreatePerson: () => import('@/components/contacts/CreatePerson.vue'),
    CreateAccount: () => import('@/components/accounts/CreateAccount.vue'),
    PhoneVerification: () => import('@/components/utilities/PhoneVerification'),
  },
  computed: {
    ...powerDialComputed,
    ...quickMenuServiceComputed,
    ...dialerServiceGetters,
    ...dialerServiceComputed,
    ...mapGetters('user', [
      'user',
      'userLoading',
      'isVerifiedUser',
      'organization',
      'isOrgAdmin',
      'getSettingByKey',
      'isLoggedIn',
      'appWarning',
      'appWarningBanner',
      'hasCalling',
      'adminCanManageCalls',
      'hasPowerDialing',
      'adminCanManagePowerDialing',
      'shouldShowCallPages',
      'isHijackMode',
      'isHubspotWidget',
    ]),
    ...mapGetters('orgUsers', ['orgUsers']),
    ...mapGetters('pusher', ['channel']),
    shouldShowRouterView() {
      if (!this.isLoggedIn) return !this.$route.meta.requiresAuth
      return this.userLoading || this.isVerifiedUser
    },
    leftNavGroups() {
      return [
        {
          permission: 'user',
          data: [
            {
              title: 'Home',
              icon: 'mdi-home',
              getLink: () => '/',
            },

            {
              title: 'People',
              icon: 'mdi-account-group',
              getLink: () => '/people',
              divider: true,
            },
            ...this.getCallsNav(),
            {
              title: 'Actions',
              icon: 'mdi-check',
              getLink: () => '/actions',
            },
            {
              title: 'Sequence Management',
              icon: '$vuetify.icons.symboDrive',
              getLink: () => '/drives',
              divider: true,
            },
            ...this.getAnalyticsNav(),
          ],
        },
        {
          permission: 'admin',
          data: [],
        },
      ]
    },
    peopleSheetLicense() {
      return process.env.VUE_APP_PEOPLE_SHEET_LICENSE_KEY
    },
    appContentHeight() {
      let heightToSubtract = this.appWarningBanner ? 108 : 80
      heightToSubtract += this.quickActionServiceActive
        ? this.isMiniQuickActionsBar
          ? 28
          : 60
        : 0
      return `calc(100vh - ${heightToSubtract}px)`
    },
    allowScroll() {
      return this.$route.meta.scrollView
    },
    userFullName() {
      return `${this.user?.first_name || ''} ${this.user?.last_name || ''}`
    },
    userInitials() {
      const initials =
        (this.user?.first_name?.[0] || '') + (this.user?.last_name?.[0] || '')
      return initials.toUpperCase()
    },
    quickNavIcons() {
      // Apply breakpoint sm and xs to remove some items to avoid clutter on top bar
      const itemsBasedOnBreakPoint = this.$vuetify.breakpoint.smAndDown
        ? this.quickNavIconGroups.slice(1)
        : this.quickNavIconGroups

      return itemsBasedOnBreakPoint.filter((group) => {
        const isEnabled =
          !group.featureFlag || FeatureFlags[group.featureFlag]()
        const hasPermission =
          group.permission == 'user' ||
          (this.isOrgAdmin && group.permission == 'admin')
        return isEnabled && hasPermission
      })
    },
    secondaryNav() {
      // Apply breakpoint sm and xs to push in some items to avoid clutter on top bar
      const navItemsBasedOnBreakpoint = this.$vuetify.breakpoint.smAndDown
        ? [
            ...this.secondaryNavGroups.slice(
              0,
              this.secondaryNavGroups.length - 1
            ),
            {
              permission: 'admin',
              data: [
                {
                  title: 'Invite a user',
                  icon: 'mdi-account-multiple-plus',
                  getLink: () => '/org-settings',
                },
              ],
            },
            ...this.secondaryNavGroups.slice(
              this.secondaryNavGroups.length - 1
            ),
          ]
        : this.secondaryNavGroups

      // Apply user role to filter out admin/non-admin items
      const navItemsBasedOnUserRole = navItemsBasedOnBreakpoint.filter(
        (group) => {
          return (
            group.permission == 'user' ||
            (this.isOrgAdmin && group.permission == 'admin')
          )
        }
      )

      return navItemsBasedOnUserRole
    },
    leftNav() {
      return this.leftNavGroups.filter((group) => {
        return (
          group.permission == 'user' ||
          (this.isOrgAdmin && group.permission == 'admin')
        )
      })
    },
    intercomScriptLoaded() {
      return window.Intercom
    },
  },
  watch: {
    channel: {
      handler(newVal) {
        if (newVal != null) {
          this.unbindPusherObserver()
          this.bindPusherObserver()
        }
      },
      immediate: true,
    },
    orgUsers: {
      handler(val, oldVal) {
        if (!oldVal || val?.length !== oldVal?.length) {
          if (this.hasPowerDialing || this.adminCanManagePowerDialing) {
            this.fetchActivePDSession()
            if (this.isOrgAdmin) {
              const allTeam = val.map((u) => u.id).join(',')
              this.pdSessionsUsersQuery = allTeam
            }
            this.fetchPDSessionsOnHold()
          }
        }
      },
      immediate: true,
    },
    user: {
      handler(newVal, oldVal) {
        if (newVal != null) {
          if (this.isLoggedIn && newVal.id !== oldVal?.id)
            this.setupSentryInfo()
        }
      },
      immediate: true,
    },
  },
  data: () => ({
    userProfilePollingInterval: null,
    quickActionsDrawer: false,
    drawerNav: false,
    userSettingsKeys: UserSettings,
    secondaryNavGroups: [
      {
        permission: 'user',
        data: [
          { title: 'My Settings', icon: 'mdi-cog', getLink: () => '/settings' },
        ],
      },
      {
        permission: 'admin',
        data: [
          {
            title: 'Org Settings',
            icon: 'mdi-account-cog',
            getLink: () => '/org-settings',
          },
        ],
      },
      {
        permission: 'user',
        data: [
          {
            title: 'View referral link',
            icon: 'mdi-gift',
            getLink: () => '/settings?type=user-profile',
          },
        ],
      },
      {
        permission: 'user',
        data: [
          {
            title: 'Feature Request',
            icon: 'mdi-lightbulb',
            href: 'https://forms.clickup.com/25746723/f/rhq93-1991/Y2TCHWN23A7W4HLC87',
            target: '_blank',
          },
          {
            title: isHijackMode() ? 'Exit Hijack' : 'Logout',
            icon: 'mdi-logout',
            click: 'logoutClicked',
          },
        ],
      },
    ],
    quickNavIconGroups: [
      {
        permission: 'admin',
        data: [
          {
            component: 'InviteUser',
          },
        ],
      },
      {
        permission: 'user',
        data: [
          {
            component: 'HelpIcon',
          },
        ],
      },
      {
        permission: 'user',
        featureFlag: 'isAppNotificationsEnabled',
        data: [
          {
            component: 'NotificationCenter',
          },
        ],
      },
      {
        permission: 'user',
        data: [
          {
            title: 'Quick Action Menu',
            component: 'QuickActionsDrawer',
            icon: '$vuetify.icons.quickActionMenu',
            borderColor: 'pink',
            click: 'openQuickActionsDrawer',
          },
        ],
      },
      {
        permission: 'user',
        data: [
          {
            component: 'EmailIcon',
          },
        ],
      },
      {
        permission: 'user',
        data: [
          {
            component: 'DialerIcon',
          },
        ],
      },
    ],

    refreshScreenDialog: false,
    refreshScreenCountDown: null,
    refreshScreenCountDownInterval: null,
  }),
  created: async function () {
    EventBus.$on('import-people', () => this.$refs.csvUploader.triggerOpen())
    EventBus.$on('log-user-out', this.logout)
    EventBus.$on('soketi-disconnected', () => {
      this.pusherUnsubscribe()
      this.setupSoketi()
      this.pusherSubscribe()
    })
    // EventBus.$on('fetch-pending-action-counts', () => this.loadPendingActions())
    if (this.$vuetify.breakpoint.mdAndUp) this.drawerNav = true
    this.setupPollingUserProfile()
  },
  updated() {
    if (getLSItem('is-hubspot-widget') && !this.isHubspotWidget)
      this.setHubspotWidget(true)
  },
  beforeDestroy() {
    this.unbindPusherObserver()
    this.resetPollingUserProfile()
  },
  methods: {
    ...mapActions('pusher', [
      'pusherUnsubscribe',
      'pusherSubscribe',
      'setupSoketi',
    ]),
    ...mapMutations('appModal', ['showModal']),
    ...mapMutations('user', ['setHubspotWidget']),
    ...mapActions('user', ['setSettingUpdateAsync', 'getCrm', 'load']),
    ...powerDialActions,
    resetPollingUserProfile() {
      clearInterval(this.userProfilePollingInterval)
      this.userProfilePollingInterval = null
    },
    setupPollingUserProfile() {
      const timeStep = 10 * 60 * 1000 // every ten mins
      this.resetPollingUserProfile()
      this.userProfilePollingInterval = setInterval(() => {
        if (this.isLoggedIn)
          this.load(true).then(() => {
            /*
            reinit cubejs
            [
              calling this would reinit the cube api
              instance and set it to Vue prototype so
              that all components get latest cube instance
            ]
            */
            cubejsApi()
            EventBus.$emit('user-profile-refreshed')
          })
      }, timeStep)
    },
    bindPusherObserver() {
      if (!this.channel) return

      this.channel.bind('mailing:throttle-changed', () => {
        console.log('mailing:throttle-changed')
      })
      this.channel.bind('mailing:needs-attention', () => {
        console.log('mailing:needs-attention')
      })
      this.channel.bind('mailing:updated', () => {
        console.log('mailing:updated')
      })
      this.channel.bind('organization:plan-updated', () => {
        console.log('organization:plan-updated')
        this.$store.dispatch('user/load')
      })
      this.channel.bind('crm:org-or-provider-updated', () => {
        this.$store.commit('snackbar/setSnack', {
          snack:
            "The default CRM for your organization has been removed. This change won't impact your current tasks.",
          snackType: 'warning',
        })
        this.getCrm()
        this.load()
      })
      this.channel.bind('crm:deleted', () => {
        console.log('crm:deleted')
        this.getCrm()
        this.load()
      })
      this.channel.bind('user:billing-plan-updated', () => {
        console.log('user:billing-plan-updated')
        this.refreshScreen()
      })
      this.channel.bind('user:permissions-updated', () => {
        console.log('user:permissions-updated')
        this.load()
      })
    },
    unbindPusherObserver() {
      if (!this.channel) return

      this.channel.unbind('mailing:throttle-changed')
      this.channel.unbind('mailing:needs-attention')
      this.channel.unbind('mailing:updated')
      this.channel.unbind('organization:plan-updated')
      this.channel.unbind('crm:org-or-provider-updated')
      this.channel.unbind('crm:deleted')
      this.channel.unbind('user:billing-plan-updated')
      this.channel.unbind('user:permissions-updated')
    },
    refreshScreen() {
      this.refreshScreenDialog = true
      this.refreshScreenCountDown = 30
      this.refreshScreenCountDownInterval = setInterval(() => {
        if (this.refreshScreenCountDown === 0) {
          clearInterval(this.refreshScreenCountDownInterval)
          this.refreshScreenCountDownInterval = null
          location.reload()
        } else {
          this.refreshScreenCountDown -= 1
        }
      }, 1000)
    },
    refreshNow() {
      this.refreshScreenDialog = false
      clearInterval(this.refreshScreenCountDownInterval)
      this.refreshScreenCountDownInterval = null
      location.reload()
    },
    logoutClicked: function () {
      if (isHijackMode()) {
        localStorage.removeItem(HIJACK_LS_KEY)
        location.reload()
      } else
        this.showModal({
          title: 'Done working?',
          doIfConfirmed: this.logout,
        })
    },
    logout: function () {
      this.$store.dispatch('user/logout').then(() => {
        this.$router.push('/login')
      })
      removeLSItem('activeTabs')
      removeLSItem('tableOps')
      removeLSItem('filters')
    },
    setupSentryInfo: function () {
      Sentry.setUser({
        id: this.user.id,
        email: this.user.email,
        first_name: this.user?.first_name,
        last_name: this.user?.last_name,
        organization_name: this.user?.organization?.name,
        organization_id: this.user?.organization?.id,
      })
    },
    handleSecondaryNavClick(item) {
      if (item.href) {
        window.open(item.href, item.target)
      } else if (item.click) {
        this[item.click]()
      } else if (item.getLink && this.$route.path != item.getLink?.()) {
        this.$router.push(item.getLink?.())
      }
    },
    openQuickActionsDrawer() {
      this.quickActionsDrawer = true
    },
    getAnalyticsNav() {
      const analyticsLink = () =>
        this.isOrgAdmin
          ? '/analytics/performance'
          : `/analytics/email-summary/${this.user.id}`
      const analyticsNav = {
        title: 'Analytics',
        icon: 'mdi-chart-bar',
        getLink: analyticsLink,
      }
      return [analyticsNav]
    },
    getCallsNav() {
      if (!this.shouldShowCallPages) return []

      const callsLink = () => {
        const lastRouteInCalls = (getLSItem('lastRouteInSection') || {}).calls
        return lastRouteInCalls
          ? lastRouteInCalls
          : this.hasPowerDialing
          ? '/calls/power-dialing/active'
          : this.isOrgAdmin
          ? '/calls/call-summary'
          : `/calls/call-summary/${this.user.id}`
      }

      const callsNav = {
        title: 'Calls',
        icon: 'mdi-phone',
        getLink: callsLink,
      }
      return [callsNav]
    },
    isChildActive(item) {
      switch (item.title) {
        case 'Calls':
          return this.$route.path.includes('/calls')
        case 'Sequence Management':
          return (
            this.$route.path.includes('/drives') ||
            this.$route.path.includes('/drive')
          )
        default:
          return false
      }
    },
  },
}
</script>

<style scoped>
.symbo-app-bar >>> .v-toolbar__content {
  flex-direction: column !important;
  padding: 0px !important;
}
</style>
