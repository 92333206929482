import api from './Api'
import { getTablePaginationParams } from './helpers'

const ENDPOINTS = {
  phoneNumbers: (id = '') => (id ? `/phoneNumbers/${id}` : '/phoneNumbers'),
  opts: {
    tableSearch: (text) => `tableSearch=${text}`,
    phoneSearch: (text) => `phoneSearch=${text}`,
  },
}

export default {
  getPhoneNumbers(options = {}) {
    let path = ENDPOINTS.phoneNumbers()
    let pathParams = []

    const { tableSearch, phoneSearch } = options

    // Number OR Name search
    if (tableSearch) pathParams.push(ENDPOINTS.opts.tableSearch(tableSearch))

    // Number OR Name search
    if (phoneSearch) pathParams.push(ENDPOINTS.opts.phoneSearch(phoneSearch))

    // Pagination
    const pagParams = getTablePaginationParams(options)
    if (pagParams) pathParams.push(pagParams)

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.get(path, { abortKey: 'GET_PHONE_NUMBERS' })
  },

  blacklistNumber(payload) {
    return api.post(`${ENDPOINTS.phoneNumbers(payload.id)}/actions/blacklist`, {
      phone_number: payload.number,
    })
  },
  markAsWrongNumber(phoneId) {
    return api.put(`${ENDPOINTS.phoneNumbers(phoneId)}`, {
      wrong_number: true,
    })
  },
}
