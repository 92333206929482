<template>
  <v-chip
    label
    :style="`${maxWidth ? `max-width: ${maxWidth} !important;` : ''} ${
      inline ? 'padding-block: 5px !important;' : ''
    }`"
    :class="
      elevation != null
        ? `elevation-${elevation} ${
            maxWidth && !inline ? 'd-flex align-center' : ''
          } ${inline ? 'd-inline' : ''}`
        : ` ${maxWidth && !inline ? 'd-flex align-center' : ''} ${
            inline ? 'd-inline' : ''
          }`
    "
    :disabled="disabled"
    :color="cleanTag.settings.color"
    :x-small="size == 'x-small'"
    :small="size == 'small'"
    :large="size == 'large'"
    :text-color="getTextColorFromBG(cleanTag?.settings?.color)"
  >
    <v-icon
      v-if="!hideIcon && cleanTag.settings.icon !== 'none'"
      v-text="cleanTag.settings.icon"
      left
      :x-small="size == 'x-small'"
      :small="size == 'small'"
      :large="size == 'large'"
      :class="{ 'd-inline': inline }"
    />

    <div
      class="text-truncate"
      :style="maxWidth ? `max-width: calc(100% - 16px)` : ''"
      :class="{ 'd-inline': inline }"
    >
      {{ cleanTag.name }}
    </div>

    <v-icon
      v-if="allowDelete"
      right
      :x-small="size == 'x-small'"
      :small="size == 'small'"
      :dense="size == 'regular'"
      :large="size == 'large'"
      :class="{ 'd-inline': inline }"
      @click="$emit('delete-tag')"
    >
      mdi-close
    </v-icon>
  </v-chip>
</template>

<script>
import { getTextColorFromBG } from '@/utils/helpers'

export default {
  props: {
    /**
     * Symbo Prospect Tag to display
     */
    tag: {
      type: Object,
      required: true,
    },
    /**
     * Hide the prospect tag icon
     */
    hideIcon: {
      type: Boolean,
      default: false,
    },
    /**
     * Render the delete icon on the tag
     */
    allowDelete: {
      type: Boolean,
      default: false,
    },
    /**
     * Attach display: inline or not
     */
    inline: {
      type: Boolean,
      default: false,
    },
    /**
     * Elevation (box-shadow) for tag
     */
    elevation: {
      type: [Number, String],
      default: 0,
    },

    /**
     * Disabled for tag (Removes all pointer events and makes the tag readonly)
     */

    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Size of tag to display
     * @values x-small, small, regular, large
     */
    size: {
      type: String,
      default: 'regular',
      validator: function (value) {
        return ['x-small', 'small', 'regular', 'large'].indexOf(value) !== -1
      },
    },
    /**
     * Max width of the tag
     * @values
     */
    maxWidth: {
      type: String,
    },
  },
  computed: {
    /**
     * Normalize custom tag settings
     */
    cleanTag() {
      let settings
      if (typeof this.tag.settings === 'string') {
        settings = JSON.parse(this.tag.settings)
      } else settings = this.tag.settings
      return { ...this.tag, settings }
    },
  },
  methods: {
    getTextColorFromBG,
  },
}
</script>

<style scoped>
.v-chip .v-icon {
  font-size: 20px;
}
</style>
