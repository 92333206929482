import api from './Api'

const ENDPOINTS = {
  availableBillingPlans: '/availableBillingPlans',
}

export default {
  getAvailableBillingPlans() {
    return api.get(ENDPOINTS.availableBillingPlans)
  },
}
