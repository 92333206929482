export const HIJACK_LS_KEY = 'hijack-mode-ls'

export const hijackModeLS = () => {
  return localStorage.getItem(HIJACK_LS_KEY)
}

export const isHijackMode = () => {
  return !!hijackModeLS()
}

export const getLSItem = (key, parse = true) => {
  // Logged in as some other user
  if (isHijackMode()) {
    const value = JSON.parse(hijackModeLS())?.[key]
    if (parse) return value ? JSON.parse(value) : null
    return value
  }

  // Regular user
  else {
    const value = localStorage.getItem(key)
    if (parse) return value ? JSON.parse(value) : null
    return value
  }
}

export const setLSItem = (key, value) => {
  // Logged in as some other user
  if (isHijackMode()) {
    const newHijackModeLS = { ...JSON.parse(hijackModeLS()), [key]: value }
    localStorage.setItem(HIJACK_LS_KEY, JSON.stringify(newHijackModeLS))
  }

  // Regular user
  else {
    localStorage.setItem(key, value)
  }
}

export const removeLSItem = (key) => {
  // Logged in as some other user
  if (isHijackMode()) {
    const newHijackModeLS = { ...JSON.parse(hijackModeLS()) }
    delete newHijackModeLS[key]
    localStorage.setItem(HIJACK_LS_KEY, JSON.stringify(newHijackModeLS))
  }

  // Regular user
  else {
    localStorage.removeItem(key)
  }
}
