import EmailsApi from '@/services/api/EmailTemplates'

// initial state
const state = () => ({
  status: '',
  enabledEmails: [],
  allEmails: [],
  showArchived: false,
  showDeleted: false,
})

// getters
const getters = {
  allEnabledEmails: (state) => state.enabledEmails,
  allEmails: (state) => state.allEmails,
  loadingStatus: (state) => state.status === 'loading',
  showArchived: (state) => state.showArchived,
  showDeleted: (state) => state.showDeleted,
}

// actions
const actions = {
  async fetchEnabledEmailTemplates({ commit, state }, options = {}) {
    return new Promise((resolve, reject) => {
      commit('request')

      // clear out existing records if beginning an append paginate
      if (options.append && options.page === 1) {
        commit('setEnabledEmailTemplates', [])
      }

      EmailsApi.getEmailTemplates({
        ...options,
        showEnabled: true,
      })
        .then((resp) => {
          // append new results instead of replace for growing select lists
          if (options.append) {
            const appendedEmails = [...state.enabledEmails, ...resp.data]
            commit('setEnabledEmailTemplates', appendedEmails)
          } else {
            commit('setEnabledEmailTemplates', resp.data)
          }

          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          // ignore error if the req. was aborted
          if (!err.aborted) {
            commit('error')
            reject(err)
          }
        })
    })
  },

  async fetchAllEmailTemplates({ commit, state }, options = {}) {
    return new Promise((resolve, reject) => {
      commit('request')

      // clear out existing records if beginning an append paginate
      if (options.append && options.page === 1) {
        commit('setAllEmailTemplates', [])
      }

      EmailsApi.getEmailTemplates({
        ...options,
        showArchived: state.showArchived,
        showDeleted: state.showDeleted,
      })
        .then((resp) => {
          // append new results instead of replace for growing select lists
          if (options.append) {
            const appendedEmails = [...state.allEmails, ...resp.data]
            commit('setAllEmailTemplates', appendedEmails)
          } else {
            commit('setAllEmailTemplates', resp.data)
          }

          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          // ignore error if the req. was aborted
          if (!err.aborted) {
            commit('error')
            reject(err)
          }
        })
    })
  },
}

// mutations
const mutations = {
  setEnabledEmailTemplates(state, enabledEmails) {
    state.enabledEmails = enabledEmails
  },
  setAllEmailTemplates(state, allEmails) {
    state.allEmails = allEmails
  },
  setShowArchived(state, showArchived) {
    if (showArchived) state.showDeleted = false
    state.showArchived = showArchived
  },
  setShowDeleted(state, showDeleted) {
    if (showDeleted) state.showArchived = false
    state.showDeleted = showDeleted
  },
  request(state) {
    state.status = 'loading'
  },
  success(state) {
    state.status = 'success'
  },
  error(state) {
    state.status = 'error'
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
