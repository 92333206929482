<template>
  <svg
    version="1.1"
    id="Layer_1"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :width="width"
    :height="height"
    viewBox="0 0 535.5 469.3"
    style="enable-background: new 0 0 535.5 469.3; fill: grey"
    xml:space="preserve"
  >
    <path
      d="M11.7,73.4v41.3c0,13.3,10.7,24,24,24h52.2L11.7,73.4z M137.1,10.6H73l88.1,75.5V34.6C161.1,21.4,150.3,10.6,137.1,10.6z
	M318.4,170.6h-58.7l82.7,70.9v-46.9C342.4,181.4,331.7,170.6,318.4,170.6z M193.1,228.8v45.9c0,13.3,10.8,24,24,24h57.5
	L193.1,228.8z M499.7,10.6H398.4c-13.3,0-24,10.7-24,24v80c0,13.3,10.7,24,24,24h101.3c13.3,0,24-10.7,24-24v-80
	C523.7,21.4,513,10.6,499.7,10.6z M318.4,10.6H217.1c-13.2,0-24,10.7-24,24v78.9l29.3,25.1h96.1c13.2,0,24-10.7,24-24v-80
	C342.4,21.4,331.7,10.6,318.4,10.6z M125.2,170.6H35.7c-13.3,0-24,10.7-24,24v80c0,13.3,10.7,24,24,24h101.3c13.3,0,24-10.7,24-24
	v-73.3L125.2,170.6z M137.1,330.6H35.7c-13.3,0-24,10.7-24,24v80c0,13.3,10.7,24,24,24h101.3c13.3,0,24-10.7,24-24v-80
	C161.1,341.4,150.3,330.6,137.1,330.6z M499.7,170.6H398.4c-13.3,0-24,10.7-24,24V269l34.6,29.7h90.7c13.3,0,24-10.7,24-24v-80
	C523.7,181.4,513,170.6,499.7,170.6z M499.7,330.6h-53.4l77.4,66.3v-42.3C523.7,341.4,513,330.6,499.7,330.6z M374.4,384.2v50.4
	c0,13.3,10.7,24,24,24h62.9L374.4,384.2z M311.9,330.6h-94.8c-13.2,0-24,10.7-24,24v80c0,13.3,10.8,24,24,24h101.3
	c13.2,0,24-10.7,24-24v-77.9L311.9,330.6z"
    />
    <rect
      x="-67"
      y="213.1"
      transform="matrix(0.758 0.6523 -0.6523 0.758 217.8482 -117.8534)"
      width="669.5"
      height="43"
    />
  </svg>
</template>

<script>
export default {
  name: 'DialpadSlash',
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: 'grey',
    },
  },
}
</script>
