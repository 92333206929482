// initial state
const state = () => ({
  analyticsDateRange: '',
  selectedCallOutcome: null,
  showInboundCalls: null,
  showAnsweredCalls: false,
  showMachineDetectedCalls: false,
  showHumanCalls: false,
})

// getters
const getters = {
  analyticsDateRange: (state) => state.analyticsDateRange,
  selectedCallOutcome: (state) => state.selectedCallOutcome,
  showInboundCalls: (state) => state.showInboundCalls,
  showAnsweredCalls: (state) => state.showAnsweredCalls,
  showMachineDetectedCalls: (state) => state.showMachineDetectedCalls,
  showHumanCalls: (state) => state.showHumanCalls,
}

// actions
const actions = {}

// mutations
const mutations = {
  setAnalyticsDateRange(state, val) {
    state.analyticsDateRange = val
  },
  setSelectedCallOutcome(state, val) {
    state.selectedCallOutcome = val
  },
  setShowInboundCalls(state, val) {
    state.showInboundCalls = val
  },
  setShowAnsweredCalls(state, val) {
    state.showAnsweredCalls = val
  },
  setShowMachineDetectedCalls(state, val) {
    state.showMachineDetectedCalls = val
  },
  setShowHumanCalls(state, val) {
    state.showHumanCalls = val
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
