import api from './Api'
import { getTablePaginationParams } from './helpers'

const ENDPOINTS = {
  org: (orgId) => (orgId ? `/organizations/${orgId}` : '/organization'),
  orgs: `/organizations`,
  rechargeWallet: () => '/organization/actions/rechargeWallet',
  orgSettings: (key) =>
    key ? `/organization/settings/${key}` : '/organization/settings',
  orgPaymentMethods: (orgId, methodId = '') =>
    methodId
      ? `/organizations/${orgId}/paymentMethods/${methodId}`
      : `/organizations/${orgId}/paymentMethods`,

  previewUpdateSubscriptionSeats: `/organization/actions/previewUpdateSubscriptionSeats`,
}

export default {
  /**
   * payload structure can be an array of settings objects (key/value)
   * or a single setting object (settings_key/settings_value)
   * 
   * {
   *    userId: Id,
   *    settings: {
   *      settings: [
   *        {
   *          key: ,
   *          value: ,
   *        }
   *      ],
   *    },
   *  }
      or
   * {
   *    userId: Id,
   *    settings: {
   *      settings_key: ,
   *      settings_value: ,
   *    },
   *  }
   * @param {*} id
   * @param {*} payload
   * @return {*}
   */
  updateSetting(payload) {
    return api.put(ENDPOINTS.orgSettings(), payload)
  },

  updateOrg(orgId, payload) {
    return api.put(ENDPOINTS.org(orgId), payload)
  },

  rechargeWallet(payload) {
    return api.post(ENDPOINTS.rechargeWallet(), payload)
  },

  deleteSetting(key) {
    return api.delete(ENDPOINTS.orgSettings(key))
  },

  getPaymentMethods(orgId) {
    return api.get(ENDPOINTS.orgPaymentMethods(orgId))
  },

  updatePaymentMethod(orgId, payload) {
    return api.put(ENDPOINTS.orgPaymentMethods(orgId, payload.id), payload)
  },

  deletePaymentMethod(orgId, methodId) {
    return api.delete(ENDPOINTS.orgPaymentMethods(orgId, methodId))
  },

  previewUpdateSubscriptionSeats(payload) {
    return api.post(ENDPOINTS.previewUpdateSubscriptionSeats, payload)
  },

  getOrgSeatMapping(orgId) {
    return api.get(`${ENDPOINTS.org(orgId)}/seatMapping`)
  },

  setOrgSeatMapping(mapping) {
    return api.post(
      `${ENDPOINTS.org()}/actions/updateSubscriptionSeats`,
      mapping
    )
  },

  cancelDowngrade(planId) {
    return api.post(`${ENDPOINTS.org()}/actions/cancelDowngrade/${planId}`)
  },

  getAllOrgs(options) {
    let path = ENDPOINTS.orgs
    let pathParams = []

    const { searchByName } = options

    // Table search input
    if (searchByName) pathParams.push(`name=${searchByName}`)

    // Pagination
    const pagParams = getTablePaginationParams(options)
    if (pagParams) pathParams.push(pagParams)

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.get(path)
  },
}
