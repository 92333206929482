import api from './Api'
import { getTablePaginationParams } from './helpers'
import { convertBlobToMP3 } from '@/utils/helpers'

const ENDPOINTS = {
  voicemailDrops: (id = '') =>
    id ? `voicemailDrops/${id}` : '/voicemailDrops',
}

export default {
  getVoicemailDrops(options = {}) {
    let path = ENDPOINTS.voicemailDrops()
    let pathParams = []

    // Pagination
    const pagParams = getTablePaginationParams(options)
    if (pagParams) pathParams.push(pagParams)

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.get(path)
  },

  async postVoicemailDrop(payload) {
    return new Promise((resolve, reject) => {
      convertBlobToMP3(payload.recording).then((mp3File) => {
        const form = new FormData()
        form.append('file', mp3File)
        form.append('name', payload.name)
        form.append('is_default', payload.is_default ? 1 : 0)

        api
          .post(ENDPOINTS.voicemailDrops(), form, {
            headers: {
              'Content-Type': `multipart/form-data`,
            },
          })
          .then((resp) => resolve(resp))
          .catch(() => reject())
      })
    })
  },

  async updateVoicemailDrop(payload) {
    return api.put(ENDPOINTS.voicemailDrops(payload.id), payload)
  },

  deleteVoicemailDrop(id) {
    return api.delete(ENDPOINTS.voicemailDrops(id))
  },
}
