import api from './Api'
import {
  getResourceRequestQuery,
  getTablePaginationParams,
  withAsync,
} from './helpers'

const ENDPOINTS = {
  prospects: (id = '') => (id ? `/prospects/${id}` : `/prospects`),
  bulkProspects: '/bulk/prospects',
  syncProspect: (id) => `${ENDPOINTS.prospects(id)}/sync`,
  undeleteProspect: (id) => `${ENDPOINTS.prospects(id)}/actions/undelete`,
  updateAutoScheduleActions: (id) =>
    `${ENDPOINTS.prospects(id)}/actions/updateAutoScheduleActions`,
  mergeDuplicates: (id) => `/prospects/${id}/actions/mergeDuplicates`,
  opts: {
    accountId: (id) => `accountId=${id}`,
    externalId: (id) => `externalId=${id}`,
    externalIds: (ids) => `externalId=${ids.join(',')}`,
    showDeleted: 'deleted_at=true',
    wildcardTableSearch: (text) => `tableSearch=${text}`,
    phoneSearch: (text) => `phoneSearch=${text}`,
    owner: (ids) => `owner=${ids.join(',')}`,
    isDuplicate: 'isDuplicate=true',
  },
}

const defaultResourceRequest = [
  'account.phoneNumbers,prospectTags,driveStates,driveStates.drive,driveStates.drive.dispositions,userSummary,csvImport',
  'phoneNumbers',
  'emailAddresses',
  'latestCompletedAction.call',
  'latestCompletedAction.mailing',
]

export default {
  getProspectById(id, withAbort = true) {
    let path = ENDPOINTS.prospects(id)
    // Resources
    path += '?' + getResourceRequestQuery(defaultResourceRequest)
    if (withAbort) return api.get(path, { abortKey: 'GET_PROSPECT_BY_ID' })
    return api.get(path)
  },

  async getProspectByExternalId(id) {
    const { response, error } = await withAsync(
      api.get,
      ENDPOINTS.prospects() + '?' + ENDPOINTS.opts.externalId(id)
    )

    if (response) {
      return response
    } else throw error
  },

  async getProspectsByExternalIds(externalIds, options = {}) {
    if (externalIds.length === 0) return
    let path =
      ENDPOINTS.prospects() + '?' + ENDPOINTS.opts.externalIds(externalIds)

    const pagParams = getTablePaginationParams(options)
    if (pagParams) path += '&' + pagParams

    const { response, error } = await withAsync(api.get, path)

    if (response) {
      return response
    } else throw error
  },

  getProspectsByAccountId(id, options = {}) {
    let path = ENDPOINTS.prospects() + '?' + ENDPOINTS.opts.accountId(id)

    const pagParams = getTablePaginationParams(options)
    if (pagParams) path += '&' + pagParams

    if (options.allUsers) path += '&' + `allUsers=1`

    // Resources
    path += '&' + getResourceRequestQuery(defaultResourceRequest)

    return api.get(path, { abortKey: 'GET_PROSPECTS_BY_ACCOUNT_ID' })
  },

  getProspects(options = {}) {
    let path = ENDPOINTS.prospects()
    let pathParams = []

    const {
      isDuplicate,
      showDeleted,
      searchTable,
      phoneSearch,
      filtersQuery,
      searchByName,
      resourceRequests: resourceRequestsOptions,
    } = options

    if (isDuplicate) pathParams.push(ENDPOINTS.opts.isDuplicate)

    if (showDeleted) pathParams.push(ENDPOINTS.opts.showDeleted)

    if (filtersQuery) pathParams.push(filtersQuery)

    // Wildcard table search
    if (searchTable || searchByName) {
      if (searchTable)
        pathParams.push(ENDPOINTS.opts.wildcardTableSearch(searchTable))
      else if (searchByName)
        pathParams.push(ENDPOINTS.opts.wildcardTableSearch(searchByName))
    }

    if (phoneSearch) {
      pathParams.push(ENDPOINTS.opts.phoneSearch(phoneSearch))
    }

    // Pagination
    const pagParams = getTablePaginationParams(options)
    if (pagParams) pathParams.push(pagParams)

    // Resources
    const resourceRequests = resourceRequestsOptions || defaultResourceRequest
    pathParams.push(getResourceRequestQuery(resourceRequests))

    pathParams = pathParams.filter((i) => !!i?.trim()) // Remove any empty params
    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.get(path, { abortKey: 'GET_PROSPECTS' })
  },

  getProspectsByDriveId(id, options = {}) {
    let path = ENDPOINTS.prospects() + '?' + `engagedDriveId=${id}`

    const pagParams = getTablePaginationParams(options)
    if (pagParams) path += '&' + pagParams

    return api.get(path, { abortKey: 'GET_PROSPECTS_BY_DRIVE_ID' })
  },

  addProspectByExternalId({ externalId, isLead }) {
    return api.post(ENDPOINTS.prospects(), {
      external_id: externalId,
      is_lead: isLead,
    })
  },

  addBulkProspectsByExternalId({ externalIds, isLead }) {
    return api.post(ENDPOINTS.bulkProspects, {
      external_ids: externalIds,
      is_lead: isLead,
    })
  },

  createProspect(data, associateCall = null) {
    let path = ENDPOINTS.prospects()
    if (associateCall) path += `?associate_call=${associateCall}`
    return api.post(path, data)
  },

  updateProspect({ prospectId, prospect }) {
    return api.put(ENDPOINTS.prospects(prospectId), prospect)
  },

  archiveProspect(prospectId) {
    return api.delete(ENDPOINTS.prospects(prospectId))
  },

  bulkArchiveProspects(payload) {
    return api.delete(ENDPOINTS.bulkProspects, {
      data: {
        prospects: payload,
      },
    })
  },

  bulkDeleteProspects(payload) {
    return api.delete(ENDPOINTS.bulkProspects, {
      data: {
        prospects: payload,
      },
    })
  },

  deleteProspect(prospectId) {
    return api.delete(ENDPOINTS.prospects(prospectId))
  },

  undoDeleteProspect(id) {
    return api.post(ENDPOINTS.undeleteProspect(id))
  },

  syncProspectWithCrm(prospectId, options = {}) {
    return api.post(ENDPOINTS.syncProspect(prospectId), options)
  },

  updateAutoScheduleActions(prospectId, options = {}) {
    return api.post(ENDPOINTS.updateAutoScheduleActions(prospectId), options)
  },

  mergeDuplicates(prospect) {
    return api.post(ENDPOINTS.mergeDuplicates(prospect.id), prospect)
  },
}
