import api from './Api'

export default {
  fetchTransferGroups() {
    return api.get('/transferGroups')
  },
  postTransferGroup(payload) {
    return api.post('/transferGroups', payload)
  },
  editTransferGroup(payload) {
    const { id } = payload
    return api.put(`/transferGroups/${id}`, payload)
  },
  deleteTransferGroup(id) {
    return api.delete(`/transferGroups/${id}`)
  },
}
