const state = () => ({
  snack: '',
  snackType: '',
  multiLine: false,
})

const mutations = {
  setSnack(state, payload) {
    state.snack = payload.snack
    state.snackType = payload.snackType
    state.multiLine = payload.multiLine || false
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
