import Vue from 'vue'
import DrivesApi from '@/services/api/Drives'
import DispositionsApi from '@/services/api/Dispositions'
import { getField, updateField } from 'vuex-map-fields'
import { generateUuid } from '@/utils/helpers'
import { htmlToText } from '@/utils/helpers'
const _cloneDeep = require('lodash/cloneDeep')
const _differenceBy = require('lodash/differenceBy')
const _find = require('lodash/find')

// initial state
const defaultDrive = {
  id: null,
  name: '',
  description: '',
  enabled: true,
  share_type: 'private',
  driveSteps: [],
  dispositions: [],
}
const defaultState = () => ({
  status: '',
  drive: _cloneDeep(defaultDrive),
  templateToClone: _cloneDeep(defaultDrive),
  driveCopy: _cloneDeep(defaultDrive), // To compare to see if a confirm is required on routeLeave
})

const getDriveErrors = (drive) => {
  const errors = []
  const { name } = drive
  if (!name) errors.push('Sequence name missing.')
  return errors
}

const getStepErrors = (step) => {
  const errors = []
  const {
    days,
    hours,
    minutes,
    time,
    step_type,
    body_html,
    subject,
    reply_to_previous_thread_id,
    specify_step_time,
  } = step
  if (specify_step_time && (days == null || time == null)) {
    errors.push('Configure step timing.')
  } else if (
    !specify_step_time &&
    (days == null || minutes == null || hours == null)
  ) {
    errors.push('Configure step timing.')
  }

  if (step_type === 'auto_email') {
    if (!body_html) errors.push('Email body missing.')
    if (!subject && !reply_to_previous_thread_id)
      errors.push('Email subject missing.')
  }
  if (step_type === 'linkedin_connect') {
    if (htmlToText(body_html)?.length > 300)
      errors.push('LinkedIn message should be 300 chars max.')
  }
  return errors
}

const state = defaultState()

// getters
const getters = {
  getField,
  drive: (state) => state.drive,
  driveCopy: (state) => state.driveCopy,
  driveSteps: (state) =>
    state.drive.driveSteps.map((step) => ({
      ...step,
      errors: getStepErrors(step),
    })),
  driveStepErrors: (state, getters) =>
    getters.driveSteps
      .map((step, index) => ({
        stepNumber: index + 1,
        errors: step.errors || [],
      }))
      .filter((step) => step.errors && step.errors.length),
  driveErrors: (state) => getDriveErrors(state.drive),
  isDriveValid: (state, getters) =>
    !getters.driveErrors.length && !getters.driveStepErrors.length,
  dispositions: (state) => state.drive.dispositions,
  driveLoading: (state) => state.status === 'loading',
  getDriveStepByIndex: (state) => (index) => {
    return state.drive.driveSteps[index]
  },
  getDriveStepById: (state) => (stepId) =>
    state.drive.driveSteps.find((step) => step.id === stepId),
  getDispositionById: (state) => (id) =>
    state.drive.dispositions.find((disp) => disp.id === id),
  getTemplateToClone: (state) => state.templateToClone,
}

// actions
const actions = {
  async fetchDrive({ commit }, { id, options = {} }) {
    return new Promise((resolve, reject) => {
      commit('request')
      DrivesApi.getDriveById(id, options)
        .then((resp) => {
          commit('setDrive', resp)
          commit('setSpecifyTimeProp')
          commit('calculateDaysMinutesHours')
          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          commit('error')
          reject(err)
        })
    })
  },

  async postBatchDriveAndSteps({ commit, getters }) {
    commit('calculateStepInterval')
    commit('setStepOrder')
    commit('convertHtmlToText')

    return new Promise((resolve, reject) => {
      commit('request')
      DrivesApi.postBatchDriveAndSteps(getters.drive)
        .then((resp) => {
          commit('setDrive', resp)
          commit('setSpecifyTimeProp')
          commit('calculateDaysMinutesHours')
          commit('success')
          commit(
            'snackbar/setSnack',
            {
              snack: 'Sequence created successfully',
              snackType: 'success',
            },
            { root: true }
          )
          resolve(resp)
        })
        .catch((err) => {
          commit('error')
          reject(err)
        })
    })
  },

  async updateBatchDriveAndSteps({ commit, getters }) {
    commit('calculateStepInterval')
    commit('setStepOrder')
    commit('convertHtmlToText')

    return new Promise((resolve, reject) => {
      commit('request')
      DrivesApi.updateBatchDriveAndSteps(getters.drive.id, getters.drive)
        .then((resp) => {
          commit('setDrive', resp)
          commit('setSpecifyTimeProp')
          commit('calculateDaysMinutesHours')
          commit('success')
          commit(
            'snackbar/setSnack',
            {
              snack: 'Sequence updated successfully',
              snackType: 'success',
            },
            { root: true }
          )
          resolve(resp)
        })
        .catch((err) => {
          commit('error')
          commit('setDrive', state.driveCopy) // Reverting to original 1st copy
          commit(
            'snackbar/setSnack',
            {
              snack: 'Error updating the drive',
              snackType: 'error',
            },
            { root: true }
          )
          reject(err)
        })
    })
  },

  async createDispositions({ state, commit }, dispositions) {
    return new Promise((resolve, reject) => {
      commit('request')
      DispositionsApi.bulkCreateDispositions({
        dispositions: dispositions.map((d) => {
          return { ...d, drive_id: state.drive.id }
        }),
      })
        .then((resp) => {
          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          commit('error')
          reject(err)
        })
    })
  },

  async deleteDispositionById({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit('request')
      DispositionsApi.deleteDispositionById(id)
        .then((resp) => {
          commit('removeDisposition', { id: id })
          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          commit('error')
          reject(err)
        })
    })
  },

  async archiveDispositionById({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit('request')
      DispositionsApi.archiveDisposition(id)
        .then((resp) => {
          commit('updateDispositionField', {
            disposition: { id },
            field: 'state',
            value: 'archived',
          })
          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          commit('error')
          reject(err)
        })
    })
  },

  async unarchiveDispositionById({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit('request')
      DispositionsApi.unarchiveDisposition(id)
        .then((resp) => {
          commit('updateDispositionField', {
            disposition: { id },
            field: 'state',
            value: 'engaged',
          })
          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          commit('error')
          reject(err)
        })
    })
  },
}

// mutations
const mutations = {
  updateField,
  updateFieldByStepIndex(state, { index, field, value }) {
    const steps = [...state.drive.driveSteps]
    const newSteps = [
      ...steps.slice(0, index),
      {
        ...steps[index],
        [field]: value,
      },
      ...steps.slice(index + 1),
    ]
    state.drive = {
      ...state.drive,
      driveSteps: newSteps,
    }
  },
  setTemplateToClone(state, template) {
    state.templateToClone = _cloneDeep(template)
  },
  setDrive(state, drive) {
    state.drive = _cloneDeep(drive)
    state.driveCopy = _cloneDeep(drive)
    state.templateToClone = _cloneDeep(defaultDrive)
  },

  resetDrive(state) {
    state.drive = _cloneDeep(defaultDrive)
    state.driveCopy = _cloneDeep(defaultDrive)
  },

  request(state) {
    state.status = 'loading'
  },

  success(state) {
    state.status = 'success'
  },

  error(state) {
    state.status = 'error'
  },

  // set days/minutes/hours from interval for display
  calculateDaysMinutesHours(state) {
    state.drive.driveSteps.forEach((driveStep) => {
      const days = Math.floor(driveStep.interval / 1440)
      const daysLeftOver = driveStep.interval % 1440
      const hours = driveStep.specify_step_time
        ? null
        : Math.floor(daysLeftOver / 60)
      const hoursLeftOver = daysLeftOver % 60
      const minutes = driveStep.specify_step_time
        ? null
        : Math.floor(hoursLeftOver)

      Vue.set(driveStep, 'days', days)
      Vue.set(driveStep, 'hours', hours)
      Vue.set(driveStep, 'minutes', minutes)
    })
    state.driveCopy.driveSteps.forEach((driveStep) => {
      const days = Math.floor(driveStep.interval / 1440)
      const daysLeftOver = driveStep.interval % 1440
      const hours = driveStep.specify_step_time
        ? null
        : Math.floor(daysLeftOver / 60)
      const hoursLeftOver = daysLeftOver % 60
      const minutes = driveStep.specify_step_time
        ? null
        : Math.floor(hoursLeftOver)

      Vue.set(driveStep, 'days', days)
      Vue.set(driveStep, 'hours', hours)
      Vue.set(driveStep, 'minutes', minutes)
    })
  },

  setSpecifyTimeProp(state) {
    state.drive.driveSteps = state.drive.driveSteps.map((step) => ({
      ...step,
      specify_step_time: step.time != null,
    }))
    state.driveCopy.driveSteps = state.driveCopy.driveSteps.map((step) => ({
      ...step,
      specify_step_time: step.time != null,
    }))
  },

  // set interval from days/minutes/hours for API
  calculateStepInterval(state) {
    state.drive.driveSteps.forEach((step) => {
      if (!step.specify_step_time) step.time = null
      else {
        step.hours = 0
        step.minutes = 0
      }
      const days = step.days || 0
      const hours = step.specify_step_time ? 0 : step.hours || 0
      const minutes = step.specify_step_time ? 0 : step.minutes || 0
      const interval = days * 1440 + hours * 60 + minutes
      step.interval = interval
    })
  },

  setStepOrder(state) {
    state.drive.driveSteps.forEach((step, index) => (step.step_order = index))
  },

  convertHtmlToText() {
    state.drive.driveSteps.forEach((step) => {
      if (step.step_type.includes('linkedin')) {
        step.body_html = htmlToText(step.body_html)
      }
    })
  },

  addDriveStep(state, { display_name, step_type }) {
    state.drive.driveSteps.push({
      id: generateUuid(),
      display_name: display_name,
      task_note: '',
      step_type: step_type,
      days: null,
      hours: null,
      minutes: null,
      interval: 0,
      time: null,
      interval_includes_weekends: false,
      execute_after_business_hours: false,
      reply_to_previous_thread_id: null,
      isNewStep: true,
      specify_step_time: false,
    })
  },

  addDispositions(state, dispositions) {
    state.drive.dispositions = [...state.drive.dispositions, ...dispositions]
  },

  removeDisposition(state, disposition) {
    state.drive.dispositions = _differenceBy(
      state.drive.dispositions,
      [disposition],
      'id'
    )
  },

  updateDispositionField(state, { disposition, field, value }) {
    _find(state.drive.dispositions, { id: disposition.id })[field] = value
  },

  replaceDisposition(state, { dispositionId, newDisposition }) {
    const index = state.drive.dispositions?.findIndex(
      (disp) => disp.id === dispositionId
    )
    if (index !== -1) {
      const newDispositions = [
        ...state.drive.dispositions.slice(0, index),
        newDisposition,
        ...state.drive.dispositions.slice(index + 1),
      ]
      state.drive.dispositions = newDispositions
    }
  },

  cloneDriveStep(state, index) {
    const clonedDriveSteps = _cloneDeep(state.drive.driveSteps)
    var clonedStep = clonedDriveSteps[index]
    clonedStep.id = generateUuid()
    state.drive.driveSteps.splice(index + 1, 0, clonedStep)
  },

  removeDriveStep(state, index) {
    const driveSteps = state.drive.driveSteps
    driveSteps.splice(index, 1)
  },

  moveDriveStepUp(state, index) {
    const driveSteps = state.drive.driveSteps
    driveSteps.splice(index - 1, 0, driveSteps.splice(index, 1)[0])
  },

  moveDriveStepDown(state, index) {
    const driveSteps = state.drive.driveSteps
    driveSteps.splice(index + 1, 0, driveSteps.splice(index, 1)[0])
  },

  cloneDrive(state) {
    // TODO: are we ok leaving hidden copied fields on post request? Will they just be ignored?
    state.drive.id = 1
    state.drive.name += ' - Copy'
    state.drive.state = 'draft'
  },

  resetState(state) {
    Object.assign(state, defaultState())
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
