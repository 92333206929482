<template>
  <v-menu v-model="isMenuOpen" offset-y :disabled="readonly">
    <template v-slot:activator="{ on: menuOn }">
      <v-tooltip
        bottom
        open-delay="100"
        :disabled="disableTooltip || isMenuOpen"
      >
        <template v-slot:activator="{ on: tooltipOn }">
          <div v-on="tooltipOn">
            <v-hover v-slot="{ hover }" :disabled="disableHover">
              <v-avatar
                v-on="menuOn"
                class="white--text text-body-2 font-weight-medium cursor-pointer"
                :color="avatarColor"
                :style="
                  hover
                    ? `border: 2px solid ${avatarBorderColor} !important`
                    : ''
                "
                :size="size"
                :class="{ 'elevation-4': elevated }"
              >
                <img v-if="avatarImage" :src="avatarImage" />
                <slot v-else name="none">
                  <span :style="`color: ${getTextColorFromBG(avatarColor)}`">
                  </span>
                  {{ userInitials }}
                </slot>
              </v-avatar>
            </v-hover>
          </div>
        </template>
        <span v-if="tooltipHTML" v-html="tooltipHTML"></span>
        <span v-else-if="tooltipText">{{ tooltipText }}</span>
        <span v-else>Owner{{ !readonly ? '.  Click to change.' : '' }}</span>
      </v-tooltip>
    </template>
    <v-card min-width="200" max-width="300" class="pa-2">
      <v-text-field
        v-model="searchUserQuery"
        dense
        hide-details
        prepend-inner-icon="mdi-magnify"
        placeholder="Search user"
      />
      <v-list dense max-height="200" class="overflow-scroll">
        <v-list-item
          v-for="user in filteredOrgUsers"
          :key="user.id"
          :class="{ 'bg-selected': user.id === selectedUser?.id }"
          :disabled="user.disabled"
          @click="selectedUser = user"
        >
          <div>
            {{ user.full_name }}
            <div
              v-if="user.disabled && disabledItemMessage"
              class="text-caption mt-n1"
            >
              {{ disabledItemMessage }}
            </div>
          </div>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { getUserInitials, getTextColorFromBG } from '@/utils/helpers'

import tinycolor from 'tinycolor2'

export default {
  name: 'UserAvatar',
  props: {
    value: {
      type: Object,
    },
    readonly: {
      type: Boolean,
    },
    elevated: {
      type: Boolean,
    },
    tooltipText: {
      type: String,
    },
    tooltipHTML: {
      type: String,
    },
    disableTooltip: {
      type: Boolean,
    },
    disableHover: {
      type: Boolean,
    },
    size: {
      type: Number,
      default: 34,
    },
    openByDefault: {
      type: Boolean,
    },
    disableItemFunc: {
      type: Function,
    },
    disabledItemMessage: {
      type: String,
    },
  },
  data: () => ({
    searchUserQuery: '',
    isMenuOpen: false,
  }),
  computed: {
    ...mapGetters('orgUsers', ['orgUsers', 'fetchingOrgUsers']),

    userInitials() {
      return this.selectedUser
        ? getUserInitials(
            this.selectedUser.first_name,
            this.selectedUser.last_name,
            this.selectedUser.full_name
          )
        : 'XY'
    },

    avatarColor() {
      return this.selectedUser?.avatar_color || 'green'
    },

    avatarImage() {
      return this.selectedUser?.avatar_url
    },

    avatarBorderColor() {
      const color = tinycolor(this.avatarColor)
      return color.darken(15).toString()
    },

    filteredOrgUsers() {
      const users = this.searchUserQuery
        ? this.orgUsers.filter((user) =>
            user.full_name
              ?.toLowerCase()
              .indludes(this.searchUserQuery.toLowerCase())
          ) || []
        : this.orgUsers || []
      return users.map((user) =>
        this.disableItemFunc && this.disableItemFunc(user)
          ? { ...user, disabled: true }
          : user
      )
    },

    selectedUser: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      },
    },
  },
  methods: {
    getTextColorFromBG,
    ...mapActions('orgUsers', ['fetchOrgUsers']),
  },
  created() {
    if (!this.orgUsers?.length && !this.fetchingOrgUsers) this.fetchOrgUsers()
  },
  mounted() {
    if (this.openByDefault) setTimeout(() => (this.isMenuOpen = true), 10)
  },
}
</script>
