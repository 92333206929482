// -----------------------------------------------------------------------------
// This is an interface for when the dialer is loaded in the Salesforce CRM
// it allows us to native dial from Salesforce
// -----------------------------------------------------------------------------
// https://resources.docs.salesforce.com/latest/latest/en-us/sfdc/pdf/api_cti.pdf
// https://ringcentral-tutorials.github.io/ringcentral-embeddable-demos/salesforce_lightning_more/tutorial/

import { EventBus } from '@/services/event-bus'

const loadInterface = (state) => {
  const script = document.createElement('script')
  script.src =
    'https://d3i000000dugqeao-dev-ed--c.vf.force.com/support/api/52.0/lightning/opencti_min.js'
  script.async = true
  script.type = 'text/javascript'
  script.onload = () => {
    console.log('📞 Salesforce dialer plugin ready...')
    setupSalesforce(state)
    initWatchers()
  }
  document.body.appendChild(script)
}

const setupSalesforce = () => {
  console.log('📞 Salesforce dialer plugin setup started...')

  window.sforce.opencti.enableClickToDial({
    callback: function () {
      console.log('📞 Click to dial enabled...')
    },
  })

  window.sforce.opencti.onClickToDial({
    listener: ({ number, recordId, recordName }) => {
      console.log('📞 Salesforce dialer plugin dialing number:', number)
      window.sforce.opencti.setSoftphonePanelVisibility({
        visible: true,
      })
      EventBus.$emit('call-now', {
        number,
        externalId: recordId,
        fullName: recordName,
      })
    },
  })

  console.log('📞 Salesforce dialer plugin setup complete...')
}

const initWatchers = () => {
  EventBus.$off('call-incoming')
  EventBus.$on('call-incoming', () => {
    console.log('📞 Salesforce dialer plugin call incoming...')
    window.sforce.opencti.setSoftphonePanelVisibility({
      visible: true,
    })
  })
}

export default {
  init: () => {
    loadInterface()
  },
}
