import { timeZonesNames } from '@vvo/tzdb'

const DEFAULT_PROSPECT_FIELDS = Object.freeze([
  {
    name: 'First Name',
    key: 'first_name',
    type: 'string',
    csvBoxType: 'text',
    useCSVType: 'string',
    example: 'John',
    validationHook: true,
    maxLength: 255,
  },
  {
    name: 'Last Name',
    key: 'last_name',
    type: 'string',
    csvBoxType: 'text',
    useCSVType: 'string',
    example: 'Doe',
    validationHook: true,
    maxLength: 255,
  },
  {
    name: 'Title',
    key: 'title',
    type: 'string',
    csvBoxType: 'text',
    useCSVType: 'string',
    example: 'VP of Sales',
    validationHook: true,
    maxLength: 255,
  },
  {
    name: 'Account Name',
    key: 'account_name',
    type: 'string',
    csvBoxType: 'text',
    useCSVType: 'string',
    example: 'Microsoft',
    validationHook: true,
    maxLength: 255,
  },
  {
    name: 'Account Industry',
    key: 'account_industry',
    type: 'string',
    csvBoxType: 'text',
    useCSVType: 'string',
    example: 'Automobile',
    validationHook: true,
    maxLength: 255,
  },

  //   Email
  {
    name: 'Work Email',
    key: 'work_email',
    type: 'string',
    csvBoxType: 'email',
    useCSVType: 'email',
    example: 'john.doe123@gmail.com',
    validationHook: true,
  },

  //   Phone
  {
    name: 'Work Phone',
    key: 'work_phone',
    type: 'string',
    csvBoxType: 'phone',
    useCSVType: 'phone',
    example:
      '+1-800-555-1234 (Auto formatting supported based on your country code)',
    validationHook: true,
  },
  {
    name: 'Mobile Phone',
    key: 'mobile_phone',
    type: 'string',
    csvBoxType: 'phone',
    useCSVType: 'phone',
    example:
      '+1-800-555-1234 (Auto formatting supported based on your country code)',
    validationHook: true,
  },
  {
    name: 'Home Phone',
    key: 'home_phone',
    type: 'string',
    csvBoxType: 'phone',
    useCSVType: 'phone',
    example:
      '+1-800-555-1234 (Auto formatting supported based on your country code)',
    validationHook: true,
  },
  {
    name: 'Other Phone',
    key: 'other_phone',
    type: 'string',
    csvBoxType: 'phone',
    useCSVType: 'phone',
    example:
      '+1-800-555-1234 (Auto formatting supported based on your country code)',
    validationHook: true,
  },

  //   Links
  {
    name: 'Website URL',
    key: 'website_url_1',
    type: 'link',
    csvBoxType: 'url',
    useCSVType: 'string',
    example: 'https://www.symbo.ai',
    validationHook: true,
    maxLength: 2000,
  },
  {
    name: 'LinkedIn URL',
    key: 'linkedin_url',
    type: 'link',
    csvBoxType: 'url',
    useCSVType: 'string',
    example: 'https://www.linkedin.com/company/gosymbo/',
    validationHook: true,
    maxLength: 255,
  },
  {
    name: 'Account Website URL',
    key: 'account_website_url',
    type: 'link',
    csvBoxType: 'url',
    useCSVType: 'string',
    example: 'https://www.symbo.ai',
    validationHook: true,
    maxLength: 255,
  },

  // Location
  {
    name: 'Timezone',
    key: 'time_zone',
    type: 'enum',
    csvBoxType: 'list',
    useCSVType: 'select',
    example: 'America/New_York',
    //   options: timeZonesNames.map((i) => ({ value: i, text: i, label: i })),
    format: timeZonesNames.join(','),
  },
  {
    name: 'Street',
    key: 'address_street',
    type: 'string',
    csvBoxType: 'text',
    useCSVType: 'string',
    example: 'Street 7',
    validationHook: true,
    maxLength: 30000,
  },
  {
    name: 'City',
    key: 'address_city',
    type: 'string',
    csvBoxType: 'text',
    useCSVType: 'string',
    example: 'New York',
    validationHook: true,
    maxLength: 255,
  },
  {
    name: 'State',
    key: 'address_state',
    type: 'string',
    csvBoxType: 'text',
    useCSVType: 'string',
    example: 'California',
    validationHook: true,
    maxLength: 255,
  },
  {
    name: 'Zip',
    key: 'address_zip',
    type: 'string',
    csvBoxType: 'text',
    useCSVType: 'string',
    example: '00016',
    validationHook: true,
    maxLength: 255,
  },
  {
    name: 'Country',
    key: 'address_country',
    type: 'string',
    csvBoxType: 'text',
    useCSVType: 'string',
    example: 'USA',
    validationHook: true,
    maxLength: 255,
  },

  //   Other
  {
    name: 'External Source',
    key: 'external_source',
    type: 'string',
    csvBoxType: 'text',
    useCSVType: 'string',
    validationHook: true,
    maxLength: 255,
  },
  {
    name: 'Date of Birth',
    key: 'date_of_birth',
    type: 'date',
    csvBoxType: 'date',
    useCSVType: 'date',
    format: 'DD/MM/YYYY',
    example: '12/10/1990',
  },
  {
    name: 'Personal Note',
    key: 'personal_note_1',
    type: 'string',
    csvBoxType: 'text',
    useCSVType: 'string',
    example: 'Intereseted in the service. Call on Friday.',
    validationHook: true,
    maxLength: 255,
  },
  {
    name: 'Revenue',
    key: 'lead_annual_revenue',
    type: 'string',
    csvBoxType: 'text',
    useCSVType: 'string',
    example: '$40,000',
    validationHook: true,
    maxLength: 255,
  },

  //   Account details
  {
    name: 'Account Employees',
    key: 'account_number_of_employees',
    type: 'integer',
    csvBoxType: 'number',
    useCSVType: 'number',
    example: '70',
  },
  {
    name: 'Account Company Type',
    key: 'account_company_type',
    type: 'string',
    csvBoxType: 'text',
    useCSVType: 'string',
    validationHook: true,
    maxLength: 255,
  },
  {
    name: 'Account Description',
    key: 'account_description',
    type: 'string',
    csvBoxType: 'text',
    useCSVType: 'string',
    example: 'Sportswear company.',
    validationHook: true,
    maxLength: 255,
  },
  {
    name: 'Account Ownership',
    key: 'account_ownership',
    type: 'string',
    csvBoxType: 'text',
    useCSVType: 'string',
    example: 'Private',
    validationHook: true,
    maxLength: 255,
  },
  {
    name: 'Account SIC',
    key: 'account_sic',
    type: 'string',
    csvBoxType: 'text',
    useCSVType: 'string',
    validationHook: true,
    maxLength: 255,
  },
  {
    name: 'Account Stock Ticker',
    key: 'account_stock_ticker',
    type: 'string',
    csvBoxType: 'text',
    useCSVType: 'string',
    validationHook: true,
    maxLength: 255,
  },
])

export default DEFAULT_PROSPECT_FIELDS
