const CALL_QUALITY_MESSAGES = Object.freeze({
  'high-rtt': {
    value: 'high-rtt',
    message: 'High Round Trip Time',
    cause: 'Round Trip Time (RTT) > 400 ms for 3 out of last 5 samples',
    description:
      'Round-trip-time (RTT) is the measure of latency in the network. Higher latency can result in perceptible delays in audio.',
    solution: 'Check your internet connection.',
  },
  high_rtt: {
    value: 'high_rtt',
    message: 'High Round Trip Time',
    cause: 'Round Trip Time (RTT) > 400 ms for 3 out of last 5 samples',
    description:
      'Round-trip-time (RTT) is the measure of latency in the network. Higher latency can result in perceptible delays in audio.',
    solution: 'Check your internet connection.',
  },
  'low-mos': {
    value: 'low-mos',
    message: 'Low Network Quality',
    cause: 'Mean Opinion Score (MOS) < 3.5 for 3 out of last 5 samples',
    description:
      'Mean Opinion Score (MOS) is a measure of the overall network conditions that affect call quality.',
    solution: 'Check your internet connection.',
  },
  low_mos: {
    value: 'low_mos',
    message: 'Low Network Quality',
    cause: 'Mean Opinion Score (MOS) < 3.5 for 3 out of last 5 samples',
    description:
      'Mean Opinion Score (MOS) is a measure of the overall network conditions that affect call quality.',
    solution: 'Check your internet connection.',
  },
  'high-jitter': {
    value: 'high-jitter',
    message: 'High Jitter',
    cause: 'Jitter > 30 ms for 3 out of last 5 samples',
    description:
      'Jitter is the measure of variability at which packets arrive at the SDK sensors. High jitter can result in audio quality problems on the call, such as crackling and choppy audio.',
    solution: 'Check your internet connection.',
  },
  high_jitter: {
    value: 'high_jitter',
    message: 'High Jitter',
    cause: 'Jitter > 30 ms for 3 out of last 5 samples',
    description:
      'Jitter is the measure of variability at which packets arrive at the SDK sensors. High jitter can result in audio quality problems on the call, such as crackling and choppy audio.',
    solution: 'Check your internet connection.',
  },
  'high-packet-loss': {
    value: 'high-packet-loss',
    message: 'High Packet Loss',
    cause: 'Packet loss > 1% in 3 out of last 5 samples',
    description:
      'Packet loss is measured as the percentage of packets that were sent but not received at the SDK sensors. High packet loss can result in choppy audio or a dropped call.',
    solution: 'Check your internet connection.',
  },
  high_packetloss: {
    value: 'high_packetloss',
    message: 'High Packet Loss',
    cause: 'Packet loss > 1% in 3 out of last 5 samples',
    description:
      'Packet loss is measured as the percentage of packets that were sent but not received at the SDK sensors. High packet loss can result in choppy audio or a dropped call.',
    solution: 'Check your internet connection.',
  },
  'low-bytes-received': {
    value: 'low-bytes-received',
    message: 'Possible Downlink Issue',
    cause: 'Bytes received = 0 for three consecutive seconds',
    description: 'The call quality is affected by the downlink bandwidth.',
    solution: 'Check your internet connection.',
  },
  'low-bytes-sent': {
    value: 'low-bytes-sent',
    message: 'Possible Uplink Issue',
    cause: 'Bytes sent = 0 for three consecutive seconds',
    description: 'The call quality is affected by the uplink bandwidth.',
    solution: 'Check your internet connection.',
  },
  'ice-connectivity-lost': {
    value: 'ice-connectivity-lost',
    message: 'Dialer Internet Connectivity Lost',
    cause: 'Internet connection is unstable',
    description:
      'Dialer lost internet connectivity and will reconnect when the connection is restored.',
    solution: 'Check your internet connection.',
  },
  ice_timeout: {
    value: 'ice_timeout',
    message: 'Dialer Internet Connectivity Lost',
    cause: 'Internet connection is unstable',
    description:
      'Dialer lost internet connectivity and will reconnect when the connection is restored.',
    solution: 'Check your internet connection.',
  },
  ice_connection: {
    value: 'ice_connection',
    message: 'Dialer Internet Connectivity Lost',
    cause: 'Internet connection is unstable',
    description:
      'Dialer lost internet connectivity and will reconnect when the connection is restored.',
    solution: 'Check your internet connection.',
  },
  no_audio_received: {
    value: 'no_audio_received',
    message: 'No Audio Received',
    cause: 'No audio received for 3 consecutive seconds',
    description:
      'This message is triggered when local or remote audio is silent.',
    solution: 'Check your microphone or internet connection.',
  },
  no_microphone_access: {
    value: 'no_microphone_access',
    message: 'No Microphone Access',
    cause: 'No microphone access in the browser',
    description:
      'This message is triggered when the browser does not have access to the microphone.',
    solution:
      'Check your browser settings. See help docs for more information.',
  },
})

export default CALL_QUALITY_MESSAGES

export function getCallQualityMessage(value) {
  return Object.values(CALL_QUALITY_MESSAGES).find((i) => i.value === value)
}
