import { UserSettings } from '@/constants'

const _isEqual = require('lodash/isEqual')

// initial state
const state = () => ({
  columns: {},
})

// getters
const getters = {
  shouldUpdateCols: (state) => (tableName, defaultCols) => {
    const cols = state.columns[tableName] || []
    // 1. Check if a new col was added i.e. difference in length (1st check)
    // 2. Check if some col was updated i.e. we decided to change the name of the column or order of it (2nd check)
    // Note: We skip the difference of visibility (show property) and width
    if (cols.length !== defaultCols.length) return true
    for (let col of cols) {
      const defaultCol = defaultCols.find((item) => item.value === col.value)
      if (
        !defaultCol ||
        !_isEqual(
          {
            ...col,
            show: true,
            width: 0,
            transformer: null,
            class: '',
            cellClass: '',
          },
          {
            ...defaultCol,
            show: true,
            width: 0,
            transformer: null,
            class: '',
            cellClass: '',
          }
        )
      )
        return true
    }
    return false
  },
  getColumnsByTableName: (state) => (tableName) => state.columns[tableName],
  getAllTableColumns: (state) => state.columns,
  getColWidth: (state, getters) => (tableName, colValue) =>
    (getters.getColumnsByTableName(tableName) || []).find(
      (col) => col.value === colValue
    )?.width,
}

// mutations
const mutations = {
  setColumns(state, columns) {
    state.columns = { ...columns }
  },
  setColumnsByTableName(state, payload) {
    state.columns = {
      ...state.columns,
      [payload.tableName]: [...payload.columns],
    }
  },
  toggleColumn(state, payload) {
    const columns = state.columns[payload.tableName]
    const newColumns = columns.map((column) => {
      if (column.value === payload.columnValue)
        return {
          ...column,
          show: !column.show,
        }
      else return column
    })
    state.columns = {
      ...state.columns,
      [payload.tableName]: newColumns,
    }
  },
  fixColumnAsSticky(state, payload) {
    const columns = state.columns[payload.tableName]
    const newColumns = columns.map((column) => {
      if (column.value === payload.columnValue)
        return {
          ...column,
          class: column.class?.includes('fixed-column')
            ? column.class?.replaceAll('fixed-column', '')
            : (column.class || '') + 'fixed-column',
          cellClass: column.cellClass?.includes('fixed-column')
            ? column.cellClass?.replaceAll('fixed-column', '')
            : (column.cellClass || '') + 'fixed-column',
        }
      else return column
    })
    state.columns = {
      ...state.columns,
      [payload.tableName]: newColumns,
    }
  },
}

// actions
const actions = {
  saveCurrentConfig({ dispatch, rootGetters, getters }) {
    dispatch(
      'user/updateSetting',
      {
        userId: rootGetters['user/user']?.id,
        settings: {
          settings_key: UserSettings.TABLE_COLUMNS_CONFIG,
          settings_value: getters.getAllTableColumns,
        },
      },
      { root: true }
    )
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
