import BillingPlansApi from '@/services/api/BillingPlans'

// initial state
const state = () => ({
  billingPlans: [],
})

// getters
const getters = {
  billingPlans: (state) => state.billingPlans,
  billingPlansAccordingToBillingInterval: (state) => (billingInterval) =>
    state.billingPlans.map((i) => {
      const priceAccordingToInterval = i.prices.find(
        (price) => price.interval === billingInterval
      )?.amount
      return {
        ...i,
        price: priceAccordingToInterval ? priceAccordingToInterval / 100 : 0,
      }
    }),
}

// actions
const actions = {
  fetchBillingPlans({ commit }) {
    return new Promise((resolve, reject) => {
      BillingPlansApi.getAvailableBillingPlans()
        .then((res) => {
          commit('setBillingPlans', res)
          resolve(res)
        })
        .catch((err) => reject(err))
    })
  },
}

// mutations
const mutations = {
  setBillingPlans(state, billingPlans) {
    state.billingPlans = billingPlans
  },
  setFetchingBillingPlans(state, val) {
    state.fetchBillingPlans = val
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
