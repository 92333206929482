import EmailsApi from '@/services/api/EmailTemplates'
import { getField, updateField } from 'vuex-map-fields'

// initial state
const defaultState = () => ({
  status: '',
  email: {
    id: 1,
    name: '',
    subject: '',
    body_html: '',
    enabled: true,
    share_type: 'private',
    files: [],
  },
})

const state = defaultState()

// getters
const getters = {
  getField,
  email: (state) => state.email,
  emailLoading: (state) => state.status === 'loading',
}

// actions
const actions = {
  async fetchEmail({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit('request')
      EmailsApi.getEmailTemplateById(id)
        .then((resp) => {
          commit('success')
          commit('setEmail', resp)
          resolve(resp)
        })
        .catch((err) => {
          commit('error')
          reject(err)
        })
    })
  },

  async postEmailTemplate({ commit, getters }) {
    return new Promise((resolve, reject) => {
      commit('request')
      EmailsApi.postEmailTemplate(getters.email)
        .then((resp) => {
          commit('success')
          commit('setEmail', resp)
          resolve(resp)
        })
        .catch((err) => {
          commit('error')
          reject(err)
        })
    })
  },

  async updateEmailTemplate({ commit, getters }) {
    return new Promise((resolve, reject) => {
      commit('request')
      EmailsApi.updateEmailTemplate(getters.email.id, getters.email)
        .then((resp) => {
          commit('success')
          commit('setEmail', resp)
          resolve(resp)
        })
        .catch((err) => {
          commit('error')
          reject(err)
        })
    })
  },

  async setEmail({ commit }, email) {
    commit('setEmail', email)
  },
}

// mutations
const mutations = {
  updateField,
  setEmail(state, email) {
    state.email = email
  },

  request(state) {
    state.status = 'loading'
  },

  success(state) {
    state.status = 'success'
  },

  error(state) {
    state.status = 'error'
  },

  cloneEmail(state) {
    state.email.id = 1
    state.email.name += ' - Copy'
    state.email.state = 'draft'
  },

  resetState(state) {
    Object.assign(state, defaultState())
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
