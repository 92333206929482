import Vue from 'vue'
import Vuex from 'vuex'
import snackbar from './modules/snackbar'
import account from './modules/account'
import accounts from './modules/accounts'
import actions from './modules/actions'
import billingPlans from './modules/billingPlans'
import contact from './modules/contact'
import contacts from './modules/contacts'
import crmContacts from './modules/crmContacts'
import crmAccounts from './modules/crmAccounts'
import crmReports from './modules/crmReports'
import dispositions from './modules/dispositions'
import disposition from './modules/disposition'
import drives from './modules/drives'
import drive from './modules/drive'
import emails from './modules/emails'
import email from './modules/email'
import driveStates from './modules/driveStates'
import manageDrive from './modules/manageDrive'
import phone from './modules/phone'
import transferGroups from './modules/transferGroups'
import user from './modules/user'
import voicemailTemplates from './modules/voicemailTemplates'
import orgUsers from './modules/orgUsers'
import analytics from './modules/analytics'
import appModal from './modules/appModal'
import pusher from './modules/pusher'
import prospectTags from './modules/prospectTags'
import prospectFields from './modules/prospectFields'
import accountFields from './modules/accountFields'
import columnsManager from './modules/columnsManger'
import filterTabs from './modules/filterTabs'
import version from './modules/version'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    account,
    accounts,
    accountFields,
    actions,
    analytics,
    appModal,
    billingPlans,
    columnsManager,
    contact,
    contacts,
    crmContacts,
    crmAccounts,
    crmReports,
    dispositions,
    disposition,
    drives,
    driveStates,
    drive,
    emails,
    email,
    filterTabs,
    manageDrive,
    orgUsers,
    phone,
    prospectFields,
    prospectTags,
    pusher,
    snackbar,
    transferGroups,
    user,
    version,
    voicemailTemplates,
  },
})
