<template>
  <div class="extra-dense-fields">
    <v-menu
      bottom
      offset-y
      :menu-props="{
        offsetY: true,
      }"
    >
      <template v-slot:activator="{ on }">
        <v-card
          v-on="on"
          flat
          height="32"
          class="px-3 select-user-input py-2 d-flex align-center"
        >
          <!-- Placeholder -->
          <div v-if="!selectedItems.length" class="grey--text">
            {{ placeholder }}
          </div>

          <!-- Selections -->
          <div v-else>
            <v-chip
              label
              small
              close
              class="bg-selected font-weight-bold primary--text selected-item-chip"
              @click:close="removeItem(selectedItems[0].id)"
            >
              {{ selectedItems[0].full_name || selectedItems[0].name }}
            </v-chip>

            <v-avatar
              v-if="selectedItems.length > 1"
              class="cursor-pointer bg-selected ml-1"
              :size="23"
            >
              <span class="text-caption primary--text font-weight-bold"
                >+{{ selectedItems.length - 1 }}</span
              >
            </v-avatar>
          </div>

          <v-spacer />
          <v-icon small>mdi-chevron-down</v-icon>
        </v-card>
      </template>
      <v-card class="px-0 py-1 extra-dense-fields">
        <v-text-field
          v-model="userSearchQuery"
          dense
          autofocus
          hide-details
          prepend-inner-icon="mdi-account"
          placeholder="Enter name or email"
          class="mx-2"
          @input="searchDebounced"
        />
        <v-list class="pt-0">
          <div style="max-height: 200px; overflow-y: scroll">
            <!-- Selected items -->
            <div>
              <v-tooltip
                v-for="item in filteredSelectedItems"
                :key="item.id"
                open-delay="500"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item
                    v-on="on"
                    v-bind="attrs"
                    class="px-3 bg-selected primary--text"
                    dense
                    @click="removeItem(item.id)"
                  >
                    <div>
                      <v-list-item-title
                        class="text-truncate text-caption font-weight-bold"
                      >
                        {{ item.full_name }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-if="item.email"
                        class="text-caption"
                      >
                        {{ item.email }}
                      </v-list-item-subtitle>
                    </div>
                  </v-list-item>
                </template>
                <span>{{ item.full_name }}</span>
              </v-tooltip>
            </div>
            <!-- Not selected items -->
            <template v-if="filteredNotSelectedItems.length">
              <v-tooltip
                v-for="item in filteredNotSelectedItems"
                :key="item.id"
                open-delay="500"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item
                    v-bind="attrs"
                    v-on="on"
                    class="px-3"
                    dense
                    @click="addItem(item)"
                  >
                    <div>
                      <v-list-item-title
                        class="text-truncate text-caption font-weight-bold"
                      >
                        {{ item.full_name }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-if="item.email"
                        class="text-caption"
                      >
                        {{ item.email }}
                      </v-list-item-subtitle>
                    </div>
                  </v-list-item>
                </template>
                <span>{{ item.full_name }}</span>
              </v-tooltip>
            </template>
            <!-- No results -->
            <template
              v-if="
                !filteredNotSelectedItems.length &&
                !filteredSelectedItems.length
              "
            >
              <span
                v-if="!validEmail || onlyUsers"
                class="text-caption px-3 my-5"
              >
                No results
              </span>
              <v-list-item v-else @click="addUnknownEmail">
                {{ userSearchQuery }}
              </v-list-item>
            </template>
          </div>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import EmailAddressesApi from '@/services/api/EmailAddresses'

import { isValidEmail } from '@/utils/helpers'

const _debounce = require('lodash/debounce')
const _unionBy = require('lodash/unionBy')

export default {
  props: {
    selectedItems: {
      type: Array,
      default: () => [],
      required: true,
    },
    onlyUsers: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      default: 'Participants',
    },
  },
  data: () => ({
    userSearchQuery: '',
    emailAddresses: [],
    searchLoading: false,
  }),
  computed: {
    ...mapGetters('orgUsers', ['orgUsers']),
    ...mapGetters('user', ['user']),
    validEmail() {
      return isValidEmail(this.userSearchQuery)
    },
    filteredSelectedItems() {
      return this.selectedItems.filter((user) =>
        user.full_name
          ?.toLowerCase()
          .includes(this.userSearchQuery?.toLowerCase().trim())
      )
    },
    filteredNotSelectedItems() {
      const orgUsers = this.orgUsers
        .filter((user) => !this.onlyUsers || user.id !== this.user.id)
        .map((user) => ({
          id: user.id,
          full_name: user.full_name,
          type: 'userId',
        }))
      const emailAddresses = this.emailAddresses.map((email) => ({
        id: email.id,
        full_name: email.prospect?.full_name || email.email,
        email: email.email,
        type: 'emailAddressId',
      }))
      const items = [...orgUsers, ...(this.onlyUsers ? [] : emailAddresses)]
      return items.filter(
        (user) =>
          !this.selectedItems.some(
            (selectedUser) => selectedUser.id === user.id
          ) &&
          user.full_name
            ?.toLowerCase()
            .includes(this.userSearchQuery?.toLowerCase().trim())
      )
    },
  },
  methods: {
    searchDebounced: _debounce(async function (val) {
      if (this.onlyUsers) return

      this.searchLoading = true
      await this.fetchEmailAddresses({
        emailSearch: val,
        itemsPerPage: 100,
      })
    }, 400),

    fetchEmailAddresses(options) {
      this.searchLoading = true
      let apiOptions = {
        ...options,
        page: 1,
      }

      return new Promise((resolve, reject) => {
        if (this.onlyUsers) resolve()
        else {
          EmailAddressesApi.getEmailAddresses({
            ...apiOptions,
          })
            .then((resp) => {
              this.lastPage = resp.pagination.lastPage
              this.emailAddresses = _unionBy(
                [...this.selectedItems, this.emailAddresses],
                resp.data,
                'id'
              )
              resolve()
            })
            .catch((err) => {
              reject(err)
            })
            .finally(() => {
              this.searchLoading = false
              this.apiState = null
            })
        }
      })
    },
    removeItem(userId) {
      const newUsers = this.selectedItems.filter((user) => user.id !== userId)
      this.$emit('selection-updated', newUsers)
    },
    addItem(user) {
      const newUsers = [...this.selectedItems, user]
      this.$emit('selection-updated', newUsers)
    },
    addUnknownEmail() {
      const user = {
        full_name: this.userSearchQuery,
        id: this.userSearchQuery,
        type: 'email',
      }
      const newUsers = [...this.selectedItems, user]
      this.$emit('selection-updated', newUsers)
      this.userSearchQuery = ''
    },
  },
  created() {
    if (!this.onlyUsers) this.fetchEmailAddresses({ itemsPerPage: 20 })
  },
}
</script>

<style scoped>
.selected-item-chip >>> .v-chip__close {
  color: #324499;
  font-size: 15px !important;
}
.selected-item-chip {
  height: 20px;
}
.select-user-input {
  border: 1px solid grey;
}
</style>
