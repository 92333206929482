const Calls = () =>
  import(/* webpackChunkName: 'Calls' */ '@/views/calls/Calls.vue')

const CallSummary = () =>
  import(/* webpackChunkName: 'CallSummary' */ '../views/calls/CallSummary')

const UserCallSummary = () =>
  import(
    /* webpackChunkName: 'UserCallSummary' */ '../views/calls/UserCallSummary'
  )
const CallDetails = () =>
  import(/* webpackChunkName: 'CallDetails' */ '../views/calls/CallDetails')

const LiveCalls = () =>
  import(/* webpackChunkName: 'LiveCalls' */ '../views/calls/LiveCalls')

const ActivePDSessions = () =>
  import(
    /* webpackChunkName: 'ActivePDSessions' */ '../views/calls/PowerDialing/ActivePDSessions'
  )

const CompletedPDSessions = () =>
  import(
    /* webpackChunkName: 'CompletedPDSessions' */ '../views/calls/PowerDialing/CompletedPDSessions'
  )

const callsRoutes = {
  component: Calls,
  props: true,
  path: '/calls',
  meta: {
    requiresAuth: true,
    showNav: true,
    navTitle: 'Calls',
  },
  children: [
    {
      path: '',
      redirect: 'power-dialing/active',
    },
    {
      path: 'power-dialing',
      redirect: 'power-dialing/active',
    },
    {
      path: 'power-dialing/active',
      name: 'ActivePDSessions',
      component: ActivePDSessions,
      meta: {
        requiresAuth: true,
        showNav: true,
        navTitle: 'Power Dialing',
        hasSubMenu: true,
        scrollView: false,
      },
    },
    {
      path: 'power-dialing/completed',
      name: 'CompletedPDSessions',
      component: CompletedPDSessions,
      meta: {
        requiresAuth: true,
        showNav: true,
        navTitle: 'Power Dialing',
        hasSubMenu: true,
        scrollView: false,
      },
    },
    {
      path: 'call-summary',
      component: CallSummary,
      meta: {
        requiresAuth: true,
        adminOnly: true,
        showNav: true,
        navTitle: 'Call Summary',
        hasSubMenu: true,
        scrollView: true,
      },
    },
    {
      path: 'live-calls',
      name: 'LiveCalls',
      component: LiveCalls,
      meta: {
        requiresAuth: true,
        adminOnly: true,
        showNav: true,
        navTitle: 'Live Calls',
        hasSubMenu: true,
        scrollView: false,
      },
    },
    {
      path: 'call-summary/:id',
      name: 'UserCallSummary',
      component: UserCallSummary,
      meta: {
        requiresAuth: true,
        showNav: true,
        navTitle: 'Call Summary',
        hasSubMenu: true,
        scrollView: true,
      },
    },
    {
      path: 'call-details',
      name: 'CallDetails',
      component: CallDetails,
      meta: {
        requiresAuth: true,
        showNav: true,
        navTitle: 'Call details',
        hasSubMenu: true,
        scrollView: true,
      },
    },
    {
      path: 'call-details/:id',
      name: 'CallDetails',
      component: CallDetails,
      meta: {
        requiresAuth: true,
        showNav: true,
        navTitle: 'Call details',
        hasSubMenu: true,
        scrollView: true,
      },
    },
  ],
}

export default [
  {
    path: '/calls',
    ...callsRoutes,
  },
]
