import {
  dateTimeRangeInUTC,
  getUserTimeZoneDate,
  isSame,
  isBefore,
  getLuxonObj,
  dateTimeRange,
  DATE_TIME_FORMAT,
} from '../utils/dateHelpers'

const DATE_RANGES = Object.freeze({
  EVER: {
    text: 'Ever',
    value: 'ever',
    getDateRange: () => 'neginf..inf',
  },
  OVERDUE: {
    text: 'Overdue',
    value: 'overdue',
    getDateRange: (utc = false) =>
      (utc ? dateTimeRangeInUTC : dateTimeRange)(
        null,
        getUserTimeZoneDate(),
        false,
        true
      ),
  },
  FUTURE: {
    text: 'Future',
    value: 'future',
    getDateRange: (utc = false) =>
      (utc ? dateTimeRangeInUTC : dateTimeRange)(
        getUserTimeZoneDate(),
        null,
        false,
        true
      ),
  },
  TODAY: {
    text: 'Today',
    value: 'today',
    getDateRange: (utc = false) =>
      (utc ? dateTimeRangeInUTC : dateTimeRange)(
        getUserTimeZoneDate(),
        getUserTimeZoneDate()
      ),
    chart: {
      granularity: 'day',
      dateRange: () =>
        dateTimeRange(
          getUserTimeZoneDate().minus({ days: 9 }).startOf('day'),
          getUserTimeZoneDate().endOf('day'),
          true
        ),
      increaseLabel: 'vs yesterday',
      chartTitle: () => 'Last 10 days',
      dataPointsCount: 10,
      comparator: isSame('day'),
      chartLabels: () => {
        const labels = Array(10).fill('')
        for (let i = 9; i >= 0; i--) {
          const daysToSubtract = 9 - i
          labels[i] = {
            label: getUserTimeZoneDate()
              .minus({ days: daysToSubtract })
              .startOf('day')
              .toFormat('dd LLL (ccc)'),
            value: getUserTimeZoneDate()
              .minus({ days: daysToSubtract })
              .startOf('day'),
          }
        }
        return labels
      },
    },
  },
  YESTERDAY: {
    text: 'Yesterday',
    value: 'yesterday',
    getDateRange: (utc = false) =>
      (utc ? dateTimeRangeInUTC : dateTimeRange)(
        getUserTimeZoneDate().minus({ days: 1 }),
        getUserTimeZoneDate().minus({ days: 1 })
      ),
    chart: {
      granularity: 'day',
      dateRange: () =>
        dateTimeRange(
          getUserTimeZoneDate().minus({ days: 10 }).startOf('day'),
          getUserTimeZoneDate().minus({ days: 1 }).endOf('day'),
          true
        ),
      increaseLabel: 'vs a day before yesterday',
      chartTitle: (startDate, endDate) =>
        [startDate.toFormat('dd LLL'), endDate.toFormat('dd LLL')].join(' - '),
      dataPointsCount: 10,
      comparator: isSame('day'),
      chartLabels: () => {
        const labels = Array(10).fill('')
        for (let i = 9; i >= 0; i--) {
          const daysToSubtract = 9 - i
          labels[i] = {
            label: getUserTimeZoneDate()
              .minus({ days: daysToSubtract + 1 })
              .startOf('day')
              .toFormat('dd LLL (ccc)'),
            value: getUserTimeZoneDate()
              .minus({ days: daysToSubtract + 1 })
              .startOf('day'),
          }
        }
        return labels
      },
    },
  },
  THIS_WEEK: {
    text: 'This Week',
    value: 'thisWeek',
    getDateRange: (utc = false) =>
      (utc ? dateTimeRangeInUTC : dateTimeRange)(
        getUserTimeZoneDate().startOf('week'),
        getUserTimeZoneDate().endOf('week')
      ),
    chart: {
      granularity: 'week',
      dateRange: () =>
        dateTimeRange(
          getUserTimeZoneDate().minus({ weeks: 9 }).startOf('week'),
          getUserTimeZoneDate().endOf('week'),
          true
        ),
      increaseLabel: 'vs last week',
      chartTitle: () => 'Last 10 weeks',
      dataPointsCount: 10,
      comparator: isSame('week'),
      chartLabels: () => {
        const labels = Array(10).fill('')
        for (let i = 9; i >= 0; i--) {
          const weeksToSubtract = 9 - i
          labels[i] = {
            label: `${getUserTimeZoneDate()
              .minus({ weeks: weeksToSubtract })
              .startOf('week')
              .toFormat('dd LLL')} - ${getUserTimeZoneDate()
              .minus({ weeks: weeksToSubtract })
              .endOf('week')
              .toFormat('dd LLL')}`,
            value: getUserTimeZoneDate()
              .minus({ weeks: weeksToSubtract })
              .startOf('week'),
          }
        }
        return labels
      },
    },
  },
  LAST_WEEK: {
    text: 'Last Week',
    value: 'lastWeek',
    getDateRange: (utc = false) =>
      (utc ? dateTimeRangeInUTC : dateTimeRange)(
        getUserTimeZoneDate().minus({ weeks: 1 }).startOf('week'),
        getUserTimeZoneDate().minus({ weeks: 1 }).endOf('week')
      ),
    chart: {
      granularity: 'week',
      dateRange: () =>
        dateTimeRange(
          getUserTimeZoneDate().minus({ weeks: 10 }).startOf('week'),
          getUserTimeZoneDate().minus({ weeks: 1 }).endOf('week'),
          true
        ),
      increaseLabel: 'vs a week before last week',
      chartTitle: (startDate, endDate) =>
        [startDate.toFormat('dd LLL'), endDate.toFormat('dd LLL')].join(' - '),
      dataPointsCount: 10,
      comparator: isSame('week'),
      chartLabels: () => {
        const labels = Array(10).fill('')
        for (let i = 9; i >= 0; i--) {
          const weeksToSubtract = 9 - i
          labels[i] = {
            label: `${getUserTimeZoneDate()
              .minus({ weeks: weeksToSubtract + 1 })
              .startOf('week')
              .toFormat('dd LLL')} - ${getUserTimeZoneDate()
              .minus({ weeks: weeksToSubtract + 1 })
              .endOf('week')
              .toFormat('dd LLL')}`,
            value: getUserTimeZoneDate()
              .minus({ weeks: weeksToSubtract + 1 })
              .startOf('week'),
          }
        }
        return labels
      },
    },
  },
  THIS_MONTH: {
    text: 'This Month',
    value: 'thisMonth',
    getDateRange: (utc = false) =>
      (utc ? dateTimeRangeInUTC : dateTimeRange)(
        getUserTimeZoneDate().startOf('month').startOf('day'),
        getUserTimeZoneDate().endOf('month').endOf('day')
      ),
    chart: {
      granularity: 'month',
      dateRange: () =>
        dateTimeRange(
          getUserTimeZoneDate().minus({ months: 9 }).startOf('month'),
          getUserTimeZoneDate().endOf('month'),
          true
        ),
      increaseLabel: 'vs last month',
      chartTitle: () => 'Last 10 months',
      dataPointsCount: 10,
      comparator: isSame('month'),
      chartLabels: () => {
        const labels = Array(10).fill('')
        for (let i = 9; i >= 0; i--) {
          const monthsToSubtract = 9 - i
          labels[i] = {
            label: getUserTimeZoneDate()
              .minus({ months: monthsToSubtract })
              .startOf('month')
              .toFormat('LLL'),
            value: getUserTimeZoneDate()
              .minus({ months: monthsToSubtract })
              .startOf('month'),
          }
        }
        return labels
      },
    },
  },
  LAST_MONTH: {
    text: 'Last Month',
    value: 'lastMonth',
    getDateRange: (utc = false) =>
      (utc ? dateTimeRangeInUTC : dateTimeRange)(
        getUserTimeZoneDate().minus({ months: 1 }).startOf('month'),
        getUserTimeZoneDate().minus({ months: 1 }).endOf('month')
      ),
    chart: {
      granularity: 'month',
      dateRange: () =>
        dateTimeRange(
          getUserTimeZoneDate().minus({ months: 10 }).startOf('month'),
          getUserTimeZoneDate().minus({ months: 1 }).endOf('month'),
          true
        ),
      increaseLabel: 'vs a month before last month',
      chartTitle: (startDate, endDate) =>
        [startDate.toFormat('LLL'), endDate.toFormat('LLL')].join(' - '),
      dataPointsCount: 10,
      comparator: isSame('month'),
      chartLabels: () => {
        const labels = Array(10).fill('')
        for (let i = 9; i >= 0; i--) {
          const monthsToSubtract = 9 - i
          labels[i] = {
            label: getUserTimeZoneDate()
              .minus({ months: monthsToSubtract + 1 })
              .startOf('month')
              .toFormat('LLL'),
            value: getUserTimeZoneDate()
              .minus({ months: monthsToSubtract + 1 })
              .startOf('month'),
          }
        }
        return labels
      },
    },
  },
  THIS_YEAR: {
    text: 'This Year',
    value: 'thisYear',
    getDateRange: (utc = false) =>
      (utc ? dateTimeRangeInUTC : dateTimeRange)(
        getUserTimeZoneDate().startOf('year'),
        getUserTimeZoneDate().endOf('year')
      ),
    chart: {
      granularity: 'year',
      dateRange: () =>
        dateTimeRange(
          getUserTimeZoneDate().minus({ years: 4 }).startOf('year'),
          getUserTimeZoneDate().endOf('year'),
          true
        ),
      increaseLabel: 'vs last year',
      chartTitle: () => 'Last 5 years',
      dataPointsCount: 5,
      comparator: isSame('year'),
      chartLabels: () => {
        const labels = Array(5).fill('')
        for (let i = 4; i >= 0; i--) {
          const yearsToSubtract = 4 - i
          labels[i] = {
            label: getUserTimeZoneDate()
              .minus({ years: yearsToSubtract })
              .startOf('year')
              .toFormat('y'),
            value: getUserTimeZoneDate()
              .minus({ years: yearsToSubtract })
              .startOf('year'),
          }
        }
        return labels
      },
    },
  },
  LAST_YEAR: {
    text: 'Last Year',
    value: 'lastYear',
    getDateRange: (utc = false) =>
      (utc ? dateTimeRangeInUTC : dateTimeRange)(
        getUserTimeZoneDate().minus({ years: 1 }).startOf('year'),
        getUserTimeZoneDate().minus({ years: 1 }).endOf('year')
      ),
    chart: {
      granularity: 'year',
      dateRange: () =>
        dateTimeRangeInUTC(
          getUserTimeZoneDate().minus({ years: 5 }).startOf('year'),
          getUserTimeZoneDate().minus({ years: 1 }).endOf('year'),
          true
        ),
      increaseLabel: 'vs a year before last year',
      chartTitle: (startDate, endDate) =>
        [startDate.toFormat('yyyy'), endDate.toFormat('yyyy')].join(' - '),
      dataPointsCount: 5,
      comparator: isSame('year'),
      chartLabels: () => {
        const labels = Array(5).fill('')
        for (let i = 4; i >= 0; i--) {
          const yearsToSubtract = 4 - i
          labels[i] = {
            label: getUserTimeZoneDate()
              .minus({ years: yearsToSubtract + 1 })
              .startOf('year')
              .toFormat('y'),
            value: getUserTimeZoneDate()
              .minus({ years: yearsToSubtract + 1 })
              .startOf('year'),
          }
        }
        return labels
      },
    },
  },
  BEFORE_DATE: {
    text: 'Before...',
    value: 'beforeDate',
    getDateRange: (value, utc = false) => {
      if (!value || typeof value !== 'string') return ''
      const [endYear, endMonth, endDay] = value.split('-')
      return (utc ? dateTimeRangeInUTC : dateTimeRange)(
        null,
        getLuxonObj(`${endYear}-${endMonth}-${endDay} 00:00:00`).minus({
          days: 1,
        })
      )
    },
  },
  AFTER_DATE: {
    text: 'After...',
    value: 'afterDate',
    getDateRange: (value, utc = false) => {
      if (!value || typeof value !== 'string') return ''
      const [startYear, startMonth, startDay] = value.split('-')
      return (utc ? dateTimeRangeInUTC : dateTimeRange)(
        getLuxonObj(`${startYear}-${startMonth}-${startDay} 00:00:00`).plus({
          days: 1,
        }),
        null
      )
    },
  },
  BETWEEN_DATE: {
    text: 'Between...',
    value: 'betweenDate',
    getDateRange: (value, utc = false) => {
      if (value && value.length) {
        let startYear, startMonth, startDay, endYear, endMonth, endDay
        if (value[0]) [startYear, startMonth, startDay] = value[0].split('-')
        if (value[1]) [endYear, endMonth, endDay] = value[1].split('-')
        return (utc ? dateTimeRangeInUTC : dateTimeRange)(
          value[0] &&
            getLuxonObj(`${startYear}-${startMonth}-${startDay} 00:00:00`),
          value[1] && getLuxonObj(`${endYear}-${endMonth}-${endDay} 23:59:59`)
        )
      }
      return ''
    },
  },
  CUSTOM: {
    text: 'Custom',
    value: 'custom',
    getDateRange: (value, utc = false) => {
      if (value && value.length) {
        let startDate = value[0]
        let endDate = value[1]
        let startYear, startMonth, startDay, endYear, endMonth, endDay

        // Swap dates if endate is earlier than start date
        if (
          startDate &&
          endDate &&
          isBefore(getLuxonObj(endDate), getLuxonObj(startDate))
        ) {
          let tempDate = endDate
          endDate = startDate
          startDate = tempDate
        }

        if (startDate) [startYear, startMonth, startDay] = startDate.split('-')
        if (endDate) [endYear, endMonth, endDay] = endDate.split('-')
        return (utc ? dateTimeRangeInUTC : dateTimeRange)(
          startDate &&
            getLuxonObj(`${startYear}-${startMonth}-${startDay} 00:00:00`),
          endDate && getLuxonObj(`${endYear}-${endMonth}-${endDay} 23:59:59`)
        )
      }
      return ''
    },
    chart: {
      granularity: 'day',
      increaseLabel: 'avg change',
      chartTitle: (startDate, endDate) =>
        [startDate.toFormat('yyyy/MM/dd'), endDate.toFormat('yyyy/MM/dd')].join(
          ' - '
        ),
      comparator: isSame('day'),
      chartLabels: (count, range) => {
        if (!range) return []
        const start = range.split('..')[0]
        const labels = Array(count).fill('')
        for (let i = 0; i < count; i++) {
          let labelDateObj = getLuxonObj(start).plus({ days: i })

          if (i === 0) labelDateObj = labelDateObj.startOf('day')
          else if (i === count - 1) labelDateObj = labelDateObj.endOf('day')

          labels[i] = {
            label: labelDateObj.toFormat('dd LLL (ccc)'),
            value: labelDateObj,
          }
        }
        return labels
      },
    },
  },
})

export default DATE_RANGES

export function getDateRangeOpt(value) {
  return Object.values(DATE_RANGES).find((i) => i.value === value)
}

export function getAnalyticsDateRanges() {
  return Object.values(DATE_RANGES).filter(
    (i) =>
      ![
        'ever',
        'overdue',
        'future',
        'today',
        'thisWeek',
        'thisMonth',
        'afterDate',
        'beforeDate',
        'betweenDate',
      ].includes(i.value)
  )
}

export function getActionsDateRanges() {
  return Object.values(DATE_RANGES).filter(
    (i) =>
      ![
        'ever',
        'today',
        'thisWeek',
        'overdue',
        'afterDate',
        'beforeDate',
        'betweenDate',
      ].includes(i.value)
  )
}

export function getPreviousRangeString(rangeObj) {
  if (!rangeObj) return

  const rangeValue = rangeObj.value

  if (rangeValue === 'today') return getDateRangeOpt('yesterday').getDateRange()
  if (rangeValue === 'thisWeek')
    return getDateRangeOpt('lastWeek').getDateRange()
  if (rangeValue === 'thisMonth')
    return getDateRangeOpt('lastMonth').getDateRange()
  if (rangeValue === 'thisYear')
    return getDateRangeOpt('lastYear').getDateRange()

  // For custom ranges and ranges which don't have a similar predecessor period
  const [start, end] = rangeObj
    .getDateRange()
    .split('..')
    .map((d) => getLuxonObj(d))
  const difference = Math.ceil(end.diff(start, 'days').toObject().days)
  const newRange = [
    start.minus({ days: difference }).startOf('day').toFormat(DATE_TIME_FORMAT),
    start.minus({ minutes: 1 }).toFormat(DATE_TIME_FORMAT),
  ]
  return newRange.join('..')
}

export function getDateRangeOptFromDateString(dateString, utc = false) {
  return Object.values(DATE_RANGES).find(
    (i) => i.getDateRange(utc) === dateString
  )
}
