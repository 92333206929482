const DISPOSITION_SENTIMENTS = Object.freeze([
  {
    color: 'orange',
    value: 'neutral',
    tooltip: 'Neutral',
  },
  {
    color: 'green',
    value: 'positive',
    tooltip: 'Positive',
  },
  {
    color: 'red',
    value: 'negative',
    tooltip: 'Negative',
  },
])

export default DISPOSITION_SENTIMENTS

export function getDispositionSentiment(value) {
  if (!value) return
  const sentiment = Object.values(DISPOSITION_SENTIMENTS).find(
    (sentiment) => value === sentiment.value
  )
  return sentiment
}
