import OrgAdminApi from '@/services/api/OrgAdmin'

import { getSentiment } from '@/constants'
import { getFullName } from '@/utils/helpers'
import { cloneDeep } from 'lodash'

const _uniqBy = require('lodash/uniqBy')

// initial state
const state = () => ({
  orgUsers: [],
  selectedOrgUser: null,
  selectedOrgUsers: [],
  fetchingOrgUsers: false,
})

// getters
const getters = {
  orgUsers: (state) =>
    state.orgUsers && state.orgUsers.length > 0
      ? state.orgUsers.map((u) => ({
          ...u,
          full_name: getFullName(u.first_name, u.last_name),
        }))
      : [],
  otherOrgUsers: (state, getters, rootState) =>
    getters.orgUsers.filter((user) => user.id !== rootState.user?.id),
  orgUsersWithPD: (state, getters) =>
    getters.orgUsers.filter((user) => !!user.has_power_dialing),
  otherOrgUsersWithPD: (state, getters) =>
    getters.orgUsersWithPD.filter((user) => !!user.has_power_dialing),
  orgUser: (state, getters) => (id) =>
    getters.orgUsers.find((user) => user.id === id),
  selectedOrgUser: (state) => state.selectedOrgUser,
  selectedOrgUsers: (state) => state.selectedOrgUsers,
  fetchingOrgUsers: (state) => state.fetchingOrgUsers,
  scheduleAsOptions: (state, getters, rootState) => {
    const otherUserMailboxes = state.orgUsers
      .map(
        (user) =>
          user.mailboxes.map((mailbox) => ({
            ...mailbox,
            full_name: user.full_name,
          })) || []
      )
      .flat()
    const currentUserMailbox = rootState.user.mailboxes?.[0]
      ? {
          ...rootState.user.mailboxes?.[0],
          full_name: rootState.user.user?.full_name,
        }
      : null
    if (currentUserMailbox)
      return _uniqBy([currentUserMailbox, ...otherUserMailboxes], 'email')
    return _uniqBy(otherUserMailboxes, 'email')
  },
}

// actions
const actions = {
  async fetchOrgUsers({ commit }) {
    commit('setFetchingOrgUsers', true)
    return new Promise((resolve, reject) => {
      OrgAdminApi.getOrgUsers()
        .then((resp) => {
          commit('setOrgUsers', resp)
          resolve(resp)
        })
        .catch((err) => {
          console.log(err)
          reject(err)
        })
        .finally(() => commit('setFetchingOrgUsers', false))
    })
  },
}

// mutations
const mutations = {
  setOrgUsers(state, users) {
    const transformedUsers = users.map((user) => ({
      ...user,
      full_name: getFullName(user.first_name, user.last_name),
    }))
    state.orgUsers = transformedUsers
  },
  setFetchingOrgUsers(state, val) {
    state.fetchingOrgUsers = val
  },
  removeCallStatsFromUsers(state) {
    state.orgUsers.forEach((user) => {
      delete user.callCount
      delete user.avgRepWordsPerMinute
      delete user.avgRepOverallSentiment
      delete user.avgProspectOverallSentiment
      delete user.sumOfCallSeconds
      delete user.repTalkTimeRatio
    })
  },
  removeEmailStatsFromUsers(state) {
    state.orgUsers.forEach((user) => {
      delete user.emailCount
      delete user.manualEmailsCount
      delete user.autoEmailsCount
      delete user.openedCount
      delete user.clickedCount
      delete user.repliedCount
    })
  },
  addCallStatsToUsers(state, callStats = []) {
    if (state.orgUsers.length == 0 || !callStats) return

    console.log('callStats', callStats)
    let newUsers = state.orgUsers.map((user) => {
      const userCallStats = callStats.find(
        (callStat) => callStat['Users.uuid'] == user.id
      )

      if (userCallStats) {
        user.callCount = userCallStats['Calls.count'] || 0
        user.answerPercentage = userCallStats['Calls.answeredCount']
          ? Math.ceil(
              (userCallStats['Calls.answeredCount'] /
                userCallStats['Calls.count']) *
                100
            )
          : 0
        user.answeredCount = userCallStats['Calls.answeredCount'] || 0
        user.answeredCountV2 =
          (userCallStats['Calls.machineAnsweredCountV2'] || 0) +
          (userCallStats['Calls.humanAnsweredCountV2'] || 0)
        user.machineAnsweredCount =
          userCallStats['Calls.machineAnsweredCount'] || 0
        user.machineAnsweredCountV2 =
          userCallStats['Calls.machineAnsweredCountV2'] || 0
        user.humanAnsweredCountV2 =
          userCallStats['Calls.humanAnsweredCountV2'] || 0
        user.humanAnsweredCount = userCallStats['Calls.humanAnsweredCount'] || 0
        user.avgRepWordsPerMinute = userCallStats['Calls.avgRepWordsPerMinute']
        user.avgRepOverallSentiment = {
          ...getSentiment(userCallStats['Calls.avgRepOverallSentiment']),
          sentimentValue: parseFloat(
            userCallStats['Calls.avgRepOverallSentiment']
          ),
        }
        user.avgProspectOverallSentiment = {
          ...getSentiment(userCallStats['Calls.avgProspectOverallSentiment']),
          sentimentValue: parseFloat(
            userCallStats['Calls.avgProspectOverallSentiment']
          ),
        }
        user.repSentimentValue = parseFloat(
          userCallStats['Calls.avgRepOverallSentiment']
        )
        user.prospectSentimentValue = parseFloat(
          userCallStats['Calls.avgProspectOverallSentiment']
        )
        user.sumOfCallSeconds =
          parseInt(userCallStats['Calls.sumOfCallSeconds']) || 0
        user.ringingTimeSeconds =
          parseInt(userCallStats['Calls.ringingTimeSeconds']) || 0
        user.connectedTimeSeconds =
          parseInt(userCallStats['Calls.connectedTimeSeconds']) || 0

        user.repTalkTimeRatio = userCallStats['Calls.repTalkTimeRatio']
      } else {
        user.callCount = 0
        user.answerPercentage = 0
        user.answeredCount = 0
        user.answeredCountV2 = 0
        user.machineAnsweredCount = 0
        user.machineAnsweredCountV2 = 0
        user.humanAnsweredCount = 0
        user.humanAnsweredCountV2 = 0
        user.avgRepWordsPerMinute = 0
        user.avgRepOverallSentiment = null
        user.avgProspectOverallSentiment = null
        user.repSentimentValue = null
        user.prospectSentimentValue = null
        user.sumOfCallSeconds = 0
        user.ringingTimeSeconds = 0
        user.connectedTimeSeconds = 0
        user.repTalkTimeRatio = null
      }
      return user
    })
    state.orgUsers = cloneDeep(newUsers)
  },
  addEmailStatsToUsers(state, emailStats = []) {
    if (state.orgUsers.length == 0 || !emailStats) return
    let newUsers = state.orgUsers.map((user) => {
      const userEmailStats = emailStats.find(
        (emailStat) => emailStat['Users.uuid'] == user.id
      )

      if (userEmailStats) {
        user.emailCount = parseInt(userEmailStats['Mailings.count'])
        user.manualEmailsCount = parseInt(userEmailStats['manualEmails'])
        user.autoEmailsCount = parseInt(userEmailStats['autoEmails'])
        user.openedCount = parseInt(
          userEmailStats['Mailings.openCountDistinct']
        )
        user.clickedCount = parseInt(
          userEmailStats['Mailings.clickCountDistinct']
        )
        user.repliedCount = parseInt(userEmailStats['Mailings.replyCount'])
      }
      return user
    })
    state.orgUsers = [...newUsers]
  },
  setSelectedOrgUser(state, user) {
    state.selectedOrgUser = user
  },
  setSelectedOrgUsers(state, users) {
    state.selectedOrgUsers = users
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
