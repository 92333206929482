import PhoneApi from '@/services/api/Phone'
import { DateTime } from 'luxon'
import { isPast } from '../../utils/dateHelpers'

// initial state
const state = () => ({
  status: '',
  phones: [],
  symboOwnedNumbers: [],
  expires_at: '',
})

// getters
const getters = {
  phones: (state) => state.phones,
  symboOwnedNumbers: (state) => state.symboOwnedNumbers,
  allPhones: (state) => state.phones.concat(state.symboOwnedNumbers),
  outboundSMSPhones: (state, getters) =>
    getters.allPhones.filter((phone) => phone.outbound_sms_enabled),
  selectedOutboundSMSPhone: (state, getters) => getters.outboundSMSPhones[0], // TODO: Implement selection for multiple outbound phones
  outboundActivatedPhones: (state, getters) =>
    getters.allPhones.filter((phone) => !phone.deactivated),
  ringBackNumber: (state) =>
    state.phones.find((phone) => !!phone.forward_inbound_calls),
  hasPhoneConnection: (state) => !!state.phones?.length,
  loading: (state) => state.status === 'loading',
  expired: (state) => isPast(state.expires_at),
}

// actions
const actions = {
  async fetchPhones({ commit }) {
    commit('request')
    await PhoneApi.fetchPhones()
      .then((resp) => {
        const symbo_numbers = resp.filter((phone) => phone.is_symbo_number)
        const authenticated_numbers = resp.filter(
          (phone) => !phone.is_symbo_number
        )
        commit('set_phones', authenticated_numbers)
        commit('set_symbo_phones', symbo_numbers)
        commit('user/set_phones', resp, { root: true })
        commit('success')
      })
      .catch((err) => {
        commit('error')
        console.log(err)
      })
  },

  disconnectPhone({ commit, state }, phoneId) {
    return new Promise((resolve, reject) => {
      commit('request')
      PhoneApi.delete(phoneId)
        .then(() => {
          const filteredPhones = state.phones.filter(
            (phone) => phone.id !== phoneId
          )
          commit('set_phones', filteredPhones)
          commit('user/set_phones', filteredPhones, { root: true })
          commit('success')
          resolve()
        })
        .catch((err) => {
          commit('error')
          console.log(err)
          reject(err)
        })
    })
  },

  setAsRingbackNumber({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      commit('request')
      PhoneApi.update(payload)
        .then(() => {
          const filteredPhones = state.phones.filter(
            (phone) => phone.id !== payload.phoneId
          )
          commit('set_phones', filteredPhones)
          commit('user/set_phones', filteredPhones, { root: true })
          commit('success')
          resolve()
        })
        .catch((err) => {
          commit('error')
          console.log(err)
          reject(err)
        })
    })
  },

  deactivatePhone({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('request')
      PhoneApi.update(payload)
        .then((res) => {
          commit('set_phone_by_id', res)
          commit('success')
          resolve()
        })
        .catch((err) => {
          commit('error')
          console.log(err)
          reject(err)
        })
    })
  },

  updatePhone({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('request')
      PhoneApi.update(payload)
        .then((res) => {
          commit('set_phone_by_id', res)
          commit('success')
          resolve()
        })
        .catch((err) => {
          commit('error')
          console.log(err)
          reject(err)
        })
    })
  },
}

// mutations
const mutations = {
  set_phones(state, phones) {
    state.phones = phones
    state.expires_at = DateTime.now()
  },

  set_phone_by_id(state, newPhoneObj) {
    const index = state.phones.findIndex((phone) => phone.id === newPhoneObj.id)
    if (index !== -1) state.phones[index] = newPhoneObj
  },

  set_symbo_phones(state, phones) {
    state.symboOwnedNumbers = phones
  },

  request(state) {
    state.status = 'loading'
  },

  success(state) {
    state.status = 'success'
  },

  error(state) {
    state.status = 'error'
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
