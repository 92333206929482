import api from './Api'
import {
  getResourceRequestQuery,
  getTablePaginationParams,
  withAsync,
} from './helpers'

const ENDPOINTS = {
  drives: (id = '') => (id ? `/drives/${id}` : `/drives`),
  driveTemplates: () => `/driveTemplates`,
  batchDrives: (id = '') =>
    id ? `/batch/driveWithSteps/${id}` : `/batch/driveWithSteps`,
  archiveDrive: (id) => `${ENDPOINTS.drives(id)}/actions/archive`,
  unarchiveDrive: (id) => `${ENDPOINTS.drives(id)}/actions/unarchive`,
  undeleteDrive: (id) => `${ENDPOINTS.drives(id)}/actions/undelete`,
  setDraftDrive: (id) => `${ENDPOINTS.drives(id)}/actions/setDraft`,
  editDriveDispositions: (id) => `${ENDPOINTS.drives(id)}/dispositions`,
  opts: {
    showArchived: 'state=archived',
    showDeleted: 'deleted_at=true',
    showDraftEngaged: 'state=draft,engaged',
    showEnabled: 'enabled=1',
    owner: (ids) => `owner=${ids.join(',')}`,
  },
}

const driveStepSort = (a, b) => a.step_order - b.step_order

const defaultResourceRequest = ['driveSteps', 'userSummary']

export default {
  getDrives(options = {}) {
    let path = ENDPOINTS.drives()
    let pathParams = []

    const {
      resourceRequests,
      showArchived,
      showDeleted,
      showEnabled,
      searchByName,
      userIds,
      sortDateRange,
    } = options

    // Include all or only enabled drives
    if (showEnabled) pathParams.push(ENDPOINTS.opts.showEnabled)

    if (sortDateRange) pathParams.push(`sortDateRange=${sortDateRange}`)

    // Determine template states to show
    if (showArchived) pathParams.push(ENDPOINTS.opts.showArchived)
    else if (showDeleted) pathParams.push(ENDPOINTS.opts.showDeleted)
    else pathParams.push(ENDPOINTS.opts.showDraftEngaged)

    // Table search input
    if (searchByName) pathParams.push(`name=${searchByName}`)

    // Pagination
    const pagParams = getTablePaginationParams(options)
    if (pagParams) pathParams.push(pagParams)

    if (userIds?.length) pathParams.push(ENDPOINTS.opts.owner(userIds))

    // Request additional resources
    pathParams.push(
      getResourceRequestQuery(resourceRequests || defaultResourceRequest)
    )

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.get(path, { abortKey: 'GET_DRIVES' })
  },

  async getDriveById(id, options = {}) {
    const { resourceRequests } = options
    let path = ENDPOINTS.drives(id)
    let pathParams = []

    if (resourceRequests) {
      pathParams.push(getResourceRequestQuery(resourceRequests))
    }

    if (pathParams.length) path += '?' + pathParams.join('&')

    const { response, error } = await withAsync(api.get, path, {
      abortKey: 'GET_DRIVE_BY_ID',
    })

    if (response) {
      response.driveSteps.sort(driveStepSort)
      return response
    } else throw error
  },

  getDriveTemplates() {
    return api.get(ENDPOINTS.driveTemplates())
  },

  async postBatchDriveAndSteps(payload) {
    const { response, error } = await withAsync(
      api.post,
      ENDPOINTS.batchDrives(),
      payload
    )

    if (response) {
      response.driveSteps.sort(driveStepSort)
      return response
    } else throw error
  },

  async updateBatchDriveAndSteps(driveId, drive) {
    const { response, error } = await withAsync(
      api.patch,
      ENDPOINTS.batchDrives(driveId),
      drive
    )

    if (response) {
      response.driveSteps.sort(driveStepSort)
      return response
    } else throw error
  },

  deleteDriveById(id) {
    return api.delete(ENDPOINTS.drives(id))
  },

  undeleteDriveById(id) {
    return api.post(ENDPOINTS.undeleteDrive(id))
  },

  archiveDriveById(driveId) {
    return api.post(ENDPOINTS.archiveDrive(driveId))
  },

  unarchiveDriveById(driveId) {
    return api.post(ENDPOINTS.unarchiveDrive(driveId))
  },

  setDraftDriveById(driveId) {
    return api.post(ENDPOINTS.setDraftDrive(driveId))
  },

  editDispositions(driveId, dispositions) {
    return api.patch(ENDPOINTS.editDriveDispositions(driveId), dispositions)
  },
}
