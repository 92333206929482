import VoicemailApi from '@/services/api/VoicemailDrops'
const _unionBy = require('lodash/unionBy')

// initial state
const state = () => ({
  voicemailTemplates: [],
  templateActionLoading: null,
})

// getters
const getters = {
  voicemailTemplates: (state) => state.voicemailTemplates,
  isItemActionLoading: (state) => (id) => state.templateActionLoading === id,
}

// actions
const actions = {
  fetchVoicemailTemplates({ commit, state }, options = {}) {
    return new Promise((resolve, reject) => {
      VoicemailApi.getVoicemailDrops(options)
        .then((resp) => {
          // append new results instead of replace
          if (options.append) {
            const appendedData = _unionBy(
              state.voicemailTemplates,
              resp.data,
              'id'
            )
            commit('setVoicemailTemplates', appendedData)
          } else {
            commit('setVoicemailTemplates', resp.data)
          }
          resolve(resp)
        })
        .catch((err) => {
          // ignore error if the req. was aborted
          if (!err.aborted) {
            reject(err)
          }
        })
    })
  },
  updateVoicemailTemplate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      VoicemailApi.updateVoicemailDrop(payload)
        .then((resp) => {
          commit('setVoicemailTemplate', resp)
          resolve(resp)
        })
        .catch((err) => reject(err))
    })
  },
  createVoicemailTemplate({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      VoicemailApi.postVoicemailDrop(payload)
        .then((resp) => {
          commit('setVoicemailTemplates', [resp, ...state.voicemailTemplates])
          resolve(resp)
        })
        .catch((err) => reject(err))
    })
  },

  deleteVoicemailTemplateById({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit('setActionLoading', id)
      VoicemailApi.deleteVoicemailDrop(id)
        .then((resp) => {
          commit('removeVoicemailTemplate', id)
          resolve(resp)
        })
        .catch((err) => reject(err))
        .finally(() => {
          commit('setActionLoading', null)
        })
    })
  },
}

// mutations
const mutations = {
  setVoicemailTemplates(state, templates) {
    state.voicemailTemplates = templates
  },
  removeVoicemailTemplate(state, id) {
    state.voicemailTemplates = state.voicemailTemplates.filter(
      (item) => item.id !== id
    )
  },
  setVoicemailTemplate(state, newVal) {
    const index = state.voicemailTemplates.findIndex(
      (template) => template.id === newVal.id
    )
    if (index !== -1)
      state.voicemailTemplates = [
        ...state.voicemailTemplates.slice(0, index),
        newVal,
        ...state.voicemailTemplates.slice(index + 1),
      ]
  },
  setActionLoading(state, id) {
    state.templateActionLoading = id
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
